import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

interface GetRiskDegreeWorkstationParams {
	organization_id: string;
	company_id: string;
	file_id: string;
	legs: number;
	force: number;
	repetition: number;
}

async function getRiskDegreeWorkstation(params: GetRiskDegreeWorkstationParams): Promise<void> {
	const { data } = await Api.get('/report/risk_degree/workstation', { params });
	return data;
}

export const useGetRiskDegreeWorkstation = (params: GetRiskDegreeWorkstationParams) => {
	return useQuery(['risk-degree-workstation', params], () => getRiskDegreeWorkstation(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.file_id
	});
};
