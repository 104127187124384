import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { message } from 'antd';

import { QUERY_KEYS } from '@/utils/query-keys';
import { I18n } from '@aws-amplify/core';
import { User } from '@/types';
import Api from '@/services/api';

export type UserDTO = Pick<User, 'id' | 'name' | 'email' | 'role' | 'isActive'>;

export type CreateUserDTO = {
	permissions?: OrganizationCompanyMap;
	email: string;
	name: string;
	role: string;
};

async function createUser(body: CreateUserDTO): Promise<User> {
	const url = '/user_management/user_create';
	const { data } = await Api.post<User>(url, body);
	return data;
}

export const useCreateUser = () => {
	const queryClient = useQueryClient();
	return useMutation<User, AxiosError<Response>, CreateUserDTO>((body: CreateUserDTO) => createUser(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response?.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_USERS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_PERMISSIONS]);
			queryClient.invalidateQueries([QUERY_KEYS.GET_USER_PERMISSIONS]);
			message.success(I18n.get('User created successfully'));
		}
	});
};
