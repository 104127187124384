import React from 'react';
import { Col, Row } from 'antd';

import { ExclamationOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';

import { Tip, WarningContainer } from './styles';
import { useTFAContext } from '../context';

export const RecoveryTokens = () => {
	const { recoveryTokens } = useTFAContext();

	return (
		<Row align="middle" justify="center">
			<Col span={22}>
				<Row justify="center" style={{ marginBottom: '2rem' }}>
					<WarningContainer>
						<ExclamationOutlined style={{ padding: 0, margin: 0 }} />
					</WarningContainer>
				</Row>
				<Row justify="center">
					<Row justify="center">
						<Title align="center" level={5}>
							Recovery tokens
						</Title>
					</Row>
					<Row justify="center" style={{ marginTop: '1rem' }}>
						<Tip style={{ width: '80%' }}>
							Here are your recovery codes, which will be requested should you need to recover your
							account. Keep in mind that they will not be accessible again after this moment, so it's
							crucial that you save them securely.
						</Tip>
					</Row>
					<Row justify="center" style={{ margin: '2rem 0 2.5rem 0', width: '60%' }}>
						{recoveryTokens.map((token) => (
							<Col key={token} span={8}>
								<Title align="center" style={{ fontWeight: '600' }} level={5}>
									{token}
								</Title>
							</Col>
						))}
					</Row>
				</Row>
			</Col>
		</Row>
	);
};
