import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useGetInjuries } from '../hooks';
import { CustomFormItem } from '../styles';
import { CustomCheckboxGroup } from './styles';
import { RequiredLabel } from '../../RequiredLabel';
import { usePersonalisedReportStepKeyContext } from '../context';
import { useApplicationContext } from '@/context/Application';

const { useFormInstance, useWatch } = Form;

export const Injuries: React.FC = () => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const { hasInjury, stepKeyFieldName } = usePersonalisedReportStepKeyContext();

	const fieldName = [...stepKeyFieldName, 'injuries'];
	const injuries_ids = useWatch(fieldName, form);

	const {
		data: injuries,
		isFetching: fetchingInjuries,
		isError: errorGettingInjuries
	} = useGetInjuries({
		is_default: true,
		has_injury: hasInjury,
		company_id: company?.id,
		organization_id: organization?.id
	});

	const options = injuries?.map(({ id, description }) => ({
		value: id,
		label: `${I18n.get(description)}`
	}));

	return (
		<Col xs={24} sm={12} lg={24}>
			<CustomFormItem
				name={fieldName}
				label={<RequiredLabel title="Possible injuries" isRequired={true} />}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the injury')
					}
				]}
			>
				<CustomCheckboxGroup
					options={options}
					value={injuries_ids}
					disabled={errorGettingInjuries || fetchingInjuries}
				/>
			</CustomFormItem>
		</Col>
	);
};
