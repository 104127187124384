import React from 'react';
import { Col, Typography } from 'antd';

interface TitleProps {
	children?: string;
}

export function Title({ children = '' }: TitleProps) {
	return (
		<Col span={12}>
			<Typography.Title level={3}>{children}</Typography.Title>
		</Col>
	);
}
