import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col } from 'antd';

import { WorstGrade } from '../WorstGrade';
import { ScoreBar } from '@/components/ui/ScoreBar';
import { ScoresContainer, BarContainer, Label } from './styles';

const grades = [
	{ title: 'Not identified', color: 'rgba(113, 113, 113, 0.5)' },
	{ title: 'Safe', color: 'rgba(44, 200, 86, 0.5)' },
	{ title: 'Info', color: 'rgba(255, 222, 49, 0.5)' },
	{ title: 'Attention', color: 'rgba(247, 138, 56, 0.5)' },
	{ title: 'Warning', color: 'rgba(231, 65, 80, 0.5)' },
	{ title: 'Danger', color: 'rgba(153, 102, 255, 0.5)' }
];

interface ExposureAverageProps {
	scores: {
		left: Array<number>;
		right: Array<number>;
	};
}

export const ExposureAverage: React.FC<ExposureAverageProps> = ({ scores }) => (
	<Row justify="center">
		<Col span={24}>
			<WorstGrade />
		</Col>
		<Col span={24}>
			<ScoresContainer>
				<BarContainer>
					<Label>{I18n.get('Left')}</Label>
					<ScoreBar divisions={grades} scores={scores?.left} />
				</BarContainer>
				<BarContainer>
					<Label>{I18n.get('Right')}</Label>
					<ScoreBar divisions={grades} scores={scores?.right} />
				</BarContainer>
			</ScoresContainer>
		</Col>
	</Row>
);
