import React from 'react';

import * as S from './styles';
interface CollapseProps {
	title?: string;
	isOpen?: boolean;
	children: JSX.Element;
}

export const Collapse: React.FC<CollapseProps> = ({ title = '', isOpen = false, children }) => {
	return (
		<S.Container defaultActiveKey={[isOpen ? '1' : '0']}>
			<S.Panel header={title} key="1">
				{children}
			</S.Panel>
		</S.Container>
	);
};
