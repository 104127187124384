import React, { useEffect, useState } from 'react';

import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';
import { Row, Col, Form, Input } from 'antd';

import { InputNumber, Button } from 'antd';
import { Title, Paragraph, Text } from '@/components/Typography';
import hooks from '@/components/views/PreliminaryAnalysis/hooks';
import { useApplicationContext } from '@/context/Application';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';

import { SelectOption } from '../_components/SelectOption';
import { InjuriesGroup } from '../_components/InjuriesGroup';
import { CollapseCheck } from '../_components/CollapseCheck';
import { RiskLevelCard } from '../_components/RiskLevelCard';

import { baseFormName, injuriesKeys, stepDescription } from '../contants';

const { useRiskLevel } = hooks;
const { useFormInstance } = Form;

export function InfluenceOfEnvironment() {
	const { organization, company } = useApplicationContext();
	const { file, getLevelRiskFields } = usePreliminaryAnalysis();

	const form = useFormInstance();
	const formName = ['influence_of_environment'];

	const { isLoading, data, isError, ...riskLevel } = useRiskLevel(organization?.id, company?.id, file?.id);

	const [levelRisk, setLevelRisk] = useState({
		step: '',
		consequence_id: '',
		probability_id: '',
		exhibition_id: ''
	});

	useEffect(() => {
		const { step, consequence_id, probability_id, exhibition_id } = levelRisk;

		if (step && consequence_id && probability_id && exhibition_id) {
			const formatStep = Array.isArray(step) ? [...formName, ...step] : [...formName, step];

			const payload = {
				step: formatStep,
				consequence_id,
				probability_id,
				exhibition_id
			};

			riskLevel.mutate(payload, {
				onSuccess: (data) => {
					form.setFields([
						{
							name: [baseFormName, ...formatStep, 'result'],
							value: data?.risk_level
						}
					]);
				}
			});
		}
	}, [levelRisk.step, levelRisk.consequence_id, levelRisk.probability_id, levelRisk.exhibition_id]);

	function updateLevelRisk(step, attribute, value) {
		const stepName = Array.isArray(step) ? [...step] : [step];
		const { consequence, exhibition, probability } = getLevelRiskFields(form, baseFormName, formName, stepName);
		if (!levelRisk.step && !levelRisk.consequence_id && !levelRisk.exhibition_id && !levelRisk.probability_id) {
			const options = ['consequence_id', 'exhibition_id', 'probability_id'];
			const forms = options
				.filter((item) => item !== attribute)
				.map((value) => ({
					name: [baseFormName, ...formName, step, value],
					value: undefined
				}));

			form.setFields(forms);
		}

		setLevelRisk({
			exhibition_id: exhibition,
			consequence_id: consequence,
			probability_id: probability,
			step,
			[attribute]: value
		});
	}

	const {
		fatigue_complaints,
		absenteeism,
		musculoskeletal_complaints,
		complaints_of_irritability,
		nonconforming,
		unexcused_absences,
		nonconforming_products,
		others
	} = injuriesKeys;

	function onResetFields(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'consequence_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'probability_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'exhibition_id'],
				value: undefined
			},

			{
				name: [baseFormName, ...formName, step, 'injuries'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'result'],
				value: undefined
			}
		]);
	}

	function setCheckedCollapse(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'checked'],
				value: true
			}
		]);
	}

	const onDelete = (key, remove, array) => {
		const [value] = array.filter(({ fieldKey }) => fieldKey === key).map(({ name: index }) => ({ index }));
		remove(value.index);
	};

	return (
		<Row gutter={[0, 10]}>
			<Col span={16} offset={2}>
				<Title level={4}>Influence of workspace and task factors</Title>
			</Col>
			<Col span={16} offset={2}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col span={16} offset={2}>
				<Row gutter={[10, 10]}>
					{/* inadequate_workspace */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'inadequate_workspace', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('inadequate_workspace');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Workspace is inadequate and forces an uncomfortable posture or limited movement">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'inadequate_workspace', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'inadequate_workspace',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('inadequate_workspace')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													nonconforming,
													unexcused_absences,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'inadequate_workspace',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'inadequate_workspace',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('inadequate_workspace');
															updateLevelRisk(
																'inadequate_workspace',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'inadequate_workspace',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'inadequate_workspace',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('inadequate_workspace');
															updateLevelRisk(
																'inadequate_workspace',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'inadequate_workspace',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'inadequate_workspace',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('inadequate_workspace');
															updateLevelRisk(
																'inadequate_workspace',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item name={[baseFormName, ...formName, 'inadequate_workspace', 'result']}>
											<RiskLevelCard
												key={[...formName, 'inadequate_workspace']}
												step={[...formName, 'inadequate_workspace']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* workstation_requires_excessive_movement */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'workstation_requires_excessive_movement', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('workstation_requires_excessive_movement');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Workstation layout requires excessive movement or uncomfortable postures">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'workstation_requires_excessive_movement',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'workstation_requires_excessive_movement',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('workstation_requires_excessive_movement')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													nonconforming,
													unexcused_absences,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'workstation_requires_excessive_movement',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'workstation_requires_excessive_movement',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse(
																'workstation_requires_excessive_movement'
															);
															updateLevelRisk(
																'workstation_requires_excessive_movement',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'workstation_requires_excessive_movement',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'workstation_requires_excessive_movement',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse(
																'workstation_requires_excessive_movement'
															);
															updateLevelRisk(
																'workstation_requires_excessive_movement',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'workstation_requires_excessive_movement',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'workstation_requires_excessive_movement',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse(
																'workstation_requires_excessive_movement'
															);
															updateLevelRisk(
																'workstation_requires_excessive_movement',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'workstation_requires_excessive_movement',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'workstation_requires_excessive_movement']}
												step={[...formName, 'workstation_requires_excessive_movement']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* distances_and_dimensions_inadequate - Anthropometry */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'distances_and_dimensions_inadequate', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('distances_and_dimensions_inadequate');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="The heights, distances and dimensions of controls or work surfaces are inadequate">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'distances_and_dimensions_inadequate',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'distances_and_dimensions_inadequate',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('distances_and_dimensions_inadequate')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													nonconforming_products,
													unexcused_absences,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'distances_and_dimensions_inadequate',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'distances_and_dimensions_inadequate',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('distances_and_dimensions_inadequate');
															updateLevelRisk(
																'distances_and_dimensions_inadequate',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'distances_and_dimensions_inadequate',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'distances_and_dimensions_inadequate',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('distances_and_dimensions_inadequate');
															updateLevelRisk(
																'distances_and_dimensions_inadequate',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'distances_and_dimensions_inadequate',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'distances_and_dimensions_inadequate',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('distances_and_dimensions_inadequate');
															updateLevelRisk(
																'distances_and_dimensions_inadequate',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={24} style={{ paddingTop: '20px' }}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>{I18n.get('Anthropometry Table x Furniture (cm)')}</Text>
											</Col>
											<Col span={24}>
												<Form.List
													name={[
														baseFormName,
														...formName,
														'distances_and_dimensions_inadequate',
														'anthropometries'
													]}
												>
													{(fields, { add, remove }) => (
														<>
															{fields.map(
																(
																	{ key, name, fieldKey, ...restField },
																	index,
																	array
																) => (
																	<Row
																		key={key}
																		gutter={[10, 0]}
																		align="middle"
																		style={{
																			alignItems: 'center'
																		}}
																	>
																		<Col span={6}>
																			<Form.Item
																				{...restField}
																				name={[name, 'location']}
																				fieldKey={[fieldKey, 'location']}
																				label={I18n.get('Local')}
																				rules={[
																					() => ({
																						validator(_, value) {
																							return !value
																								? Promise.reject(
																										I18n.get(
																											'Enter a location'
																										)
																								  )
																								: Promise.resolve();
																						}
																					})
																				]}
																			>
																				<Input />
																			</Form.Item>
																		</Col>
																		<Col span={6}>
																			<Form.Item
																				{...restField}
																				name={[name, 'found']}
																				fieldKey={[fieldKey, 'found']}
																				label={I18n.get('Found (cm)')}
																				rules={[
																					() => ({
																						validator(_, value) {
																							return !value
																								? Promise.reject(
																										I18n.get(
																											'Enter what was found'
																										)
																								  )
																								: Promise.resolve();
																						}
																					})
																				]}
																			>
																				<InputNumber
																					min={1}
																					stringMode
																					step="0.1"
																					style={{
																						width: '100%'
																					}}
																				/>
																			</Form.Item>
																		</Col>
																		<Col span={5}>
																			<Form.Item
																				{...restField}
																				name={[name, 'foreseen']}
																				fieldKey={[fieldKey, 'foreseen']}
																				label={I18n.get('Foreseen (cm)')}
																				rules={[
																					() => ({
																						validator(_, value) {
																							return !value
																								? Promise.reject(
																										I18n.get(
																											'Insert predicted'
																										)
																								  )
																								: Promise.resolve();
																						}
																					})
																				]}
																			>
																				<InputNumber
																					min={1}
																					stringMode
																					step="0.1"
																					style={{
																						width: '100%'
																					}}
																				/>
																			</Form.Item>
																		</Col>
																		<Col
																			span={2}
																			style={{
																				textAlign: 'center'
																			}}
																		>
																			<Button
																				type="link"
																				danger
																				onClick={() =>
																					onDelete(key, remove, array)
																				}
																			>
																				<Icons.DeleteOutlined />
																			</Button>
																		</Col>
																	</Row>
																)
															)}
															<Row>
																<Col>
																	<Button
																		type="primary"
																		icon={<Icons.PlusOutlined />}
																		disabled={!!(fields.length > 3)}
																		onClick={() => add()}
																	>
																		{I18n.get('Add information')}
																	</Button>
																</Col>
															</Row>
														</>
													)}
												</Form.List>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'distances_and_dimensions_inadequate',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'distances_and_dimensions_inadequate']}
												step={[...formName, 'distances_and_dimensions_inadequate']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* moving_objects_above_the_shoulder - exists tool (REBA) */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'moving_objects_above_the_shoulder', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('moving_objects_above_the_shoulder');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs handling of work objects above the shoulder or below the knee">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'moving_objects_above_the_shoulder',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'moving_objects_above_the_shoulder',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('moving_objects_above_the_shoulder')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													nonconforming_products,
													unexcused_absences,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'moving_objects_above_the_shoulder',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'moving_objects_above_the_shoulder',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('moving_objects_above_the_shoulder');
															updateLevelRisk(
																'moving_objects_above_the_shoulder',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'moving_objects_above_the_shoulder',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'moving_objects_above_the_shoulder',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('moving_objects_above_the_shoulder');
															updateLevelRisk(
																'moving_objects_above_the_shoulder',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'moving_objects_above_the_shoulder',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'moving_objects_above_the_shoulder',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('moving_objects_above_the_shoulder');
															updateLevelRisk(
																'moving_objects_above_the_shoulder',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'moving_objects_above_the_shoulder',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'moving_objects_above_the_shoulder']}
												step={[...formName, 'moving_objects_above_the_shoulder']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* forces_the_worker_same_posture - exists tool (REBA)*/}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'forces_the_worker_same_posture', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('forces_the_worker_same_posture');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="The workspace forces the worker to maintain the same working posture">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'forces_the_worker_same_posture',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'forces_the_worker_same_posture',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('forces_the_worker_same_posture')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													nonconforming_products,
													unexcused_absences,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'forces_the_worker_same_posture',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'forces_the_worker_same_posture',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('forces_the_worker_same_posture');
															updateLevelRisk(
																'forces_the_worker_same_posture',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'forces_the_worker_same_posture',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'forces_the_worker_same_posture',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('forces_the_worker_same_posture');
															updateLevelRisk(
																'forces_the_worker_same_posture',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'forces_the_worker_same_posture',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'forces_the_worker_same_posture',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('forces_the_worker_same_posture');
															updateLevelRisk(
																'forces_the_worker_same_posture',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'forces_the_worker_same_posture',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'forces_the_worker_same_posture']}
												step={[...formName, 'forces_the_worker_same_posture']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* hard_to_handle_work_objects - exists tool (REBA)*/}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'hard_to_handle_work_objects', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('hard_to_handle_work_objects');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Handles work objects that are difficult to handle and slippery">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'hard_to_handle_work_objects',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'hard_to_handle_work_objects',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('hard_to_handle_work_objects')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													nonconforming_products,
													unexcused_absences,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'hard_to_handle_work_objects',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'hard_to_handle_work_objects',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('hard_to_handle_work_objects');
															updateLevelRisk(
																'hard_to_handle_work_objects',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'hard_to_handle_work_objects',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'hard_to_handle_work_objects',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('hard_to_handle_work_objects');
															updateLevelRisk(
																'hard_to_handle_work_objects',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'hard_to_handle_work_objects',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'hard_to_handle_work_objects',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('hard_to_handle_work_objects');
															updateLevelRisk(
																'hard_to_handle_work_objects',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'hard_to_handle_work_objects', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'hard_to_handle_work_objects']}
												step={[...formName, 'hard_to_handle_work_objects']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* high_voltage_contact_or_pressure_on_body - exists tool (REBA)*/}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'high_voltage_contact_or_pressure_on_body', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('high_voltage_contact_or_pressure_on_body');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Activity requires high-voltage contact or local pressure acting on the body">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'high_voltage_contact_or_pressure_on_body',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'high_voltage_contact_or_pressure_on_body',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('high_voltage_contact_or_pressure_on_body')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													nonconforming_products,
													unexcused_absences,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'high_voltage_contact_or_pressure_on_body',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'high_voltage_contact_or_pressure_on_body',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse(
																'high_voltage_contact_or_pressure_on_body'
															);
															updateLevelRisk(
																'high_voltage_contact_or_pressure_on_body',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'high_voltage_contact_or_pressure_on_body',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'high_voltage_contact_or_pressure_on_body',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse(
																'high_voltage_contact_or_pressure_on_body'
															);
															updateLevelRisk(
																'high_voltage_contact_or_pressure_on_body',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'high_voltage_contact_or_pressure_on_body',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'high_voltage_contact_or_pressure_on_body',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse(
																'high_voltage_contact_or_pressure_on_body'
															);
															updateLevelRisk(
																'high_voltage_contact_or_pressure_on_body',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'high_voltage_contact_or_pressure_on_body',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'high_voltage_contact_or_pressure_on_body']}
												step={[...formName, 'high_voltage_contact_or_pressure_on_body']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
