import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Skeleton, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import { useGetCompaniesWithOrganizations } from '@/hooks/useUser/useGetCompaniesWithOrganizations';
import { useGetOnlyUserPermissions } from '@/hooks/useUser/useGetOnlyUserPermissions';
import { useCreateUser } from '@/hooks/useUser/useCreateUser';
import { useUpdateUserPermission } from '@/hooks';
import { FormInstance } from 'antd/es/form/Form';
import { Text } from '@/components/Typography';
import { BaseFormValues } from '../Form';
import { User } from '@/types';
import * as S from './styles';

interface ConfirmationProps {
	onConfirmModalFinish: () => void;
	formData: BaseFormValues;
	isCreating?: boolean;
	onReturn: () => void;
	form: FormInstance;
	user?: User;
}

export function Confirmation({
	onConfirmModalFinish,
	isCreating = false,
	onReturn,
	formData,
	user
}: ConfirmationProps) {
	const [removedPermissions, setRemovedPermissions] = useState<string[]>([]);
	const [addedPermissions, setAddedPermissions] = useState<string[]>([]);

	const { mutateAsync: createMutate } = useCreateUser();
	const { mutateAsync: updateMutate } = useUpdateUserPermission();

	const { isFetching: isLoadingUserPermissions, data: userPermissions } = useGetOnlyUserPermissions({
		user_id: user ? user.id : undefined
	});
	let { isFetching: isLoadingRemovedCompanies, data: removedInfo } = useGetCompaniesWithOrganizations({
		companies_ids: removedPermissions
	});
	const { isFetching: isLoadingAddedCompanies, data: addedInfo } = useGetCompaniesWithOrganizations({
		companies_ids: addedPermissions
	});

	useEffect(() => {
		if (isCreating) {
			Object.keys(formData.permissions ?? []).forEach((organization_id) => {
				const clear = formData.permissions?.[organization_id]?.filter((company_id: string) => !!company_id);
				if (clear) {
					clear.forEach((company_id: string) => {
						if (!addedPermissions.includes(company_id)) {
							setAddedPermissions((oldPermissions) => [company_id, ...oldPermissions]);
						}
					});
				}
			});
		} else if (userPermissions && !isLoadingUserPermissions) {
			Object.keys(userPermissions).forEach((organization_id) => {
				if (formData.permissions?.[organization_id]) {
					userPermissions[organization_id].forEach((company_id: string) => {
						if (
							!formData.permissions?.[organization_id].includes(company_id) &&
							!removedPermissions.includes(company_id)
						) {
							setRemovedPermissions((oldPermissions) => [company_id, ...oldPermissions]);
						}
					});
				}
			});

			Object.keys(formData.permissions ?? []).forEach((organization_id2) => {
				if (formData.permissions?.[organization_id2] && userPermissions?.[organization_id2]) {
					formData.permissions?.[organization_id2].forEach((company_id: string) => {
						if (
							!userPermissions[organization_id2].includes(company_id) &&
							!addedPermissions.includes(company_id)
						) {
							setAddedPermissions((oldPermissions) => [company_id, ...oldPermissions]);
						}
					});
				} else {
					const clear = formData.permissions?.[organization_id2]?.filter(
						(company_id: string) => !!company_id
					);
					if (clear) {
						clear.forEach((id: string) => {
							if (!addedPermissions.includes(id)) {
								setAddedPermissions((oldPermissions) => [id, ...oldPermissions]);
							}
						});
					}
				}
			});
		}
	}, [userPermissions, formData, addedPermissions, isCreating, isLoadingUserPermissions, removedPermissions]);

	function renderIntro() {
		if (isCreating) {
			return (
				<Text style={{ marginBottom: '15px' }}>
					{I18n.get('You are creating user')}{' '}
					<Text strong>
						{formData.name} ({formData.email}){' '}
					</Text>
					{I18n.get('with permission level')} <Text strong>{formData.role.toLocaleLowerCase()}</Text>.
				</Text>
			);
		} else if (formData.role !== user?.role) {
			return (
				<Text style={{ marginBottom: '15px' }}>
					{I18n.get('You are changing')}{' '}
					<Text strong>
						{formData.name} ({formData.email}){' '}
					</Text>{' '}
					{I18n.get("permission's level to")} <Text strong>{formData.role.toLocaleLowerCase()}</Text>.
				</Text>
			);
		}
	}

	function renderAddedPermissions() {
		if (addedInfo && addedInfo?.length) {
			return (
				<>
					<Row>
						<Text>
							<Text strong>
								{formData.name} ({formData.email}){' '}
							</Text>{' '}
							{I18n.get('is receiving permission level')}{' '}
							<Text strong>{formData.role.toLocaleLowerCase()}</Text>{' '}
							{I18n.get('in the following companies and industrial sites:')}
						</Text>
					</Row>
					<Row style={{ width: '100%' }}>
						<S.ConfirmColumn>
							{addedInfo.map(({ company, organization }) => {
								return (
									<Row
										key={company.id}
										style={{
											border: ' 2px solid #e6e6e6',
											borderRadius: '10px',
											marginBottom: '5px'
										}}
									>
										<Col
											sm={11}
											style={{
												borderRight: ' 1px solid #e6e6e6',
												padding: '5px 0 0 10px'
											}}
										>
											<Text ellipsis>{organization.name}</Text>
										</Col>
										<Col
											sm={11}
											style={{ borderRight: ' 1px solid #e6e6e6', padding: '5px 0 0 10px' }}
										>
											<Text ellipsis>{company.name}</Text>
										</Col>
										<Col sm={2}>
											<Button
												value={`${organization.id}:${company.id}`}
												icon={<DeleteOutlined />}
												style={{
													width: '100%',
													borderRadius: '0 9px 9px 0',
													border: '0',
													borderLeft: '1px solid #e6e6e6',
													color: '#E74150'
												}}
												onClick={handleAddedPermissionsCancel}
											/>
										</Col>
									</Row>
								);
							})}
						</S.ConfirmColumn>
					</Row>
				</>
			);
		}
	}

	function renderRemovedPermissions() {
		if (removedInfo && removedInfo.length) {
			return (
				<>
					<Row>
						<Text>
							{I18n.get('You are removing the permission level')}{' '}
							<Text strong>{formData.role.toLocaleLowerCase()}</Text> {I18n.get('from')}{' '}
							<Text strong>
								{formData.name} ({formData.email}){' '}
							</Text>{' '}
							{I18n.get('in the following companies and industrial sites')}
						</Text>
					</Row>
					<Row style={{ width: '100%' }}>
						<S.ConfirmColumn>
							{removedInfo.map(({ company, organization }) => {
								return (
									<Row
										key={company.id}
										style={{
											border: ' 2px solid #e6e6e6',
											borderRadius: '10px',
											marginBottom: '5px'
										}}
									>
										<Col
											sm={11}
											style={{
												borderRight: ' 1px solid #e6e6e6',
												padding: '5px 0 0 10px'
											}}
										>
											<Text ellipsis>{organization.name}</Text>
										</Col>
										<Col
											sm={11}
											style={{ borderRight: ' 1px solid #e6e6e6', padding: '5px 0 0 10px' }}
										>
											<Text ellipsis>{company.name}</Text>
										</Col>
										<Col sm={2}>
											<Button
												value={`${organization.id}:${company.id}`}
												icon={<DeleteOutlined />}
												style={{
													width: '100%',
													borderRadius: '0 9px 9px 0',
													border: '0',
													borderLeft: '1px solid #e6e6e6',
													color: '#E74150'
												}}
												onClick={handleRemovedPermissionCancel}
											/>
										</Col>
									</Row>
								);
							})}
						</S.ConfirmColumn>
					</Row>
				</>
			);
		}
	}

	function renderNoChanges() {
		if (
			(!removedInfo || !removedInfo.length) &&
			(!addedInfo || !addedInfo.length) &&
			user?.role === formData.role
		) {
			return (
				<Row style={{ marginBottom: '15px', marginLeft: '5px' }}>
					<Text>You need to make at least one change.</Text>
				</Row>
			);
		}
	}

	function handleRemovedPermissionCancel(event: React.MouseEvent<HTMLButtonElement>) {
		const name = event.currentTarget.value;
		const organization_id = name.split(':')[0];
		const company_id = name.split(':')[1];

		formData.permissions?.[organization_id].push(company_id);

		const newPermissions = removedPermissions.filter((id) => id !== company_id);
		setRemovedPermissions(newPermissions);

		if (!removedInfo && !addedInfo && formData.role === user?.role) {
			handleOnReturn();
		}
	}

	function handleAddedPermissionsCancel(event: React.MouseEvent<HTMLButtonElement>) {
		const name = event.currentTarget.value;
		const organization_id = name.split(':')[0];
		const company_id = name.split(':')[1];

		const index = formData.permissions?.[organization_id].indexOf(company_id);
		formData.permissions?.[organization_id].splice(index ?? 0, 1);

		const newPermissions = addedPermissions.filter((id) => id !== company_id);
		setAddedPermissions(newPermissions);

		if (!removedPermissions.length && !newPermissions.length && formData.role === user?.role) {
			handleOnReturn();
		}
	}

	function handleOnReturn(): void {
		onReturn();
		setRemovedPermissions([]);
		setAddedPermissions([]);
	}

	function handleOnFinish(): void {
		if (
			(!removedInfo || !removedInfo.length) &&
			(!addedInfo || !addedInfo.length) &&
			user?.role === formData.role
		) {
			message.error(I18n.get('You need to make at least one change.'));
		} else {
			if (isCreating) {
				createMutate({ ...formData });
				setRemovedPermissions([]);
				setAddedPermissions([]);
			} else {
				updateMutate({ ...formData, user_id: user?.id });
				setRemovedPermissions([]);
				setAddedPermissions([]);
			}
			onConfirmModalFinish();
		}
	}

	if (isLoadingUserPermissions || isLoadingAddedCompanies || isLoadingRemovedCompanies) {
		return <Skeleton />;
	}

	return (
		<Row>
			<Col span={24}>
				<Row gutter={[10, 0]}>{renderIntro()}</Row>
				<Row gutter={[10, 0]}>{renderAddedPermissions()}</Row>
				<Row gutter={[10, 0]}>{renderRemovedPermissions()}</Row>
				<Row gutter={[10, 0]}>{renderNoChanges()}</Row>
			</Col>
			<Col span={24}>
				<Row justify="center" gutter={[10, 0]}>
					<Col>
						<Button onClick={handleOnReturn} style={{ borderRadius: '8px' }}>
							{I18n.get('Return')}
						</Button>
					</Col>
					<Col>
						<Button type="primary" onClick={handleOnFinish} style={{ borderRadius: '8px' }}>
							{I18n.get('Confirm')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
