import React, { useState } from 'react';
import { Col, Form, Row, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import {
	useCreateReport,
	useUpdateReportBasicInformation,
	useUpdateReportInputs,
	useUpdateSelectedTask
} from '../hooks';
import { UpdateReportInputs, UpdateSelectedTask } from '../hooks/types/request';
import { LibertyMutualReportDTO, TaskName } from '../hooks/types/response';
import { useApplicationContext } from '@/context/Application';
import { CreateOrUpdate, StepToMethodMapper } from '../context/types';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { useLibertyMutualContext } from '../context';
import { StepsNavigation } from './StepsNavigation';
import { CustomFormContainer } from './styles';
import { Header } from './Header';
import { Footer } from './Footer';

const { useFormInstance } = Form;

export const Report = () => {
	const { organization, company } = useApplicationContext();
	const { validateFields, getFieldValue } = useFormInstance();
	const { steps, gettingReport, libertyMutual, file_id, handleUpdateReport } = useLibertyMutualContext();

	const { mutateAsync: createReport, isLoading: creatingReport } = useCreateReport();
	const { mutateAsync: updateTask, isLoading: updatingTask } = useUpdateSelectedTask();
	const { mutateAsync: updateInputs, isLoading: updatingInputs } = useUpdateReportInputs();
	const { mutateAsync: updateBasicInfo, isLoading: updatingBasicInfo } = useUpdateReportBasicInformation();

	const [currentStep, setCurrentStep] = useState<number>(libertyMutual.step);

	async function handleStepChange(step: number) {
		if (step < currentStep) {
			setCurrentStep(step);
			return;
		}
		await createOrUpdate(step);
	}

	async function createOrUpdate(step: number) {
		try {
			const values = await validateFields();
			if (values) {
				const body: CreateOrUpdate = {
					...values,
					collection_date: getFieldValue(['collection_date']) ?? moment(),
					file_id,
					company_id: company?.id,
					report_id: libertyMutual?.id,
					organization_id: organization?.id,
					task_name: getFieldValue(['task_name']),
					system_of_units_id: getFieldValue(['system_of_units_id'])
				};

				const stepToMethodMapper: StepToMethodMapper = {
					0: (body) => createOrUpdateBasicInfo(body),
					1: (body) => {
						if (isUpdateSelectedTask(body)) {
							return updateTask(body);
						}
					},
					2: (body) => {
						if (isUpdateInputs(body)) {
							return updateInputs(body);
						}
					}
				};

				const report = await stepToMethodMapper[currentStep](body);
				if (report?.id) {
					handleUpdateReport(report);
					setCurrentStep(step);
				}
			}
		} catch (error: any) {
			showErrorMessage(error);
		}
	}

	function showErrorMessage(error: any) {
		let message = "Some required steps wasn't filled";
		const liberty_mutual_inputs = error?.values?.liberty_mutual_inputs;
		if (libertyMutual.task.name === TaskName.lift) {
			if (isStartHigherThanEnd(liberty_mutual_inputs)) {
				message =
					'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift';
			}
		} else if (libertyMutual.task.name === TaskName.lower) {
			if (isEndHigherThanStart(liberty_mutual_inputs)) {
				message =
					'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower';
			}
		}

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get(message),
			duration: 6
		});
	}

	function isStartHigherThanEnd(liberty_mutual_inputs: any) {
		return liberty_mutual_inputs?.start_hand_height > liberty_mutual_inputs?.end_hand_height;
	}

	function isEndHigherThanStart(liberty_mutual_inputs: any) {
		return liberty_mutual_inputs?.start_hand_height < liberty_mutual_inputs?.end_hand_height;
	}

	function createOrUpdateBasicInfo(body: any): Promise<LibertyMutualReportDTO> {
		return libertyMutual?.id ? updateBasicInfo(body) : createReport(body);
	}

	function isUpdateSelectedTask(body: CreateOrUpdate): body is UpdateSelectedTask {
		return (body as UpdateSelectedTask).task_id !== undefined;
	}

	function isUpdateInputs(body: CreateOrUpdate): body is UpdateReportInputs {
		return (body as UpdateReportInputs).liberty_mutual_inputs !== undefined;
	}

	const loading = creatingReport || updatingTask || updatingInputs || updatingBasicInfo;

	if (loading) {
		return <LoadingSkeleton loading={loading} />;
	}

	return (
		<Row justify="center">
			<Col xs={24} xxl={20}>
				<Row justify="center">
					<Col xs={6} lg={4} xxl={3}>
						<StepsNavigation currentStep={currentStep} onStepChange={handleStepChange} />
					</Col>
					<Col xs={18}>
						<Row justify="start" gutter={[16, 24]}>
							<Col xs={24}>
								<Header />
							</Col>
							<CustomFormContainer xs={24}>
								{!gettingReport && steps[currentStep]?.component}
								<Footer currentStep={currentStep} onStepChange={handleStepChange} />
							</CustomFormContainer>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
