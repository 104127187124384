import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { FilterForm } from './Form';
import { Collapse } from './Collapse';
import { useUserManagementContext } from '../../context';
import type { UserStatus, UserRole } from '@/types/User';

interface FormData {
	name?: string;
	email?: string;
	role?: UserRole;
	status?: UserStatus;
}

const { useForm } = Form;

export function Filter() {
	const [form] = useForm<FormData>();
	const { handleUpdateFilter, handleResetFilter } = useUserManagementContext();

	async function handleOnFinish(): Promise<void> {
		const values = await form.validateFields();
		handleUpdateFilter(values);
	}

	function handleOnClear(): void {
		handleResetFilter();
		form.resetFields();
	}

	return (
		<Collapse title={I18n.get('Filter')}>
			<FilterForm form={form} onFinish={handleOnFinish} onClear={handleOnClear} />
		</Collapse>
	);
}
