import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';
import { customReportService } from '../../../../hooks/services';

export type PersonalisedReportDownloadPDFResponse = {
	url: string;
};

export type PersonalisedReportResultDownloadPDFDTO = BaseContext & {
	locale: string;
	file_id?: string;
	report_id?: string;
	custom_report_result_id?: string;
};

async function downloadPDF<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result/pdf';
	const { data } = await Api.post<T>(url, payload);
	return data;
}

export function useDownloadPDF() {
	return useMutation<
		PersonalisedReportDownloadPDFResponse,
		AxiosError<Response>,
		PersonalisedReportResultDownloadPDFDTO
	>((body) => downloadPDF<PersonalisedReportDownloadPDFResponse, PersonalisedReportResultDownloadPDFDTO>(body), {
		onError: (err) => {
			const title = 'Oops! Something happened.';
			const message = err.response?.data?.message ?? 'Failed to update information';
			customReportService.showErrorMessage(title, message);
		},
		onSuccess: async (data) => {
			const message = 'Report downloaded';
			const description = 'Analysis downloaded successfully';
			customReportService.showSuccessMessage(message, description);
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data.url;
			archive.click();
			archive.remove();
		}
	});
}
