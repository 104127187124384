const DEFAULT_BUCKET =
	'https://kinebot-statics.s3.amazonaws.com/reports-image/kim-push-pulll/';

export const VehiclesImages = {
	Crane: DEFAULT_BUCKET + 'crane.png',
	Conveyor: DEFAULT_BUCKET + 'conveyor.png',
	Barrow1: DEFAULT_BUCKET + 'barrow1.png',
	Barrow2: DEFAULT_BUCKET + 'barrow2.png',
	Barrow3: DEFAULT_BUCKET + 'barrow3.png',
	Wheel1: DEFAULT_BUCKET + 'wheel1.png',
	Wheel2: DEFAULT_BUCKET + 'wheel2.png',
	Wheel3: DEFAULT_BUCKET + 'wheel3.png',
	Wheel4: DEFAULT_BUCKET + 'wheel4.png',
	Wheel5: DEFAULT_BUCKET + 'wheel5.png',
	Wheel6: DEFAULT_BUCKET + 'wheel6.png',
	Wheel7: DEFAULT_BUCKET + 'wheel7.png',
	Wheel8: DEFAULT_BUCKET + 'wheel8.png',
	Carriage1: DEFAULT_BUCKET + 'carriage1.png',
	Carriage2: DEFAULT_BUCKET + 'carriage2.png',
	Carriage3: DEFAULT_BUCKET + 'carriage3.png',
	Carriage4: DEFAULT_BUCKET + 'carriage4.png',
	Carriage5: DEFAULT_BUCKET + 'carriage5.png',
	Carriage6: DEFAULT_BUCKET + 'carriage6.png'
};
