import React from 'react';
import { Col, Form, Image, InputNumber, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import lower_start_hand_height from '@/assets/svg/ergonomic_tools/liberty_mutual/lower/start_hand_height.svg';
import lift_start_hand_height from '@/assets/svg/ergonomic_tools/liberty_mutual/lift/start_hand_height.svg';

import { useLibertyMutualContext } from '../../../context';
import { CustomLabel, CustomTooltip } from './CustomLabel';
import { TaskName } from '../../../hooks/types/response';
import { SystemOfUnits } from '../../../context/types';

export const StartHandHeight = () => {
	const { libertyMutual, decimalSeparator } = useLibertyMutualContext();

	const systemOfUnitsToSymbolMapper: SystemOfUnits = {
		metric: 'cm',
		imperial: 'in'
	};

	const labelTitle = `${I18n.get('Start hand height')} (${
		systemOfUnitsToSymbolMapper[libertyMutual?.system_of_units?.name]
	})`;

	const tooltipSVGMapper = {
		[TaskName.lift]: lift_start_hand_height,
		[TaskName.lower]: lower_start_hand_height,
		[TaskName.push]: '',
		[TaskName.pull]: '',
		[TaskName.carry]: ''
	};

	const TooltipContent = (
		<Row align="bottom">
			<Col xs={7}>
				<Image src={tooltipSVGMapper[libertyMutual.task.name]} alt={labelTitle} preview={false} width="80px" />
			</Col>
		</Row>
	);

	const label = (
		<CustomLabel
			icon={
				<CustomTooltip top={-120} left={13} content={TooltipContent} placement="topLeft">
					<InfoCircleOutlined style={{ fontSize: '0.65rem', marginLeft: '0.1rem' }} />
				</CustomTooltip>
			}
			title={labelTitle}
		/>
	);

	return (
		<Row>
			<Col xs={24}>
				<Form.Item
					name={['liberty_mutual_inputs', 'start_hand_height']}
					label={label}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: I18n.get('Insert a start height')
						}
					]}
				>
					<InputNumber
						min={0}
						placeholder="0"
						decimalSeparator={decimalSeparator}
					/>
				</Form.Item>
			</Col>
		</Row>
	);
};
