import React from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Checkbox } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setOptionsReport } from '@/redux/reports/actions';

const Container = styled(Col)`
	padding-top: 20px;
`;

export const Options = () => {
	const dispatch = useDispatch();
	const options = useSelector((state) => state.reports.options);

	function onChangeSignature(e) {
		dispatch(
			setOptionsReport({
				signature: {
					...options.signature,
					checked: e.target.checked
				}
			})
		);
	}

	function onChangeConclusion(e) {
		dispatch(
			setOptionsReport({
				conclusion: {
					...options.conclusion,
					checked: e.target.checked
				}
			})
		);
	}

	return (
		<Container sm={24}>
			<Row gutter={[10, 0]}>
				<Col>
					<Checkbox name="checked" onChange={onChangeConclusion} checked={options.conclusion.checked}>
						{I18n.get('Include conclusion')}
					</Checkbox>
				</Col>
				<Col>
					<Checkbox name="signature" onChange={onChangeSignature} checked={options.signature.checked}>
						{I18n.get('Include signature')}
					</Checkbox>
				</Col>
			</Row>
		</Container>
	);
};
