import React from 'react';
import { Row, Col } from 'antd';

import { PersonalisedReportSVG, PersonalisedReportHoveredSVG } from '@/components/Icons';

export const PersonalisedReportIcon: React.FC = () => (
	<Col xs={24}>
		<Row justify="center">
			<Col className="custom-icon" span={24}>
				<PersonalisedReportSVG width={30} height={30} />
			</Col>
			<Col className="custom-icon-hovered" span={24}>
				<PersonalisedReportHoveredSVG width={30} height={30} />
			</Col>
		</Row>
	</Col>
);
