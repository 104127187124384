import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type GetSectorsResponse = {
	id: string;
	name: string;
};

export type GetSectorsError = AxiosError<any>;

export interface GetSectorsDTO {
	organization_id: string;
	company_id: string;
}

async function getSectors(params: GetSectorsDTO) {
	const { organization_id, company_id } = params;
	const { data } = await Api.get(`/sector/${organization_id}/${company_id}`);
	return data;
}

export const useGetSectors = (organizationId: string, companyId: string) => {
	const payload = { organization_id: organizationId, company_id: companyId };

	return useQuery<GetSectorsResponse[], GetSectorsError, GetSectorsDTO>(
		[QUERY_KEYS.GET_SECTORS],
		() => getSectors(payload),
		{
			enabled: !!organizationId && !!companyId
		}
	);
};
