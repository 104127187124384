import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Doughnut } from 'react-chartjs-2';
import { useDashboardContext } from '../../context';
import { ENUM_FIVE_SCORE_SCALE, ScoresScale } from '../../types';
interface SummaryChartProps {
	scores: ScoresScale[];
}

export const SummaryChart: React.FC<SummaryChartProps> = ({ scores }) => {
	const { handleFilterSelection } = useDashboardContext();

	const types: Record<string, string> = {
		not_analyzed: 'Not analyzed',
		acceptable: 'Acceptable risk',
		moderate: 'Moderate risk',
		high: 'High risk',
		very_high: 'Very high risk',
		extreme: 'Serious and imminent risk'
	};

	return (
		<Doughnut
			options={{
				onClick: (_, item) => {
					const isNotAnalyzed = item[0]?.element?.options?.backgroundColor !== '#C3C3C3';
					isNotAnalyzed &&
						setTimeout(() => {
							handleFilterSelection({
								stressLevel: [scores[item[0].index].id]
							});
						}, 100);
				},
				responsive: true,
				onHover: (event, chartElement) => {
					const target = event.native?.target as HTMLInputElement;
					const isNotAnalyzed = chartElement[0]?.element?.options?.backgroundColor === '#C3C3C3';
					if (chartElement[0]) {
						target.style.cursor = !isNotAnalyzed ? 'pointer' : 'default';
					}
				},
				maintainAspectRatio: true,
				plugins: {
					datalabels: {
						formatter: (value, ctx) => {
							let datasets = ctx.chart.data?.datasets;
							if (datasets.indexOf(ctx.dataset) === datasets?.length - 1) {
								return value + '%';
							} else {
								return '0%';
							}
						}
					},
					tooltip: {
						callbacks: {
							label: function (context) {
								if (context.datasetIndex === 0) {
									const value = context.parsed;
									const datasets = context.chart.data.datasets;
									const sum = datasets[0].data.reduce((a, b) => Number(a) + Number(b), 0);
									const percentage = Math.round((value / Number(sum)) * 100) + '%';
									const label = I18n.get(types[scores[context.dataIndex].type]);
									return `${label}: ${percentage}`;
								}
								return '';
							}
						}
					}
				}
			}}
			data={{
				datasets: [
					{
						backgroundColor:
							scores?.length > 0
								? scores.map((score) => {
										return ENUM_FIVE_SCORE_SCALE[score.type].trailColor;
								  })
								: [],
						borderColor:
							scores?.length > 0
								? scores.map((score) => {
										return ENUM_FIVE_SCORE_SCALE[score.type].trailColor;
								  })
								: [],
						borderWidth: 1,
						data: scores?.length > 0 ? scores.map((item) => item.amount) : [],
						datalabels: {
							color: '#FFFFFF',
							font: {
								size: 16,
								weight: 700
							}
						}
					}
				]
			}}
		/>
	);
};
