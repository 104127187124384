import React from 'react';
import { Table, Modal, Tooltip } from 'antd';
import * as Icons from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import { enumStatusFile } from '@/utils/enum';
import { formatFileSize } from '@/utils/mask';
import ActionsForFile from './ActionsForFile';
import { Text } from '../Typography';
import { TagRisk } from './styles';

export function List({
	data,
	loading,
	onDelete,
	onDownload,
	onSelect,
	onRedirectPreliminary,
	setMaxPage,
	setPage,
	maxPage,
	page
}) {
	const onDeleteFile = (id) => {
		Modal.confirm({
			title: I18n.get('Heads up! Want to delete this file?'),
			icon: <Icons.ExclamationCircleOutlined />,
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			confirmLoading: true,
			okType: 'danger',
			onOk: () => onDelete(id)
		});
	};

	const colors = ['#2CC852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];

	const riskLevels = {
		1: 'Acceptable risk',
		2: 'Moderate risk',
		3: 'High Risk',
		4: 'Very high risk',
		5: 'Serious and imminent risk'
	};

	const riskCalc = (score) => {
		let result;

		if (score >= 0 && score <= 19) {
			result = 1;
		}

		if (score >= 20 && score <= 69) {
			result = 2;
		}

		if (score >= 70 && score <= 199) {
			result = 3;
		}

		if (score >= 200 && score <= 400) {
			result = 4;
		}

		if (score >= 401 && score <= 10000) {
			result = 5;
		}

		return result;
	};

	const columns = [
		{
			title: I18n.get('Description'),
			dataIndex: 'original_name',
			key: 'original_name',
			width: '20%',
			render: (text) => (
				<Tooltip title={text}>
					<Text style={{ width: '150px' }} ellipsis={false}>
						{text}
					</Text>
				</Tooltip>
			)
		},
		{
			title: I18n.get('Size'),
			dataIndex: 'size',
			align: 'center',
			key: 'size',
			render: (size) => (size ? formatFileSize(size) : '-')
		},
		{
			title: I18n.get('Upload date'),
			dataIndex: 'createdAt',
			key: 'createdAt',
			align: 'center',
			render: (text) => moment(text).format('DD/MM/YYYY - h:mm:ss A')
		},
		{
			title: I18n.get('Status'),
			dataIndex: 'status',
			key: 'status',
			align: 'center',
			render: (text) => `${I18n.get(enumStatusFile[text] || '-')}`
		},
		{
			title: I18n.get('Sector'),
			dataIndex: 'sector',
			key: 'sector',
			align: 'center',
			render: (text) => (text ? text.name : I18n.get('Not assigned'))
		},
		{
			title: I18n.get('Workstation'),
			dataIndex: 'workstations',
			key: 'workstations',
			align: 'center',
			render: (text) => text?.name || '-'
		},
		{
			title: I18n.get('Rating'),
			dataIndex: 'preliminary_analysis',
			key: 'preliminary_analysis',
			align: 'center',
			render: (num) =>
				num?.consolidated ? (
					<TagRisk brightcolors={riskCalc(num.worst_score)} color={colors[riskCalc(num.worst_score) - 1]}>
						<Tooltip title={I18n.get(`${riskLevels[riskCalc(num.worst_score)]}`)}>
							{riskCalc(num.worst_score) === 5 ? (
								<Text ellipsis={true}>{I18n.get('Serious risk')}</Text>
							) : (
								<Text ellipsis={true}>{I18n.get(riskLevels[riskCalc(num.worst_score)])}</Text>
							)}
						</Tooltip>
					</TagRisk>
				) : (
					I18n.get('Not assigned')
				)
		},

		{
			title: I18n.get('Actions'),
			dataIndex: 'url',
			key: 'url',
			align: 'center',
			render: (_, file) => (
				<ActionsForFile
					file={file}
					onDownload={onDownload}
					onDelete={onDeleteFile}
					onShowOptionsReports={onSelect}
					onRedirectPreliminary={onRedirectPreliminary}
				/>
			)
		}
	];

	return (
		<Table
			rowKey="id"
			columns={columns}
			loading={loading}
			dataSource={data?.rows}
			pagination={{
				hideOnSinglePage: false,
				showSizeChanger: true,
				total: data?.count,
				current: page === 0 ? page + 1 : page,
				defaultPageSize: maxPage,
				pageSizeOptions: ['5', '10', '20', '30', '50', '100', '200'],
				pageSize: maxPage,
				onChange: (page) => {
					setPage(page);
				},
				onShowSizeChange: (_, size) => setMaxPage(size)
			}}
		/>
	);
}
