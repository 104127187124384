import React from 'react';
import { Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as Icons from '@ant-design/icons';

import { ColorPalette } from '@/types';
import { RiskBar } from '../../RiskBar';
import { Risk, RiskBarOptions } from '../../RiskBar/types';
import { DashboardWorkstation, ENUM_FIVE_SCORE_SCALE, WorkstationsScore } from '../../types';
import { LinkCustom, WorkstationCard, WorkstationScoreValue, WorkstationTitle } from './styles';

const { ACCEPTABLE, MODERATE, HIGH, VERY_HIGH, EXTREME } = ColorPalette;
interface WorkstationScoreProps {
	workstation: DashboardWorkstation;
}

export const WorkstationScore: React.FC<WorkstationScoreProps> = ({ workstation }) => {
	const stylesIcons: Record<string, number | string> = {
		fontSize: 20,
		width: '1.75rem'
	};

	const ENUM_STRESS_ICON: Record<number, JSX.Element> = {
		1: <Icons.CheckCircleFilled style={{ ...stylesIcons, color: ACCEPTABLE }} />,
		2: <Icons.ExclamationCircleFilled style={{ ...stylesIcons, color: MODERATE }} />,
		3: <Icons.ExclamationCircleFilled style={{ ...stylesIcons, color: HIGH }} />,
		4: <Icons.WarningFilled style={{ ...stylesIcons, color: VERY_HIGH }} />,
		5: <Icons.WarningFilled style={{ ...stylesIcons, color: EXTREME }} />
	};

	const stressLevelOptions: Array<string> = [ACCEPTABLE, MODERATE, HIGH, VERY_HIGH, EXTREME];

	const riskBarOptions: RiskBarOptions = {
		caption: {
			align: 'center',
			display: 'right'
		},
		orientation: 'horizontal'
	};

	const pathTo: Record<string, string> = {
		pathname: '/reporting',
		search: `?workstation_id=${workstation.workstation_id}`
	};

	function risksMapper(scores: WorkstationsScore[]): Risk[] {
		const risks: Risk[] = scores.map(({ amount, type, id, scale }) => {
			const risk: Risk = {
				fontColor: ENUM_FIVE_SCORE_SCALE[type].fontColor,
				trailColor: ENUM_FIVE_SCORE_SCALE[type].trailColor,
				id,
				percentage: calculatePercentage(amount),
				score: scale,
				workstation: workstation.workstation,
				workstation_id: workstation.workstation_id
			};
			risk.report = 'workstations';
			return risk;
		});

		return risks.sort((a, b) => a.id - b.id);
	}

	function calculatePercentage(amount: number): string {
		return (amount / workstation.reviews) * 100 + '%';
	}

	return (
		<WorkstationCard>
			<Row align="middle" justify={'center'}>
				<Col span={2}>{ENUM_STRESS_ICON[workstation.worst_review_level]}</Col>
				<Col style={{ justifyContent: 'flex-start' }} span={6}>
					<LinkCustom to={pathTo}>
						<WorkstationTitle>{I18n.get(workstation.workstation)}</WorkstationTitle>
					</LinkCustom>
				</Col>
				<Col span={12}>
					<RiskBar options={riskBarOptions} risks={risksMapper(workstation.scores)} />
				</Col>
				<Col style={{ display: 'flex', justifyContent: 'end' }} span={4}>
					<WorkstationScoreValue
						style={{
							color: stressLevelOptions[workstation.worst_review_level - 1]
						}}
					>
						{`${('0000' + workstation.worst_score).slice(-5)}`}
					</WorkstationScoreValue>
				</Col>
			</Row>
		</WorkstationCard>
	);
};
