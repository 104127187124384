import React from 'react';
import { Input } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItem } from '../../../components/FormItem';

export const ActivityName: React.FC = () => (
	<FormItem
		fontSize="1rem"
		title="Activity"
		isRequired={true}
		errorMessage={I18n.get('Insert the activity')}
		namePath={['basic_information', 'activity_name']}
	>
		<Input size="large" placeholder={I18n.get('Activity')} />
	</FormItem>
);
