import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

interface GetRiskDegreeBodyPartsParams {
	organization_id: string;
	company_id: string;
	file_id: string;
}

async function getRiskDegreeBodyParts(params: GetRiskDegreeBodyPartsParams): Promise<void> {
	const { data } = await Api.get('/report/rula/risk_degree/body_parts', { params });
	return data;
}

export const useRiskDegreeBodyParts = (params: GetRiskDegreeBodyPartsParams) => {
	console.log({ params });
	return useQuery(['risk-degree-body-parts', params], () => getRiskDegreeBodyParts(params), {
		enabled: !!params.organization_id && !!params.company_id && !!params.file_id
	});
};
