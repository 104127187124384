import Api from '@/services/api';

async function getScore({ organization_id, company_id, file_id }: any) {
	const url = `/ergonomic-tool/reba/score/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getScoreParts({ organization_id, company_id, file_id }: any) {
	const url = `/ergonomic-tool/reba/score-parts/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getNotesPerSecond({ organization_id, company_id, file_id }: any) {
	const url = `/ergonomic-tool/reba/note-per-second/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getResults({ organization_id, company_id, file_id }: any) {
	const url = `/ergonomic-tool/reba/results/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function setComments(payload: any) {
	const { data } = await Api.put(`/ergonomic-tool/reba/comment`, payload);
	return data;
}

export const Services = {
	getScore,
	getScoreParts,
	getNotesPerSecond,
	getResults,
	setComments
};
