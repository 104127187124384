import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, DatePicker } from 'antd';

import { CustomFormItem } from './styles';

export const CollectionDate: React.FC = () => {
	return (
		<Col xs={24}>
			<CustomFormItem name={['collection_date']} label={I18n.get('Collection date')} labelCol={{ span: 24 }}>
				<DatePicker allowClear size="large" format={'L'} style={{ width: '100%' }} />
			</CustomFormItem>
		</Col>
	);
};
