import React from 'react';
import { Title } from '@/components/Typography';
import { I18n } from '@aws-amplify/core';
import { Col, Row } from 'antd';

import { TaskNameMapper } from '../../../hooks/types/response';
import { PercentileScoreContainer, Topic } from './styles';
import { useLibertyMutualContext } from '../../../context';

export const Score = () => {
	const {
		libertyMutualResult: {
			informations: { task_name },
			results: {
				percentile_man,
				percentile_woman,
				percentile_man_initial,
				percentile_man_sustain,
				percentile_woman_initial,
				percentile_woman_sustain
			}
		}
	} = useLibertyMutualContext();

	function renderPercentiles() {
		if (task_name === TaskNameMapper.Push || task_name === TaskNameMapper.Pull) {
			return (
				<>
					<Row justify="space-between" align="middle">
						<Col xs={10}>
							<Topic style={{ fontWeight: '400' }}>{I18n.get('Initial')}</Topic>
						</Col>
						<Col xs={10}>
							<Topic style={{ fontWeight: '400' }}>{I18n.get('Initial')}</Topic>
						</Col>
						<Col xs={10} style={{ marginTop: '0.5rem' }}>
							<PercentileScoreContainer>{percentile_man_initial}</PercentileScoreContainer>
						</Col>
						<Col xs={10} style={{ marginTop: '0.5rem' }}>
							<PercentileScoreContainer>{percentile_woman_initial}</PercentileScoreContainer>
						</Col>
					</Row>
					<Row justify="space-between" align="middle" style={{ marginTop: '1rem' }}>
						<Col xs={10}>
							<Topic style={{ fontWeight: '400' }}>{I18n.get('Sustain')}</Topic>
						</Col>
						<Col xs={10}>
							<Topic style={{ fontWeight: '400' }}>{I18n.get('Sustain')}</Topic>
						</Col>
						<Col xs={10} style={{ marginTop: '0.5rem' }}>
							<PercentileScoreContainer>{percentile_man_sustain}</PercentileScoreContainer>
						</Col>
						<Col xs={10} style={{ marginTop: '0.5rem' }}>
							<PercentileScoreContainer>{percentile_woman_sustain}</PercentileScoreContainer>
						</Col>
					</Row>
				</>
			);
		}

		return (
			<Row justify="space-between" style={{ marginTop: '1rem' }}>
				<Col xs={10}>
					<PercentileScoreContainer>{percentile_man}</PercentileScoreContainer>
				</Col>
				<Col xs={10}>
					<PercentileScoreContainer>{percentile_woman}</PercentileScoreContainer>
				</Col>
			</Row>
		);
	}

	return (
		<Row justify="start">
			<Col xs={24}>
				<Title level={5}>Results</Title>
			</Col>
			<Col xs={24}>
				<Row>
					<Col xs={24}>
						<Topic style={{ fontSize: '0.7rem', whiteSpace: 'nowrap' }}>
							{I18n.get('Activity is safe for')}:
						</Topic>
					</Col>
				</Row>
				<Row justify="space-between" style={{ marginTop: '1rem' }}>
					<Col xs={10}>
						<Topic>{I18n.get('Man')}:</Topic>
					</Col>
					<Col xs={10}>
						<Topic>{I18n.get('Woman')}:</Topic>
					</Col>
				</Row>
				{renderPercentiles()}
			</Col>
		</Row>
	);
};
