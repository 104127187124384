import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { DownloadOutlined, LoadingOutlined, LockOutlined, UnlockOutlined } from '@ant-design/icons';

import { Header } from './Header';
import { Information } from './Information';
import { Comment } from './Comment';
import {
	DownloadButton,
	ReportContainer,
	SectionTitle,
	SectionContainer,
	FooterStyled,
	ConsolidatedButton,
	DesconsolidatedButton
} from './styles';
import { FileDTO } from './types';

type OnConsolidatePDFProps = {
	file: FileDTO;
	status: boolean;
};
type SectionsProps = { title: any; component: JSX.Element };

type ReportTemplateProps = {
	title: string;
	sections: SectionsProps[];
	fileData: FileDTO;
	reportData?: string;
	isLoading: boolean;
	onDownloadPDF: (fileData: FileDTO) => {};
	onConsolidatePDF?: ({ file, status }: OnConsolidatePDFProps) => {};
	isLoadingPDF: boolean;
	onChangeComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
	hasConsolidated?: boolean;
	consolidated?: boolean;
	isDisabledComments?: boolean;
};

export function ReportTemplate({
	title,
	sections,
	fileData,
	reportData,
	isLoading,
	onDownloadPDF,
	onConsolidatePDF,
	isLoadingPDF,
	onChangeComment,
	hasConsolidated = false,
	consolidated = false,
	isDisabledComments = false
}: ReportTemplateProps) {
	if (isLoading) {
		return (
			<Col span={24}>
				<LoadingOutlined />
			</Col>
		);
	}

	const handleConsolidetedButton = (status: boolean) => {
		return hasConsolidated && onConsolidatePDF!({ file: fileData, status });
	};

	return (
		<Row justify="center" align="middle">
			<Col style={{ display: 'flex', flexDirection: 'column' }} span={24}>
				<ReportContainer span={24}>
					<Header title={title} />
					<>
						<Col span={24}>
							<SectionTitle>{I18n.get('Company')}</SectionTitle>
						</Col>
						<Information file={fileData} reportData={reportData} />
					</>
					{sections.map((section, index) => (
						<React.Fragment key={index}>
							<Col span={24}>
								<SectionTitle>{section.title}</SectionTitle>
							</Col>
							<Col span={24}>
								<SectionContainer>{section.component}</SectionContainer>
							</Col>
						</React.Fragment>
					))}
					<FooterStyled span={24}>
						<Row align="bottom" justify="space-between" style={{ width: '100%' }}>
							<Col xxl={19} xl={18}>
								<Comment onChangeComment={onChangeComment} isDisabled={isDisabledComments} />
							</Col>
							<Col xxl={4} xl={5}>
								<Row justify="center" style={{ marginRight: '2.5rem' }}>
									<Col>
										{hasConsolidated &&
											(!consolidated ? (
												<ConsolidatedButton
													loading={isLoadingPDF}
													onClick={() => handleConsolidetedButton(true)}
													icon={<LockOutlined style={{ color: '#2cc852' }} />}
												>
													{I18n.get('Consolidate report')}
												</ConsolidatedButton>
											) : (
												<DesconsolidatedButton
													loading={isLoadingPDF}
													onClick={() => handleConsolidetedButton(false)}
													icon={<UnlockOutlined style={{ color: '#828282' }} />}
												>
													{I18n.get('Deconsolidate')}
												</DesconsolidatedButton>
											))}
									</Col>
									<Col>
										<DownloadButton
											ghost
											size="large"
											type="primary"
											loading={isLoadingPDF}
											style={{ padding: '6px' }}
											icon={<DownloadOutlined />}
											onClick={() => onDownloadPDF(fileData)}
										>
											{I18n.get('Download PDF')}
										</DownloadButton>
									</Col>
								</Row>
							</Col>
						</Row>
					</FooterStyled>
				</ReportContainer>
			</Col>
		</Row>
	);
}
