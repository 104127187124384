import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Select, Row, Col, Input, Button, message, Divider, DatePicker } from 'antd';

import Api from '@/services/api';
import { checkIfValidUUID } from '@/utils';
import { useFetch } from '@/hooks/useFetch';
import hooks from '@/components/Settings/Organizations/hooks';
import { useApplicationContext } from '@/context/Application';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { updateInformations, validationStep, next } from '@/redux/reports/actions';

const { useGetWorkstations } = hooks;

export function Informations() {
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const { organization, company } = useApplicationContext();
	const [nameSector, setNameSector] = useState('');
	const [recoverySector, setRecoverySector] = useState(null);

	const [sectorCreated, setSectorCreated] = useState({
		isFetching: false,
		data: null,
		err: null
	});
	const sectorId = Form.useWatch('sector', form);
	const workstations = useGetWorkstations(organization.id, company.id, sectorId);

	const { informations } = useSelector((state) => state.reports.customized);

	useEffect(() => {
		sectorId && form.resetFields(['workstation']);
	}, [sectorId]);

	const {
		data: sectors,
		error: errSectors,
		isFetching: sectorsLoading,
		doFetch: findAllSectors
	} = useFetch({
		method: 'get',
		url: `/sector/${organization.id}/${company.id}`
	});

	const normalizeData = () => {
		const initialValues = {
			sector: null,
			company: null,
			workstation: null,
			collection_date: null
		};

		if (company?.name) {
			_.set(initialValues, 'company', company.name);
		}

		if (informations?.workstation) {
			_.set(initialValues, 'workstation', informations.workstation);
		}

		if (informations?.sector) {
			/*
				this function form.setFieldsValue not working with
				select options.
			*/
			const sector = JSON.parse(informations.sector);
			_.set(initialValues, 'sector', sector.id);
			setRecoverySector(sector);
		}

		if (informations.collection_date) {
			_.set(initialValues, 'collection_date', moment(informations.collection_date));
		}

		return initialValues;
	};

	const onFormatData = (values) => {
		let sectorId = values.sector;

		if (!checkIfValidUUID(values.sector)) {
			sectorId = recoverySector.id;
			setRecoverySector(null);
		}

		const [sector] = sectors?.filter((item) => sectorId === item.id && item);
		const dataForm = { ...values, sector: JSON.stringify(sector) };

		dispatch(updateInformations(dataForm));
		dispatch(validationStep('informations', true));
		dispatch(next('customized'));
	};

	const onFinish = () => {
		form.validateFields()
			.then(onFormatData)
			.catch((errorInfo) => {});
	};

	useEffect(() => {
		if (form && informations) {
			const dataForm = normalizeData();
			form.setFieldsValue(dataForm);
		}
	}, [form, informations]);

	useEffect(() => {
		if (informations) {
			const { collection_date, sector, workstation, is_valid } = informations;
			sector && workstation && collection_date && !is_valid && dispatch(validationStep('informations', true));
		}
	}, [informations]);

	useEffect(() => {
		if (errSectors) {
			message.error(I18n.get('Failed to fetch sectors'));
		}

		if (sectorCreated.err) {
			message.error(I18n.get('Failed to create a new sector'));
		}
	}, [errSectors, sectorCreated.err]);

	const filterOption = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	const onNameChange = (e) => {
		setNameSector(e.target.value);
	};

	const createSector = () => {
		const body = {
			organization_id: organization.id,
			company_id: company.id,
			name: nameSector
		};

		function onLoading(boolean) {
			setSectorCreated((current) => ({ ...current, isFetching: boolean }));
		}

		function onSuccess(response) {
			setNameSector('');
			setSectorCreated((current) => ({ ...current, data: response.data }));
			message.success(I18n.get('Sector created successfully'));
			findAllSectors();
		}

		function onError(err) {
			setSectorCreated((current) => ({ ...current, err: err }));
		}

		Api.post('/sector/create', body)
			.then(onLoading(true))
			.then(onSuccess)
			.catch(onError)
			.finally(() => onLoading(false));
	};

	return (
		<Form form={form} layout="vertical" style={{ padding: '25px 10px 10px 10px' }} onValuesChange={onFinish}>
			<Row gutter={[10, 10]}>
				<Col sm={12}>
					<Form.Item name="company" label={I18n.get('Company')}>
						<Input disabled={true} />
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item
						name="sector"
						label={I18n.get('Sector')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Select a sector'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<Select
							showSearch
							placeholder={I18n.get('Sector')}
							optionFilterProp="children"
							filterOption={filterOption}
							loading={sectorsLoading}
							disabled={sectorsLoading}
							dropdownRender={(menu) => (
								<div>
									{menu}
									<Divider style={{ margin: '4px 0' }} />
									<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
										<Input
											style={{ flex: 'auto', borderRadius: '7px' }}
											value={nameSector}
											onChange={onNameChange}
										/>
										<Button
											style={{
												flex: 'none',
												padding: '8px',
												display: 'block',
												cursor: 'pointer'
											}}
											onClick={createSector}
											type="link"
											loading={sectorCreated.isFetching}
											disabled={!nameSector || nameSector.length < 3}
										>
											{I18n.get('Add item')}
										</Button>
									</div>
								</div>
							)}
						>
							{sectors?.map((item, key) => (
								<Select.Option key={key} value={item.id}>
									{item.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item
						label={I18n.get('Workstation')}
						name="workstation"
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert a workstation'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<WorkstationSelect
							sectorId={sectorId}
							loading={sectorsLoading}
							workstations={workstations?.data}
						/>
					</Form.Item>
				</Col>
				<Col sm={12}>
					<Form.Item
						label={I18n.get('Collection date')}
						name="collection_date"
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the date of collection'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<DatePicker
							format={'DD/MM/YYYY'}
							placeholder={I18n.get('Collection date')}
							style={{ width: '100%' }}
						/>
					</Form.Item>
				</Col>
				<Col sm={24} style={{ textAlign: 'end' }}>
					<Form.Item shouldUpdate noStyle>
						{() => (
							<Button
								type="primary"
								onClick={onFinish}
								disabled={
									!form.isFieldsTouched(true) ||
									form.getFieldsError().filter(({ errors }) => errors.length).length > 0
								}
							>
								{I18n.get('Next')}
							</Button>
						)}
					</Form.Item>
				</Col>
			</Row>
		</Form>
	);
}
