import React from 'react';
import { Row, Col } from 'antd';

interface ContainerProps {
	children: JSX.Element | JSX.Element[];
}

export function Container({ children }: ContainerProps) {
	return (
		<Col span={24}>
			<Row justify="space-around" align="middle">
				{children}
			</Row>
		</Col>
	);
}
