import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Image, Row } from 'antd';

import weightSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/weight.svg';

import { Body, CardContainer, Header, UnitContainer } from '../styles';
import { useLibertyMutualContext } from '../../../../context';

export const ObjectWeightCard = () => {
	const {
		libertyMutualResult: { summary }
	} = useLibertyMutualContext();

	return (
		<CardContainer>
			<Header>{I18n.get('Weight')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0.5rem 0' }}>
					<Image
						style={{ position: 'relative' }}
						preview={false}
						draggable={false}
						alt={I18n.get(`Object weight: ${I18n.get(summary.object_weight)}`)}
						src={weightSVG}
						width="40px"
					/>
					<UnitContainer>{summary.unit}</UnitContainer>
				</Row>
				<Row justify="center" style={{ fontSize: '1.1rem' }}>
					{I18n.get(summary.object_weight)}
				</Row>
			</Body>
		</CardContainer>
	);
};
