import styled from 'styled-components';
import { Col } from 'antd';

import { TaskName } from '../../../hooks/types/response';

interface CustomTaskContainerProps {
	selected_task: TaskName;
}

export const CustomTaskContainer = styled(Col)<CustomTaskContainerProps>`
	height: ${(props) => setHeight(props.selected_task)};
	display: flex;
	color: #ffffff;
	padding: 1rem 1rem;
	align-items: center;
	border-radius: 1.5rem;
	flex-direction: column;
	justify-content: center;
	background-color: #2f54eb;

	@media (min-width: 1200px) {
		height: ${(props) => setHeightFor1200px(props.selected_task)};
	}
`;

export const CustomImageContainer = styled.div`
	display: flex;
	text-align: center;
	align-items: center;
	justify-content: end;
	flex-direction: column;

	.ant-image {
		max-width: 90px;
		margin-bottom: 1rem;

		@media (min-width: 1200px) {
			max-width: 145px;
		}
	}
`;

export const TaskTitle = styled.span`
	font-size: 1rem;
	font-weight: 600;
`;

function setHeight(selected_task: TaskName) {
	const taskNameMapper = {
		[TaskName.push]: '200px',
		[TaskName.pull]: '200px',
		[TaskName.lift]: '150px',
		[TaskName.lower]: '150px',
		[TaskName.carry]: '150px'
	};

	return taskNameMapper[selected_task];
}

function setHeightFor1200px(selected_task: TaskName) {
	const taskNameMapper = {
		[TaskName.push]: '250px',
		[TaskName.pull]: '250px',
		[TaskName.lift]: '200px',
		[TaskName.lower]: '200px',
		[TaskName.carry]: '250px'
	};

	return taskNameMapper[selected_task];
}
