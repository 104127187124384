import React from 'react';
import { Col, Row } from 'antd';

import { Exposures } from '../Exposures';
import { Consequences } from '../Consequences';
import { Probabilities } from '../Probabilities';
import { ToolCard } from '@/components/ToolCard';
import { Injuries, RiskLevelCard } from './Inputs';
import { usePersonalisedReportSubStepKeyContext } from './context';
import { Chart } from './Chart';

const memberToTitleMapper: { [key: string]: string } = {
	neck: 'Neck',
	trunk: 'Trunk',
	left_knee: 'Left knee',
	right_knee: 'Right knee',
	left_elbow: 'Left lower arm',
	right_elbow: 'Right lower arm',
	left_shoulder: 'Left upper arm',
	right_shoulder: 'Right upper arm'
};

export const KinebotStepKey: React.FC = () => {
	const { hasInjury, reba, fileId, stepKey, subStepKeyName, stepKeyFieldName, mapStepKeyDropdownOptions } =
		usePersonalisedReportSubStepKeyContext();

	function renderRebaChart(): React.ReactNode {
		if (!fileId) {
			return null;
		}
		if (!!reba && reba.data) {
			const currentScore = reba.data
				.flatMap((data) => data)
				.find(({ name }) => name === memberToTitleMapper[subStepKeyName]);

			return <Chart scores={currentScore?.scores} />;
		}

		return stepKey.ergonomic_tools.map(({ id, description, subtitle, title }) => {
			return (
				<ToolCard
					key={id}
					title={title}
					fileId={fileId}
					subtitle={subtitle}
					isActive={false}
					reportSelected={description}
				/>
			);
		});
	}

	return (
		<Row gutter={[0, 50]}>
			<Col xs={24} lg={12} style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
				<Row>
					{hasInjury && (
						<Col xs={24}>
							<Row justify="start">
								<Col sm={24} lg={18}>
									<Injuries />
								</Col>
							</Row>
						</Col>
					)}
				</Row>
				<Row justify="end">
					<Col xs={24}>{renderRebaChart()}</Col>
				</Row>
			</Col>
			<Col xs={24} lg={12}>
				<Row>
					<Col xs={24}>
						<Row justify="start">
							<Col xs={24}>
								<Consequences
									mapDropdownOptions={mapStepKeyDropdownOptions}
									stepKeyFieldName={stepKeyFieldName}
								/>
								<Probabilities
									mapDropdownOptions={mapStepKeyDropdownOptions}
									stepKeyFieldName={stepKeyFieldName}
								/>
								<Exposures
									mapDropdownOptions={mapStepKeyDropdownOptions}
									stepKeyFieldName={stepKeyFieldName}
									disabled={true}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{ marginTop: '2rem' }}>
					<Col xs={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<RiskLevelCard />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
