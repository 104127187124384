import styled, { css } from 'styled-components';
import { Row, Col, Typography } from 'antd';
import * as Icons from '@ant-design/icons';

const { Text } = Typography;

enum ColorStatusIcons {
	SAFE = '#52c41a',
	WARNING = '#fdc365',
	DANGER = '#ff4170'
}

const defaultDimensionsIcons = css`
	margin: 2px;
	font-size: 1.3rem;
`;

export const Check = styled(Icons.CheckCircleOutlined)`
	${defaultDimensionsIcons}
	color: ${ColorStatusIcons.SAFE};
`;

export const Exclamation = styled(Icons.ExclamationCircleOutlined)`
	${defaultDimensionsIcons}
	color: ${ColorStatusIcons.WARNING};
`;

export const Close = styled(Icons.CloseCircleOutlined)`
	${defaultDimensionsIcons}
	color: ${ColorStatusIcons.DANGER};
`;

export const Title = styled(Text)`
	font-size: 1rem;
	font-weight: bold;
`;

export const Description = styled(Text)`
	font-size: 0.8rem;
`;

export const Container = styled(Col)`
	margin-top: 20px;
`;

export const InsideContainer = styled(Row)`
	margin-top: 20px;
`;
