import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Title } from '../Header/components/Title';
import * as S from './styles';

interface ModalProps {
	title: string;
	isOpen: boolean;
	onClose?: () => void;
	children?: JSX.Element | JSX.Element[];
}

export function Modal({ children, isOpen, onClose, title }: ModalProps) {
	return (
		<S.ModalRounded open={isOpen} onCancel={onClose} footer={false}>
			<Title>{I18n.get(title)}</Title>
			{children}
		</S.ModalRounded>
	);
}
