import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useForm } from 'antd/lib/form/Form';

import { ModalCreate } from './Create';
import { ModalConfirmation } from './Confirmation';
import { CreateUserButton } from '../components/Button';
import type { BaseFormValues } from '@/views/Users/types';

export function UserCreateModal() {
	const [form] = useForm();
	const [formData, setFormData] = useState<BaseFormValues>();
	const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
	const [isConfirmCreateModalOpen, setIsConfirmCreateModalOpen] = useState<boolean>(false);

	function handleCreateModalHidden(): void {
		setIsCreateModalOpen(false);
	}

	function handleCreateModalClose(): void {
		setIsCreateModalOpen(false);
		form.resetFields();
	}

	function handleCreateModalOpen(): void {
		setIsCreateModalOpen(true);
	}

	async function handleCreateFormFinish(): Promise<void> {
		const values = await form.validateFields();
		setFormData(values);
		handleCreateModalHidden();
		setIsConfirmCreateModalOpen(true);
	}

	function handleConfirmModalClose(): void {
		setIsConfirmCreateModalOpen(false);
		form.resetFields();
	}

	function handleConfirmModalReturn(): void {
		handleCreateModalOpen();
		setIsConfirmCreateModalOpen(false);
	}

	function handleConfirmModalFinish(): void {
		handleConfirmModalClose();
		form.resetFields();
	}

	return (
		<>
			<CreateUserButton onClick={handleCreateModalOpen}>{I18n.get('Create')}</CreateUserButton>
			<ModalCreate
				form={form}
				isOpen={isCreateModalOpen}
				onClose={handleCreateModalClose}
				onFinish={handleCreateFormFinish}
			/>
			<ModalConfirmation
				form={form}
				isOpen={isConfirmCreateModalOpen}
				onClose={handleConfirmModalClose}
				onFinish={handleConfirmModalFinish}
				onReturn={handleConfirmModalReturn}
				formData={formData ?? ({} as BaseFormValues)}
			/>
		</>
	);
}
