import React from 'react';
import { Breadcrumb } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Link, useLocation } from 'react-router-dom';

const breadcrumbNameMap: Record<string, string> = {
	'/upload': 'Upload',
	'/super-pea': 'AEP',
	'/reporting': 'Reports',
	'/settings': 'Settings',
	'/settings/plan': 'Plan',
	'/users': 'Manage users',
	'/reporting/reba': 'REBA',
	'/reporting/niosh': 'NIOSH',
	'/action-plans': 'Action plans',
	'/settings/account': 'My account',
	'/settings/companies': 'Companies',
	'/settings/preferences': 'Preferences',
	'/settings/information': 'Information',
	'/reporting/angle-time': 'Angle by time',
	'/settings/integrations': 'Integrations',
	'/super-pea/file-history': 'File history',
	'/reporting/strain-index': 'Strain Index',
	'/super-pea/new-document': 'New document',
	'/reporting/kim-pp': 'KIM - Push and pull',
	'/settings/integrations/azure-ad': 'Azure AD',
	'/reporting/liberty-mutual': 'Liberty Mutual',
	'/settings/integrations/azure-ad/group': 'Grupo',
	'/reporting/kim-mho': 'Manual Handling Operations',
	'/reporting/personalised-report': 'Personalised report',
	'/settings/personalised-reports': 'Personalised reports',
	'/settings/personalised-reports/manage': 'Manage report',
	'/preliminary-ergonomic-analysis': 'Ergonomic Workstation Analysis',
	'/reporting/preliminary-ergonomic-analysis': 'Ergonomic Workstation Analysis'
};

export const WrapperBreadcrumb: React.FC = () => {
	const location = useLocation();
	const pathSnippets: string[] = location.pathname.split('/').filter((i) => i);

	const extraBreadcrumbItems: JSX.Element[] = pathSnippets.map((_, index) => {
		const url: string = `/${pathSnippets.slice(0, index + 1).join('/')}`;
		const split: string[] = url.split('/');

		let pathDescription: string | Array<string> = breadcrumbNameMap[url];

		if (pathSnippets.length === index + 1) {
			pathDescription = breadcrumbNameMap[url] || split.slice(-1);
		}

		return (
			<Breadcrumb.Item key={url}>
				<Link to={url}>{I18n.get(pathDescription)}</Link>
			</Breadcrumb.Item>
		);
	});

	const breadcrumbItems: JSX.Element[] = [
		<Breadcrumb.Item key="home">
			<Link to="/">{I18n.get('Home')}</Link>
		</Breadcrumb.Item>
	].concat(extraBreadcrumbItems);

	return <Breadcrumb>{breadcrumbItems}</Breadcrumb>;
};
