import React from 'react';
import { Select } from 'antd';
import { useQuery } from '@tanstack/react-query';

import Api from '@/services/api';

import { useApplicationContext } from '@/context/Application';
import { I18n } from '@aws-amplify/core';

export const ConsequenceSelect: React.FC = (props) => {
	const { organization, company } = useApplicationContext();

	const parameters = {
		organizationId: organization?.id,
		companyId: company?.id
	};

	async function getConsequencies({ organizationId, companyId }: any) {
		const url = `/ergonomic-tool/consequences?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get(url);
		return data;
	}

	const { isLoading, data } = useQuery(['consequencies', parameters], () => getConsequencies(parameters), {
		enabled: !!organization.id && !!company.id
	});

	return (
		<Select {...props} style={{ width: '100%' }} loading={isLoading}>
			{data?.map((item: any, index: number) => (
				<Select.Option key={index} value={item.id}>
					{I18n.get(item.description)}
				</Select.Option>
			))}
		</Select>
	);
};
