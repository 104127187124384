import React from 'react';
import { Col, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import { Report } from './Report';
import { useGetReport } from './hooks';
import { Text } from '@/components/Typography';
import { PersonalisedReportProvider } from './context';
import { LoadingSkeleton } from './context/LoadingSkeleton';
import { PersonalisedReportRouteParams } from './context/types';
import { useApplicationContext } from '@/context/Application';

export const PersonalisedReport: React.FC = () => {
	const { organization, company,  } = useApplicationContext();
	const { report_id, file_id } = useParams<PersonalisedReportRouteParams>();
	const history = useHistory()

	const currentSequenceParam = new URLSearchParams(history.location.search)

	const currentSequence = currentSequenceParam.get('current_sequence')

	const {
		data: personalisedReport,
		isError: errorGettingReport,
		isLoading: gettingReport,
		isFetching: fetchingReport
	} = useGetReport({
		file_id,
		report_id,
		company_id: company?.id,
		organization_id: organization?.id,
		current_sequence: Number(currentSequence)
	});

	if (errorGettingReport) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (gettingReport || fetchingReport) {
		return <LoadingSkeleton />;
	}

	return (
		<Row>
			<Col xs={24}>
				<PersonalisedReportProvider personalisedReport={personalisedReport}>
					<Report />
				</PersonalisedReportProvider>
			</Col>
		</Row>
	);
};
