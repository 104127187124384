import React from 'react';
import { I18n } from '@aws-amplify/core';
import { CheckboxOptionType, Col, Form, Row } from 'antd';

import { CustomRadioGroup } from './styles';
import { LoadingSkeleton } from './LoadingSkeleton';
import { Paragraph, Title } from '@/components/Typography';
import { useApplicationContext } from '@/context/Application';
import { useWorkerSelfEvaluations } from '@/hooks/v1/useWorkerSelfEvaluation';

export const WorkerSelfEvaluation: React.FC = () => {
	const { organization, company } = useApplicationContext();

	const {
		data: workerSelfEvaluations,
		isLoading,
		isFetching,
		isError
	} = useWorkerSelfEvaluations({
		company_id: company?.id,
		organization_id: organization?.id
	});

	if (isLoading || isFetching) {
		return <LoadingSkeleton />;
	}

	const options: CheckboxOptionType[] = workerSelfEvaluations.map(({ id, description }) => ({
		value: id,
		label: I18n.get(description)
	}));

	return (
		<Row justify="start">
			<Col span={24}>
				<Title level={5}>Self-evaluation</Title>
			</Col>
			<Col span={24}>
				<Paragraph>What is the level of stress perceived by the worker when performing the task?</Paragraph>
			</Col>
			<Form.Item
				name={['characteristics', 'worker_self_evaluation_id']}
				rules={[
					{
						required: true,
						message: 'Select a self evaluation'
					}
				]}
			>
				<CustomRadioGroup disabled={isError} options={options} />
			</Form.Item>
		</Row>
	);
};
