import React from 'react';
import { Col, Form, Image, InputNumber, Row } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';

import push_horizontal_distance from '@/assets/svg/ergonomic_tools/liberty_mutual/push/horizontal_distance.svg';
import pull_horizontal_distance from '@/assets/svg/ergonomic_tools/liberty_mutual/pull/horizontal_distance.svg';
import carry_horizontal_distance from '@/assets/svg/ergonomic_tools/liberty_mutual/carry/horizontal_distance.svg';

import { CustomLabel, CustomTooltip } from './CustomLabel';
import { useLibertyMutualContext } from '../../../context';
import { TaskName } from '../../../hooks/types/response';
import { SystemOfUnits } from '../../../context/types';

export const HorizontalDistance = () => {
	const { libertyMutual, decimalSeparator } = useLibertyMutualContext();

	const systemOfUnitsToSymbolMapper: SystemOfUnits = {
		metric: 'm',
		imperial: 'ft'
	};

	const labelTitle = `${I18n.get('Horizontal distance')} (${
		systemOfUnitsToSymbolMapper[libertyMutual?.system_of_units?.name]
	})`;

	const tooltipSVGMapper = {
		[TaskName.lift]: '',
		[TaskName.lower]: '',
		[TaskName.push]: push_horizontal_distance,
		[TaskName.pull]: pull_horizontal_distance,
		[TaskName.carry]: carry_horizontal_distance
	};

	const TooltipContent = (
		<Row align="bottom">
			<Col xs={7}>
				<Image src={tooltipSVGMapper[libertyMutual.task.name]} alt={labelTitle} preview={false} width="80px" />
			</Col>
		</Row>
	);

	const label = (
		<CustomLabel
			icon={
				<CustomTooltip top={-115} left={14} content={TooltipContent} placement="topLeft">
					<InfoCircleOutlined style={{ fontSize: '0.65rem', marginLeft: '0.1rem' }} />
				</CustomTooltip>
			}
			title={labelTitle}
		/>
	);

	return (
		<Row>
			<Col xs={24}>
				<Form.Item
					name={['liberty_mutual_inputs', 'horizontal_distance']}
					label={label}
					labelCol={{ span: 24 }}
					rules={[
						{
							required: true,
							message: I18n.get('Insert a horizontal distance')
						}
					]}
				>
					<InputNumber min={0} width="50%" placeholder="0" decimalSeparator={decimalSeparator} />
				</Form.Item>
			</Col>
		</Row>
	);
};
