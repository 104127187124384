import React from 'react';
import { PlusCircleFilled } from '@ant-design/icons';
import * as S from './styles';

interface ButtonProps {
	onClick?: () => void;
	children?: string;
}

export const CreateUserButton: React.FC<ButtonProps> = ({ children = '', onClick }) => {
	return (
		<S.Container span={12}>
			<S.CreateButton type="primary" onClick={onClick} icon={<PlusCircleFilled />}>
				{children}
			</S.CreateButton>
		</S.Container>
	);
};
