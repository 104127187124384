import carry_hover from '@/assets/img/ergonomic_tools/liberty_mutual/task/carry_hover.png';
import lower_hover from '@/assets/img/ergonomic_tools/liberty_mutual/task/lower_hover.png';
import lift_hover from '@/assets/img/ergonomic_tools/liberty_mutual/task/lift_hover.png';
import push_hover from '@/assets/img/ergonomic_tools/liberty_mutual/task/push_hover.png';
import pull_hover from '@/assets/img/ergonomic_tools/liberty_mutual/task/pull_hover.png';
import carry from '@/assets/img/ergonomic_tools/liberty_mutual/task/carry.png';
import lower from '@/assets/img/ergonomic_tools/liberty_mutual/task/lower.png';
import lift from '@/assets/img/ergonomic_tools/liberty_mutual/task/lift.png';
import push from '@/assets/img/ergonomic_tools/liberty_mutual/task/push.png';
import pull from '@/assets/img/ergonomic_tools/liberty_mutual/task/pull.png';

import { TaskName } from '../hooks/types/response';

export type TaskContent = {
	img: string;
	width: string;
	name: TaskName;
	img_hover: string;
};

export type TasksContentList = {
	[key: string]: TaskContent;
};

export const tasksContentList: TasksContentList = {
	[TaskName.lift]: {
		img: lift,
		width: '100%',
		name: TaskName.lift,
		img_hover: lift_hover
	},
	[TaskName.lower]: {
		img: lower,
		width: '100%',
		name: TaskName.lower,
		img_hover: lower_hover
	},
	[TaskName.push]: {
		img: push,
		width: '110%',
		name: TaskName.push,
		img_hover: push_hover
	},
	[TaskName.pull]: {
		img: pull,
		width: '90%',
		name: TaskName.pull,
		img_hover: pull_hover
	},
	[TaskName.carry]: {
		img: carry,
		width: '80%',
		name: TaskName.carry,
		img_hover: carry_hover
	}
};
