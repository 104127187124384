import React from 'react';
import { Modal } from '@/views/Users/components/Modal';
import { UserDataForm } from '@/views/Users/components/Modal/Form';
import type { FormInstance } from 'antd/es/form/Form';

interface ModalCreateProps {
	form: FormInstance;
	isOpen?: boolean;
	onClose?(): void;
	onFinish?(): void;
}

export function ModalCreate({ form, isOpen = false, onClose, onFinish }: ModalCreateProps) {
	return (
		<Modal title="Create user" isOpen={isOpen} onClose={onClose}>
			<UserDataForm form={form} onFinish={onFinish} />
		</Modal>
	);
}
