import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import { Select, Row, Col, DatePicker, Form, Descriptions, Radio } from 'antd';

import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import hooks from '@/components/Settings/Organizations/hooks';
import { useApplicationContext } from '@/context/Application';

const { useFormInstance } = Form;
const { useGetWorkstations } = hooks;

export const Information = ({ sectors, file, onChangeReportType, sectorId }) => {
	const { organization, company } = useApplicationContext();

	const form = useFormInstance();
	const workstations = useGetWorkstations(organization.id, company.id, sectorId);
	const [inicialSector, setInicialSector] = useState(form.getFieldValue(['sector_id']));

	useEffect(() => {
		if (sectorId && sectorId !== inicialSector) {
			form.resetFields(['workstation_id']);
			form.setFields([
				{
					name: ['workstation_id'],
					value: undefined
				}
			]);
			setInicialSector('');
		}
	}, [sectorId]);

	return (
		<Row justify="center" gutter={[5, 20]} style={{ marginTop: 20 }}>
			<Col sm={18}>
				<Row>
					<Col sm={24}>
						<label>{I18n.get('Enter the data related to the selected video below')}</label>
					</Col>
				</Row>
				<Row justify="center" align="middle">
					<Col sm={12} style={{ marginTop: '20px', paddingLeft: '60px' }}>
						<Descriptions column={1} size="small">
							<Descriptions.Item
								label={I18n.get('File')}
								contentStyle={{ fontWeight: 'bold' }}
								labelStyle={{ fontWeight: 'bold' }}
							>
								{file?.data.original_name}
							</Descriptions.Item>
							<Descriptions.Item label={I18n.get('Date')}>
								{moment(file?.data.createdAt).format('L')}
							</Descriptions.Item>
						</Descriptions>
					</Col>
					<Col sm={12} style={{ marginTop: '20px' }}>
						<Form.Item
							name="report_type"
							style={{
								display: 'flex',
								justifyContent: 'center'
							}}
						>
							<Radio.Group
								buttonStyle="solid"
								style={{
									marginLeft: '25px',
									marginTop: '20px',
									display: 'flex',
									justifyContent: 'center'
								}}
								onChange={onChangeReportType}
							>
								<Radio.Button
									value="ISO_11228"
									style={{
										width: '150px',
										textAlign: 'center'
									}}
								>
									ISO 11228-1
								</Radio.Button>
								<Radio.Button
									value="NIOSH"
									style={{
										width: '150px',
										textAlign: 'center'
									}}
								>
									NIOSH
								</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
				<Row justify="center" gutter={[10, 20]} style={{ marginTop: '10px', marginLeft: '20px' }}>
					<Col sm={12}>
						<Form.Item name="organization_id" label={I18n.get('Company')}>
							<Select disabled={true} placeholder={I18n.get('Company')}>
								<Select.Option value={organization?.id}>{organization?.name}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="sector_id"
							label={I18n.get('Sector')}
							rules={[
								{
									required: true,
									message: I18n.get('Select the sector')
								}
							]}
						>
							<SectorSelect sectors={sectors} loading={sectors.isLoading} disabled={sectors.isLoading} />
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							label={I18n.get('Workstation')}
							name="workstation_id"
							rules={[
								{
									required: true,
									message: I18n.get('Enter the workstation')
								}
							]}
						>
							<WorkstationSelect
								workstations={workstations?.data}
								sectorId={sectorId}
								loading={sectors.isLoading}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							label={I18n.get('Collection date')}
							name="collection_date"
							rules={[
								{
									required: true,
									message: I18n.get('Insert the date of collection')
								}
							]}
						>
							<DatePicker
								format={'L'}
								placeholder={I18n.get('Collection date')}
								style={{ width: '100%' }}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
