import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Diagnostic } from './styles';
import { TypeScores, DescriptionScores } from '../types';

interface ScoreProps {
	result: number;
}

export const Score: React.FC<ScoreProps> = ({ result = 0 }) => {
	let isResult = { type: TypeScores.EMPTY, description: DescriptionScores.NOT_FOUND };

	if (result >= 1 && result <= 2) {
		isResult = { type: TypeScores.SAFE, description: DescriptionScores.ACCEPTABLE };
	}

	if (result > 2 && result <= 4) {
		isResult = { type: TypeScores.INFO, description: DescriptionScores.LIGHT };
	}

	if (result > 4 && result <= 6) {
		isResult = { type: TypeScores.WARNING, description: DescriptionScores.AVERAGE };
	}

	if (result > 6 && result >= 7) {
		isResult = { type: TypeScores.DANGER, description: DescriptionScores.HIGH };
	}

	return <Diagnostic type={isResult.type}>{I18n.get(isResult.description)}</Diagnostic>;
};
