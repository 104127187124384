import { Tag } from 'antd';
import styled from 'styled-components';

export const TagRisk = styled(Tag)`
	span {
		color: ${({ brightcolors }) => (brightcolors === 2 ? 'black' : 'white')};
	}
`;

export const CustomButton = styled.div`
	&:hover {
		span {
			color: white;
		}
	}
`;
