import React from 'react';
import { Image, Card } from 'antd';

const { Meta } = Card;

interface PersonCardProps {
	url: string;
	altImage: string;
	title: string;
	subTitle?: string;
}

export const PersonCard: React.FC<PersonCardProps> = ({ url, altImage, title, subTitle, ...props }) => (
	<Card
		{...props}
		hoverable
		style={{ width: 120, height: '100%' }}
		cover={<Image preview alt={altImage} src={url} />}
	>
		<Meta title={title} description={subTitle} />
	</Card>
);
