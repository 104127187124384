import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { HeaderContainer, HeaderSubTitle, HeaderTitle, ImageStyled } from './styles';
import kinebotLogo from '@/assets/img/logo.png';
import { PROFILE_DEFAULT } from '@/utils/enum';
import { Logo } from './Logo';

type HeaderType = {
	title: string;
};

export const Header: React.FC<HeaderType> = ({ title }) => {
	const { organization, company } = useApplicationContext();
	const company_logo = company?.url_logo !== PROFILE_DEFAULT.URL ? company?.url_logo : undefined;
	const profile_logo = company_logo ?? organization?.url_logo;

	return (
		<HeaderContainer align="middle">
			<Col span={6}>
				<Logo src={kinebotLogo} side="left" />
			</Col>
			<Col span={12}>
				<HeaderTitle>{I18n.get('Result')}</HeaderTitle>
				<HeaderSubTitle>{I18n.get(title)}</HeaderSubTitle>
			</Col>
			<Col span={6} style={{ display: 'flex', justifyContent: 'end' }}>
				<ImageStyled src={profile_logo || PROFILE_DEFAULT.URL} preview={false} width={'auto'} height={'30%'} />
			</Col>
		</HeaderContainer>
	);
};
