export enum TypeScores {
	EMPTY = 'empty',
	SAFE = 'safe',
	INFO = 'info',
	WARNING = 'warning',
	DANGER = 'danger'
}

export enum DescriptionScores {
	NOT_FOUND = 'Not found',
	ACCEPTABLE = 'Acceptable',
	LIGHT = 'Light',
	AVERAGE = 'Average',
	HIGH = 'High'
}

export type DiagnosticColors = {
	color: string;
	border: string;
};

export interface DiagnosticProps {
	type: TypeScores;
}
