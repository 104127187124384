import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Image } from 'antd';

import { CustomImageContainer, CustomTaskContainer, TaskTitle } from './styles';
import { useLibertyMutualContext } from '../../../context';
import { tasksContentList } from '../../tasksContentList';

interface TaskImageProps {
	width: string;
}

export const TaskImage: React.FC<TaskImageProps> = ({ width }) => {
	const { libertyMutual } = useLibertyMutualContext();
	const task = libertyMutual.task;
	return (
		<CustomTaskContainer selected_task={task.name}>
			<CustomImageContainer>
				<Image
					width={width}
					preview={false}
					draggable={false}
					alt={I18n.get(task.description)}
					src={tasksContentList[task.name].img_hover}
				/>
				<TaskTitle>{I18n.get(task.description)}</TaskTitle>
			</CustomImageContainer>
		</CustomTaskContainer>
	);
};
