import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Descriptions } from 'antd';
import { loadList, couplingList } from './contants';

interface ParametersProps {
	repetition: number;
	coupling: number;
	force: number;
}

export const Parameters: React.FC<ParametersProps> = ({ repetition = 0, force = 0, coupling = 0 }) => {
	const selectedLoad = loadList[force - 1];
	const selectedCoupling = couplingList[coupling - 1];
	return (
		<Row>
			<Col span={24}>
				<Descriptions bordered layout="vertical">
					<Descriptions.Item label={I18n.get('Repetitions')}>
						<span>
							{repetition} / {I18n.get('minute')}
						</span>
					</Descriptions.Item>
					<Descriptions.Item label={I18n.get('Coupling')}>
						<span>{I18n.get(selectedCoupling?.label)}</span>
					</Descriptions.Item>
					<Descriptions.Item label={I18n.get('Load')}>
						<span>{I18n.get(selectedLoad?.label)}</span>
					</Descriptions.Item>
				</Descriptions>
			</Col>
		</Row>
	);
};
