import styled from 'styled-components';
import { Col } from 'antd';

export const ConfirmColumn = styled(Col)`
	position: relative;
	overflow-y: scroll;
	margin: 15px 0;
	max-height: 160px;
	padding-right: 3px;
	width: 100%;

	::-webkit-scrollbar {
		width: 2px;
	}

	::-webkit-scrollbar-track {
		border-radius: 2px;
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		background: #4156f2;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
