import React from 'react';
import { Form, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/Application';
import { useWorkstations } from '@/hooks/v1';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

export const Workstations: React.FC = () => {
	const { organization, company } = useApplicationContext();

	const form = useFormInstance();
	const sectorId = useWatch(['sector_id'], form);

	const { data: workstations, isFetching } = useWorkstations({
		organization_id: organization?.id,
		company_id: company?.id,
		sector_id: sectorId
	});

	function handleWorkstationSelect(id: string): void {
		form.setFieldValue(['workstation_id'], id);
	}

	function handleClearWorkstations(): void {
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<CustomFormItem
				name={['workstation_id']}
				label={I18n.get('Workstation')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the workstation')
					}
				]}
			>
				<WorkstationSelect
					size="large"
					sectorId={sectorId}
					loading={isFetching}
					workstations={workstations}
					handleSelectWorkstation={handleWorkstationSelect}
					handleClearWorkstations={handleClearWorkstations}
				/>
			</CustomFormItem>
		</Col>
	);
};
