import { Space } from 'antd';
import styled from 'styled-components';

interface GradeProps {
	children: number;
}

function scaleReba(value: number): string {
	if (value >= 0 && value <= 1) {
		return 'rgba(113, 113, 113, 1)';
	}

	if (value >= 2 && value <= 3) {
		return 'rgba(75, 192, 86, 1)';
	}

	if (value >= 4 && value <= 7) {
		return 'rgba(200, 150, 86, 1)';
	}

	if (value >= 8 && value <= 10) {
		return 'rgba(255, 40, 72, 1)';
	}

	return 'rgba(153, 102, 255, 1)';
}

export const Label = styled.h2`
	text-align: center;
	font-weight: bold;
	margin: 0;
`;

export const Grade = styled(Label)`
	color: ${({ children }: GradeProps) => scaleReba(children)};
`;

export const Container = styled(Space)`
	display: flex;
	justify-content: center;
`;
