import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Container, Title } from './components';
import { UserCreateModal } from './UserCreateModal';

export function Header() {
	return (
		<Container>
			<Title>{I18n.get('Manage users')}</Title>
			<UserCreateModal />
		</Container>
	);
}
