import React, { useRef, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Row, Col, message } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { ErgonomicToolsModal } from './ErgonomicToolsModal';
import { clearReports } from '@/redux/reports/actions';
import { useSocketFile } from '@/hooks/useSocket';
import { Title } from '@/components/Typography';
import { Filter } from './Filter';
import Api from '@/services/api';
import { List } from './List';

import { useDeleteFile } from '@/hooks/useDeleteFile';

export function Report() {
	const { organization, company } = useApplicationContext();

	const history = useHistory();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const { file } = useSocketFile();
	const isMounted = useRef(false);

	const deleteFile = useDeleteFile({
		organization_id: organization.id,
		company_id: company.id
	});

	const urlParams = new URLSearchParams(search);
	const url_filters = Object.fromEntries(urlParams.entries());

	const [files, setFiles] = useState({});
	const [loading, setLoading] = useState(true);
	const [errors, setErrors] = useState(null);

	const [maxPage, setMaxPage] = useState(10);
	const [page, setPage] = useState(0);

	const [deletedFile, setDeletedFile] = useState(null);
	const [selectedReport, setSelectedReport] = useState({
		file: null,
		type: null,
		visibleModal: false
	});

	useEffect(() => {
		return () => {
			isMounted.current = true;
		};
	}, []);

	const getFilesCallback = useCallback(() => {
		const fixedOffset = page !== 0 ? page - 1 : page;
		let url = `/file/${organization.id}/${company.id}?organization_id=${organization.id}&company_id=${company.id}&limit=${maxPage}&offset=${fixedOffset}&`;

		if (search && !search.includes('cleared=true')) {
			url = `/file/${organization.id}/${company.id}/${search}&limit=${maxPage}&offset=${fixedOffset}`;
		}

		Api.get(url)
			.then((res) => {
				!isMounted.current && setFiles(res.data);
			})
			.catch((err) => {
				!isMounted.current && setErrors(err);
			})
			.finally(() => {
				!isMounted.current && setLoading(false);
			});
	}, [maxPage, page]);

	useEffect(() => {
		organization && company && getFilesCallback();
	}, [maxPage, page]);

	useEffect(() => {
		if (deletedFile) {
			message.success(I18n.get('File deleted successfully'));
			setDeletedFile(null);
			getFilesCallback();
		}
	}, [deletedFile]);

	useEffect(() => {
		if (errors) {
			const description = errors.response?.data?.message || errors.message;
			message.error(I18n.get(description));
			setErrors(null);
		}
	}, [errors]);

	useEffect(() => {
		if (file) {
			setFiles((data) => ({
				...data,
				rows: data.rows?.map((item) =>
					item.id === file.id
						? {
								...item,
								status: file.status,
								workstation: file.workstation,
								sector: {
									name: file.sector?.name
								}
						  }
						: item
				)
			}));
		}
	}, [file]);

	const onCloseReport = () => {
		setSelectedReport((current) => ({
			...current,
			visible: false,
			file: null,
			type: null
		}));
		dispatch(clearReports());
	};

	async function handleOnDeleteFile(id) {
		try {
			const result = await deleteFile.mutateAsync(id);
			setDeletedFile(result);
		} catch (error) {
			setErrors(error);
		}
	}

	function onRedirectPreliminary(fileId) {
		history.push(`/reporting/preliminary-ergonomic-analysis/${fileId}`);
	}

	const onDownload = async ({ id, original_name }) => {
		try {
			const { data } = await Api.post('/upload/create-url-download', {
				file_id: id,
				company_id: company.id,
				original_name: original_name,
				organization_id: organization?.id
			});

			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = data;
			archive.click();
			archive.remove();
		} catch (error) {
			setErrors(error);
		}
	};

	const onSelect = (file) => {
		setSelectedReport((current) => ({
			...current,
			file: file,
			visible: true,
			type: file.type
		}));
	};

	return (
		<Row>
			{organization && company && (
				<>
					<ErgonomicToolsModal
						onClose={onCloseReport}
						file={selectedReport.file}
						report={selectedReport.type}
						visible={selectedReport.visible}
					/>
					<Col span={24}>
						<Title level={3}>Files</Title>
					</Col>
					<Col span={24}>
						<Filter filters={url_filters} setPage={setPage} />
					</Col>
					<Col span={24}>
						<List
							data={files}
							loading={loading}
							onDelete={handleOnDeleteFile}
							onSelect={onSelect}
							onDownload={onDownload}
							onRedirectPreliminary={onRedirectPreliminary}
							setMaxPage={setMaxPage}
							setPage={setPage}
							maxPage={maxPage}
							page={page}
						/>
					</Col>
				</>
			)}
		</Row>
	);
}
