export enum ToolsNames {
	rula = 'rula',
	reba = 'reba',
	niosh = 'niosh',
	kim_pp = 'kim_pp',
	kim_mho = 'kim_mho',
	angle_time = 'angle_time',
	strain_index = 'strain_index',
	liberty_mutual = 'liberty_mutual'
}

export type ErgonomicTools =
	| 'reba'
	| 'niosh'
	| 'kim-pp'
	| 'kim-mho'
	| 'angle-time'
	| 'score-parts'
	| 'strain-index'
	| 'liberty-mutual'
	| 'personalised-report';

export type ErgonomicTool = {
	id: string;
	name: ToolsNames;
	description: ErgonomicTools;
	title: string;
	subtitle: string;
	category_id: string;
	created_at: Date;
	updated_at: Date;
	deleted_at: Date | null;
};
