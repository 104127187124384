import React, { useEffect, useState } from 'react';
import { Select, Row, Col, DatePicker, Form, Descriptions } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import * as S from './styles';
import hooks from '@/components/Settings/Organizations/hooks';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { useApplicationContext } from '@/context/Application';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';

const { useFormInstance } = Form;
const { useGetWorkstations } = hooks;

interface InformationsProps {
	file?: {
		original_name: string;
		created_at: string;
	};
	sectors: any;
	sectorId: string;
}

export const Informations: React.FC<InformationsProps> = ({ file = {}, sectors, sectorId }) => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();
	const workstations = useGetWorkstations(organization.id, company.id, sectorId);
	const [inicialSector, setInicialSector] = useState(form.getFieldValue(['sector_id']));

	useEffect(() => {
		if (sectorId && sectorId !== inicialSector) {
			form.resetFields(['workstation_id']);
			form.setFields([
				{
					name: ['workstation_id'],
					value: undefined
				}
			]);
			setInicialSector('');
		}
	}, [sectorId]);

	function handleCreationDateFormat(date = ''): string {
		return !!date ? moment(date).format('L') : '';
	}

	return (
		<S.Container justify="center" align="middle" gutter={[0, 20]}>
			<Col sm={18}>
				<Row>
					<Col span={24}>
						<label>{I18n.get('Enter the data related to the selected video below')}</label>
					</Col>
					<Col span={24} style={{ marginTop: '20px' }}>
						<Descriptions column={1} size="small">
							<Descriptions.Item
								label={I18n.get('File')}
								labelStyle={{ fontWeight: 'bold' }}
								contentStyle={{ fontWeight: 'bold' }}
							>
								{file?.original_name ?? ''}
							</Descriptions.Item>
							<Descriptions.Item label={I18n.get('Date')}>
								{handleCreationDateFormat(file?.created_at)}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
			</Col>
			<Col sm={18}>
				<Row gutter={[10, 20]}>
					<Col sm={12}>
						<Form.Item name="organization_id" label={I18n.get('Company')}>
							<Select disabled={true} placeholder={I18n.get('Company')}>
								<Select.Option value={organization.id}>{organization.name}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="sector_id"
							label={I18n.get('Sector')}
							rules={[
								{
									message: I18n.get('Select the sector'),
									required: true
								}
							]}
						>
							<SectorSelect sectors={sectors} loading={sectors.isLoading} disabled={sectors.isLoading} />
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="workstation_id"
							label={I18n.get('Workstation')}
							rules={[
								{
									message: I18n.get('Enter the workstation'),
									required: true
								}
							]}
						>
							<WorkstationSelect
								sectorId={sectorId}
								loading={sectors.isLoading}
								workstations={workstations?.data}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="collection_date"
							label={I18n.get('Collection date')}
							rules={[
								{
									message: I18n.get('Insert the date of collection'),
									required: true
								}
							]}
						>
							<DatePicker
								format="L"
								style={{ width: '100%' }}
								placeholder={I18n.get('Collection date')}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</S.Container>
	);
};
