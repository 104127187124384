import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form } from 'antd';

import { Title, Paragraph, Text } from '@/components/Typography';
import hooks from '@/components/views/PreliminaryAnalysis/hooks';

import { useApplicationContext } from '@/context/Application';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';

import { SelectOption } from '../_components/SelectOption';
import { InjuriesGroup } from '../_components/InjuriesGroup';
import { CollapseCheck } from '../_components/CollapseCheck';
import { RiskLevelCard } from '../_components/RiskLevelCard';

import { baseFormName, injuriesKeys, stepDescription } from '../contants';

const { useRiskLevel } = hooks;
const { useFormInstance } = Form;

export function OrganizationalInfluences() {
	const { organization, company } = useApplicationContext();
	const { file, getLevelRiskFields } = usePreliminaryAnalysis();

	const form = useFormInstance();
	const formName = ['organizational_influences'];

	const { isLoading, data, isError, ...riskLevel } = useRiskLevel(organization?.id, company?.id, file?.id);

	const [levelRisk, setLevelRisk] = useState({
		step: '',
		consequence_id: '',
		probability_id: '',
		exhibition_id: ''
	});

	useEffect(() => {
		const { step, consequence_id, probability_id, exhibition_id } = levelRisk;

		if (step && consequence_id && probability_id && exhibition_id) {
			const formatStep = Array.isArray(step) ? [...formName, ...step] : [...formName, step];

			const payload = {
				step: formatStep,
				consequence_id,
				probability_id,
				exhibition_id
			};

			riskLevel.mutate(payload, {
				onSuccess: (data) => {
					form.setFields([
						{
							name: [baseFormName, ...formatStep, 'result'],
							value: data?.risk_level
						}
					]);
				}
			});
		}
	}, [levelRisk.step, levelRisk.consequence_id, levelRisk.probability_id, levelRisk.exhibition_id]);

	function updateLevelRisk(step, attribute, value) {
		const stepName = Array.isArray(step) ? [...step] : [step];
		const { consequence, exhibition, probability } = getLevelRiskFields(form, baseFormName, formName, stepName);
		if (!levelRisk.step && !levelRisk.consequence_id && !levelRisk.exhibition_id && !levelRisk.probability_id) {
			const options = ['consequence_id', 'exhibition_id', 'probability_id'];
			const forms = options
				.filter((item) => item !== attribute)
				.map((value) => ({
					name: [baseFormName, ...formName, step, value],
					value: undefined
				}));

			form.setFields(forms);
		}

		setLevelRisk({
			exhibition_id: exhibition,
			consequence_id: consequence,
			probability_id: probability,
			step,
			[attribute]: value
		});
	}

	const {
		fatigue_complaints,
		absenteeism,
		musculoskeletal_complaints,
		complaints_of_irritability,
		non_conforming_products,
		unexcused_absences
	} = injuriesKeys;

	function onResetFields(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'consequence_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'probability_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'exhibition_id'],
				value: undefined
			},

			{
				name: [baseFormName, ...formName, step, 'injuries'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'result'],
				value: undefined
			}
		]);
	}

	function setCheckedCollapse(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'checked'],
				value: true
			}
		]);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={16} offset={2}>
				<Title level={4} align="start">
					Influence of organizational factors
				</Title>
			</Col>
			<Col span={16} offset={2}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col span={16} offset={2}>
				<Row gutter={[10, 10]}>
					{/* production_standards_are_not_clear */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'production_standards_are_not_clear', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('production_standards_are_not_clear');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Production standards are not clear or understood by workers">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'production_standards_are_not_clear',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'production_standards_are_not_clear',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('production_standards_are_not_clear')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'production_standards_are_not_clear',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'production_standards_are_not_clear',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('production_standards_are_not_clear');
															updateLevelRisk(
																'production_standards_are_not_clear',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'production_standards_are_not_clear',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'production_standards_are_not_clear',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('production_standards_are_not_clear');
															updateLevelRisk(
																'production_standards_are_not_clear',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'production_standards_are_not_clear',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'production_standards_are_not_clear',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('production_standards_are_not_clear');
															updateLevelRisk(
																'production_standards_are_not_clear',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'production_standards_are_not_clear',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'production_standards_are_not_clear']}
												step={[...formName, 'production_standards_are_not_clear']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* operating_mode_drive_is_insufficient */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'operating_mode_drive_is_insufficient', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('operating_mode_drive_is_insufficient');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="The operative mode descriptions are insufficient or wrong">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'operating_mode_drive_is_insufficient',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'operating_mode_drive_is_insufficient',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('operating_mode_drive_is_insufficient')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'operating_mode_drive_is_insufficient',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'operating_mode_drive_is_insufficient',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('operating_mode_drive_is_insufficient');
															updateLevelRisk(
																'operating_mode_drive_is_insufficient',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'operating_mode_drive_is_insufficient',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'operating_mode_drive_is_insufficient',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('operating_mode_drive_is_insufficient');
															updateLevelRisk(
																'operating_mode_drive_is_insufficient',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'operating_mode_drive_is_insufficient',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'operating_mode_drive_is_insufficient',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('operating_mode_drive_is_insufficient');
															updateLevelRisk(
																'operating_mode_drive_is_insufficient',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'operating_mode_drive_is_insufficient',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'operating_mode_drive_is_insufficient']}
												step={[...formName, 'operating_mode_drive_is_insufficient']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* time_to_carry_out_the_activities_is_inadequate */}
					<Col span={24}>
						<Form.Item
							name={[
								baseFormName,
								...formName,
								'time_to_carry_out_the_activities_is_inadequate',
								'checked'
							]}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('time_to_carry_out_the_activities_is_inadequate');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="The time to carry out the activities is inadequate">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'time_to_carry_out_the_activities_is_inadequate',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'time_to_carry_out_the_activities_is_inadequate',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('time_to_carry_out_the_activities_is_inadequate')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'time_to_carry_out_the_activities_is_inadequate',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'time_to_carry_out_the_activities_is_inadequate',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse(
																'time_to_carry_out_the_activities_is_inadequate'
															);
															updateLevelRisk(
																'time_to_carry_out_the_activities_is_inadequate',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'time_to_carry_out_the_activities_is_inadequate',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'time_to_carry_out_the_activities_is_inadequate',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse(
																'time_to_carry_out_the_activities_is_inadequate'
															);
															updateLevelRisk(
																'time_to_carry_out_the_activities_is_inadequate',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'time_to_carry_out_the_activities_is_inadequate',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'time_to_carry_out_the_activities_is_inadequate',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse(
																'time_to_carry_out_the_activities_is_inadequate'
															);
															updateLevelRisk(
																'time_to_carry_out_the_activities_is_inadequate',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'time_to_carry_out_the_activities_is_inadequate',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'time_to_carry_out_the_activities_is_inadequate']}
												step={[...formName, 'time_to_carry_out_the_activities_is_inadequate']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/*  pace_of_work_is_accelerated */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'pace_of_work_is_accelerated', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('pace_of_work_is_accelerated');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="The pace of work is accelerated">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'pace_of_work_is_accelerated',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'pace_of_work_is_accelerated',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('pace_of_work_is_accelerated')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'pace_of_work_is_accelerated',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'pace_of_work_is_accelerated',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('pace_of_work_is_accelerated');
															updateLevelRisk(
																'pace_of_work_is_accelerated',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'pace_of_work_is_accelerated',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'pace_of_work_is_accelerated',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('pace_of_work_is_accelerated');
															updateLevelRisk(
																'pace_of_work_is_accelerated',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'pace_of_work_is_accelerated',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'pace_of_work_is_accelerated',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('pace_of_work_is_accelerated');
															updateLevelRisk(
																'pace_of_work_is_accelerated',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'pace_of_work_is_accelerated', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'pace_of_work_is_accelerated']}
												step={[...formName, 'pace_of_work_is_accelerated']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* tasks_different_technical_available */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'tasks_different_technical_available', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('tasks_different_technical_available');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="The content of the tasks is different from the technical means available">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'tasks_different_technical_available',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'tasks_different_technical_available',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('tasks_different_technical_available')
												}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'tasks_different_technical_available',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'tasks_different_technical_available',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('tasks_different_technical_available');
															updateLevelRisk(
																'tasks_different_technical_available',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'tasks_different_technical_available',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'tasks_different_technical_available',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('tasks_different_technical_available');
															updateLevelRisk(
																'tasks_different_technical_available',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'tasks_different_technical_available',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'tasks_different_technical_available',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('tasks_different_technical_available');
															updateLevelRisk(
																'tasks_different_technical_available',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'tasks_different_technical_available',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'tasks_different_technical_available']}
												step={[...formName, 'tasks_different_technical_available']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* high_cognitive_demand */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'high_cognitive_demand', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('high_cognitive_demand');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Task with high cognitive demand">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'high_cognitive_demand', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'high_cognitive_demand',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('high_cognitive_demand')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'high_cognitive_demand',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'high_cognitive_demand',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('high_cognitive_demand');
															updateLevelRisk(
																'high_cognitive_demand',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'high_cognitive_demand',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'high_cognitive_demand',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('high_cognitive_demand');
															updateLevelRisk(
																'high_cognitive_demand',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'high_cognitive_demand',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'high_cognitive_demand',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('high_cognitive_demand');
															updateLevelRisk(
																'high_cognitive_demand',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'high_cognitive_demand', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'high_cognitive_demand']}
												step={[...formName, 'high_cognitive_demand']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
