import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useQuery, useMutation, UseQueryResult, UseMutationResult } from '@tanstack/react-query';

import { Services } from './services';
import { BaseParameters, ErrorResponse, FileDTO, ReportDTO, WorstGradeDTO, CalculationAnglesResponse } from './types';

const { getFile, getReport, calculationAngles, getRiskRangeSettings, getWorstGrade, getReportDownload } = Services;

export const useFile = (parameters: BaseParameters): UseQueryResult<FileDTO, Error> => {
	const { organization_id, company_id, file_id } = parameters;
	return useQuery<FileDTO, Error>(['file', parameters], () => getFile(parameters), {
		enabled: !!organization_id && !!company_id && !!file_id
	});
};

export const useRecoveryReport = (parameters: BaseParameters): UseQueryResult<ReportDTO, ErrorResponse> => {
	const { organization_id, company_id, file_id } = parameters;
	return useQuery(['reba-recovery', parameters], () => getReport(parameters), {
		enabled: !!organization_id && !!company_id && !!file_id,
		retry: false,
		refetchOnWindowFocus: false
	});
};

export const useRebaCalculate = (): UseMutationResult<CalculationAnglesResponse, ErrorResponse> => {
	return useMutation((payload: any) => calculationAngles(payload));
};

export const useRiskRangeSettings = (payload: any): UseQueryResult<any, ErrorResponse> => {
	const enabled = !!payload.organizationId && !!payload.companyId;
	return useQuery(['risk_ranges', 'risk_ranges_settings', payload], () => getRiskRangeSettings(payload), {
		enabled,
		retry: false,
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};

export const useWorstGrade = (parameters: BaseParameters): UseQueryResult<WorstGradeDTO, ErrorResponse> => {
	const { organization_id, company_id, file_id } = parameters;
	return useQuery(['reba-worst-grade', parameters], () => getWorstGrade(parameters), {
		enabled: !!organization_id && !!company_id && !!file_id
	});
};

export const useReportDownload = (parameters: BaseParameters): UseMutationResult<Blob, ErrorResponse> => {
	return useMutation((payload: any) => getReportDownload({ ...parameters, ...payload }), {
		onSuccess: (data) => {
			const blob = new Blob([data], { type: 'application/pdf' });
			window.open(URL.createObjectURL(blob));
			const response = I18n.get('Document created successfully');
			message.success(response);
		},
		onError: (err) => {
			const response = I18n.get(err.response.data.message);
			message.error(response);
		}
	});
};
