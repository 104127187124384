import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Image, Row } from 'antd';

import carrySVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/carry.svg';
import pushSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/push.svg';
import pullSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/pull.svg';

import { TaskNameMapper } from '../../../../hooks/types/response';
import { useLibertyMutualContext } from '../../../../context';
import { Body, CardContainer, Header } from '../styles';

type Style = {
	[key: string]: {
		distance: React.CSSProperties;
		height: React.CSSProperties;
	};
};

interface DisplacementCardProps {
	task_name: TaskNameMapper.Pull | TaskNameMapper.Push | TaskNameMapper.Carry;
}

export const DisplacementCard: React.FC<DisplacementCardProps> = ({ task_name }) => {
	const {
		libertyMutualResult: { summary }
	} = useLibertyMutualContext();

	const taskSVGMapper = {
		[TaskNameMapper.Push]: pushSVG,
		[TaskNameMapper.Pull]: pullSVG,
		[TaskNameMapper.Carry]: carrySVG,
	};

	const stateSVGAbsoluteValues: Style = {
		[TaskNameMapper.Pull]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '5%',
				left: '43%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '35%',
				left: '83%'
			}
		},
		[TaskNameMapper.Push]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '5%',
				right: '26%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '33%',
				left: '58%'
			}
		},
        [TaskNameMapper.Carry]: {
			distance: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '5%',
				right: '26%'
			},
			height: {
				fontWeight: 400,
				position: 'absolute',
				bottom: '33%',
				left: '50%'
			}
		}
	};

	return (
		<CardContainer
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between'
			}}
		>
			<Header>{I18n.get('Displacement')}</Header>
			<Body style={{ padding: '1rem 3rem 2rem 3rem' }}>
				<Row justify="center" align="middle">
					<Image
						width="180px"
						preview={false}
						draggable={false}
						alt={I18n.get(task_name)}
						src={taskSVGMapper[task_name]}
					/>
					<span style={stateSVGAbsoluteValues[task_name].distance}>{summary.horizontal_distance ?? 0}</span>
					<span style={stateSVGAbsoluteValues[task_name].height}>{summary.vertical_hand_height ?? 0}</span>
				</Row>
			</Body>
		</CardContainer>
	);
};
