import React, { useEffect, useRef } from 'react';
import { Row, Col, Form, Input, DatePicker } from 'antd';
import { I18n } from '@aws-amplify/core';

import hook from '@/components/Settings/Organizations/hooks';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import hooks from '@/components/views/PreliminaryAnalysis/hooks';
import { Title, Paragraph, Text } from '@/components/Typography';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { useApplicationContext } from '@/context/Application';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';

const { useSectors } = hooks;
const { useFormInstance } = Form;
const { useGetWorkstations } = hook;

export const Informations: React.FC = () => {
	const { organization, company } = useApplicationContext();
	const form = useFormInstance();
	const screens = useBreakpoint();
	const offset = screens.xxl ? 0 : 4;

	const { file } = usePreliminaryAnalysis();
	const sectors = useSectors(organization?.id, company?.id);
	const sectorId = Form.useWatch(['file', 'sector_id'], form);
	const workstations = useGetWorkstations(organization.id, company.id, sectorId);

	const setInicialSector = useRef(form.getFieldValue(['file', 'sector_id']));

	useEffect(() => {
		setInicialSector.current = sectorId;
	}, [sectorId]);

	const clearFunction = () => {
		const newSectorId = form.getFieldValue(['file', 'sector_id']);
		form.resetFields(['file', 'workstation_id']);
		form.setFields([
			{
				name: ['file', 'workstation_id'],
				value: undefined
			}
		]);
		form.setFields([
			{
				name: ['file', 'sector_id'],
				value: newSectorId
			}
		]);
	};

	return (
		<Row gutter={[0, 10]}>
			<Col span={12} offset={4}>
				<Title level={4}>Informations</Title>
			</Col>
			<Col span={12} offset={4}>
				<Paragraph>Enter the data related to the selected video below</Paragraph>
			</Col>
			<Col span={12} offset={4}>
				<Text strong style={{ display: 'block', padding: '5px 0' }}>
					{I18n.get('Company')}: {organization?.name}
				</Text>
				<Text strong style={{ display: 'block', padding: '5px 0' }}>
					{I18n.get('File')}: {file?.original_name}
				</Text>
			</Col>
			<Row gutter={[10, 0]}>
				<Col sm={12} md={12} lg={12} xl={12} xxl={6} offset={4}>
					<Form.Item
						name={['file', 'sector_id']}
						label={I18n.get('Sector')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Select the sector'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<SectorSelect
							onClick={() => clearFunction()}
							sectors={sectors}
							loading={sectors.isLoading}
							disabled={sectors.isLoading}
						/>
					</Form.Item>
				</Col>
				<Col sm={12} md={12} lg={12} xl={12} xxl={6} offset={offset}>
					<Form.Item
						name={['preliminary_analysis', 'cellule_name']}
						label={I18n.get('Cellule')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the cell'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col sm={12} md={12} lg={12} xl={12} xxl={6} offset={4}>
					<Form.Item
						name={['file', 'workstation_id']}
						label={I18n.get('Workstation')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the workstation'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<WorkstationSelect
							sectorId={sectorId}
							loading={sectors.isLoading}
							workstations={workstations?.data}
						/>
					</Form.Item>
				</Col>
				<Col sm={12} md={12} lg={12} xl={12} xxl={6} offset={offset}>
					<Form.Item
						name={['preliminary_analysis', 'activity_name']}
						label={I18n.get('Activity')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the activity'));
									}
									return Promise.resolve();
								}
							})
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col sm={12} md={12} lg={12} xl={12} xxl={6} offset={4}>
					<Form.Item
						name={['preliminary_analysis', 'role_name']}
						label={I18n.get('Responsibility')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the responsibility'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<Input />
					</Form.Item>
				</Col>
				<Col sm={12} md={12} lg={12} xl={12} xxl={6} offset={offset}>
					<Form.Item
						label={I18n.get('Collection date')}
						name={['preliminary_analysis', 'collection_date']}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Enter the collection date'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<DatePicker
							format={'L'}
							style={{ width: '100%' }}
							placeholder={''}
							disabledDate={(current) => current && current.valueOf() >= Date.now()}
						/>
					</Form.Item>
				</Col>
				<Col sm={12} md={12} lg={12} xl={12} xxl={6} offset={4}>
					<Form.Item
						name={['preliminary_analysis', 'analyst_name']}
						label={I18n.get('Analyst')}
						rules={[
							() => ({
								validator(_, value) {
									if (!value) {
										return Promise.reject(I18n.get('Insert the analyst'));
									}

									return Promise.resolve();
								}
							})
						]}
					>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</Row>
	);
};
