import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getCompanies(organization_id: string) {
	const url = `/company/user_list/${organization_id}`;
	const { data } = await Api.get(url);
	return data;
}

export function useCompanies(organization_id: string) {
	const enabled = !!organization_id;

	const companies = useQuery(['companies', organization_id], () => getCompanies(organization_id), {
		enabled
	});
	return { companies };
}
