import React, { useEffect } from 'react';
import * as I from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import {
	useDownloadPDF,
	useConsolidateReport,
	PersonalisedReportResultDownloadPDFDTO,
	PersonalisedReportResultConsolidateDTO
} from './hooks';
import { usePersonalisedReportResultContext } from './context';
import { usePersonalisedReportContext } from '../../../context';
import { useApplicationContext } from '@/context/Application';

export const DownloadPDF: React.FC = () => {
	const { organization, company } = useApplicationContext();
	const { report_id, file_id } = usePersonalisedReportContext();
	const { personalisedReportResult, browserLanguage, isLoading, handleLoadingStatus } =
		usePersonalisedReportResultContext();

	const { mutateAsync: downloadPDF, isLoading: downloadingPDF } = useDownloadPDF();
	const { mutateAsync: consolidateReport, isLoading: consolidatingReport } = useConsolidateReport();

	useEffect(() => {
		handleLoadingStatus(downloadingPDF || consolidatingReport);
	}, [downloadingPDF, consolidatingReport]);

	async function handleDownload(): Promise<void> {
		const payload: PersonalisedReportResultDownloadPDFDTO = {
			file_id,
			report_id,
			locale: browserLanguage,
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_result_id: personalisedReportResult.id
		};
		await downloadPDF(payload);
	}

	async function handleConsolidate(): Promise<void> {
		const payload: PersonalisedReportResultConsolidateDTO = {
			file_id,
			report_id,
			locale: browserLanguage,
			company_id: company?.id,
			organization_id: organization?.id,
			custom_report_result_id: personalisedReportResult.id
		};
		await consolidateReport(payload);
	}

	const renderConsolidateButton = (): React.ReactNode => {
		const props = {
			style: {
				color: '#2cc852',
				borderRadius: '0.25rem',
				border: '1px solid #2cc852'
			},
			icon: <I.LockOutlined style={{ color: '#2cc852' }} />,
			title: 'Consolidate analysis'
		};

		if (personalisedReportResult.consolidated) {
			props.style = {
				color: '#828282',
				borderRadius: '0.25rem',
				border: '1px solid #828282'
			};
			props.icon = <I.UnlockOutlined style={{ color: '#828282' }} />;
			props.title = 'Deconsolidate';
		}

		const { icon, style, title } = props;
		return (
			<Button
				icon={icon}
				style={style}
				onClick={handleConsolidate}
				loading={isLoading && consolidatingReport}
				disabled={isLoading || consolidatingReport || downloadingPDF}
			>
				{I18n.get(title)}
			</Button>
		);
	};

	return (
		<Row justify="end" gutter={[16, 16]}>
			<Col>
				<Button
					style={{
						color: '#2F54EB',
						borderRadius: '0.25rem',
						border: '1px solid #2F54EB'
					}}
					loading={isLoading && downloadingPDF}
					disabled={isLoading || downloadingPDF || consolidatingReport}
					onClick={handleDownload}
					icon={<I.DownloadOutlined style={{ color: '#2F54EB' }} />}
				>
					{I18n.get('Download PDF')}
				</Button>
			</Col>
			<Col>{renderConsolidateButton()}</Col>
		</Row>
	);
};
