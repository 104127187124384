export const colorsRiskDegree = {
	not_identified: {
		color: 'rgba(113, 113, 113, 0.2)',
		border: 'rgba(107, 107, 107, 0.7)'
	},
	safe: {
		color: 'rgba(44, 200, 86, 0.5)',
		border: 'rgba(44, 200, 86, 1)'
	},
	info: {
		color: 'rgba(255, 222, 49, 0.5)',
		border: 'rgba(255, 222, 49, 1)'
	},
	warning: {
		color: 'rgba(255, 40, 72, 0.5)',
		border: 'rgba(255, 40, 72, 1)'
	},
	danger: {
		color: 'rgba(153, 102, 255, 0.5)',
		border: 'rgba(153, 102, 255, 1)'
	}
};
