import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import Api from '@/services/api';
import { ExposureAverage } from './ExposureAverage';
import { JobInformations } from '../Components/JobInformations';
import { FileData } from '../Components/FileData';
import { Charts } from './Charts';
import { Options } from '../Components/Options';
import { Conclusion } from '../Components/Conclusion';
import { References } from './References';

import { formatSecondsDuration } from '@/utils/mask';
import hooks from '@/components/Settings/Organizations/hooks';
import { setRiskDegree, setData, prev } from '@/redux/reports/actions';
import { useApplicationContext } from '@/context/Application';
import { useGetRiskDegreeWorkstation, useRiskDegreeBodyParts } from '../hooks';
import { collectionDateParse, fileDurationParse, fileSizeFormat, sectorJSON } from '@/redux/reports/selectors';

const { useGetWorkstations } = hooks;

const Container = styled(Row)`
	padding: 25px 10px 10px 10px;
`;

export function Diagnostic() {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [selectedWorkstation, setSelectedWorkstation] = useState();
	const [workstationName, setWorkstationName] = useState();

	const { parameters, informations } = useSelector((state) => state.reports.customized);

	const file = useSelector((state) => state.reports.file);
	const sector = useSelector(sectorJSON);
	const fileSize = useSelector(fileSizeFormat);
	const fileDuration = useSelector(fileDurationParse);
	const collectionDate = useSelector(collectionDateParse);

	const data = useSelector((state) => state.reports.data);
	const options = useSelector((state) => state.reports.options);
	const user = useSelector((state) => state.auth.user.user);

	const { organization, company } = useApplicationContext();
	const workstations = useGetWorkstations(organization.id, company.id, sector.id);

	const { companyInfo, workstation } = useSelector(
		(state) => ({
			workstation: state.reports.customized.informations.workstation,
			companyInfo: state.reports.customized.informations.company
		}),
		shallowEqual
	);

	useEffect(() => {
		workstation && setSelectedWorkstation(workstation);
	}, [workstation]);

	useEffect(() => {
		if (selectedWorkstation) {
			const findWorkstation = workstations.data?.find((workstations) => workstations.id === selectedWorkstation);
			setWorkstationName(findWorkstation);
		}
	}, [selectedWorkstation]);

	const {
		data: riskDegree,
		error: riskDegreeError,
		isFetching: riskDegreeLoading
	} = useGetRiskDegreeWorkstation({
		organization_id: file.organization_id,
		company_id: file.company_id,
		file_id: file.id,
		legs: parameters.legs,
		force: parameters.force,
		repetition: parameters.legs
	});

	const {
		data: bodyParts,
		error: bodyPartsError,
		isFetching: bodyPartsLoading
	} = useRiskDegreeBodyParts({
		organization_id: file.organization_id,
		company_id: file.company_id,
		file_id: file.id
	});

	useEffect(() => {
		if (riskDegree) {
			dispatch(setRiskDegree(riskDegree));
		}
		if (bodyParts) {
			dispatch(setData(bodyParts));
		}
	}, [riskDegree, bodyParts]);

	const Title = ({ children }) => (
		<Col sm={24} style={{ paddingBottom: '15px' }}>
			<h2>{I18n.get(children)}</h2>
		</Col>
	);

	function onPrevious() {
		dispatch(prev('customized'));
	}

	function onSuccessResponse(response) {
		const blob = new Blob([response.data], { type: 'application/pdf' });
		window.open(URL.createObjectURL(blob));
	}

	function onErrorResponse(err) {
		console.log(err, 'err');
	}

	function normalizeData() {
		const sector = JSON.parse(informations.sector);
		const durationFormated = formatSecondsDuration(file.duration);
		const browserLanguage = window.navigator.language.substring(0, 2) ?? 'en';

		return {
			tool: 'rula',
			document_type: 'pdf',
			report: 'score_parts',
			language: browserLanguage,
			parameters: {
				risk_degree: riskDegree?.score || null,
				data: data,
				sector_name: sector.name,
				configuration: 'Default',
				collection_date: collectionDate,
				company_name: informations.company,
				workstation_name: workstationName.name,
				repetition: parameters.repetition,
				file: {
					name: file.original_name,
					duration: durationFormated,
					size: file.size
				},
				signature: options.signature.checked && {
					author: user.name
				},
				conclusion: options.conclusion.checked && {
					text: options.conclusion.text
				}
			}
		};
	}

	function onFinish() {
		const url = '/report/document';
		const body = normalizeData();
		const opts = { responseType: 'blob' };

		Api.post(url, body, opts)
			.then(setLoading(true))
			.then(onSuccessResponse)
			.catch(onErrorResponse)
			.finally(() => setLoading(false));
	}

	const Footer = () => (
		<Col sm={24} style={{ paddingTop: '15px' }}>
			<Row justify="end">
				<Col>
					<Button style={{ margin: '0 8px' }} onClick={onPrevious}>
						{I18n.get('Previous')}
					</Button>
				</Col>
				<Col>
					<Button type="primary" loading={loading} onClick={onFinish}>
						{I18n.get('Finish')}
					</Button>
				</Col>
			</Row>
		</Col>
	);

	return (
		<Container gutter={[10, 10]}>
			<Title>{I18n.get('Analysis results')}</Title>
			<JobInformations
				rangeRisk="RULA"
				sector={sector?.name}
				company={companyInfo}
				workstation={workstationName?.name}
				collectionDate={collectionDate}
				repetition={parameters.repetition}
			/>
			<FileData originalName={file.original_name} duration={fileDuration} size={fileSize} />
			<ExposureAverage isLoading={riskDegreeLoading} data={riskDegree?.score} isError={riskDegreeError} />
			<Charts loading={bodyPartsLoading} err={bodyPartsError} data={bodyParts} />
			<References />
			<Options />
			<Conclusion />
			<Footer />
		</Container>
	);
}
