export const riskColors = ['#2CC852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];

export const riskLevels = {
	1: 'Acceptable risk',
	2: 'Moderate risk',
	3: 'High Risk',
	4: 'Very high risk',
	5: 'Serious and imminent risk'
};

export const riskCalc = (score: number) => {
	let result;

	if (score >= 0 && score <= 19) {
		result = 1;
	}

	if (score >= 20 && score <= 69) {
		result = 2;
	}

	if (score >= 70 && score <= 199) {
		result = 3;
	}

	if (score >= 200 && score <= 400) {
		result = 4;
	}

	if (score >= 401 && score <= 10000) {
		result = 5;
	}

	return result;
};
