import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

type BodyPartsScore = {
	hip: number | null;
	neck: number | null;
	trunk: number | null;
	score_seconds: number;
	left_knee: number | null;
	right_knee: number | null;
	left_ankle: number | null;
	right_ankle: number | null;
	left_upper_arm: number | null;
	left_lower_arm: number | null;
	right_lower_arm: number | null;
	right_upper_arm: number | null;
};

interface GetRebaReportDTO {
	organization_id: string;
	company_id: string;
	file_id: string;
}

type GetRebaReportResponse = {
	id: string;
	step: number;
	force: number;
	comment: string;
	coupling: number;
	repetition: number;
	collection_date: Date;
	body_parts: BodyPartsScore;
};

type GetRebaReportError = AxiosError<any>;

async function getRebaReport(parameters: GetRebaReportDTO) {
	const { organization_id, company_id, file_id } = parameters;
	const url = `/ergonomic-tool/reba/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	if (data.status === 'failed') {
		return null;
	}
	return data;
}

export const useGetRebaReport = (organizationId: string, companyId: string, fileId: string) => {
	const payload = { organization_id: organizationId, company_id: companyId, file_id: fileId };
	return useQuery<GetRebaReportResponse, GetRebaReportError>(
		[QUERY_KEYS.GET_REBA_REPORT, payload],
		() => getRebaReport(payload),
		{
			enabled: !!organizationId && !!companyId && !!fileId,
			refetchOnWindowFocus: false,
			retry: false
		}
	);
};
