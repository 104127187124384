import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Image, Row } from 'antd';

import good from '@/assets/svg/ergonomic_tools/liberty_mutual/coupling/good.svg';
import fair from '@/assets/svg/ergonomic_tools/liberty_mutual/coupling/fair.svg';
import poor from '@/assets/svg/ergonomic_tools/liberty_mutual/coupling/poor.svg';

import { CustomDivider } from './styles';

export const HandCouplingTooltipContent = () => {
	return (
		<Row justify="space-between" align="middle">
			<Col xs={24}>
				<Row align="bottom">
					<Col xs={7} style={{ marginBottom: '1rem' }}>
						<Image src={good} alt={I18n.get('Good')} preview={false} width="40px" />
					</Col>
					<Col xs={1}>
						<CustomDivider type="vertical" />
					</Col>
					<Col xs={7} style={{ marginBottom: '1rem' }}>
						<Image src={fair} alt={I18n.get('Fair')} preview={false} width="45px" />
					</Col>
					<Col xs={1}>
						<CustomDivider type="vertical" />
					</Col>
					<Col xs={7} style={{ marginBottom: '1rem' }}>
						<Image src={poor} alt={I18n.get('Poor')} preview={false} width="50px" />
					</Col>
				</Row>
			</Col>
			<Col xs={24}>
				<Row align="middle">
					<Col xs={8}>
						<span>{I18n.get('Good')}</span>
					</Col>
					<Col xs={8}>
						<span>{I18n.get('Fair')}</span>
					</Col>
					<Col xs={8}>
						<span>{I18n.get('Poor')}</span>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
