import { I18n } from '@aws-amplify/core';
import { Col, Modal, Row } from 'antd';
import React from 'react';

import { ExclamationCircleOutlined, ExclamationOutlined } from '@ant-design/icons';
import { Title } from '@/components/Typography';
import { roleEnum } from '@/utils/enum';

import { DeactivateContainer, DeactivateTokenDescription, WarningContainer } from './styles';
import { ConfirmButton } from './Generate2FAModal/styles';
import { Deactivate2FADTO } from './types/response';
import { useTFAContext } from './context';

export const Deactivate = () => {
	const { forced2FA, user } = useTFAContext();
	const { handleDelete2FA } = useTFAContext();

	async function handleConfirmClick() {
		let deleted = {} as Deactivate2FADTO;
		if (forced2FA.force_two_fa && user?.role !== roleEnum.MASTER) {
			Modal.confirm({
				title: I18n.get(
					'Your company has asked for all users to keep two-factor ' +
						'authentication enabled for security reasons. ' +
						"You'll be asked to activate it again on your next login. " +
						'Want to deactivate it anyway?'
				),
				icon: <ExclamationCircleOutlined />,
				okText: I18n.get('Confirm'),
				cancelText: I18n.get('Cancel'),
				okType: 'danger',
				onOk: async () => {
					deleted = await dactivate2fa(deleted);
				}
			});
		} else {
			deleted = await dactivate2fa(deleted);
		}
	}

	async function dactivate2fa(deleted: Deactivate2FADTO) {
		deleted = await handleDelete2FA();
		if (deleted?.message) {
			window.location.reload();
		}
		return deleted;
	}

	return (
		<DeactivateContainer align="top" justify="center">
			<Col>
				<Row justify="center">
					<WarningContainer>
						<ExclamationOutlined style={{ padding: 0, margin: 0 }} />
					</WarningContainer>
				</Row>
				<Row justify="center">
					<Title align="center" level={4} style={{ width: '50%', marginTop: '2rem' }}>
						You are about to deactivate your 2FA.
					</Title>
				</Row>
				<Row justify="center" style={{ marginTop: '3rem' }}>
					<DeactivateTokenDescription style={{ width: '60%' }}>
						{I18n.get('Confirming this action will delete your two-factor authentication. Are you sure?')}
					</DeactivateTokenDescription>
				</Row>
				<Row justify="center" style={{ marginTop: '5.5rem' }}>
					<ConfirmButton onClick={handleConfirmClick} type="primary" htmlType="submit">
						{I18n.get('Confirm')}
					</ConfirmButton>
				</Row>
			</Col>
		</DeactivateContainer>
	);
};
