import styled from 'styled-components';
import { ColorsRiskDegreeType, PartFactorsType, PropsStyledBodyPart } from './types';

export const originalImageSize = {
	height: 2200,
	width: 970
};

export const colorsRiskDegree: ColorsRiskDegreeType = {
	safe: {
		default: 'filter: invert(87%) sepia(16%) saturate(714%) hue-rotate(71deg) brightness(100%) contrast(90%)',
		selected: 'filter: invert(87%) sepia(16%) saturate(714%) hue-rotate(71deg) brightness(87%) contrast(90%)'
	},
	info: {
		default: 'filter: invert(84%) sepia(32%) saturate(368%) hue-rotate(359deg) brightness(105%) contrast(94%)',
		selected: 'filter: invert(84%) sepia(32%) saturate(368%) hue-rotate(359deg) brightness(97%) contrast(94%)'
	},
	warning: {
		default: 'filter: invert(69%) sepia(77%) saturate(2157%) hue-rotate(306deg) brightness(115%) contrast(87%)',
		selected: 'filter: invert(69%) sepia(77%) saturate(2157%) hue-rotate(306deg) brightness(90%) contrast(87%)'
	},
	danger: {
		default: 'filter: invert(89%) sepia(25%) saturate(5768%) hue-rotate(197deg) brightness(90%) contrast(102%)',
		selected: 'filter: invert(89%) sepia(25%) saturate(5768%) hue-rotate(197deg) brightness(80%) contrast(102%)'
	}
};

export const Container = styled.div`
	width: auto;
	height: auto;
	position: relative;

	img:not(#full-body):not(.disabled) {
		position: absolute;
		width: auto;
		transition: all ease 0.2s;
		:hover {
			cursor: pointer;
			transform: scale(1.05);
			z-index: 10;
		}
	}

	img.disabled {
		filter: invert(95%) sepia(0%) saturate(1201%) hue-rotate(147deg) brightness(75%) contrast(93%);
		position: absolute;
		width: auto;
	}

	img.selected {
		z-index: 10;
		transform: scale(1.05);
	}
`;

export const BodyImage = styled.img`
	opacity: 0;
`;

export const BodyPart = styled.img<PropsStyledBodyPart>`
	${(props) => props.colorPart?.default};

	height: ${(props) => props.height * props.factors.scale}px;
	left: ${(props) => props.width * props.factors.left}px;
	top: ${(props) => props.height * props.factors.top}px;

	&.selected {
		${(props) => props.colorPart?.selected};
	}

	&.unselected {
		opacity: 0.6;
	}

	:hover {
		${(props) => props.colorPart?.selected};
		opacity: 1;
	}
`;

export const partFactors: PartFactorsType = {
	head: { scale: 316.9 / originalImageSize.height, top: 0, left: 0.38 },
	neck: { scale: 92.2 / originalImageSize.height, top: 0.125, left: 0.397 },
	trunk: { scale: 658.5 / originalImageSize.height, top: 0.161, left: 0.285 },
	hip: { scale: 289.5 / originalImageSize.height, top: 0.449, left: 0.28 },
	right_upper_arm: { scale: 394.7 / originalImageSize.height, top: 0.185, left: 0.18 },
	right_lower_arm: { scale: 345 / originalImageSize.height, top: 0.36, left: 0.105 },
	right_wrist: { scale: 224 / originalImageSize.height, top: 0.512, left: 0.004 },
	left_upper_arm: { scale: 397.9 / originalImageSize.height, top: 0.185, left: 0.69 },
	left_lower_arm: { scale: 371.5 / originalImageSize.height, top: 0.353, left: 0.715 },
	left_wrist: { scale: 227.1 / originalImageSize.height, top: 0.512, left: 0.84 },
	left_knee: { scale: 945 / originalImageSize.height, top: 0.57, left: 0.52 },
	right_knee: { scale: 927.2 / originalImageSize.height, top: 0.57, left: 0.267 }
};
