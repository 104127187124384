import React from 'react';
import { Modal } from '@/views/Users/components/Modal';
import { Confirmation } from '@/views/Users/components/Modal/Confirmation';
import type { FormInstance } from 'antd/es/form/Form';
import type { BaseFormValues } from '@/views/Users/types';

interface ModalConfirmationProps {
	form: FormInstance;
	formData: BaseFormValues;
	isOpen?: boolean;
	onClose?(): void;
	onFinish(): void;
	onReturn(): void;
}

export function ModalConfirmation({
	form,
	formData,
	isOpen = false,
	onClose,
	onFinish,
	onReturn
}: ModalConfirmationProps) {
	return (
		<Modal title="Create user" isOpen={isOpen} onClose={onClose}>
			<Confirmation
				form={form}
				isCreating
				formData={formData}
				onReturn={onReturn}
				onConfirmModalFinish={onFinish}
			/>
		</Modal>
	);
}
