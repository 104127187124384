import React from 'react';
import { Row, Col, Form, message, Input } from 'antd';
import _ from 'lodash';

import { Title } from '@/components/Typography';
import { usePersonalisedReportResultContext } from './context';
import { useApplicationContext } from '@/context/Application';
import { PersonalisedReportResultUpdateCommentDTO, useUpdateComment } from './hooks';

const { TextArea } = Input;
const { useMessage } = message;
const { useFormInstance } = Form;

export const Comment: React.FC = () => {
	const form = useFormInstance();
	const [, contextHolder] = useMessage();
	const { organization, company } = useApplicationContext();
	const { personalisedReportResult, isLoading, handleLoadingStatus } = usePersonalisedReportResultContext();

	const { mutateAsync, isLoading: updatingComment } = useUpdateComment();

	const setComment = _.debounce(async (value) => {
		handleLoadingStatus(true);
		await updateComment(value);
		handleLoadingStatus(false);
	}, 1200);

	async function updateComment(text: string): Promise<void> {
		const parameters: PersonalisedReportResultUpdateCommentDTO = {
			comment: text,
			company_id: company.id,
			organization_id: organization.id,
			custom_report_result_id: personalisedReportResult.id
		};

		const updatedComment = await mutateAsync(parameters);
		if (updatedComment?.id) {
			form.setFieldValue('comment', updatedComment.comment);
		}
	}

	function handleChange(text: string): void {
		setComment(text);
	}

	return (
		<Row gutter={[0, 24]}>
			<Col offset={1} span={23}>
				<Row gutter={[16, 16]}>
					<Col span={24}>
						<Title style={{ margin: 0 }} level={3}>
							Comment
						</Title>
						{contextHolder}
					</Col>
					<Col span={24}>
						<Form.Item name={['comment']} labelCol={{ span: 24 }}>
							<TextArea
								rows={4}
								showCount
								maxLength={500}
								onChange={(event) => handleChange(event.target.value)}
								disabled={updatingComment || isLoading || personalisedReportResult?.consolidated}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
