import { Col } from 'antd';
import styled from 'styled-components';
import { TypeScores, DiagnosticColors, DiagnosticProps } from '../types';

export const Container = styled(Col)`
	gap: 0.7rem;
	display: flex;
	align-items: baseline;
`;

export const colorsRiskDegree: Record<TypeScores, DiagnosticColors> = {
	safe: {
		color: 'rgba(75, 192, 86, 0.5)',
		border: 'rgba(75, 192, 86, 1)'
	},
	info: {
		color: 'rgba(255, 206, 86, 0.5)',
		border: 'rgba(255, 206, 86, 1)'
	},
	warning: {
		color: 'rgba(255, 40, 72, 0.5)',
		border: 'rgba(255, 40, 72, 1)'
	},
	danger: {
		color: 'rgba(153, 102, 255, 0.5)',
		border: 'rgba(153, 102, 255, 1)'
	},
	empty: {
		color: 'rgba(188, 188, 188, 0.5)',
		border: 'rgba(188, 188, 188, 1)'
	}
};

export const Diagnostic = styled.h2<DiagnosticProps>`
	color: ${({ type }) => colorsRiskDegree[type].border};
`;

export const MessageError = styled.p`
	color: red;
`;
