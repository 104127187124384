import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useWorkstations } from '@/hooks/v1';
import { FormItem } from '../../../components/FormItem';
import { useApplicationContext } from '@/context/Application';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';

const { useFormInstance, useWatch } = Form;

export const Workstations: React.FC = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const sectorId = useWatch(['basic_information', 'sector_id'], form);
	const fieldName = ['basic_information', 'workstation_id'];

	const {
		data: workstations,
		isLoading,
		isFetching,
		isError
	} = useWorkstations({
		organization_id: organization?.id,
		company_id: company?.id,
		sector_id: sectorId
	});

	function handleWorkstationSelect(id: string): void {
		form.setFieldValue(fieldName, id);
	}

	function handleClearWorkstations(): void {
		form.setFieldValue(fieldName, undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				fontSize="1rem"
				isRequired={true}
				title="Workstation"
				namePath={fieldName}
				errorMessage={I18n.get('Select a workstation')}
			>
				<WorkstationSelect
					size="large"
					sectorId={sectorId}
					loading={isFetching}
					workstations={workstations}
					handleSelectWorkstation={handleWorkstationSelect}
					handleClearWorkstations={handleClearWorkstations}
					disabled={isLoading || isFetching || isError || !sectorId}
				/>
			</FormItem>
		</Col>
	);
};
