import Api from '@/services/api';
import { useQuery } from '@tanstack/react-query';
import { BaseContext } from '@/components/ui/Inputs/types/request';

async function getCompanies(organization_id?: string, company_id?: string) {
	const url = `/company/list?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export function useListCompanies({ organization_id, company_id }: BaseContext) {
	const enabled = !!organization_id && !!company_id;

	const companies = useQuery(['companies_list', organization_id], () => getCompanies(organization_id, company_id), {
		enabled
	});
	return companies;
}
