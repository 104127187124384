import styled from 'styled-components';
import { Button, Col } from 'antd';

export const Container = styled(Col)`
	text-align: end;
`;

export const CreateButton = styled(Button)`
	width: max-content;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 0.5rem;
	span {
		font-weight: 600;
	}
`;
