import React from 'react';
import { Text } from '@/components/Typography';
import { Col, Row } from 'antd';

import initial_lift_end_lower from '@/assets/svg/ergonomic_tools/liberty_mutual/result/initial_lift_end_lower.svg';
import initial_lower_end_lift from '@/assets/svg/ergonomic_tools/liberty_mutual/result/initial_lower_end_lift.svg';

import { FrequencyCard, HandCouplingCard, ObjectWeightCard, InitialStateCard, EndStateCard } from './Cards';
import { TaskNameMapper } from '../../../hooks/types/response';
import { useLibertyMutualContext } from '../../../context';

export const LiftLowerSummary = () => {
	const {
		libertyMutualResult: {
			informations: { task_name }
		}
	} = useLibertyMutualContext();

	if (!isLift(task_name) && !isLower(task_name)) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	function isLift(task_name: TaskNameMapper): task_name is TaskNameMapper.Lift {
		return task_name === TaskNameMapper.Lift;
	}

	function isLower(task_name: TaskNameMapper): task_name is TaskNameMapper.Lower {
		return task_name === TaskNameMapper.Lower;
	}

	const svgMapper = {
		[TaskNameMapper.Lift]: {
			initial: initial_lift_end_lower,
			end: initial_lower_end_lift
		},
		[TaskNameMapper.Lower]: {
			initial: initial_lower_end_lift,
			end: initial_lift_end_lower
		}
	};

	return (
		<Row>
			<Col xs={24}>
				<Row justify="center">
					<Col xs={20} style={{ maxWidth: '700px' }}>
						<Row justify="space-between">
							<Col xs={11} style={{ maxWidth: '300px', marginBottom: '1rem' }}>
								<InitialStateCard svgMapper={svgMapper} task_name={task_name} />
							</Col>
							<Col xs={11} style={{ maxWidth: '300px', marginBottom: '1rem' }}>
								<EndStateCard svgMapper={svgMapper} task_name={task_name} />
							</Col>
						</Row>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} style={{ maxWidth: '1000px' }}>
						<Row justify="space-between">
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<HandCouplingCard />
							</Col>
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<FrequencyCard />
							</Col>
							<Col xs={7} style={{ maxWidth: '250px' }}>
								<ObjectWeightCard />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
