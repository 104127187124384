import {
	Payload,
	FormFields,
	InformationsFormFields,
	WorkConditionsFormFields,
	CharacteristicsFormFields
} from './types';
import {
	CreateOrUpdate,
	CreateOrUpdateWorkCondition,
	CreateOrUpdateCharacteristic,
	CreateOrUpdateGenericStepKeyResult
} from '../types';

export interface ICreateOrUpdateTypeMapper {
	isInformationsPayload(data: Payload | undefined): data is CreateOrUpdate;
	isCreateOrUpdateInformations(data: FormFields): data is InformationsFormFields;
	isCreateOrUpdateWorkCondition(data: FormFields): data is WorkConditionsFormFields;
	isCreateOrUpdateCharacteristics(data: FormFields): data is CharacteristicsFormFields;
	isWorkConditionPayload(data: Payload | undefined): data is CreateOrUpdateWorkCondition;
	isCharacteristicPayload(data: Payload | undefined): data is CreateOrUpdateCharacteristic;
	isGenericStepsPayload(payload: Payload | undefined): payload is CreateOrUpdateGenericStepKeyResult;
}

export class CreateOrUpdateTypeMapper implements ICreateOrUpdateTypeMapper {
	public isCreateOrUpdateInformations(data: FormFields): data is InformationsFormFields {
		return (data as InformationsFormFields).activity_name !== undefined;
	}

	public isCreateOrUpdateWorkCondition(data: FormFields): data is WorkConditionsFormFields {
		const workConditionData = data as WorkConditionsFormFields;
		return workConditionData.work_hours !== undefined || workConditionData.work_minutes !== undefined;
	}

	public isCreateOrUpdateCharacteristics(data: FormFields): data is CharacteristicsFormFields {
		return (data as CharacteristicsFormFields).total_working_population !== undefined;
	}

	public isInformationsPayload(data: Payload | undefined): data is CreateOrUpdate {
		return (data as CreateOrUpdate).activity_name !== undefined;
	}

	public isWorkConditionPayload(data: Payload | undefined): data is CreateOrUpdateWorkCondition {
		const workConditionData = data as CreateOrUpdateWorkCondition;
		return workConditionData.work_hours !== undefined || workConditionData.work_minutes !== undefined;
	}

	public isCharacteristicPayload(data: Payload | undefined): data is CreateOrUpdateCharacteristic {
		return (data as CreateOrUpdateCharacteristic).total_working_population !== undefined;
	}

	public isGenericStepsPayload(payload: Payload | undefined): payload is CreateOrUpdateGenericStepKeyResult {
		return (payload as CreateOrUpdateGenericStepKeyResult).step_keys_results !== undefined;
	}
}
