import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Tabs } from 'antd';
import { I18n } from '@aws-amplify/core';

import { baseFormName, injuriesKeys, stepDescription } from '../contants';
import { ApplyToolButton } from '../_components/ApplyToolButton';
import { CollapseCheck } from '../_components/CollapseCheck';
import { RiskLevelCard } from '../_components/RiskLevelCard';
import { InjuriesGroup } from '../_components/InjuriesGroup';
import { SelectOption } from '../_components/SelectOption';
import { Charts } from './Charts';

import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { useApplicationContext } from '@/context/Application';
import { Paragraph, Text, Title } from '@/components/Typography';
import hooks from '@/components/views/PreliminaryAnalysis/hooks';
import { Spinner } from '@/components/Spinner';
import { kinebotStepKeyExposureDescriptionMapper } from '@/types';

const { useRiskLevel } = hooks;
const { useFormInstance } = Form;

export function PosturesAndMovements() {
	const form = useFormInstance();
	const formName = ['postures_and_movements'];

	const { file, exhibitionList, rebaAngles, getLevelRiskFields, calculateBodyPartPercentage } =
		usePreliminaryAnalysis();
	const { organization, company } = useApplicationContext();

	const { angles, errorGettingAngles, gettingAngles } = rebaAngles;
	const { isLoading, data, isError, ...riskLevel } = useRiskLevel(organization?.id, company?.id, file?.id);

	const [membersExhibition, setMembersExhibition] = useState([]);

	const [levelRisk, setLevelRisk] = useState({
		step: '',
		consequence_id: '',
		probability_id: '',
		exhibition_id: ''
	});

	useEffect(() => {
		const members = ['trunk', 'neck', 'left_lower_arm', 'right_lower_arm', 'left_upper_arm', 'right_upper_arm'];
		const memberFieldMapper = {
			trunk: 'trunk',
			neck: 'neck',
			left_lower_arm: 'left_elbow',
			right_lower_arm: 'right_elbow',
			left_upper_arm: 'left_shoulder',
			right_upper_arm: 'right_shoulder'
		};

		if (
			checkIfAllMembersHaveAngles() &&
			membersExhibition.length === 0 &&
			exhibitionList?.exhibitions?.length > 0
		) {
			const exhibitions = exhibitionList.exhibitions.reduce((map, exhibition) => {
				map[exhibition.name] = exhibition.id;
				return map;
			}, {});

			const membersExhibitionsList = members.map((member) => {
				const percentages = calculateBodyPartPercentage(angles[member]);
				const acceptablePercentage = percentages[1];
				const id = setExhibitionId(acceptablePercentage, exhibitions);

				return {
					step: ['postures', memberFieldMapper[member]],
					id
				};
			});

			setMembersExhibition(membersExhibitionsList);
		}
	}, [angles]);

	function checkIfAllMembersHaveAngles() {
		for (const member in angles) {
			if (Object.hasOwnProperty.call(angles, member)) {
				return angles[member]?.length > 0;
			}
		}
	}

	function setExhibitionId(acceptablePercentage, exhibitions) {
		if (acceptablePercentage < 10) {
			return exhibitions.to_be_continued;
		}

		if (acceptablePercentage < 30) {
			return exhibitions.frequent;
		}

		if (acceptablePercentage < 50) {
			return exhibitions.occasionally;
		}

		if (acceptablePercentage < 80) {
			return exhibitions.irregular;
		}

		if (acceptablePercentage < 90) {
			return exhibitions.rarely;
		}

		return exhibitions.remotely;
	}

	useEffect(() => {
		if (membersExhibition.length > 0) {
			membersExhibition.forEach((member) => {
				form.setFieldValue([baseFormName, ...formName, ...member.step, 'exhibition_id'], member.id);
				updateLevelRisk(member.step, 'exhibition_id', member.id);
			});
		}
	}, [membersExhibition]);

	useEffect(() => {
		const { step, consequence_id, probability_id, exhibition_id } = levelRisk;

		if (step && consequence_id && probability_id && exhibition_id) {
			const formatStep = Array.isArray(step) ? [...formName, ...step] : [...formName, step];

			const payload = {
				step: formatStep,
				consequence_id,
				probability_id,
				exhibition_id
			};

			riskLevel.mutate(payload, {
				onSuccess: (data) => {
					form.setFields([
						{
							name: [baseFormName, ...formatStep, 'result'],
							value: data?.risk_level
						}
					]);
				}
			});
		}
	}, [levelRisk.step, levelRisk.consequence_id, levelRisk.probability_id, levelRisk.exhibition_id]);

	function updateLevelRisk(step, attribute, value) {
		const stepName = Array.isArray(step) ? [...step] : [step];
		const { consequence, exhibition, probability } = getLevelRiskFields(form, baseFormName, formName, stepName);

		if (!levelRisk.step && !levelRisk.consequence_id && !levelRisk.exhibition_id && !levelRisk.probability_id) {
			const options = ['consequence_id', 'exhibition_id', 'probability_id'];
			const forms = options
				.filter((item) => item !== attribute)
				.map((value) => ({
					name: [baseFormName, ...formName, step, value],
					value: undefined
				}));

			form.setFields(forms);
		}

		setLevelRisk({
			exhibition_id: exhibition,
			consequence_id: consequence,
			probability_id: probability,
			step,
			[attribute]: value
		});
	}

	function mapExhibitionsForKinebotStepKey() {
		return exhibitionList?.exhibitions?.map((exhibition) => ({
			...exhibition,
			description: kinebotStepKeyExposureDescriptionMapper[exhibition.name]
		}));
	}

	const PullInformation = () => (
		<div>
			<Text strong>Apply the tool</Text>
			{':'}
			<ApplyToolButton type="toTop" onClick={goToApplyTool} styles={{ width: '80%', marginTop: '5px' }}>
				<Text>Load surveys</Text>
				<Text type="secondary">REBA</Text>
			</ApplyToolButton>
		</div>
	);

	const {
		low_back_pain_complaints,
		elbow_pain,
		neck_pain,
		shoulder_pain,
		fatigue_complaints,
		headache,
		non_conforming_products,
		musculoskeletal_complaints,
		complaints_of_irritability,
		unexcused_absences,
		absenteeism,
		wrist_pain,
		nonconforming_products,
		others
	} = injuriesKeys;

	const injuriesKeysDefault = [
		fatigue_complaints,
		absenteeism,
		musculoskeletal_complaints,
		complaints_of_irritability,
		nonconforming_products,
		unexcused_absences,
		others
	];

	function onResetPostures() {
		const parts = ['trunk', 'neck', 'left_elbow', 'right_elbow', 'left_shoulder', 'right_shoulder'];
		parts.forEach((item) => {
			form.setFields([
				{
					name: [baseFormName, ...formName, 'postures', item, 'checked'],
					value: false
				},
				{
					name: [baseFormName, ...formName, 'postures', item, 'consequence_id'],
					value: undefined
				},
				{
					name: [baseFormName, ...formName, 'postures', item, 'probability_id'],
					value: undefined
				},
				{
					name: [baseFormName, ...formName, 'postures', item, 'exhibition_id'],
					value: undefined
				},
				{
					name: [baseFormName, ...formName, 'postures', item, 'injuries'],
					value: undefined
				},
				{
					name: [baseFormName, ...formName, 'postures', item, 'result'],
					value: undefined
				}
			]);
		});
	}

	function onResetFields(step) {
		const current = Array.isArray(step) ? step : [step];
		form.setFields([
			{
				name: [baseFormName, ...formName, ...current, 'consequence_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, ...current, 'probability_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, ...current, 'exhibition_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, ...current, 'injuries'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, ...current, 'result'],
				value: undefined
			}
		]);
	}

	const setCheckedCollapse = (step) => {
		const formatStep = Array.isArray(step)
			? [baseFormName, ...formName, ...step, 'checked']
			: [baseFormName, ...formName, step, 'checked'];

		form.setFields([
			{
				name: formatStep,
				value: true
			}
		]);
	};

	function goToApplyTool() {
		let url = `/reporting/reba/${file.id}`;
		window.open(url, '_blank');
	}

	if (gettingAngles) {
		return <Spinner />;
	}

	return (
		<Row gutter={[0, 10]}>
			<Col xs={24} sm={20} md={16} offset={2}>
				<Title level={4}>Postures and movements</Title>
			</Col>
			<Col xs={24} sm={20} md={16} offset={2}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col xs={24} sm={20} md={16} offset={2}>
				<Row gutter={[10, 10]}>
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'uncomfortable_postures_and_movements', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('uncomfortable_postures_and_movements');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Stays in uncomfortable postures and movements">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'uncomfortable_postures_and_movements',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'uncomfortable_postures_and_movements',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() =>
													setCheckedCollapse('uncomfortable_postures_and_movements')
												}
												options={[
													low_back_pain_complaints,
													elbow_pain,
													shoulder_pain,
													fatigue_complaints,
													headache,
													non_conforming_products
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'uncomfortable_postures_and_movements',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'uncomfortable_postures_and_movements',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('uncomfortable_postures_and_movements');
															updateLevelRisk(
																'uncomfortable_postures_and_movements',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'uncomfortable_postures_and_movements',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'uncomfortable_postures_and_movements',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('uncomfortable_postures_and_movements');
															updateLevelRisk(
																'uncomfortable_postures_and_movements',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'uncomfortable_postures_and_movements',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'uncomfortable_postures_and_movements',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('uncomfortable_postures_and_movements');
															updateLevelRisk(
																'uncomfortable_postures_and_movements',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'uncomfortable_postures_and_movements',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'uncomfortable_postures_and_movements']}
												step={[...formName, 'uncomfortable_postures_and_movements']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'frequent_change_in_joint_positions', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('frequent_change_in_joint_positions');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs continuous and/or highly frequent change in joint positions">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'frequent_change_in_joint_positions',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'frequent_change_in_joint_positions',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => {
													setCheckedCollapse('frequent_change_in_joint_positions');
												}}
												options={injuriesKeysDefault}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'frequent_change_in_joint_positions',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'frequent_change_in_joint_positions',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('frequent_change_in_joint_positions');
															updateLevelRisk(
																'frequent_change_in_joint_positions',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'frequent_change_in_joint_positions',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'frequent_change_in_joint_positions',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('frequent_change_in_joint_positions');
															updateLevelRisk(
																'frequent_change_in_joint_positions',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'frequent_change_in_joint_positions',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'frequent_change_in_joint_positions',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('frequent_change_in_joint_positions');
															updateLevelRisk(
																'frequent_change_in_joint_positions',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'frequent_change_in_joint_positions',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'frequent_change_in_joint_positions']}
												step={[...formName, 'frequent_change_in_joint_positions']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'constrained_posture_for_long_duration', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('constrained_posture_for_long_duration');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Stays in constrained posture for long duration">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'constrained_posture_for_long_duration',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'constrained_posture_for_long_duration',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => {
													setCheckedCollapse('constrained_posture_for_long_duration');
												}}
												options={injuriesKeysDefault}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'constrained_posture_for_long_duration',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'constrained_posture_for_long_duration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('constrained_posture_for_long_duration');
															updateLevelRisk(
																'constrained_posture_for_long_duration',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'constrained_posture_for_long_duration',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'constrained_posture_for_long_duration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('constrained_posture_for_long_duration');
															updateLevelRisk(
																'constrained_posture_for_long_duration',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'constrained_posture_for_long_duration',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	baseFormName,
																	...formName,
																	'constrained_posture_for_long_duration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('constrained_posture_for_long_duration');
															updateLevelRisk(
																'constrained_posture_for_long_duration',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'constrained_posture_for_long_duration',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'constrained_posture_for_long_duration']}
												step={[...formName, 'constrained_posture_for_long_duration']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'walk_for_long_duration', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('walk_for_long_duration');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Walk for long duration and/or long distance (on a horizontal surface as well as an inclined surface)">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'walk_for_long_duration', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'walk_for_long_duration',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => {
													setCheckedCollapse('walk_for_long_duration');
												}}
												options={injuriesKeysDefault}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'walk_for_long_duration',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'walk_for_long_duration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('walk_for_long_duration');
															updateLevelRisk(
																'walk_for_long_duration',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'walk_for_long_duration',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'walk_for_long_duration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('walk_for_long_duration');
															updateLevelRisk(
																'walk_for_long_duration',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'walk_for_long_duration',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'walk_for_long_duration',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('walk_for_long_duration');
															updateLevelRisk(
																'walk_for_long_duration',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'walk_for_long_duration', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'walk_for_long_duration']}
												step={[...formName, 'walk_for_long_duration']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'performs_frequent_stair_climbing', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('performs_frequent_stair_climbing');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs frequent stair climbing">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'performs_frequent_stair_climbing',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'performs_frequent_stair_climbing',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => {
													setCheckedCollapse('performs_frequent_stair_climbing');
												}}
												options={injuriesKeysDefault}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_frequent_stair_climbing',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_frequent_stair_climbing',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('performs_frequent_stair_climbing');
															updateLevelRisk(
																'performs_frequent_stair_climbing',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_frequent_stair_climbing',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_frequent_stair_climbing',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('performs_frequent_stair_climbing');
															updateLevelRisk(
																'performs_frequent_stair_climbing',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_frequent_stair_climbing',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_frequent_stair_climbing',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('performs_frequent_stair_climbing');
															updateLevelRisk(
																'performs_frequent_stair_climbing',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'performs_frequent_stair_climbing',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'performs_frequent_stair_climbing']}
												step={[...formName, 'performs_frequent_stair_climbing']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'stay_in_a_sedentary_position', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('stay_in_a_sedentary_position');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Remains in a sedentary/standing position for a long time">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'stay_in_a_sedentary_position',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'stay_in_a_sedentary_position',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => {
													setCheckedCollapse('stay_in_a_sedentary_position');
												}}
												options={injuriesKeysDefault}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'stay_in_a_sedentary_position',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'stay_in_a_sedentary_position',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('stay_in_a_sedentary_position');
															updateLevelRisk(
																'stay_in_a_sedentary_position',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'stay_in_a_sedentary_position',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'stay_in_a_sedentary_position',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('stay_in_a_sedentary_position');
															updateLevelRisk(
																'stay_in_a_sedentary_position',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'stay_in_a_sedentary_position',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'stay_in_a_sedentary_position',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('stay_in_a_sedentary_position');
															updateLevelRisk(
																'stay_in_a_sedentary_position',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'stay_in_a_sedentary_position', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'stay_in_a_sedentary_position']}
												step={[...formName, 'stay_in_a_sedentary_position']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'postures', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetPostures();
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Postures requirements (Kinebot)">
								<Row>
									<Col span={24} style={{ padding: '10px' }}>
										<Tabs defaultActiveKey="1">
											<Tabs.TabPane forceRender={true} tab={I18n.get('Trunk')} key={1}>
												<Row style={{ padding: '10px' }}>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Text strong>Possible injuries</Text>
																<Form.Item
																	style={{ paddingTop: '10px' }}
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'trunk',
																		'injuries'
																	]}
																	rules={[
																		({ getFieldValue }) => ({
																			validator(_, value) {
																				const checkBox = getFieldValue([
																					baseFormName,
																					...formName,
																					'postures',
																					'checked'
																				]);

																				if (
																					(checkBox && !value) ||
																					value?.length === 0
																				) {
																					return Promise.reject(
																						I18n.get('Select the injuries')
																					);
																				}

																				return Promise.resolve();
																			}
																		})
																	]}
																>
																	<InjuriesGroup
																		onChange={() => {
																			setCheckedCollapse('postures');
																		}}
																		options={[
																			low_back_pain_complaints,
																			elbow_pain,
																			shoulder_pain,
																			fatigue_complaints,
																			non_conforming_products,
																			others
																		]}
																	/>
																</Form.Item>
															</Col>
															<Col span={24}>
																{file.reba ? (
																	<Charts
																		key={[...formName, 'postures', 'trunk']}
																		member="trunk"
																		data={angles}
																		isError={errorGettingAngles}
																		isLoading={gettingAngles}
																	/>
																) : (
																	<PullInformation />
																)}
															</Col>
														</Row>
													</Col>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Row gutter={[0, 20]}>
																	<Col span={24}>
																		<Text strong>Consequence</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'trunk',
																				'consequence_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the consequence'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ConsequenceSelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'trunk'],
																						'consequence_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Probability</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'trunk',
																				'probability_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the probability'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ProbabilitySelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'trunk'],
																						'probability_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Exposure</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'trunk',
																				'exhibition_id'
																			]}
																		>
																			<SelectOption.ExhibitionSelect
																				disabled={true}
																				exhibitions={mapExhibitionsForKinebotStepKey()}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Col>
															<Col span={24}>
																<Form.Item
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'trunk',
																		'result'
																	]}
																>
																	<RiskLevelCard
																		key={[...formName, 'postures', 'trunk']}
																		step={[...formName, 'postures', 'trunk']}
																	/>
																</Form.Item>
															</Col>
														</Row>
													</Col>
												</Row>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender={true} tab={I18n.get('Neck')} key={2}>
												<Row style={{ padding: '10px' }}>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Text strong>Possible injuries</Text>
																<Form.Item
																	style={{ paddingTop: '10px' }}
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'neck',
																		'injuries'
																	]}
																	rules={[
																		({ getFieldValue }) => ({
																			validator(_, value) {
																				const checkBox = getFieldValue([
																					baseFormName,
																					...formName,
																					'postures',
																					'checked'
																				]);

																				if (
																					(checkBox && !value) ||
																					value?.length === 0
																				) {
																					return Promise.reject(
																						I18n.get('Select the injuries')
																					);
																				}

																				return Promise.resolve();
																			}
																		})
																	]}
																>
																	<InjuriesGroup
																		onChange={() => {
																			setCheckedCollapse('postures');
																		}}
																		options={[
																			neck_pain,
																			headache,
																			fatigue_complaints,
																			others
																		]}
																	/>
																</Form.Item>
															</Col>
															<Col span={24}>
																{file.reba ? (
																	<Charts
																		key={[...formName, 'postures', 'neck']}
																		member="neck"
																		data={angles}
																		isError={errorGettingAngles}
																		isLoading={gettingAngles}
																	/>
																) : (
																	<PullInformation />
																)}
															</Col>
														</Row>
													</Col>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Row gutter={[0, 20]}>
																	<Col span={24}>
																		<Text strong>Consequence</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'neck',
																				'consequence_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the consequence'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ConsequenceSelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'neck'],
																						'consequence_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Probability</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'neck',
																				'probability_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the probability'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ProbabilitySelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'neck'],
																						'probability_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Exposure</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'neck',
																				'exhibition_id'
																			]}
																		>
																			<SelectOption.ExhibitionSelect
																				disabled={true}
																				exhibitions={mapExhibitionsForKinebotStepKey()}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Col>
															<Col span={24}>
																<Form.Item
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'neck',
																		'result'
																	]}
																>
																	<RiskLevelCard
																		key={[...formName, 'postures', 'neck']}
																		step={[...formName, 'postures', 'neck']}
																	/>
																</Form.Item>
															</Col>
														</Row>
													</Col>
												</Row>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender={true} tab={I18n.get('Left elbow')} key={3}>
												<Row style={{ padding: '10px' }}>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Text strong>Possible injuries</Text>
																<Form.Item
																	style={{ paddingTop: '10px' }}
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'left_elbow',
																		'injuries'
																	]}
																	rules={[
																		({ getFieldValue }) => ({
																			validator(_, value) {
																				const checkBox = getFieldValue([
																					baseFormName,
																					...formName,
																					'postures',
																					'checked'
																				]);

																				if (
																					(checkBox && !value) ||
																					value?.length === 0
																				) {
																					return Promise.reject(
																						I18n.get('Select the injuries')
																					);
																				}

																				return Promise.resolve();
																			}
																		})
																	]}
																>
																	<InjuriesGroup
																		onChange={() => {
																			setCheckedCollapse('postures');
																		}}
																		options={[
																			wrist_pain,
																			elbow_pain,
																			shoulder_pain,
																			fatigue_complaints,
																			others
																		]}
																	/>
																</Form.Item>
															</Col>
															<Col span={24}>
																{file.reba ? (
																	<Charts
																		key={[
																			...formName,
																			'postures',
																			'left_lower_arm'
																		]}
																		member="left_lower_arm"
																		data={angles}
																		isError={errorGettingAngles}
																		isLoading={gettingAngles}
																	/>
																) : (
																	<PullInformation />
																)}
															</Col>
														</Row>
													</Col>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Row gutter={[0, 20]}>
																	<Col span={24}>
																		<Text strong>Consequence</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'left_elbow',
																				'consequence_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the consequence'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ConsequenceSelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'left_elbow'],
																						'consequence_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Probability</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'left_elbow',
																				'probability_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the probability'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ProbabilitySelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'left_elbow'],
																						'probability_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Exposure</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'left_elbow',
																				'exhibition_id'
																			]}
																		>
																			<SelectOption.ExhibitionSelect
																				disabled={true}
																				exhibitions={mapExhibitionsForKinebotStepKey()}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Col>
															<Col span={24}>
																<Form.Item
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'left_elbow',
																		'result'
																	]}
																>
																	<RiskLevelCard
																		key={[...formName, 'postures', 'left_elbow']}
																		step={[...formName, 'postures', 'left_elbow']}
																	/>
																</Form.Item>
															</Col>
														</Row>
													</Col>
												</Row>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender={true} tab={I18n.get('Right elbow')} key={4}>
												<Row style={{ padding: '10px' }}>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Text strong>Possible injuries</Text>
																<Form.Item
																	style={{ paddingTop: '10px' }}
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'right_elbow',
																		'injuries'
																	]}
																	rules={[
																		({ getFieldValue }) => ({
																			validator(_, value) {
																				const checkBox = getFieldValue([
																					baseFormName,
																					...formName,
																					'postures',
																					'checked'
																				]);

																				if (
																					(checkBox && !value) ||
																					value?.length === 0
																				) {
																					return Promise.reject(
																						I18n.get('Select the injuries')
																					);
																				}

																				return Promise.resolve();
																			}
																		})
																	]}
																>
																	<InjuriesGroup
																		onChange={() => {
																			setCheckedCollapse('postures');
																		}}
																		options={[
																			wrist_pain,
																			elbow_pain,
																			shoulder_pain,
																			fatigue_complaints,
																			others
																		]}
																	/>
																</Form.Item>
															</Col>
															<Col span={24}>
																{file.reba ? (
																	<Charts
																		key={[
																			...formName,
																			'postures',
																			'right_lower_arm'
																		]}
																		member="right_lower_arm"
																		data={angles}
																		isError={errorGettingAngles}
																		isLoading={gettingAngles}
																	/>
																) : (
																	<PullInformation />
																)}
															</Col>
														</Row>
													</Col>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Row gutter={[0, 20]}>
																	<Col span={24}>
																		<Text strong>Consequence</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'right_elbow',
																				'consequence_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the consequence'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ConsequenceSelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'right_elbow'],
																						'consequence_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Probability</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'right_elbow',
																				'probability_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the probability'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ProbabilitySelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'right_elbow'],
																						'probability_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Exposure</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'right_elbow',
																				'exhibition_id'
																			]}
																		>
																			<SelectOption.ExhibitionSelect
																				disabled={true}
																				exhibitions={mapExhibitionsForKinebotStepKey()}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Col>
															<Col span={24}>
																<Form.Item
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'right_elbow',
																		'result'
																	]}
																>
																	<RiskLevelCard
																		key={[...formName, 'postures', 'right_elbow']}
																		step={[...formName, 'postures', 'right_elbow']}
																	/>
																</Form.Item>
															</Col>
														</Row>
													</Col>
												</Row>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender={true} tab={I18n.get('Left shoulder')} key={5}>
												<Row style={{ padding: '10px' }}>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Text strong>Possible injuries</Text>
																<Form.Item
																	style={{ paddingTop: '10px' }}
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'left_shoulder',
																		'injuries'
																	]}
																	rules={[
																		({ getFieldValue }) => ({
																			validator(_, value) {
																				const checkBox = getFieldValue([
																					baseFormName,
																					...formName,
																					'postures',
																					'checked'
																				]);

																				if (
																					(checkBox && !value) ||
																					value?.length === 0
																				) {
																					return Promise.reject(
																						I18n.get('Select the injuries')
																					);
																				}

																				return Promise.resolve();
																			}
																		})
																	]}
																>
																	<InjuriesGroup
																		onChange={() => {
																			setCheckedCollapse('postures');
																		}}
																		options={[
																			wrist_pain,
																			elbow_pain,
																			shoulder_pain,
																			fatigue_complaints,
																			others
																		]}
																	/>
																</Form.Item>
															</Col>
															<Col span={24}>
																{file.reba ? (
																	<Charts
																		key={[
																			...formName,
																			'postures',
																			'left_upper_arm'
																		]}
																		member="left_upper_arm"
																		data={angles}
																		isError={errorGettingAngles}
																		isLoading={gettingAngles}
																	/>
																) : (
																	<PullInformation />
																)}
															</Col>
														</Row>
													</Col>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Row gutter={[0, 20]}>
																	<Col span={24}>
																		<Text strong>Consequence</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'left_shoulder',
																				'consequence_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the consequence'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ConsequenceSelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'left_shoulder'],
																						'consequence_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Probability</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'left_shoulder',
																				'probability_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the probability'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ProbabilitySelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'left_shoulder'],
																						'probability_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Exposure</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'left_shoulder',
																				'exhibition_id'
																			]}
																		>
																			<SelectOption.ExhibitionSelect
																				disabled={true}
																				exhibitions={mapExhibitionsForKinebotStepKey()}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Col>
															<Col span={24}>
																<Form.Item
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'left_shoulder',
																		'result'
																	]}
																>
																	<RiskLevelCard
																		key={[...formName, 'postures', 'left_shoulder']}
																		step={[
																			...formName,
																			'postures',
																			'left_shoulder'
																		]}
																	/>
																</Form.Item>
															</Col>
														</Row>
													</Col>
												</Row>
											</Tabs.TabPane>
											<Tabs.TabPane forceRender={true} tab={I18n.get('Right shoulder')} key={6}>
												<Row style={{ padding: '10px' }}>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Text strong>Possible injuries</Text>
																<Form.Item
																	style={{ paddingTop: '10px' }}
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'right_shoulder',
																		'injuries'
																	]}
																	rules={[
																		({ getFieldValue }) => ({
																			validator(_, value) {
																				const checkBox = getFieldValue([
																					baseFormName,
																					...formName,
																					'postures',
																					'checked'
																				]);

																				if (
																					(checkBox && !value) ||
																					value?.length === 0
																				) {
																					return Promise.reject(
																						I18n.get('Select the injuries')
																					);
																				}

																				return Promise.resolve();
																			}
																		})
																	]}
																>
																	<InjuriesGroup
																		onChange={() => {
																			setCheckedCollapse('postures');
																		}}
																		options={[
																			wrist_pain,
																			elbow_pain,
																			shoulder_pain,
																			fatigue_complaints,
																			others
																		]}
																	/>
																</Form.Item>
															</Col>
															<Col span={24}>
																{file.reba ? (
																	<Charts
																		key={[
																			...formName,
																			'postures',
																			'right_upper_arm'
																		]}
																		member="right_upper_arm"
																		data={angles}
																		isError={errorGettingAngles}
																		isLoading={gettingAngles}
																	/>
																) : (
																	<PullInformation />
																)}
															</Col>
														</Row>
													</Col>
													<Col sm={12}>
														<Row>
															<Col span={24}>
																<Row gutter={[0, 20]}>
																	<Col span={24}>
																		<Text strong>Consequence</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'right_shoulder',
																				'consequence_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the consequence'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ConsequenceSelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'right_shoulder'],
																						'consequence_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Probability</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'right_shoulder',
																				'probability_id'
																			]}
																			rules={[
																				({ getFieldValue }) => ({
																					validator(_, value) {
																						const checkBox = getFieldValue([
																							baseFormName,
																							...formName,
																							'postures',
																							'checked'
																						]);

																						if (checkBox && !value) {
																							return Promise.reject(
																								I18n.get(
																									'Select the probability'
																								)
																							);
																						}

																						return Promise.resolve();
																					}
																				})
																			]}
																		>
																			<SelectOption.ProbabilitySelect
																				onChange={(value) => {
																					setCheckedCollapse('postures');
																					updateLevelRisk(
																						['postures', 'right_shoulder'],
																						'probability_id',
																						value
																					);
																				}}
																			/>
																		</Form.Item>
																	</Col>
																	<Col span={24}>
																		<Text strong>Exposure</Text>
																		<Form.Item
																			style={{ paddingTop: '10px' }}
																			name={[
																				baseFormName,
																				...formName,
																				'postures',
																				'right_shoulder',
																				'exhibition_id'
																			]}
																		>
																			<SelectOption.ExhibitionSelect
																				disabled={true}
																				exhibitions={mapExhibitionsForKinebotStepKey()}
																			/>
																		</Form.Item>
																	</Col>
																</Row>
															</Col>
															<Col span={24}>
																<Form.Item
																	name={[
																		baseFormName,
																		...formName,
																		'postures',
																		'right_shoulder',
																		'result'
																	]}
																>
																	<RiskLevelCard
																		key={[
																			...formName,
																			'postures',
																			'right_shoulder'
																		]}
																		step={[
																			...formName,
																			'postures',
																			'right_shoulder'
																		]}
																	/>
																</Form.Item>
															</Col>
														</Row>
													</Col>
												</Row>
											</Tabs.TabPane>
										</Tabs>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
