import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { CustomFormItem } from './styles';
import { useSectors } from '@/hooks/v1';

const { useFormInstance, useWatch } = Form;

export const Sectors: React.FC = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();

	const companyId = useWatch('company_id', form);
	const sectorId = useWatch('sector_id', form);

	const { sectors } = useSectors({
		organizationId: organization?.id,
		companyId: company?.id
	});

	function handleSelectSector(id: string) {
		form.setFieldValue(['sector_id'], id);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearSector() {
		form.setFieldValue(['sector_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<CustomFormItem
				name={['sector_id']}
				label={I18n.get('Sector')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the sector')
					}
				]}
			>
				<SectorSelect
					allowClear
					size="large"
					sectors={sectors}
					sectorid={sectorId}
					fieldname={'sector_id'}
					onClear={handleClearSector}
					loading={sectors.isFetching}
					onChange={(value: string) => handleSelectSector(value)}
					disabled={sectors.isLoading || sectors.isFetching || sectors.isError || !companyId}
				/>
			</CustomFormItem>
		</Col>
	);
};
