import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

interface CreateRebaDTO {
	force: number;
	comment: string;
	file_id: string;
	coupling: number;
	sector_id: string;
	company_id: string;
	repetition: number;
	collection_date: Date;
	workstation_id: string;
	organization_id: string;
}

type CreateRebaResponse = {
	status: string;
	message: string;
	data: any;
};

type CreateRebaError = AxiosError<any>;

async function createReport(parameters: CreateRebaDTO) {
	const { data } = await Api.post('/ergonomic-tool/reba', parameters);
	return data;
}

export const useCreateReba = () => {
	const queryClient = useQueryClient();
	return useMutation<CreateRebaResponse, CreateRebaError, CreateRebaDTO>((payload) => createReport(payload), {
		onSuccess: async () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_REBA_REPORT]);
		}
	});
};
