import React from 'react';
import { Form, InputNumber, Tooltip } from 'antd';
import nioshImage from '@/assets/img/ergonomic_tools/niosh.png';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';

const imageSize = {
	width: 400, // in px
	height: 450 // in px
};

const ImageContainer = styled.div`
	position: relative;
	width: ${imageSize.width}px;
	height: ${imageSize.height}px;
`;

const Image = styled.img`
	height: 90%;
	margin-bottom: 20px;
`;

const InputContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	left: ${(props) => props.left * imageSize.width}px;
	top: ${(props) => props.top * imageSize.height}px;

	.ant-input-number-handler-wrap {
		visibility: hidden;
	}
`;

export function Measurements({ readOnly = false }) {
	const imageInputs = [
		{
			name: 'mass_m',
			label: 'M',
			tooltip: I18n.get('Weight of the object'),
			unit: 'kg',
			digits: 4,
			maxValue: 50,
			positioningFactor: {
				left: 0.6,
				top: 0.1
			}
		},
		{
			name: 'distance_dc',
			label: 'Dc',
			tooltip: I18n.get('Distance the object is moved vertically'),
			unit: 'cm',
			digits: 3,
			maxValue: 250,
			positioningFactor: {
				left: 0.73,
				top: 0.3
			}
		},
		{
			name: 'distance_vc',
			label: 'Vc',
			tooltip: I18n.get('Vertical distance of the object at the beginning of handling, relative to the ground'),
			unit: 'cm',
			digits: 3,
			maxValue: 250,
			positioningFactor: {
				left: 0.73,
				top: 0.55
			}
		},
		{
			name: 'distance_h',
			label: 'H',
			tooltip: I18n.get('Horizontal distance of the object relative to the body'),
			unit: 'cm',
			digits: 3,
			maxValue: 100,
			positioningFactor: {
				left: 0.28,
				top: 0.87
			}
		},
		{
			name: 'angle_a',
			label: 'A',
			tooltip: I18n.get('Rotation angle'),
			unit: '°',
			digits: 3,
			maxValue: 180,
			positioningFactor: {
				left: -0.05,
				top: 0.48
			}
		}
	];

	return (
		<ImageContainer>
			{imageInputs.map((item) => (
				<Tooltip
					key={item.name}
					title={item.tooltip}
					placement="right"
					overlayStyle={{
						width: '200px'
					}}
				>
					<InputContainer left={item.positioningFactor.left} top={item.positioningFactor.top}>
						{readOnly && <span>{item.label + ' ='}</span>}
						<Form.Item
							name={item.name}
							rules={[
								{
									required: true,
									type: 'number',
									min: 0,
									max: item.maxValue,
									message: I18n.get('Invalid')
								}
							]}
							style={{
								margin: 0,
								marginRight: readOnly ? '-15px' : '5px'
							}}
						>
							<InputNumber
								placeholder={item.label}
								size={readOnly ? 'small' : 'middle'}
								readOnly={readOnly}
								style={{
									width: `${3 + item.digits}ch`,
									...(readOnly && { border: 'none' })
								}}
							/>
						</Form.Item>
						<span style={{ zIndex: 1 }}>{' ' + item.unit}</span>
					</InputContainer>
				</Tooltip>
			))}
			<Image src={nioshImage} alt="" />
		</ImageContainer>
	);
}
