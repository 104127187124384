import React from 'react';
import styled from 'styled-components';
import { PieChart } from './Pie';
import { I18n } from '@aws-amplify/core';

const Container = styled.div`
	width: 100%;
`;

const SinglePieContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	height: 100%;
`;

const CriticalPiesContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 15px;
`;

const CriticalPie = styled.div`
	width: 160px;
	padding-inline: 5px;
	height: auto;
	border-radius: 20px;

	:hover {
		cursor: pointer;
		background-color: rgba(79, 205, 255, 0.15);
	}

	@media (max-width: 1460px) {
		width: 125px;
		padding-inline: 0px;
	}
`;

const Title = styled.h3`
	font-weight: bold;
	width: 100%;
	margin: auto;
	padding: 0px;
	text-align: center;
`;

export function PieChartCollection({ parts, onClick, activeTabLabel }) {
	function sortingFunction(a, b) {
		const [, a_risks] = a;
		const [, b_risks] = b;

		let risks_length = a_risks.length;
		let critical_index = risks_length - 1;

		for (let i = critical_index; i >= 0; i--) {
			if ((a_risks[i] === 0 && b_risks[i] === 0) || a_risks[i] === b_risks[i]) continue;

			return a_risks[i] > b_risks[i] ? -1 : 1;
		}
	}

	function getCriticalBodyParts(bodyParts) {
		let criticalParts = {};
		const sortedArray = Object.entries(bodyParts).sort(sortingFunction);
		sortedArray.forEach(([label, array], i) => i < 4 && (criticalParts[label] = array));
		return criticalParts;
	}

	const criticalParts = getCriticalBodyParts(parts);

	const isSelected = activeTabLabel !== 'score_seconds';

	return (
		<Container>
			{isSelected ? (
				<SinglePieContainer>
					<Title>{I18n.get('Viewing the selected body part')}:</Title>
					<div>
						<PieChart key={activeTabLabel} data={parts[activeTabLabel]} label={activeTabLabel} />
					</div>
				</SinglePieContainer>
			) : (
				<CriticalPiesContainer>
					<Title>{I18n.get('The 4 most critical body parts')}</Title>
					{Object.keys(criticalParts).map((part) => (
						<CriticalPie key={part}>
							<PieChart
								key={part}
								data={criticalParts[part]}
								label={part}
								onClick={() => onClick(part)}
							/>
						</CriticalPie>
					))}
				</CriticalPiesContainer>
			)}
		</Container>
	);
}
