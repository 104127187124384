import React from 'react';
import { Form, Input } from 'antd';
import { I18n } from '@aws-amplify/core';

const { TextArea } = Input;

interface CommentProps {
	isDisabled?: boolean;
	onChangeComment: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const Comment: React.FC<CommentProps> = ({ isDisabled = false, onChangeComment }) => (
	<Form.Item label={I18n.get('Comment:')} name={['comment']} labelCol={{ span: 24 }}>
		<TextArea rows={4} showCount maxLength={500} onChange={onChangeComment} disabled={isDisabled} />
	</Form.Item>
);
