import React from 'react';

import rightUpperArmSVG from '@/assets/svg/human_body/right_upper_arm.svg';
import rightLowerArmSVG from '@/assets/svg/human_body/right_lower_arm.svg';
import leftLowerArmSVG from '@/assets/svg/human_body/left_lower_arm.svg';
import leftUpperArmSVG from '@/assets/svg/human_body/left_upper_arm.svg';
import rightWristSVG from '@/assets/svg/human_body/right_wrist.svg';
import leftWristSVG from '@/assets/svg/human_body/left_wrist.svg';
import fullBodySVG from '@/assets/svg/human_body/full_body.svg';
import rightLegSVG from '@/assets/svg/human_body/right_leg.svg';
import leftLegSVG from '@/assets/svg/human_body/left_leg.svg';
import trunkSVG from '@/assets/svg/human_body/trunk.svg';
import headSVG from '@/assets/svg/human_body/head.svg';
import neckSVG from '@/assets/svg/human_body/neck.svg';
import hipSVG from '@/assets/svg/human_body/hip.svg';

import { Container, BodyImage, BodyPart, colorsRiskDegree, originalImageSize, partFactors } from './styles';
import { BodyPartsType, PropsHumanBody } from './types';

const bodyParts: BodyPartsType = {
	head: {
		image: headSVG,
		color: null,
		enable: false
	},
	neck: {
		image: neckSVG,
		color: null,
		enable: true
	},
	trunk: {
		image: trunkSVG,
		color: null,
		enable: true
	},
	hip: {
		image: hipSVG,
		color: null,
		enable: false
	},
	left_upper_arm: {
		image: leftUpperArmSVG,
		color: null,
		enable: true
	},
	left_lower_arm: {
		image: leftLowerArmSVG,
		color: null,
		enable: true
	},
	left_wrist: {
		image: leftWristSVG,
		color: null,
		enable: false
	},
	right_upper_arm: {
		image: rightUpperArmSVG,
		color: null,
		enable: true
	},
	right_lower_arm: {
		image: rightLowerArmSVG,
		color: null,
		enable: true
	},
	right_wrist: {
		image: rightWristSVG,
		color: null,
		enable: false
	},
	left_knee: {
		image: leftLegSVG,
		color: null,
		enable: true
	},
	right_knee: {
		image: rightLegSVG,
		color: null,
		enable: true
	}
};

const riskLabels = ['not_identified', 'safe', 'info', 'warning', 'danger'];

export const HumanBody: React.FC<PropsHumanBody> = ({ height, onSelect, selectedPartName, bodyPartScores }) => {
	let isSelected = Object.keys(bodyParts).includes(selectedPartName);

	for (const key of Object.keys(bodyPartScores)) {
		for (let risk_index = riskLabels.length - 1; risk_index >= 0; risk_index--) {
			if (bodyPartScores[key][risk_index] > 0) {
				bodyParts[key].color = colorsRiskDegree[riskLabels[risk_index]];
				break;
			}
		}
	}

	const onClickBodyPart = (e: React.MouseEvent<HTMLImageElement>) => {
		const bodyPart = e.currentTarget.id;
		onSelect(bodyPart);
	};

	function allScoresAreZero(bodyPartScores: number[]): boolean {
		let sum = 0;
		bodyPartScores.forEach((note, index) => {
			if (index > 0) {
				sum = note + sum;
			}
		});
		return sum === 0;
	}

	const width = (height / 2200) * originalImageSize.width;

	return (
		<Container>
			<BodyImage id="full-body" src={fullBodySVG} alt="" style={{ height }} />
			{Object.entries(bodyParts).map(([partName, part]) => {
				let selectedClassName = '';
				const disabledClassName = !part.enable || allScoresAreZero(bodyPartScores[partName]) ? 'disabled' : '';
				if (isSelected) {
					selectedClassName = partName === selectedPartName ? 'selected' : 'unselected';
				}

				return (
					<BodyPart
						key={partName}
						className={[disabledClassName, selectedClassName].join(' ')}
						id={partName}
						src={part.image}
						colorPart={part.color}
						factors={partFactors[partName]}
						onClick={onClickBodyPart}
						height={height}
						width={width}
					/>
				);
			})}
		</Container>
	);
};
