import { AxiosError } from 'axios';
import { message } from 'antd';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEYS } from '@/utils/query-keys';
import { I18n } from '@aws-amplify/core';
import { Response, User } from '@/types';
import Api from '@/services/api';

export type UserDTO = Pick<User, 'id' | 'name' | 'email' | 'role' | 'isActive'>;

export type UpdateUserPermissionDTO = {
	user_id?: string;
	role: string;
	permissions?: OrganizationCompanyMap;
};

export type UpdateUserPermissionResponse = {
	status: string;
	message: string;
};

async function updateUserPermission(body: UpdateUserPermissionDTO): Promise<UpdateUserPermissionResponse> {
	const url = '/user_management/user_update';
	const { data } = await Api.put<UpdateUserPermissionResponse>(url, body);
	return data;
}

export const useUpdateUserPermission = () => {
	const queryClient = useQueryClient();
	return useMutation<UpdateUserPermissionResponse, AxiosError<Response>, UpdateUserPermissionDTO>(
		(body: UpdateUserPermissionDTO) => updateUserPermission(body),
		{
			onError: (err) => {
				message.error(I18n.get(err.response?.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries([QUERY_KEYS.GET_USERS]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_PERMISSIONS]);
				queryClient.invalidateQueries([QUERY_KEYS.GET_USER_PERMISSIONS]);
				message.success(I18n.get('User permissions updated successfully'));
			}
		}
	);
};
