import React from 'react';
import { Tooltip } from 'antd';
import { I18n } from '@aws-amplify/core';

interface TooltipActionProps {
	isActive?: boolean;
	children: JSX.Element;
	title?: string;
}

export function TooltipAction({ isActive = true, children, title = '' }: TooltipActionProps) {
	return (
		<Tooltip placement="top" title={I18n.get(isActive ? title : '')}>
			{children}
		</Tooltip>
	);
}
