import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Image, Row } from 'antd';

import fairSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/coupling/fair.svg';
import goodSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/coupling/good.svg';
import poorSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/coupling/poor.svg';

import { useLibertyMutualContext } from '../../../../context';
import { HandCoupling } from '../../../../context/types';
import { Body, CardContainer, Header } from '../styles';

export const HandCouplingCard = () => {
	const {
		libertyMutualResult: {
			summary: { hand_coupling }
		}
	} = useLibertyMutualContext();

	const couplingSVGMapper = {
		[HandCoupling.Fair]: fairSVG,
		[HandCoupling.Good]: goodSVG,
		[HandCoupling.Poor]: poorSVG
	};

	return (
		<CardContainer>
			<Header>{I18n.get('Hand coupling')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0 0' }}>
					<Image
						preview={false}
						draggable={false}
						alt={I18n.get(hand_coupling)}
						src={couplingSVGMapper[hand_coupling]}
						width="50px"
					/>
				</Row>
				<Row justify="center">{I18n.get(hand_coupling)}</Row>
			</Body>
		</CardContainer>
	);
};
