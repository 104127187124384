import React from 'react';
import { Row, Col, Form, message, Input } from 'antd';
import { Title } from '@/components/Typography';
import _ from 'lodash';

import { useApplicationContext } from '@/context/Application';
import { UpdateComment } from '../../hooks/types/request';
import { useLibertyMutualContext } from '../../context';
import { useUpdateComment } from '../../hooks';

const { TextArea } = Input;

interface CommentProps {
	disableEditComment: boolean;
	onCommentChange: (editingComment: boolean) => void;
}

const { useFormInstance } = Form;

export const Comment: React.FC<CommentProps> = ({ onCommentChange, disableEditComment }) => {
	const { setFieldValue } = useFormInstance();
	const { libertyMutual } = useLibertyMutualContext();
	const { organization, company } = useApplicationContext();

	const [, contextHolder] = message.useMessage();

	const { mutateAsync, isLoading } = useUpdateComment();

	const setComment = _.debounce(async (value) => {
		onCommentChange(true);
		await updateComment(value);
		onCommentChange(false);
	}, 1200);

	async function updateComment(text: string) {
		const parameters: UpdateComment = {
			comment: text,
			company_id: company.id,
			report_id: libertyMutual.id,
			organization_id: organization.id
		};

		const updatedComment = await mutateAsync(parameters);
		if (updatedComment?.id) {
			setFieldValue('comment', updatedComment.comment);
		}
	}

	function handleChange(text: string) {
		setComment(text);
	}

	return (
		<Row gutter={[0, 24]}>
			<Col xs={24}>
				<Row gutter={[16, 16]}>
					<Col xs={24}>
						<Title style={{ margin: 0 }} level={4}>
							Comment
						</Title>
						{contextHolder}
					</Col>
					<Col xs={24}>
						<Form.Item name={['comment']} labelCol={{ xs: 24 }}>
							<TextArea
								rows={4}
								maxLength={500}
								showCount={true}
								onChange={(event) => handleChange(event.target.value)}
								disabled={isLoading || disableEditComment}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
