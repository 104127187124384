import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Row, Col, Form, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import _ from 'lodash';

import { Hooks } from './Form/Results/hooks';
import { useForm } from './Form/hooks';
import { Step, FormData } from '../types';
import { Title } from '@/components/Typography';
import { useRebaReportContext } from '../context';
import { useStep } from './hooks';
import { Informations, Parameters, Persons, Results } from './Form';
import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';

const { useSaveComments } = Hooks;

export const Steps: React.FC = () => {
	const history = useHistory();
	const queryClient = useQueryClient();
	const { file, report, base_parameters, initial_values, create, update, download } = useRebaReportContext();

	const comments = useSaveComments(base_parameters);

	const { form, onFinish, handleValidationStep } = useForm(base_parameters);
	const { currentStep, next, previous, setStepManyPersons, setSelectedPersonValue, gotToResults } = useStep();

	async function handleSaveComments() {
		const { comment } = form.getFieldsValue(true);
		await comments.mutateAsync(comment);
	}

	async function handleOnClose() {
		await handleSaveComments();
		history.push(`/reporting`);
	}

	async function handleDownload() {
		const locale = window.navigator.language ?? 'en-US';
		await handleSaveComments();
		download.mutate({ locale });
	}

	const debounceSaveComments = _.debounce((value) => {
		comments.mutate(value);
	}, 2000);

	const onChangeComment = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => {
			debounceSaveComments(e.target.value);
		},
		[debounceSaveComments]
	);

	const steps: Array<Step> = [
		{
			title: 'Information',
			content: <Informations />,
			is_valid: true
		},
		{
			title: 'Parameters',
			content: <Parameters />,
			is_valid: true
		},
		{
			title: 'Result',
			content: (
				<Results
					file={file}
					report={report}
					onDownloadPDF={handleDownload}
					onChangeComment={onChangeComment}
					isLoadingPDF={download.isLoading}
				/>
			),
			is_valid: true
		}
	];

	const many_person_step: Step = {
		title: 'Persons',
		content: <Persons />,
		is_valid: true
	};

	const handleCreateReport = useCallback(
		(payload: FormData) => {
			create.mutate(payload, {
				onSuccess: () => {
					gotToResults(steps.length);
					queryClient.invalidateQueries(['file']);
					queryClient.invalidateQueries(['reba-recovery']);
					message.success(I18n.get('Report created successfully'));
				},
				onError: () => {
					message.error(I18n.get('Failed to create the report'));
				}
			});
		},
		[create, steps.length, queryClient, gotToResults]
	);

	const handleUpdateReport = useCallback(
		(payload: FormData) => {
			update.mutate(payload, {
				onSuccess: () => {
					gotToResults(steps.length);
					queryClient.invalidateQueries(['file']);
					queryClient.invalidateQueries(['reba-recovery']);
					message.success(I18n.get('Report updated successfully'));
				},
				onError: () => {
					message.error(I18n.get('Failed to update the report'));
				}
			});
		},
		[update, steps.length, queryClient, gotToResults]
	);

	const handleSubmit = useCallback(async () => {
		const data = await onFinish();
		data && (report.data ? handleUpdateReport(data) : handleCreateReport(data));
	}, [report.data, handleCreateReport, handleUpdateReport, onFinish]);

	if (file.data?.many_people) {
		const { selected_person, many_persons_images } = file.data || {};
		many_persons_images?.length > 1 && setStepManyPersons(steps, many_person_step);
		setSelectedPersonValue(initial_values, selected_person);
	}

	async function onNext(current: number, size: number): Promise<void> {
		try {
			current < size - 2 && (await handleValidationStep());
			next();
		} catch (error) {
			message.error(I18n.get('Enter the required values'));
		}
	}

	function onPrevious(): void {
		previous();
	}

	const isDisabledFinish = useMemo(() => {
		const result = file.data?.many_persons_images?.filter((item: any) => item.exposure_percentage > 50);

		if (file.data?.many_people) {
			return result?.length > 0 ? false : true;
		}

		return false;
	}, [file.data?.many_persons_images, file.data?.many_people]);

	const isLoadingFinish = create.isLoading || update.isLoading || report.isLoading;

	return (
		<Form form={form} layout="vertical" initialValues={initial_values}>
			<Row justify="center" gutter={[0, 10]}>
				<Col span={24}>
					<Title level={4} style={{ textAlign: 'center' }}>
						REBA - Rapid Entire Body Assessment
					</Title>
				</Col>
				<Col span={24}>
					<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
						<Col sm={20} xxl={14}>
							<HeaderStep current={currentStep} steps={steps} />
						</Col>
					</Row>
					<Row justify="center">
						<Col sm={24} style={{ minHeight: '350px' }}>
							{steps[currentStep]?.content}
						</Col>
					</Row>
					<Row justify="center">
						<Col span={24}>
							<Footer
								steps={steps}
								isFetching={false}
								current={currentStep}
								onPrevious={onPrevious}
								onFinish={handleSubmit}
								onClose={handleOnClose}
								isLoading={isLoadingFinish}
								isDisabledFinish={isDisabledFinish}
								onNext={() => onNext(currentStep, steps.length)}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
};
