import React from 'react';
import { Row, Col, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { Container, Body, Header } from './styles';

interface CollapseCheckProps {
	value: boolean;
	header: JSX.Element;
	onChange: () => void;
	children?: JSX.Element;
}

export const CollapseCheck: React.FC<CollapseCheckProps> = ({ header, children, onChange, value = false }) => {
	function handleChange(event: React.MouseEvent<HTMLDivElement, MouseEvent> | CheckboxChangeEvent): void {
		event.preventDefault();
		event.stopPropagation();
		onChange();
	}

	return (
		<Container
			onClick={(event) => {
				if (!value) {
					handleChange(event);
				}
			}}
			hasBody={!!children}
			selected={value}
		>
			<Col onClick={handleChange} span={24} style={{ cursor: 'pointer' }}>
				<Row justify="start" align="middle" gutter={[24, 0]}>
					<Col xs={1}>
						<Checkbox checked={value} onChange={handleChange} />
					</Col>
					<Header span={23} onClick={handleChange} selected={value}>
						{header}
					</Header>
				</Row>
			</Col>
			{children && value && (
				<Col span={24}>
					<Body open={value}>{children}</Body>
				</Col>
			)}
		</Container>
	);
};
