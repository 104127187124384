import { createContext, useContext } from 'react';
import { Context } from './types';

export const RebaReportContext = createContext<Context>({} as Context);

export const useRebaReportContext = () => {
	const rebaReportContext = useContext(RebaReportContext);

	if (!rebaReportContext) {
		throw new Error('useRebaReportContext has to be used within <RebaReportContext.Provider>');
	}

	return rebaReportContext;
};
