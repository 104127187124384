import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';
import { Services } from './services';
import { QUERY_KEYS } from '@/utils/query-keys';

const { getScore, getNotesPerSecond, getScoreParts, getResults, setComments } = Services;

const useScore = (payload: any) => {
	return useQuery(['reba-score', payload], () => getScore(payload));
};

const useScorePerSecond = (payload: any) => {
	return useQuery(['reba-note-per-second', payload], () => getNotesPerSecond(payload));
};

const useScoreParts = (payload: any) => {
	return useQuery(['reba-score-parts', payload], () => getScoreParts(payload));
};

const useRebaResults = (payload: any) => {
	return useQuery(['reba-results', payload], () => getResults(payload));
};

const useSaveComments = (payload: any) => {
	const queryClient = useQueryClient();
	return useMutation((comment: string) => setComments({ ...payload, comment }), {
		onSuccess: () => {
			queryClient.invalidateQueries([QUERY_KEYS.GET_REBA_REPORT]);
		},
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};

export const Hooks = {
	useScore,
	useScorePerSecond,
	useScoreParts,
	useRebaResults,
	useSaveComments
};
