import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import Api from '@/services/api';

interface GetAngleTimeReportDTO {
	organization_id: string;
	company_id: string;
	file_id: string;
}

type GetAngleTimeReportResponse = {
	id: string;
	range_risk_id: string;
	collection_date: Date;
	comment: string | null;
};

type GetAngleTimeReportError = AxiosError<any>;

export async function getAngleTimeReport(payload: GetAngleTimeReportDTO) {
	const { organization_id, company_id, file_id } = payload;
	const url = `/ergonomic-tool/angle-time/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

export const useGetAngleTimeReport = (organizationId: string, companyId: string, fileId: string) => {
	const payload = { organization_id: organizationId, company_id: companyId, file_id: fileId };
	return useQuery<GetAngleTimeReportResponse, GetAngleTimeReportError>(
		['angle-time-report', payload],
		() => getAngleTimeReport(payload),
		{
			enabled: !!organizationId && !!companyId && !!fileId
		}
	);
};
