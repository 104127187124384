import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Form, notification } from 'antd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import {
	Step,
	States,
	Context,
	Methods,
	StepsNavigation,
	DefaultStepsMapper,
	PersonalisedReportRouteParams
} from './types';
import {
	useGetExposures,
	useGetProbabilities,
	PersonalisedReportResponse,
	PersonalisedReportStepKeysResponse
} from '../hooks';
import { useGetConsequences } from '../hooks/useGetConsequences';
import { useApplicationContext } from '@/context/Application';
import { Characteristics, GenericStep, Informations, WorkConditions, Results } from '../Report/Steps';

interface PersonalisedReportProviderProps {
	children: ReactNode;
	personalisedReport: PersonalisedReportResponse;
}

type DefineComponent = {
	id: string;
	name: string;
	description: string;
	step_keys: PersonalisedReportStepKeysResponse[];
};

const { useForm } = Form;
const PersonalisedReportContext = createContext<Context>({} as Context);

const defaultSteps: DefaultStepsMapper = {
	has_file: <></>,
	basic_information: <Informations />,
	has_work_conditions: <WorkConditions />,
	has_characteristics: <Characteristics />
};

export const PersonalisedReportProvider: React.FC<Readonly<PersonalisedReportProviderProps>> = ({
	children,
	personalisedReport
}) => {
	const [form] = useForm();
	const history = useHistory();
	const { organization, company } = useApplicationContext();
	const { file_id, report_id } = useParams<PersonalisedReportRouteParams>();

	const [steps, setSteps] = useState<Step[]>([]);

	const urlQueryParams = new URLSearchParams(history.location.search)

	const queryCurrentParam = urlQueryParams.get('current_sequence')

	const [currentStep, setCurrentStep] = useState<number>(Number(queryCurrentParam));

	const {
		data: consequences,
		isLoading: gettingConsequences,
		isFetching: fetchingConsequences,
		isError: errorGettingConsequences
	} = useGetConsequences({
		score_scale_id: personalisedReport?.custom_report_score_scale_id,
		organization_id: organization?.id,
		company_id: company?.id
	});

	const {
		data: exposures,
		isLoading: gettingExposures,
		isFetching: fetchingExposures,
		isError: errorGettingExposures
	} = useGetExposures({
		score_scale_id: personalisedReport?.custom_report_score_scale_id,
		organization_id: organization?.id,
		company_id: company?.id
	});

	const {
		data: probabilities,
		isLoading: gettingProbabilities,
		isFetching: fetchingProbabilities,
		isError: errorGettingProbabilities
	} = useGetProbabilities({
		score_scale_id: personalisedReport?.custom_report_score_scale_id,
		organization_id: organization?.id,
		company_id: company?.id
	});

	useEffect(() => {
		const steps: Step[] = personalisedReport.steps.map(
			({ id, name, description, custom_report_step_setting_id, sequence, step_keys }) => ({
				id,
				name,
				sequence,
				step_keys,
				description,
				custom_report_step_setting_id,
				component: defineComponent({ id, name, description, step_keys })
			})
		);
		setSteps(steps);
	}, []);

	const step = steps.find(({ sequence }) => sequence === currentStep);
	const step_setting_id = step?.custom_report_step_setting_id;

	function defineComponent({ id, name, description, step_keys }: DefineComponent): JSX.Element {
		let defaultComponent = defaultSteps[name];
		if (!defaultComponent) {
			defaultComponent = <GenericStep step_id={id} title={description} stepKeys={step_keys} />;
		}
		let component = defaultComponent;
		if (isResultStep(name)) {
			component = <Results />;
		}
		return component;
	}

	function isResultStep(name: string) {
		return name === 'result';
	}

	function scrollToError(error: any) {
		const errorName = error?.errorFields ? error.errorFields[0]?.name : 'Something went wrong';
		form.scrollToField(errorName, { behavior: 'smooth', block: 'center', inline: 'center' });

		notification.error({
			message: I18n.get('Ops... something happened!'),
			description: I18n.get("Some required step wasn't filled"),
			duration: 5
		});
	}

	function handlePreviousStep() {
		const previousStep = currentStep - 1
		setCurrentStep(previousStep);
		history.push({ search: `?current_sequence=${previousStep}` });
	}

	async function handleStepNagivationClick(step: number) {
		try {
			if ((isNotCompleted() && isTwoStepsAhead(step)) || clickedOnLastStep(step)) {
				return;
			}
			if (isGreaterThanCurrentStep(step)) {
				await form.validateFields();
			}
			setCurrentStep(step);
			history.push({ search: `?current_sequence=${step}` });
		} catch (error) {
			scrollToError(error);
		}
	}

	function clickedOnLastStep(step: number): boolean {
		return step === steps.length - 1;
	}

	function isGreaterThanCurrentStep(step: number) {
		return step > currentStep;
	}

	function isTwoStepsAhead(step: number) {
		return step > currentStep + 1;
	}

	function isNotCompleted() {
		return !personalisedReport.is_completed;
	}

	async function handleNextStep() {
		const nextStep = currentStep + 1;
		setCurrentStep(nextStep);
		history.push({ search: `?current_sequence=${nextStep}` });
	}

	const stepsNavigation: StepsNavigation[] = personalisedReport.steps.map(({ id, description }) => ({
		id,
		description
	}));

	const states: States = {
		steps,
		file_id,
		report_id,
		currentStep,
		stepsNavigation,
		step_setting_id,
		personalisedReport,
		consequencesData: {
			consequences,
			gettingConsequences,
			fetchingConsequences,
			errorGettingConsequences
		},
		exposuresData: {
			exposures,
			gettingExposures,
			fetchingExposures,
			errorGettingExposures
		},
		probabilitiesData: {
			probabilities,
			gettingProbabilities,
			fetchingProbabilities,
			errorGettingProbabilities
		}
	};

	const methods: Methods = {
		scrollToError,
		handleNextStep,
		handlePreviousStep,
		handleStepNagivationClick
	};

	const context: Context = {
		...states,
		...methods
	};

	const collection_date = personalisedReport.basic_information?.collection_date;

	const initialValues = {
		...personalisedReport,
		basic_information: {
			...personalisedReport.basic_information,
			collection_date: collection_date ? moment(collection_date) : moment()
		}
	};
	return (
		<PersonalisedReportContext.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</PersonalisedReportContext.Provider>
	);
};

export function usePersonalisedReportContext() {
	const context = useContext(PersonalisedReportContext);
	return context;
}
