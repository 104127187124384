import { useHistory, useLocation } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import { Form, message } from 'antd';
import moment from 'moment';

import { BaseParameters, FormData } from '../../types';

export const useForm = ({ organization_id, company_id, file_id }: BaseParameters) => {
	const history = useHistory();
	const [form] = Form.useForm();
	const { search } = useLocation();

	const handleValidationStep = async (): Promise<void> => {
		await form.validateFields();
	};

	function onClose(): void {
		history.push(`/reporting/${search}`);
	}

	async function onFinish(): Promise<FormData | undefined> {
		try {
			await form.validateFields();
			const values = form.getFieldsValue(true);
			const collection_date = moment(values.collection_date).format();

			const payload = {
				...values,
				collection_date,
				organization_id,
				company_id,
				file_id
			};

			return payload;
		} catch (error) {
			message.error(I18n.get('Enter the required values'));
		}
	}

	return { form, onFinish, onClose, handleValidationStep };
};
