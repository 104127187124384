import React, { useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useForm } from 'antd/lib/form/Form';
import { Button, Space, message, Modal } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { ExclamationCircleOutlined, StopOutlined, ToolOutlined, UndoOutlined } from '@ant-design/icons';

import { TooltipAction } from './Tooltip';
import { QUERY_KEYS } from '@/utils/query-keys';
import { Modal as CustomModal } from '../../Modal';
import { Confirmation } from '../../Modal/Confirmation';
import { BaseFormValues, UserDataForm } from '../../Modal/Form';
import { useChangeStatusUser } from '@/hooks/useChangeStatusUser';

const { GET_USERS, GET_PERMISSIONS } = QUERY_KEYS;

interface ActionButtonsProps {
	user: any;
}

export function ActionButtons({ user }: ActionButtonsProps) {
	const [form] = useForm();
	const queryClient = useQueryClient();
	const updateStatusUser = useChangeStatusUser();

	const [formData, setFormData] = useState();
	const [userId, setUserId] = useState<string>();
	const [isFormChanged, setIsFormChanged] = useState<boolean>(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] = useState<boolean>(false);

	function handleIsFormChanged() {
		setIsFormChanged(true);
	}

	function handleEditModalHidden() {
		setIsEditModalOpen(false);
	}

	function handleEditModalClose() {
		queryClient.resetQueries([GET_PERMISSIONS]);
		setIsEditModalOpen(false);
		form.resetFields();
	}

	function handleEditModalOpen() {
		setIsEditModalOpen(true);
		setUserId(user.id);
	}

	async function handleEditFormFinish() {
		if (isFormChanged) {
			const values = await form.validateFields();
			setFormData(values);
			handleEditModalHidden();
			setIsConfirmEditModalOpen(true);
		} else {
			message.error(I18n.get('You must change user role or at least one premission.'));
		}
	}

	function handleConfirmModalClose() {
		queryClient.resetQueries([QUERY_KEYS.GET_PERMISSIONS]);
		setIsConfirmEditModalOpen(false);
		form.resetFields();
	}

	function handleConfirmModalReturn() {
		handleEditModalOpen();
		setIsConfirmEditModalOpen(false);
	}

	function handleConfirmModalFinish() {
		handleConfirmModalClose();
		form.resetFields();
	}

	function getTitleModalUpdate(status: boolean): string {
		return status ? 'Heads up! Want to enable this user?' : 'Heads up! Want to disable this user?';
	}

	function handleUpdateStatusUser(status: boolean): void {
		const title = getTitleModalUpdate(status);

		Modal.confirm({
			title: I18n.get(title),
			okText: I18n.get('Confirm'),
			cancelText: I18n.get('Cancel'),
			icon: <ExclamationCircleOutlined />,
			okType: status ? 'default' : 'danger',
			onOk: async () => {
				try {
					const result = await updateStatusUser.mutateAsync({
						id: user.id,
						status: status
					});
					queryClient.invalidateQueries([GET_USERS]);
					message.success(I18n.get(result.message));
				} catch (error) {
					message.error(I18n.get('Failed to update user status'));
				}
			}
		});
	}

	return (
		<Space size="middle">
			<TooltipAction title="Edit" isActive={user.is_active}>
				<Button
					ghost
					type="primary"
					icon={<ToolOutlined />}
					disabled={!user.is_active}
					onClick={handleEditModalOpen}
				/>
			</TooltipAction>
			<TooltipAction title={user.is_active ? 'Deactivate' : 'Activate'}>
				{user.is_active ? (
					<Button danger ghost onClick={() => handleUpdateStatusUser(false)} icon={<StopOutlined />} />
				) : (
					<Button
						ghost
						type="primary"
						icon={<UndoOutlined />}
						onClick={() => handleUpdateStatusUser(true)}
						style={{ color: '#17A93B', borderColor: '#17A93B' }}
					/>
				)}
			</TooltipAction>
			{isEditModalOpen && (
				<CustomModal isOpen={isEditModalOpen} onClose={handleEditModalClose} title="Edit user">
					<UserDataForm
						form={form}
						initialValues={{
							...user,
							user_id: userId,
							permissionsFilter: ''
						}}
						onFinish={handleEditFormFinish}
						isDisabled
						onFormChange={handleIsFormChanged}
					/>
				</CustomModal>
			)}
			{isConfirmEditModalOpen && (
				<CustomModal title="Edit user" isOpen={isConfirmEditModalOpen} onClose={handleConfirmModalClose}>
					<Confirmation
						form={form}
						onConfirmModalFinish={handleConfirmModalFinish}
						onReturn={handleConfirmModalReturn}
						user={user}
						formData={formData ?? ({} as BaseFormValues)}
					/>
				</CustomModal>
			)}
		</Space>
	);
}
