import React from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/es/button';
import { Button } from 'antd';

interface PreviousButtonProps extends ButtonProps {
	onPrevious?: React.MouseEventHandler<HTMLElement>;
	iconStyle?: boolean;
	style: any;
}

export function PreviousButton({ onPrevious, style }: Readonly<PreviousButtonProps>) {
	return <Button icon={<LeftOutlined style={style} />} type={'link'} onClick={onPrevious} style={style} />;
}
