import styled from 'styled-components';
import { Col } from 'antd';

export const colorsRiskDegree = {
	safe: {
		color: 'rgba(75, 192, 86, 0.5)',
		border: 'rgba(75, 192, 86, 1)'
	},
	info: {
		color: 'rgba(255, 206, 86, 0.5)',
		border: 'rgba(255, 206, 86, 1)'
	},
	warning: {
		color: 'rgba(255, 40, 72, 0.5)',
		border: 'rgba(255, 40, 72, 1)'
	},
	danger: {
		color: 'rgba(153, 102, 255, 0.5)',
		border: 'rgba(153, 102, 255, 1)'
	}
};

export const LevelBox = styled.div`
	width: 50%;
	display: inline-block;
	background-color: ${(props) => colorsRiskDegree[props.type].color || '#bcbcbc'};
	border: 1px solid ${(props) => colorsRiskDegree[props.type].border || '#bcbcbc'};
`;

export const LabelBox = styled.div`
	width: 50%;
	display: inline-block;
`;

export const ContainerLevel = styled(Col)`
	text-align: center;
`;
