import React from 'react';
import { UserManagementProvider } from './context';
import { Container, Header, UserList } from './components';

export function Users() {
	return (
		<UserManagementProvider>
			<Container>
				<Header />
				<UserList />
			</Container>
		</UserManagementProvider>
	);
}
