import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Form, message, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import _ from 'lodash';

import { Result } from './Result';
import { useFile, useSectors } from '@/hooks/v1';
import { BodyParts } from './BodyParts';
import { Information } from './Information';
import { AngleTimeContext } from './context';
import Spinner from '@/components/Layout/Spinner';
import { useGetAngleTimeReport } from '@/hooks/useGetAngleTimeReport';
import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';

import {
	useRiskRangeSettings,
	useCreateReport,
	useUpdateReport,
	useSaveComment,
	useGeneratePDF,
	useGetAngles,
	useRiskRanges
} from './hooks';

export function AngleTime() {
	const history = useHistory();
	const [form] = Form.useForm();
	const { search } = useLocation();

	const [parts, setParts] = useState([]);
	const [currentStep, setCurrentStep] = useState(0);
	const [riskRangeId, setRiskRangeId] = useState('');
	const [riskRangeName, setRiskRangeName] = useState('');
	const [checkAllParts, setCheckAllParts] = useState(false);

	const [isRendered, setIsRendered] = useState(false);

	const { file_id: fileId } = useParams();
	const locale = window.navigator.language ?? 'en-US';

	const organizationId = useSelector((state) => state.organization.organization?.id);
	const companyId = useSelector((state) => state.organization.company?.id);
	const organizationName = useSelector((state) => state.organization.organization?.name);

	const createReport = useCreateReport();
	const updateReport = useUpdateReport();
	const angleTime = useGetAngleTimeReport(organizationId, companyId, fileId);

	const pdf = useGeneratePDF();
	const riskRanges = useRiskRanges();
	const updateComment = useSaveComment();
	const settings = useRiskRangeSettings(riskRangeId);
	const angles = useGetAngles(organizationId, companyId, fileId);
	const sectorId = Form.useWatch('sector_id', form);

	const { sectors } = useSectors({
		organizationId: organizationId,
		companyId: companyId
	});

	const { file } = useFile({
		organizationId,
		companyId,
		fileId
	});

	async function onNext() {
		if (currentStep < steps.length - 1) {
			try {
				await form.validateFields();
				setCurrentStep((prev) => prev + 1);
			} catch (error) {
				message.error(I18n.get('Enter the required values'));
			}
		} else {
			setCurrentStep((prev) => prev + 1);
		}
	}

	function onPrevious() {
		setCurrentStep((prev) => prev - 1);
	}

	const selectRangeRisk = (id) => () => {
		setRiskRangeId(id);
	};

	async function saveComment() {
		const { comment } = form.getFieldsValue(true);

		const body = {
			organizationId,
			companyId,
			fileId,
			comment: comment ?? ''
		};

		if (angleTime.data?.comment === comment) return;

		await updateComment.mutateAsync(body);
	}

	async function onDownloadPDF() {
		await saveComment();
		pdf.mutate({
			angle_time_report_id: angleTime.data?.id,
			organization_id: organizationId,
			company_id: companyId,
			file_id: fileId,
			parts,
			locale
		});
	}

	async function onClose() {
		await saveComment();
		message.loading(I18n.get('Comment updated successfully'), 2).then(() => history.push(`/reporting/${search}`));
	}

	async function onFinish() {
		try {
			await form.validateFields();

			const { sector_id, workstation_id, range_risk_id, collection_date, parts } = form.getFieldsValue(true);

			const payload = {
				file_id: fileId,
				sector_id: sector_id,
				company_id: companyId,
				workstation_id: workstation_id,
				range_risk_id: range_risk_id,
				organization_id: organizationId,
				collection_date: moment(collection_date).format(),
				parts
			};

			const [range_risk_selected] = riskRanges.data.filter((item) => item.id === range_risk_id);

			setParts(parts);
			setRiskRangeId(range_risk_id);
			setRiskRangeName(range_risk_selected.name);

			_.isEmpty(angleTime.data) ? createReport.mutate(payload) : updateReport.mutate(payload);

			onNext();
		} catch (error) {
			message.error(I18n.get('Enter the required values'));
		}
	}

	const steps = [
		{
			title: I18n.get('Information'),
			content: <Information sectors={sectors} riskRanges={riskRanges} sectorId={sectorId} />,
			is_valid: true
		},
		{
			title: I18n.get('Body parts'),
			content: <BodyParts />,
			is_valid: true
		},
		{
			title: I18n.get('Result'),
			content: (
				<Result
					sectors={sectors}
					form={form}
					file={file}
					angles={angles}
					settings={settings}
					isLoadingPDF={pdf.isLoading}
					onDownloadPDF={onDownloadPDF}
				/>
			)
		}
	];

	function validateNotNullObjects(body_parts) {
		return body_parts && sectors?.data && settings?.data;
	}

	function setRenderedLastStep() {
		setCurrentStep(2);
		setIsRendered(true);
	}

	function verifySavedBodyParts() {
		setRiskRangeId(angleTime?.data?.range_risk_id);
		!parts?.length && setParts(angleTime?.data?.body_parts);
		validateNotNullObjects(angleTime?.data?.body_parts) && setRenderedLastStep();
	}

	useEffect(() => {
		!isRendered && verifySavedBodyParts();
	}, [angleTime, sectors, settings]);

	const isLoading =
		sectors.isLoading || file.isLoading || angleTime.isLoading || createReport.isLoading || updateReport.isLoading;
	const isError = angles.isError || settings.isError || file.isError;
	const collectionDate = angleTime.data?.collection_date ? moment(angleTime.data.collection_date) : moment();
	const savedParts = angleTime.data?.body_parts || [];

	const initialValues = {
		organization_id: organizationId,
		sector_id: file.data?.sector_id,
		workstation_id: file.data?.workstation_id,
		range_risk_id: angleTime.data?.range_risk_id,
		comment: angleTime.data?.comment,
		collection_date: collectionDate
	};

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <h2>Internal server error</h2>;
	}

	const context = {
		organizationId,
		organizationName,
		companyId,
		riskRangeId,
		fileId,
		file,
		selectRangeRisk,
		riskRangeName,
		form,
		checkAllParts,
		setCheckAllParts,
		parts,
		setParts,
		savedParts: savedParts
	};

	return (
		<AngleTimeContext.Provider value={context}>
			<Form form={form} layout="vertical" initialValues={initialValues}>
				<Row justify="center">
					<Col sm={24} style={{ marginBottom: '16px', textAlign: 'center' }}>
						<Typography.Title level={4}>{I18n.get('Angle by time')}</Typography.Title>
					</Col>
					<Col xxl={20} xl={22} sm={24}>
						<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
							<Col sm={14} xxl={12}>
								<HeaderStep current={currentStep} steps={steps} />
							</Col>
						</Row>
						<Row justify="center">
							<Col sm={24} style={{ minHeight: '350px' }}>
								{isLoading ? <Spinner /> : steps[currentStep]?.content}
							</Col>
						</Row>
						<Row justify="center" style={{ marginTop: '30px' }}>
							<Col sm={24}>
								{!isLoading && (
									<Footer
										steps={steps}
										onNext={onNext}
										onClose={onClose}
										onFinish={onFinish}
										current={currentStep}
										isFetching={isLoading}
										onPrevious={onPrevious}
									/>
								)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Form>
		</AngleTimeContext.Provider>
	);
}
