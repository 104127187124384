import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';
import { QUERY_KEYS } from '@/utils/query-keys';

export type GetFileResponse = {
	id: string;
	original_name: string;
	generated_name: string;
	created_at: string;
};

export type GetFileError = AxiosError<any>;

export interface GetFileDTO {
	organization_id: string;
	company_id: string;
	file_id: string;
}

async function getFile(params: GetFileDTO) {
	const { file_id, ...rest } = params;
	const { data } = await Api.get(`/upload/${file_id}`, { params: rest });
	return data;
}

export const useGetFile = (organizationId: string, companyId: string, fileId: string) => {
	const payload = { organization_id: organizationId, company_id: companyId, file_id: fileId };

	return useQuery<GetFileResponse, GetFileError, GetFileDTO>([QUERY_KEYS.GET_FILE], () => getFile(payload), {
		enabled: !!organizationId && !!companyId && !!fileId
	});
};
