import React from 'react';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import { Row, Col, Form, FormInstance, Input, Select } from 'antd';
import { Permissions } from './Permissions';
import { Button } from '@/components/ui/Button';
import type { Role } from '@/types/entities/User';

export type BaseFormValues = {
	permissions?: OrganizationCompanyMap;
	permissionsFilter: string;
	user_id?: string;
	email: string;
	name: string;
	role: Role;
};

interface UserDataFormProps<T extends BaseFormValues> {
	onFormChange?: () => void;
	onFinish?: () => void;
	isDisabled?: boolean;
	form: FormInstance;
	initialValues?: T;
}

export function UserDataForm<T extends BaseFormValues>({
	onFormChange,
	isDisabled = false,
	form,
	initialValues,
	onFinish
}: UserDataFormProps<T>) {
	return (
		<Form form={form} layout="vertical" name="create_user" initialValues={initialValues} onFinish={onFinish}>
			<Form.Item
				name="name"
				label={I18n.get('Name')}
				rules={[
					{
						required: true,
						message: I18n.get('Please enter a valid name')
					}
				]}
			>
				<Input disabled={isDisabled} placeholder={I18n.get('Name')} />
			</Form.Item>
			<Form.Item
				name="email"
				label={I18n.get('Email')}
				rules={[
					{
						type: 'email',
						message: I18n.get('Enter a valid e-mail')
					},
					{
						required: true,
						message: I18n.get('Enter an e-mail')
					}
				]}
			>
				<Input disabled={isDisabled} placeholder={I18n.get('Email')} />
			</Form.Item>
			<Form.Item
				name="role"
				label={I18n.get('Permissions')}
				rules={[
					{
						required: true,
						message: I18n.get('Enter a permission')
					}
				]}
			>
				<Select
					onChange={onFormChange}
					showSearch
					style={{ width: '100%' }}
					placeholder={I18n.get('Permissions')}
					optionFilterProp="children"
					disabled={initialValues?.role === 'MASTER'}
					options={[
						{
							value: 'MASTER',
							label: I18n.get('Master'),
							disabled: true
						},
						{
							value: 'SUPERVISOR',
							label: I18n.get('Supervisor')
						},
						{
							value: 'USER',
							label: I18n.get('User')
						}
					]}
				/>
			</Form.Item>
			<Form.Item name="permissionsFilter" label={I18n.get('Companies and Industrial Sites')}>
				<Input placeholder={I18n.get('Search a company or industrial site')} prefix={<SearchOutlined />} />
			</Form.Item>
			<Form.Item name="permissions">
				<Permissions isCreating={true} onFormChange={onFormChange} />
			</Form.Item>
			<Row justify="center" style={{ marginTop: '10px' }}>
				<Col>
					<Button type="primary" htmlType="submit" style={{ borderRadius: '8px' }}>
						{I18n.get('Continue')}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
