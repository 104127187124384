import React from 'react';
import { Col, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItem } from '../../../components/FormItem';
import { useApplicationContext } from '@/context/Application';

export const Organizations: React.FC = () => {
	const { organization } = useApplicationContext();

	const organizations = [
		{
			label: organization?.name,
			value: organization?.id
		}
	];

	return (
		<Col xs={24}>
			<FormItem
				fontSize="1rem"
				isRequired={true}
				title={I18n.get('Company')}
				namePath={['basic_information', 'organization_id']}
			>
				<Select size="large" disabled={true} value={organization?.id} options={organizations} />
			</FormItem>
		</Col>
	);
};
