import React from 'react';
import { Col, Row } from 'antd';

import { Exposures } from '../Exposures';
import { Consequences } from '../Consequences';
import { Probabilities } from '../Probabilities';
import { ToolCard } from '@/components/ToolCard';
import { Injuries, RiskLevelCard } from './Inputs';
import { useSocketReport } from '@/hooks/useSocket';
import { ergonomicToolReportMapper } from './context/types';
import { usePersonalisedReportStepKeyContext } from './context';
import { usePersonalisedReportContext } from '../../../context';

export const DefaultStepKey: React.FC = () => {
	const { file_id, personalisedReport } = usePersonalisedReportContext();
	const { hasInjury, ergonomicTools, stepKeyFieldName, mapStepKeyDropdownOptions, mapErgonomicTools } =
		usePersonalisedReportStepKeyContext();
	const tools = useSocketReport();

	return (
		<Row gutter={[0, 50]}>
			{(hasInjury || ergonomicTools.length > 0) && (
				<Col xs={24} lg={12}>
					<Row>
						{hasInjury && (
							<Col xs={24}>
								<Row justify="start">
									<Col sm={24} lg={18}>
										<Injuries />
									</Col>
								</Row>
							</Col>
						)}
					</Row>
					<Row style={{ marginTop: '1rem' }}>
						{file_id && ergonomicTools.length > 0 && (
							<Col xs={24}>
								<Row justify="start" gutter={[16, 16]}>
									{ergonomicTools.map(({ id, description, name, subtitle, title }) => {
										const mappedErgonomicTools = mapErgonomicTools(
											tools,
											personalisedReport.basic_information
										);
										const { status } = mappedErgonomicTools[ergonomicToolReportMapper[name]];
										return (
											<ToolCard
												key={id}
												title={title}
												fileId={file_id}
												subtitle={subtitle}
												isActive={status ?? false}
												reportSelected={description}
											/>
										);
									})}
								</Row>
							</Col>
						)}
					</Row>
				</Col>
			)}
			<Col xs={24} lg={12}>
				<Row>
					<Col xs={24}>
						<Row justify="start">
							<Col xs={24}>
								<Consequences
									mapDropdownOptions={mapStepKeyDropdownOptions}
									stepKeyFieldName={stepKeyFieldName}
								/>
								<Probabilities
									mapDropdownOptions={mapStepKeyDropdownOptions}
									stepKeyFieldName={stepKeyFieldName}
								/>
								<Exposures
									mapDropdownOptions={mapStepKeyDropdownOptions}
									stepKeyFieldName={stepKeyFieldName}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row style={{ marginTop: '2rem' }}>
					<Col xs={24}>
						<Row gutter={[0, 10]}>
							<Col span={24}>
								<RiskLevelCard />
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
