import React from 'react';
import { Avatar, Form } from 'antd';
import * as S from './styles';

interface CompanyInfo {
	id: string;
	logo: string;
	name: string;
}

interface Base {
	id: string;
	name: string;
	logo: string;
	companies: CompanyInfo[];
}

interface ListCollapseProps<T extends Base> {
	onChange?: (value: OrganizationCompanyMap) => void;
	value: OrganizationCompanyMap;
	onFormChange?: () => void;
	data?: T[];
}

interface CollapseProps<T extends Base> {
	organizationPermissionsList: T;
	children: JSX.Element;
	isBlured: boolean;
}

export function Collapse<T extends Base>({ organizationPermissionsList, isBlured, children }: CollapseProps<T>) {
	return (
		<S.Container $isBlured={isBlured}>
			<S.Panel
				key={organizationPermissionsList.id}
				header={
					<>
						<Avatar
							src={<img src={organizationPermissionsList.logo} alt="Organization logo" />}
							style={{ marginRight: '10px', marginLeft: '5px' }}
							size={20}
						/>
						{organizationPermissionsList.name}
					</>
				}
				showArrow={false}
			>
				{children}
			</S.Panel>
		</S.Container>
	);
}

export function ListCollapse<T extends Base>({ data = [], value, onFormChange }: ListCollapseProps<T>) {
	return (
		<>
			{data.map((organization) => {
				return (
					<Form.Item
						name={['permissions', organization.id]}
						style={{ marginBottom: '0px' }}
						key={organization.id}
					>
						<Collapse
							key={organization.id}
							isBlured={value[organization.id] && value[organization.id].length > 0}
							organizationPermissionsList={organization}
						>
							<Form.Item name={['permissions', organization.id]}>
								<S.CheckGroupCustom
									onChange={onFormChange}
									options={organization.companies.map((company) => {
										return {
											value: company.id,
											label: (
												<>
													<Avatar
														src={<img src={company.logo} alt="Company logo" />}
														style={{ marginRight: '10px', marginLeft: '5px' }}
														size={20}
													/>
													{company.name}
												</>
											)
										};
									})}
								/>
							</Form.Item>
						</Collapse>
					</Form.Item>
				);
			})}
		</>
	);
}
