import React from 'react';
import { Route } from 'react-router-dom';

import Can from '@/components/Can';
import * as V from '@/components/views';
import { Report } from '@/components/Report';
import { Dashboard } from '@/components/Dashboard';
import { Account } from '@/components/Settings/Account';
import { ManageUsers, Preferences } from '@/components/Settings';
import { Organizations } from '@/components/Settings/Organizations';
import { ContractPlans } from '@/components/Settings/ContractPlans';
import { Users } from '../views/Users';

const reportRoutes = [
	{
		type: 'niosh',
		component: V.Niosh
	},
	{
		type: 'reba',
		component: V.Reba
	},
	{
		type: 'angle-time',
		component: V.AngleTime
	},
	{
		type: 'strain-index',
		component: V.StrainIndex
	},
	{
		type: 'kim-pp',
		component: V.KimPushPull
	},
	{
		type: 'kim-mho',
		component: V.KimManualHandling
	},
	{
		type: 'liberty-mutual',
		component: V.LibertyMutual
	},
	{
		type: 'preliminary-ergonomic-analysis',
		component: V.PreliminaryAnalysisForm
	}
];

export function PrivateRoutes() {
	return (
		<Route
			component={() => (
				<>
					<Route exact path="/" component={Dashboard} />
					<Route exact path="/users" component={Users} />
					<Route exact path="/upload" component={V.Upload} />
					<Route exact path="/reporting" component={Report} />
					<Route exact path="/settings" component={Account} />
					<Route exact path="/settings/account" component={Account} />
					<Route exact path="/action-plans" component={V.ActionPlans} />
					<Route exact path="/super-pea/new-document" component={V.MergePea} />
					<Route exact path="/super-pea/file-history" component={V.SuperPreliminaryAnalysis} />
					<Route
						exact
						path="/reporting/personalised-report/:report_id/:file_id?"
						component={V.PersonalisedReport}
					/>
					{reportRoutes.map((report) => (
						<Route
							key={report.type}
							component={report.component}
							path={`/reporting/${report.type}/:file_id`}
						/>
					))}
					{Can('settings', 'management') && (
						<>
							<Route
								exact
								path="/settings/companies"
								component={process.env.REACT_APP_CONTRACT_PLANS ? ContractPlans : Organizations}
							/>
							<Route exact path="/settings/preferences" component={Preferences} />
							<Route exact path="/settings/manage-users" component={ManageUsers} />
							<Route exact path="/settings/personalised-reports" component={V.PersonalisedReportsList} />
							<Route
								exact
								path="/settings/personalised-reports/manage/:report_id?"
								component={V.PersonalisedReportsManager}
							/>
						</>
					)}
				</>
			)}
		/>
	);
}
