import styled from 'styled-components';
import { Radio } from 'antd';

export const WrapperRadioButton = styled(Radio)`
	.ant-radio {
		display: none;
	}

	&.ant-radio-wrapper-checked {
		.ant-card-bordered {
			border-bottom: 2px solid #3856f8;
		}
	}
`;
