import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form, Select } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { useGetSystemOfUnits } from '../../hooks';
import { OptionType } from '../../context/types';
import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

export const SystemOfUnits: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { company, organization } = useApplicationContext();

	const systemOfUnitId = useWatch('system_of_units_id', form);

	const {
		data: systemOfUnits,
		isFetching,
		isError
	} = useGetSystemOfUnits({
		organization_id: organization?.id,
		company_id: company?.id
	});

	function handleSelectSystemOfUnit(id: string) {
		setFieldValue(['system_of_units_id'], id);
	}

	function handleClearSystemOfUnits() {
		setFieldValue(['system_of_units_id'], undefined);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	const mappedSystemOfUnits = systemOfUnits?.map((system) => ({
		label: I18n.get(system?.description),
		value: system?.id
	}));

	return (
		<Col xs={24}>
			<CustomFormItem
				name={['system_of_units_id']}
				label={I18n.get('System of unit')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the system of unit')
					}
				]}
			>
				<Select
					showSearch
					allowClear
					size="large"
					loading={isFetching}
					value={systemOfUnitId}
					options={mappedSystemOfUnits}
					disabled={isError || isFetching}
					onClear={handleClearSystemOfUnits}
					onChange={handleSelectSystemOfUnit}
					placeholder={I18n.get('System of unit')}
					filterOption={(input, option) => filterOption(option, input)}
				/>
			</CustomFormItem>
		</Col>
	);
};
