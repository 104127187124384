export const couplingList = [
	{
		label: 'Good',
		value: 1
	},
	{
		label: 'Fair',
		value: 2
	},
	{
		label: 'Poor',
		value: 3
	},
	{
		label: 'Unacceptable',
		value: 4
	}
];

export const loadList = [
	{
		label: 'Load less than 5kg (intermittent)',
		value: 1
	},
	{
		label: 'Load from 5kg to 20kg (intermittent)',
		value: 2
	},
	{
		label: 'Load from 5kg to 20kg (static or repetitive)',
		value: 3
	},
	{
		label: 'Greater than 20 kg of repetitive load or blows',
		value: 4
	}
];
