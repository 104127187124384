import React from 'react';
import { Card } from 'antd';
import { I18n } from '@aws-amplify/core';

import { Container, Grade, Label } from './styles';
import { useWorstGrade } from '../../../../../hooks';
import { useRebaReportContext } from '../../../../../context';

const headStyle = { backgroundColor: '#fafafa' };

export const WorstGrade: React.FC = () => {
	const { base_parameters } = useRebaReportContext();
	const { isLoading, data, isError } = useWorstGrade(base_parameters);

	if (isLoading) {
		return <p>{I18n.get('Loading')}...</p>;
	}

	if (isError) {
		return <p>{I18n.get('Failed to fetch information')}</p>;
	}

	return (
		<Container size={[5, 10]} direction="horizontal">
			<Card size="small" title={I18n.get('REBA Score')} headStyle={headStyle}>
				{data?.worst_grade > 0 ? (
					<Grade>{data?.worst_grade}</Grade>
				) : (
					<Label style={{ color: 'rgba(113, 113, 113, 1)' }}>-</Label>
				)}
			</Card>
			<Card size="small" title={I18n.get('Worst grade found in time')} headStyle={headStyle}>
				{data?.worst_grade > 0 ? (
					<Label>{data?.time}</Label>
				) : (
					<Label style={{ color: 'rgba(113, 113, 113, 1)' }}>-</Label>
				)}
			</Card>
		</Container>
	);
};
