import React from 'react';
import { Badge } from 'antd';
import { Text } from '@/components/Typography';

interface BadgeStatusProps {
	status?: boolean;
}

export function BadgeStatus({ status = true }: BadgeStatusProps) {
	return (
		<>
			<Badge color={status ? 'green' : 'red'} />
			<Text style={{ marginLeft: '5%' }}>{status ? 'Active' : 'Inactive'}</Text>
		</>
	);
}
