import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Form } from 'antd';

import { useApplicationContext } from '@/context/Application';
import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useListCompanies } from '@/hooks/v1/useListCompanies';

import { CustomFormItem } from './styles';

const { useFormInstance, useWatch } = Form;

export const Companies: React.FC = () => {
	const form = useFormInstance();
	const { setFieldValue } = useFormInstance();
	const { organization, company } = useApplicationContext();

	const companyId = useWatch('company_id', form);
	const {
		data: companies,
		isLoading,
		isFetching,
		isError
	} = useListCompanies({
		organization_id: organization?.id,
		company_id: company?.id
	});

	function handleCompanySelect(id: string) {
		setFieldValue(['company_id'], id);
		form.setFieldValue(['sector_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	function handleClearCompany() {
		setFieldValue(['company_id'], undefined);
		form.setFieldValue(['sector_id'], undefined);
		form.setFieldValue(['workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<CustomFormItem
				name={['company_id']}
				label={I18n.get('Industrial site')}
				labelCol={{ span: 24 }}
				rules={[
					{
						required: true,
						message: I18n.get('Select the industrial site')
					}
				]}
			>
				<CompanySelect
					size="large"
					loading={isFetching}
					companies={companies}
					companyId={companyId}
					handleClearCompany={handleClearCompany}
					handleSelectCompany={handleCompanySelect}
					disabled={isLoading || isFetching || isError}
				/>
			</CustomFormItem>
		</Col>
	);
};
