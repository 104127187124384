import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FileResultsProps } from './types';
import { StyledRow, Topic, Value } from './styles';
import { useApplicationContext } from '@/context/Application';

const FORMAT_SECONDS_FILE: string = 'HH:mm:ss';

export const Information: React.FC<FileResultsProps> = ({ file, reportData }) => {
	const { organization, company } = useApplicationContext();

	function formatSeconds(seconds: number = 0): string {
		return moment.utc(seconds * 1000).format(FORMAT_SECONDS_FILE);
	}

	return (
		<Col span={24} style={{ margin: '0.5rem 0 1rem 60px' }}>
			<Row align={'middle'}>
				<Col span={10}>
					<StyledRow>
						<Topic>{I18n.get('Company')}:</Topic>
						<Value>{organization?.name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Industrial site')}:</Topic>
						<Value>{company?.name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Sector')}:</Topic>
						<Value>{file.sector?.name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Workstation')}:</Topic>
						<Value>{file.workstations?.name}</Value>
					</StyledRow>
				</Col>
				<Col span={12}>
					<StyledRow>
						<Topic>{I18n.get('File')}:</Topic>
						<Value>{file.original_name}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Date')}:</Topic>
						<Value>{moment(reportData?.collection_date ?? file?.updatedAt).format('DD/MM/YYYY')}</Value>
					</StyledRow>
					<StyledRow>
						<Topic>{I18n.get('Duration')}:</Topic>
						<Value>{formatSeconds(file?.duration)}</Value>
					</StyledRow>
					{reportData?.duration_frequency_report && (
						<StyledRow>
							<Topic>{I18n.get('Risk range')}:</Topic>
							<Value>{reportData.duration_frequency_report.range_risk.name}</Value>
						</StyledRow>
					)}
					{file?.riskRangeName && (
						<StyledRow>
							<Topic>{I18n.get('Risk range')}:</Topic>
							<Value>{file.riskRangeName}</Value>
						</StyledRow>
					)}
				</Col>
			</Row>
		</Col>
	);
};
