import React from 'react';
import moment from 'moment';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Item, ItemValue } from './styles';

const FORMAT_SECONDS_FILE: string = 'HH:mm:ss';

export const FileData = ({ originalName, duration = 0, size }: any) => {
	function formatSeconds(seconds: number = 0): string {
		return moment.utc(seconds * 1000).format(FORMAT_SECONDS_FILE);
	}

	return (
		<Col sm={24} style={{ paddingTop: '20px' }}>
			<Row>
				<Col sm={24}>
					<h3>{I18n.get('File data')}</h3>
				</Col>
				<Col sm={4}>
					<Item>{I18n.get('File')}</Item>
					<ItemValue>{originalName}</ItemValue>
				</Col>
				<Col sm={4}>
					<Item>{I18n.get('Duration')}</Item>
					<ItemValue>{formatSeconds(duration)}</ItemValue>
				</Col>
				<Col sm={4}>
					<Item>{I18n.get('Size')}</Item>
					<ItemValue>{size}</ItemValue>
				</Col>
			</Row>
		</Col>
	);
};
