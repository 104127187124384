import { Radio } from 'antd';
import styled from 'styled-components';

interface RadioButtonProps {
	length: number;
}

export const RadioButton = styled(Radio.Button)<RadioButtonProps>`
	text-align: center;
	width: ${({ length }) => 100 / length}%;
`;

export const RadioItem = styled(Radio)`
	height: 30px;
	display: block;
	line-height: 30px;
`;
