import React, { useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { useCreateReba } from '@/hooks/useCreateReba';
import { useUpdateReba } from '@/hooks/useUpdateReba';

import { useApplicationContext } from '@/context/Application';
import { useFile, useRebaCalculate, useReportDownload } from './hooks';
import { useGetRebaReport } from '@/hooks/useGetRebaReport';
import { BaseParameters, UrlParams } from './types';
import { Spinner } from '@/components/Spinner';
import { RebaReportContext } from './context';
import { Steps } from './Steps';

export const Reba: React.FC = () => {
	const { file_id } = useParams<UrlParams>();
	const { organization, company } = useApplicationContext();

	const base_parameters: BaseParameters = useMemo(
		() => ({
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: file_id
		}),
		[organization?.id, company?.id, file_id]
	);

	const create = useCreateReba();
	const update = useUpdateReba();
	const file = useFile(base_parameters);
	const report = useGetRebaReport(organization.id, company.id, file_id);

	const { mutate: calculation } = useRebaCalculate();
	const download = useReportDownload(base_parameters);

	useEffect(() => {
		if (file.data) {
			calculation(base_parameters);
		}
	}, [file.data, calculation]);

	const isLoading = report.isLoading || file.isLoading;

	const initial_values = {
		step: report.data?.step ?? 0,
		force: report.data?.force ?? 1,
		sector_id: file?.data?.sector_id,
		company_id: file?.data?.company_id,
		comment: report.data?.comment ?? '',
		coupling: report.data?.coupling ?? 1,
		repetition: report.data?.repetition ?? 1,
		workstation_id: file?.data?.workstations?.id,
		collection_date: report.data?.collection_date ? moment(report.data?.collection_date) : moment()
	};

	const context = {
		organization,
		company,
		file,
		report,
		base_parameters,
		initial_values,
		create,
		update,
		download
	};

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<RebaReportContext.Provider value={context}>
			<Steps />
		</RebaReportContext.Provider>
	);
};
