import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { CompanyResponse, OrganizationResponse } from '@/views/Users/components/Modal/Confirmation/types';
import { QUERY_KEYS } from '@/utils/query-keys';
import type { Response } from '@/types';
import Api from '@/services/api';

export type CompanyWithOrganizationResponse = CompanyWithOrganization[];

export type CompanyWithOrganization = {
	company: CompanyResponse;
	organization: OrganizationResponse;
};

export type CompanyWithOrganizationDTO = {
	companies_ids: string[];
};

async function getCompaniesWithOrganizations(
	params: CompanyWithOrganizationDTO
): Promise<CompanyWithOrganizationResponse> {
	const url = '/user_management/companies_with_organizations_list';
	const { data } = await Api.get<CompanyWithOrganizationResponse>(url, { params });
	return data;
}

export const useGetCompaniesWithOrganizations = (params: CompanyWithOrganizationDTO) => {
	const { companies_ids } = params;
	const companyWithOrganization = useQuery<CompanyWithOrganizationResponse, AxiosError<Response>>(
		[QUERY_KEYS.GET_COMPANIES_WITH_ORGANIZATION, params.companies_ids],
		() => getCompaniesWithOrganizations(params),
		{
			enabled: companies_ids.length > 0
		}
	);

	return companyWithOrganization;
};
