import React from 'react';
import { Col, Form, InputNumber, Row, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FrequencyTimeFormatMapper, OptionType } from '../../../context/types';
import { FrequencyTimeFormatDTO } from '../../../hooks/types/response';
import { useLibertyMutualContext } from '../../../context';

const { useFormInstance } = Form;

export const Frequency = () => {
	const { setFieldValue } = useFormInstance();
	const { libertyMutual, decimalSeparator } = useLibertyMutualContext();

	const frequencyIntervals = [
		{
			label: `${I18n.get(libertyMutual?.task.plural_description)} ${I18n.get(
				`per ${FrequencyTimeFormatMapper.HOUR}`
			)}`,
			value: FrequencyTimeFormatDTO.HOUR
		},
		{
			label: `${I18n.get(libertyMutual?.task.plural_description)} ${I18n.get(
				`per ${FrequencyTimeFormatMapper.MINUTE}`
			)}`,
			value: FrequencyTimeFormatDTO.MINUTE
		}
	];

	function handleClear() {
		setFieldValue(['liberty_mutual_inputs', 'frequency_time_format'], undefined);
	}

	function filterOption(option: OptionType, input: string): boolean {
		return (option?.label.toLowerCase() ?? '').includes(input.toLowerCase());
	}

	return (
		<Row>
			<Col xs={24}>
				<Row justify="start" align="bottom" gutter={[16, 16]}>
					<Col xs={24} xl={7}>
						<Form.Item
							name={['liberty_mutual_inputs', 'frequency']}
							label={I18n.get('Frequency')}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Insert a frequency value')
								}
							]}
						>
							<InputNumber
								min={0}
								placeholder="0"
								decimalSeparator={decimalSeparator}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} xl={13}>
						<Form.Item
							name={['liberty_mutual_inputs', 'frequency_time_format']}
							rules={[
								{
									required: true,
									message: I18n.get('Select a frequency interval')
								}
							]}
						>
							<Select
								showSearch
								allowClear
								size="middle"
								placeholder={`${I18n.get(libertyMutual?.task.plural_description)} ${I18n.get('per')}`}
								onClear={handleClear}
								options={frequencyIntervals}
								disabled={!libertyMutual?.id}
								filterOption={(input, option) => filterOption(option, input)}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
