import React from 'react';
import { Row, Col } from 'antd';
import { I18n } from '@aws-amplify/core';
import * as S from './styles';

export const Details = ({ reportData }) => {
	const posture_options = {
		posture1: {
			text_one: 'Trunk upright or slightly inclined forward, no twisting',
			text_two: 'Force application height can be selected freely',
			text_three: 'No hindrance for the legs'
		},
		posture2: {
			text_one:
				'Body inclined towards the direction of movement or slight twisting when pulling the load on one side',
			text_two: 'Fixed force application height ranging from 0.9 – 1.2 m',
			text_three: 'None or only slight hindrance for the legs',
			text_four: 'Predominantly pulling'
		},
		posture3: {
			text_one:
				'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view',
			text_two: 'Significant hindrance for the legs',
			text_three: 'Constant twisting and/or lateral inclination of the trunk'
		}
	};

	return (
		<Row gutter={[20, 20]} style={{ marginBottom: '40px' }} justify="center">
			<S.Container sm={10}>
				<S.Title level={4}>{I18n.get('Driveway conditions')}</S.Title>
				{reportData?.vehicle_input < 8 ? (
					<S.InsideContainer>
						<Col sm={2}>
							{reportData?.driveway_conditions_input < 2 ? (
								<S.Check />
							) : reportData?.driveway_conditions_input < 4 ? (
								<S.Exclamation />
							) : (
								<S.Close />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<S.Description>
								{I18n.get(reportData?.labels.driveway_conditions.description)}
							</S.Description>
						</Col>
					</S.InsideContainer>
				) : (
					<S.InsideContainer>
						<Col sm={2}>
							<S.Check />
						</Col>
						<Col sm={21} offset={1}>
							<S.Description>
								{I18n.get('Driveway conditions are not applied for the selected vehicle.')}
							</S.Description>
						</Col>
					</S.InsideContainer>
				)}
			</S.Container>
			<S.Container sm={10} offset={1}>
				<S.Title level={4}>{I18n.get('Unfavorable vehicle properties')}</S.Title>
				{reportData?.labels.properties.map((option, index) => (
					<S.InsideContainer key={index}>
						<Col sm={2}>
							{reportData?.properties[index] === 1 ? (
								<S.Close />
							) : reportData?.properties[index] === 4 ? (
								<S.Check />
							) : (
								<S.Exclamation />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<S.Description>{I18n.get(option.description)}.</S.Description>
						</Col>
					</S.InsideContainer>
				))}
			</S.Container>
			<S.Container sm={10}>
				<S.Title level={4}>{I18n.get('Unfavorable working conditions')}</S.Title>
				{reportData?.labels.working_conditions.map((option, index) => (
					<S.InsideContainer key={index}>
						<Col sm={2}>
							{reportData?.working_conditions[index] === 6 ? (
								<S.Check />
							) : reportData?.working_conditions[index] <= 3 ? (
								<S.Close />
							) : (
								<S.Exclamation />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<S.Description>{I18n.get(option.description)}.</S.Description>
						</Col>
					</S.InsideContainer>
				))}
			</S.Container>
			<S.Container sm={10} offset={1}>
				<S.Title level={4}>{I18n.get('Posture')}</S.Title>
				<S.InsideContainer>
					<Col sm={2}>
						{reportData?.posture === 0 ? (
							<S.Check />
						) : reportData?.posture === 1 ? (
							<S.Exclamation />
						) : (
							<S.Close />
						)}
					</Col>
					<Col sm={21} offset={1}>
						<S.Description>
							{I18n.get(posture_options[reportData?.labels.posture.value].text_one)}.
						</S.Description>
					</Col>
				</S.InsideContainer>
				<S.InsideContainer>
					<Col sm={2}>
						{reportData?.posture === 0 ? (
							<S.Check />
						) : reportData?.posture === 1 ? (
							<S.Exclamation />
						) : (
							<S.Close />
						)}
					</Col>
					<Col sm={21} offset={1}>
						<S.Description>
							{I18n.get(posture_options[reportData?.labels.posture.value].text_two)}.
						</S.Description>
					</Col>
				</S.InsideContainer>
				<S.InsideContainer>
					<Col sm={2}>
						{reportData?.posture === 0 ? (
							<S.Check />
						) : reportData?.posture === 1 ? (
							<S.Exclamation />
						) : (
							<S.Close />
						)}
					</Col>
					<Col sm={21} offset={1}>
						<S.Description>
							{I18n.get(posture_options[reportData?.labels.posture.value].text_three)}.
						</S.Description>
					</Col>
				</S.InsideContainer>
				{posture_options[reportData?.labels.posture.value].text_four ? (
					<S.InsideContainer>
						<Col sm={2}>
							{reportData?.posture === 0 ? (
								<S.Check />
							) : reportData?.posture === 1 ? (
								<S.Exclamation />
							) : (
								<S.Close />
							)}
						</Col>
						<Col sm={21} offset={1}>
							<S.Description>
								{I18n.get(posture_options[reportData?.labels.posture.value].text_four)}.
							</S.Description>
						</Col>
					</S.InsideContainer>
				) : (
					''
				)}
			</S.Container>
		</Row>
	);
};
