import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Form, Row } from 'antd';

import { BackButton } from '../../components/BackButton';
import { usePersonalisedReportContext } from '../../../context';
import { CreateOrUpdateWorkCondition } from '../../../context/types';
import { useApplicationContext } from '@/context/Application';
import { useCreateWorkConditionResult, useUpdateWorkConditionResult } from './hooks';
import { CreateOrUpdateService, WorkConditionsFormFields } from '../../../context/services';

const { useFormInstance } = Form;

export const Footer: React.FC = () => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();
	const { personalisedReport, step_setting_id, report_id, handleNextStep, handlePreviousStep, scrollToError } =
		usePersonalisedReportContext();

	const { mutateAsync: createResult, isLoading: creatingReport } = useCreateWorkConditionResult();
	const { mutateAsync: updateResult, isLoading: updatingReport } = useUpdateWorkConditionResult();

	function handlePreviousClick(): void {
		handlePreviousStep();
	}

	async function handleNextClick(): Promise<void> {
		try {
			const validation = await form.validateFields();
			if (validation) {
				const values: WorkConditionsFormFields = await form.getFieldValue(['work_conditions']);
				const createOrUpdateService = new CreateOrUpdateService();
				const payload = createOrUpdateService.mapPayload(values, {
					report_id,
					step_setting_id,
					company_id: company?.id,
					organization_id: organization?.id,
					result_id: personalisedReport.result_id,
					workstation_id: personalisedReport.basic_information?.workstation_id
				});
				if (createOrUpdateService.isWorkConditionPayload(payload)) {
					if (alreadyHasResult()) {
						return await update(payload);
					}
					const report = await createResult(payload);
					if (report.id) {
						handleNextStep();
					}
				}
			}
		} catch (error: any) {
			scrollToError(error);
		}
	}

	function alreadyHasResult(): boolean {
		return !!personalisedReport.work_conditions?.id;
	}

	async function update(payload: CreateOrUpdateWorkCondition): Promise<void> {
		const report = await updateResult({
			...payload,
			work_condition_id: personalisedReport.work_conditions?.id
		});
		if (report.id) {
			handleNextStep();
		}
	}

	return (
		<Row>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				<Row justify="center">
					<BackButton onPreviousClick={handlePreviousClick} disabled={creatingReport || updatingReport} />
					<Col>
						<Button
							type="primary"
							htmlType="submit"
							onClick={handleNextClick}
							loading={creatingReport || updatingReport}
							disabled={creatingReport || updatingReport}
						>
							{I18n.get('Next')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
