import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Pagination } from 'antd';
import Table, { type ColumnsType } from 'antd/lib/table';

import { Filter } from '../Filter';
import { BadgeStatus } from './BadgeStatus';
import { Text } from '@/components/Typography';
import { ActionButtons } from './ActionButtons';
import { useGetUsers, type UserDTO } from '@/hooks';
import { useUserManagementContext } from '../../context';
import { useApplicationContext } from '@/context/Application';

export function UserList() {
	const { organization, company } = useApplicationContext();
	const { filter, page, limit, handleSetPage, handleSetPageLimit } = useUserManagementContext();

	const { isLoading, data, isError } = useGetUsers({
		organization_id: organization.id,
		company_id: company.id,
		filter,
		page,
		limit
	});
	// const { isLoading, data, isError } = useGetUsers({ filter, page, limit });

	const columns: ColumnsType<UserDTO> = [
		{
			title: <Text>Name</Text>,
			ellipsis: true,
			dataIndex: 'name',
			key: 'name',
			width: '25%',
			align: 'center'
		},
		{
			title: <Text>E-mail</Text>,
			dataIndex: 'email',
			key: 'email',
			responsive: ['sm'],
			ellipsis: true,
			width: '25%',
			align: 'center'
		},
		{
			title: <Text>Role</Text>,
			dataIndex: 'role',
			key: 'role',
			responsive: ['lg'],
			ellipsis: true,
			width: '20%',
			align: 'center',
			render: (value) => <Text>{value}</Text>
		},
		{
			title: I18n.get('Status'),
			dataIndex: 'is_active',
			key: 'is_active',
			responsive: ['xl'],
			align: 'center',
			render: (isActive: boolean) => <BadgeStatus status={isActive} />
		},
		{
			title: I18n.get('Actions'),
			key: 'action',
			align: 'center',
			render: (user) => <ActionButtons user={user} />
		}
	];

	if (isError) {
		return <Text style={{ color: 'red' }}>Failed to load users</Text>;
	}

	return (
		<>
			<Col span={24}>
				<Filter />
			</Col>
			<Col sm={24} md={24}>
				<Row justify="end" gutter={[0, 15]}>
					<Col span={24}>
						<Table
							rowKey="id"
							columns={columns}
							pagination={false}
							loading={isLoading}
							dataSource={data?.rows}
							rowClassName={(record) => (!record.is_active ? 'disabled-row-table' : '')}
						/>
					</Col>
					<Col>
						<Pagination
							current={page}
							pageSize={limit}
							total={data?.count}
							onChange={(page) => handleSetPage(page)}
							onShowSizeChange={(_, size) => handleSetPageLimit(size)}
							pageSizeOptions={['10', '20', '30', '50', '100']}
						/>
					</Col>
				</Row>
			</Col>
		</>
	);
}
