import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Radio, InputNumber } from 'antd';

import { Title } from '@/components/Typography';
import { RadioButton, RadioItem } from './styles';

const couplingList = [
	{
		label: 'Good',
		value: 1
	},
	{
		label: 'Fair',
		value: 2
	},
	{
		label: 'Poor',
		value: 3
	},
	{
		label: 'Unacceptable',
		value: 4
	}
];

const forceList = [
	{
		label: 'Load less than 5kg (intermittent)',
		value: 1
	},
	{
		label: 'Load from 5kg to 20kg (intermittent)',
		value: 2
	},
	{
		label: 'Load from 5kg to 20kg (static or repetitive)',
		value: 3
	},
	{
		label: 'Greater than 20 kg of repetitive load or blows',
		value: 4
	}
];

export const Parameters: React.FC = () => (
	<Row justify="center" align="middle" gutter={[10, 15]}>
		<Col xs={24} sm={24} md={24} lg={14}>
			<Col xs={24} sm={24} md={24}>
				<Title level={5}>{I18n.get('Repetitions per minute')}:</Title>
				<Form.Item
					name="repetition"
					rules={[
						{
							required: true,
							message: I18n.get('Select the number of repetitions')
						}
					]}
				>
					<InputNumber min={1} max={100} style={{ width: '60px' }} />
				</Form.Item>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Title level={5}>{I18n.get('Coupling')}:</Title>
				<Form.Item
					name="coupling"
					rules={[
						{
							required: true,
							message: I18n.get('Select the coupling quality')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '100%' }}>
						{couplingList.map((item, index) => (
							<RadioButton key={index} value={item.value} length={couplingList.length}>
								{I18n.get(item.label)}
							</RadioButton>
						))}
					</Radio.Group>
				</Form.Item>
			</Col>
			<Col xs={24} sm={24} md={24}>
				<Title level={5}>{I18n.get('Force')}:</Title>
				<Form.Item
					name="force"
					rules={[
						{
							required: true,
							message: I18n.get('Select the load')
						}
					]}
				>
					<Radio.Group buttonStyle="solid" style={{ width: '100%' }}>
						{forceList.map((item, index) => (
							<RadioItem key={index} value={item.value}>
								{I18n.get(item.label)}
							</RadioItem>
						))}
					</Radio.Group>
				</Form.Item>
			</Col>
		</Col>
	</Row>
);
