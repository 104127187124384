import React, { createContext, ReactNode, useContext, useState } from 'react';
import { Text } from '@/components/Typography';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';

import { States, Context, Methods, FormStep, LibertyMutualRouteParams } from './types';
import { InitialValuesMapper } from '../hooks/services/InitialValuesMapper';
import { useApplicationContext } from '@/context/Application';
import { LibertyMutualReportDTO } from '../hooks/types/response';
import { Informations } from '../Report/Informations';
import { useGetReport, useGetResult } from '../hooks';
import { LoadingSkeleton } from '../LoadingSkeleton';
import { Variables } from '../Report/Variables';
import { Result } from '../Report/Result';
import { Task } from '../Report/Task';

type LibertyMutualProviderProps = {
	children: ReactNode;
};

const { useForm } = Form;
const LibertyMutualContext = createContext<Context>({} as Context);

const steps: FormStep[] = [
	{
		icon: null,
		title: 'Informations',
		component: <Informations />
	},
	{
		icon: null,
		title: 'Task',
		component: <Task />
	},
	{
		icon: null,
		title: 'Variables',
		component: <Variables />
	},
	{
		icon: null,
		title: 'Result',
		component: <Result />
	}
];

export function LibertyMutualProvider({ children }: LibertyMutualProviderProps) {
	const [form] = useForm();
	const { file_id } = useParams<LibertyMutualRouteParams>();
	const { organization, company } = useApplicationContext();

	const [updatedLibertyMutual, setUpdatedLibertyMutual] = useState<LibertyMutualReportDTO | undefined>(undefined);

	const {
		data: libertyMutual,
		isFetching: fetchingReport,
		isError: errorGettingReport
	} = useGetReport({
		organization_id: organization?.id,
		company_id: company?.id,
		file_id
	});
	const {
		data: libertyMutualResult,
		isInitialLoading: fetchingResult,
		isError: errorGettingResult
	} = useGetResult({
		organization_id: organization?.id,
		company_id: company?.id,
		file_id,
		finished: isReportFinished()
	});

	const initialValuesMapper = new InitialValuesMapper(libertyMutual);
	const initialValues = initialValuesMapper.mapInitialValues();

	const decimalSeparatorMapper = {
		metric: ',',
		imperial: '.'
	};

	function isReportFinished(): boolean | undefined {
		return (
			hasLiftLowerCarryResult(updatedLibertyMutual, libertyMutual) ||
			hasPushPullResult(updatedLibertyMutual, libertyMutual)
		);
	}

	function hasLiftLowerCarryResult(
		updatedLibertyMutual: LibertyMutualReportDTO | undefined,
		libertyMutual: LibertyMutualReportDTO | undefined
	) {
		return !!updatedLibertyMutual?.percentile_woman || !!libertyMutual?.percentile_woman;
	}

	function hasPushPullResult(
		updatedLibertyMutual: LibertyMutualReportDTO | undefined,
		libertyMutual: LibertyMutualReportDTO | undefined
	) {
		return !!updatedLibertyMutual?.percentile_woman_initial || !!libertyMutual?.percentile_woman_initial;
	}

	function handleUpdateReport(report: LibertyMutualReportDTO) {
		setUpdatedLibertyMutual(report);
	}

	const loading = fetchingReport || fetchingResult;

	if (errorGettingResult) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (loading) {
		return <LoadingSkeleton loading={loading} />;
	}

	const states: States = {
		steps,
		file_id,
		errorGettingReport,
		libertyMutualResult,
		gettingReport: loading,
		libertyMutual: updatedLibertyMutual ?? libertyMutual,
		decimalSeparator: decimalSeparatorMapper[libertyMutual?.system_of_units?.name]
	};

	const methods: Methods = {
		handleUpdateReport
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<LibertyMutualContext.Provider value={context}>
			<Form form={form} initialValues={initialValues}>
				{children}
			</Form>
		</LibertyMutualContext.Provider>
	);
}

export function useLibertyMutualContext() {
	const context = useContext(LibertyMutualContext);
	return context;
}
