import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Button, Col, Form, Row } from 'antd';

import { BackButton } from '../../components/BackButton';
import { usePersonalisedReportContext } from '../../../context';
import { useApplicationContext } from '@/context/Application';
import { CreateOrUpdateGenericStepKeyResult } from '../../../context/types';
import { CreateOrUpdateService, StepFormFields } from '../../../context/services';
import { useCalculateScores, useCreateGenericStepKeyResult, useUpdateGenericStepKeyResult } from './hooks';

const { useFormInstance } = Form;

interface FooterProps {
	step_id: string;
}

export const Footer: React.FC<FooterProps> = ({ step_id }) => {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();
	const { personalisedReport, step_setting_id, report_id, handlePreviousStep, handleNextStep, scrollToError } =
		usePersonalisedReportContext();

	const { mutateAsync: calculateScores, isLoading: calculatingScores } = useCalculateScores();
	const { mutateAsync: createResult, isLoading: creatingReport } = useCreateGenericStepKeyResult();
	const { mutateAsync: updateResult, isLoading: updatingReport } = useUpdateGenericStepKeyResult();

	const stepKeys = personalisedReport.steps.find(({ id }) => id === step_id)?.step_keys;

	function handlePreviousClick(): void {
		handlePreviousStep();
	}

	async function handleNextClick(): Promise<void> {
		try {
			const validated = await form.validateFields();
			if (validated) {
				const values: StepFormFields = await form.getFieldValue([`${step_id}`]);
				const createOrUpdateService = new CreateOrUpdateService();
				const payload = createOrUpdateService.mapPayload(values, {
					stepKeys,
					report_id,
					step_setting_id,
					company_id: company?.id,
					organization_id: organization?.id,
					result_id: personalisedReport.result_id
				});
				if (createOrUpdateService.isGenericStepsPayload(payload)) {
					if (hasAnyStepKeyResult()) {
						return await update(payload);
					}
					const report = await createResult(payload);
					if (report.length > 0) {
						await calculateScores({
							company_id: company?.id,
							custom_report_id: report_id,
							organization_id: organization?.id,
							custom_report_result_id: personalisedReport.result_id
						});
						handleNextStep();
					}
				}
			}
		} catch (error: any) {
			scrollToError(error);
		}
	}

	function hasAnyStepKeyResult(): boolean | undefined {
		const stepKeyResults = stepKeys ? stepKeys.filter(({ step_key_result_id }) => step_key_result_id) : [];
		return stepKeys && stepKeyResults.length > 0;
	}

	async function update(payload: CreateOrUpdateGenericStepKeyResult): Promise<void> {
		const report = await updateResult(payload);
		if (report.length > 0) {
			await calculateScores({
				company_id: company?.id,
				custom_report_id: report_id,
				organization_id: organization?.id,
				custom_report_result_id: personalisedReport.result_id
			});
			handleNextStep();
		}
	}

	return (
		<Row>
			<Col xs={24} style={{ marginTop: '2rem' }}>
				<Row justify="center">
					<BackButton
						onPreviousClick={handlePreviousClick}
						disabled={creatingReport || calculatingScores || updatingReport}
					/>
					<Col>
						<Button
							type="primary"
							htmlType="submit"
							onClick={handleNextClick}
							loading={creatingReport || calculatingScores || updatingReport}
							disabled={creatingReport || calculatingScores || updatingReport}
						>
							{I18n.get('Next')}
						</Button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
