import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQueryParams } from '@/hooks/useQueryParams';
import type { Context, State } from './types';
import type { GetUsersFilter } from '@/types/dto/user.dto';
interface UserManagementProviderProps {
	children: JSX.Element;
}

const initialState: State = {
	filter: {},
	page: 1,
	limit: 10
};

export const UserManagementContext = createContext<Context>({} as Context);

function deleteQueryParams(key: string) {
	const url = new URL(window.location.href);
	url.searchParams.delete(key);
	window.history.replaceState({}, '', url.toString());
}

export const UserManagementProvider = ({ children }: UserManagementProviderProps) => {
	const isNameParam = useQueryParams('name');

	const [state, setState] = useState<State>(initialState);
	const [initialValueSettled, setInitialValueSettled] = useState<Boolean>(false);

	useEffect(() => {
		if (isNameParam && !state?.filter?.name && !initialValueSettled) {
			setState((prev) => ({
				...prev,
				name_param: isNameParam,
				filter: { name: isNameParam },
				page: 1,
				limit: 10
			}));
			deleteQueryParams('name');
			setInitialValueSettled(true);
		}
	}, [isNameParam, state]);

	function handleUpdateFilter(values: GetUsersFilter): void {
		setState((prev) => ({ ...prev, filter: values }));
	}

	function handleResetFilter(): void {
		setState((prev) => ({ ...prev, filter: {}, page: 1, limit: 10 }));
	}

	function handleSetPage(page: number): void {
		setState((prev) => ({ ...prev, page }));
	}

	function handleSetPageLimit(limit: number): void {
		setState((prev) => ({ ...prev, limit }));
	}

	const context: Context = {
		...state,
		handleUpdateFilter,
		handleResetFilter,
		handleSetPage,
		handleSetPageLimit
	};

	return <UserManagementContext.Provider value={context}>{children}</UserManagementContext.Provider>;
};

export function useUserManagementContext() {
	const context = useContext(UserManagementContext);
	return context;
}
