import React from 'react';

const defaultState = {
	organizationId: '',
	organizationName: '',
	companyId: '',
	riskRangeId: '',
	file: '',
	form: null,
	checkAllParts: false,
	parts: [],
	savedParts: [],
};

const defaultContext = {
	...defaultState,
	selectRangeRisk: () => {},
	setCheckAllParts: () => {},
	setParts: () => {}
};

export const AngleTimeContext = React.createContext(defaultContext);
