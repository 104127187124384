export enum QUERY_KEYS {
	GET_PLANS = 'get-plans',
	GET_USERS = 'get-users',
	GET_FILE = 'get-file',
	GET_PLAN = 'get-plan',
	GET_SECTORS = 'get-sectors',
	GET_COMPANIES = 'get-companies',
	GET_REBA_REPORT = 'get-reba-report',
	GET_COMPANY_USERS = 'get-company-users',
	GET_PERMISSIONS = 'get-all-permissions',
	GET_USER_PERMISSIONS = 'get-user-permissions',
	GET_PLANS_CONTRACT_PLANS = 'get-plans-contract-plans',

	GET_ORGANIZATIONS_CONTRACT_PLANS = 'get-organizations-contract-plans',
	GET_ORGANIZATION_USERS_CONTRACT_PLANS = 'get-organization-users-contract-plans',

	GET_COMPANIES_CONTRACT_PLANS = 'get-companies-contract-plans',
	GET_COMPANY_CONTRACT_PLANS = 'get-company-contract-plans',
	GET_COMPANIES_WITH_ORGANIZATION = 'get-company-with-organization'
}
