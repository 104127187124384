import { InitialValues, InitialValuesMapperInterface } from './servicesInterfaces';
import { LibertyMutualReportDTO } from '../types/response';
import moment from 'moment';

export type MappedFields = {
	[key: string]: string | number;
};

export class InitialValuesMapper implements InitialValuesMapperInterface {
	constructor(private libertyMutual: LibertyMutualReportDTO | undefined) {}

	public mapInitialValues(): InitialValues | undefined {
		if (!this.libertyMutual) {
			return undefined;
		}

		const {
			task,
			comment,
			sector_id,
			company_id,
			workstation_id,
			system_of_units,
			liberty_mutual_inputs,
			collection_date
		} = this.libertyMutual;

		return {
			comment,
			sector_id,
			company_id,
			workstation_id,
			task_id: task?.id,
			task_name: task?.name,
			liberty_mutual_inputs,
			collection_date: moment(collection_date) ?? moment(),
			system_of_units_id: system_of_units?.id
		};
	}
}
