import React from 'react';
import { Col, Divider, Row } from 'antd';

import * as C from './Components';
import { Footer } from './Footer';
import { Comment } from './Comment';
import { useGetResult } from './hooks';
import { ResultsContainer } from './styles';
import { DownloadPDF } from './DownloadPDF';
import { Text, Title } from '@/components/Typography';
import { LoadingSkeleton } from './context/LoadingSkeleton';
import { PersonalisedReportResultProvider } from './context';
import { usePersonalisedReportContext } from '../../../context';
import { useApplicationContext } from '@/context/Application';

export const Results: React.FC = () => {
	const { company, organization } = useApplicationContext();
	const { file_id, report_id, personalisedReport } = usePersonalisedReportContext();

	const {
		isLoading: gettingResult,
		isFetching: fetchingResult,
		isError: errorGettingResult,
		data: personalisedReportResult
	} = useGetResult({
		file_id,
		report_id,
		company_id: company?.id,
		organization_id: organization?.id,
		custom_report_result_id: personalisedReport.result_id
	});

	if (errorGettingResult || isNotLoadingAndResultDoesNotExist()) {
		return <Text>Oops... Something went wrong!</Text>;
	}

	if (gettingResult || fetchingResult) {
		return <LoadingSkeleton />;
	}

	function isNotLoadingAndResultDoesNotExist(): boolean {
		return !gettingResult && !fetchingResult && !personalisedReportResult?.id;
	}

	return (
		<PersonalisedReportResultProvider personalisedReportResult={personalisedReportResult}>
			<Row justify="center" gutter={[0, 10]}>
				<Col span={24} style={{ marginLeft: '1rem' }}>
					<Title level={4}>Result</Title>
				</Col>
				<Col span={24}>
					<ResultsContainer>
						<C.Header />
						<Divider type="horizontal" />
						<C.SummaryInformation />
						<Divider type="horizontal" />
						<C.WorkConditions />
						<C.Characters />
						{personalisedReportResult?.steps?.map((step) => (
							<Row key={step.id}>
								<C.StepsDescription stepName={step.description} stepKeys={step.step_keys} />
								<Divider type="horizontal" />
							</Row>
						))}
						<C.Niosh />
						<C.KimManualHandling />
						<C.Reba />
						<C.KimPushPull />
						<C.StrainIndex />
						<C.LibertyMutual />
						<C.ActionPlans />
						<Comment />
						<DownloadPDF />
					</ResultsContainer>
				</Col>
				<Footer />
			</Row>
		</PersonalisedReportResultProvider>
	);
};
