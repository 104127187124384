import React, { useCallback, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputRef, Modal, Row, Select } from 'antd';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';

import {
	useGetUsers,
	useCreateActionPlan,
	useUpdateActionPlan,
	UpdateActionPlanDTO,
	useDeleteActionPlan,
	UpdateActionPlanTaskDTO
} from '../../hooks';
import { Tasks } from './Tasks';
import { BoardType, TaskType } from '@/types';
import { Title } from '@/components/Typography';
import { CustomFormItem, EditButton } from './styles';
import { usePersonalisedReportSubStepKeyContext } from '../../context';
import { useApplicationContext } from '@/context/Application';
import { AntdDropdownOption } from '@/components/views/PersonalisedReports/PersonalisedReport/context/types';
import { usePersonalisedReportContext } from '@/components/views/PersonalisedReports/PersonalisedReport/context';

const { useFormInstance, useWatch } = Form;

export type TaskListProps = {
	id: string;
	type: TaskType;
	taskId?: string;
	description: string;
	isCompleted: boolean;
};

interface ActionPlanModalProps {
	visible: boolean;
	onClose: () => void;
}

export const ActionPlanModal: React.FC<ActionPlanModalProps> = ({ onClose, visible }) => {
	const form = useFormInstance();
	const { file_id } = usePersonalisedReportContext();
	const { company, organization } = useApplicationContext();
	const { actionPlanData, stepKeySettingId, stepKeyFieldName, subStepKeyId } =
		usePersonalisedReportSubStepKeyContext();

	const { actionPlan } = actionPlanData;
	const title = actionPlan?.title;

	const [editingTitle, setEditingTitle] = useState<boolean>(!title);
	const [tasks, setTasks] = useState<UpdateActionPlanTaskDTO[]>([]);
	const [actionPlanTitle, setActionPlanTitle] = useState<string | undefined>(title);

	const { data: responsibleUsers, isFetching: fetchingUsers } = useGetUsers({
		organization_id: organization.id,
		company_id: company.id
	});

	const { mutateAsync: createActionPlan, isLoading: creatingActionPlan } = useCreateActionPlan();
	const { mutateAsync: updateActionPlan, isLoading: updatingActionPlan } = useUpdateActionPlan();
	const { mutateAsync: deleteActionPlan, isLoading: deletingActionPlan } = useDeleteActionPlan();

	const deadline = useWatch([...stepKeyFieldName, 'action_plans', 'deadline'], form) ?? actionPlan?.deadline;
	const description = useWatch([...stepKeyFieldName, 'action_plans', 'description'], form) ?? actionPlan?.description;

	const editInputRef = useCallback((node: InputRef): void => {
		if (node) {
			node.focus();
		}
	}, []);

	function onEditTitle(): void {
		setEditingTitle(true);
	}

	async function onFinishEditing(): Promise<void> {
		const title = form.getFieldValue([...stepKeyFieldName, 'action_plans', 'title']);
		if (title) {
			setActionPlanTitle(title);
			setEditingTitle(false);
		}
	}

	async function handleDeleteActionPlan(): Promise<void> {
		Modal.confirm({
			title: I18n.get('Warning!'),
			okType: 'danger',
			okText: I18n.get('Yes'),
			cancelText: I18n.get('Cancel'),
			icon: <ExclamationCircleOutlined />,
			content: I18n.get('Do you want to delete this action plan?'),
			async onOk() {
				await deleteActionPlan({
					id: actionPlan?.id,
					company_id: company?.id,
					organization_id: organization?.id
				});
				form.setFieldValue([...stepKeyFieldName, 'action_plans', 'title'], undefined);
				form.setFieldValue([...stepKeyFieldName, 'action_plans', 'responsible_user_id'], undefined);
				form.setFieldValue([...stepKeyFieldName, 'action_plans', 'deadline'], moment());
				form.setFieldValue([...stepKeyFieldName, 'action_plans', 'description'], undefined);
				setActionPlanTitle(undefined);
				onClose();
			}
		});
	}

	function handleTasksChange(tasks: TaskListProps[]): void {
		const body: UpdateActionPlanTaskDTO[] = tasks.map((task) => {
			const mappedTask: UpdateActionPlanTaskDTO = {
				type: task.type,
				is_completed: task.isCompleted,
				description: task.description
			};

			if (task.taskId) {
				mappedTask.id = task.taskId;
			}

			return mappedTask;
		});

		setTasks(body);
		form.resetFields(['task_title', 'condition']);
	}

	function mapResponsibleUsers(): AntdDropdownOption[] {
		return responsibleUsers.map(({ id, name }) => ({
			label: name,
			value: id
		}));
	}

	async function handleSaveClick(): Promise<void> {
		const data = form.getFieldValue([...stepKeyFieldName, 'action_plans']);
		if (!actionPlanTitle || !data?.deadline || !data?.responsible_user_id) {
			return;
		}
		const body: UpdateActionPlanDTO = {
			tasks,
			file_id,
			id: actionPlan?.id,
			board: BoardType.TO_DO,
			title: actionPlanTitle,
			company_id: company?.id,
			deadline: data?.deadline,
			description: data?.description,
			organization_id: organization?.id,
			custom_report_sub_step_key_id: subStepKeyId,
			responsible_user_id: data?.responsible_user_id,
			custom_report_step_key_setting_id: stepKeySettingId
		};
		let createdActionPlan;
		if (actionPlan?.id) {
			createdActionPlan = await updateActionPlan(body);
		} else {
			createdActionPlan = await createActionPlan(body);
		}
		if (createdActionPlan?.id) {
			onClose();
		}
	}

	return (
		<Modal open={visible} onCancel={onClose} width={750} footer={false} centered>
			<Row justify="center">
				<Col xs={24}>
					<Title level={5}>Create action plan</Title>
				</Col>
				<Col xs={22}>
					<Row justify="center" gutter={[10, 0]} style={{ marginTop: '1rem' }}>
						<Col xs={24}>
							<Title style={{ marginBottom: '1rem' }} level={5}>
								{editingTitle ? (
									<CustomFormItem
										labelAlign="left"
										labelCol={{ xs: 24 }}
										label={I18n.get('Title')}
										name={[...stepKeyFieldName, 'action_plans', 'title']}
										rules={[
											{
												required: true,
												message: I18n.get('Enter action plan title')
											}
										]}
									>
										<Input
											ref={editInputRef}
											onBlur={onFinishEditing}
											style={{ fontSize: '1.4rem' }}
											onPressEnter={onFinishEditing}
											placeholder={I18n.get('Title')}
										/>
									</CustomFormItem>
								) : (
									<Row align="top">
										<Col xs="auto">
											<Title style={{ margin: '0' }} level={5}>
												{I18n.get(actionPlanTitle)}
											</Title>
										</Col>
										<Col xs={2}>
											<EditButton onClick={onEditTitle}>
												<EditOutlined />
											</EditButton>
										</Col>
									</Row>
								)}
							</Title>
						</Col>
						<Col xs={12}>
							<CustomFormItem
								style={{ paddingTop: '10px' }}
								label={I18n.get('Person responsible')}
								name={[...stepKeyFieldName, 'action_plans', 'responsible_user_id']}
								rules={[
									{
										required: true,
										message: I18n.get('Please enter a responsible')
									}
								]}
							>
								<Select
									allowClear
									style={{
										width: '100%'
									}}
									options={mapResponsibleUsers()}
									loading={fetchingUsers}
									placeholder={I18n.get('Person responsible')}
									value={
										responsibleUsers.find((user) => user.id === actionPlan?.responsible_user_id)?.id
									}
								/>
							</CustomFormItem>
						</Col>
						<Col xs={12}>
							<CustomFormItem
								rules={[
									{
										required: true,
										message: I18n.get('Please enter a valid date')
									}
								]}
								name={[...stepKeyFieldName, 'action_plans', 'deadline']}
								style={{ paddingTop: '10px' }}
								label={I18n.get('Deadline')}
							>
								<DatePicker
									format="L"
									value={moment(deadline)}
									style={{ width: '100%' }}
									placeholder={I18n.get('Deadline')}
								/>
							</CustomFormItem>
						</Col>
						<Col xs={24}>
							<CustomFormItem
								name={[...stepKeyFieldName, 'action_plans', 'description']}
								style={{ margin: '0' }}
								label={I18n.get(
									'Describe the situation found and the preventive measures already implemented (if exist)'
								)}
							>
								<Input.TextArea
									rows={2}
									showCount
									maxLength={500}
									value={description}
									placeholder={I18n.get('Enter a description for your action plan.')}
									autoSize={{
										minRows: 3,
										maxRows: 5
									}}
								/>
							</CustomFormItem>
						</Col>
					</Row>
				</Col>
				<Col xs={22}>
					<Row justify="center" gutter={[10, 0]} style={{ marginTop: '1rem' }}>
						<Col xs={24}>
							<Title level={5}>{I18n.get('Tasks')}</Title>
						</Col>
						<Tasks fetchedTasks={actionPlan?.action_plan_task} onTasksChange={handleTasksChange} />
					</Row>
				</Col>
				<Col xs={22}>
					<Row justify="center">
						{actionPlan?.id ? (
							<Button
								danger
								type="primary"
								style={{ marginTop: '1rem' }}
								onClick={handleDeleteActionPlan}
								loading={creatingActionPlan || updatingActionPlan || deletingActionPlan}
								disabled={creatingActionPlan || updatingActionPlan || deletingActionPlan}
							>
								{I18n.get('Delete')}
							</Button>
						) : (
							<Button
								onClick={onClose}
								style={{ marginTop: '1rem' }}
								loading={creatingActionPlan || updatingActionPlan || deletingActionPlan}
								disabled={creatingActionPlan || updatingActionPlan || deletingActionPlan}
							>
								{I18n.get('Cancel')}
							</Button>
						)}
						<Button
							type="primary"
							htmlType="submit"
							onClick={handleSaveClick}
							style={{ margin: '1rem 0 0 0.5rem' }}
							loading={creatingActionPlan || updatingActionPlan || deletingActionPlan}
							disabled={creatingActionPlan || updatingActionPlan || deletingActionPlan}
						>
							{I18n.get('Save')}
						</Button>
					</Row>
				</Col>
			</Row>
		</Modal>
	);
};
