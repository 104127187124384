import { Col } from 'antd';
import styled from 'styled-components';

export const InfiniteColumn = styled(Col)`
	user-select: none;
	position: relative;
	overflow-y: scroll;
	height: 200px;
	border-radius: 15px;
	border: 2px solid #d9d9d9;
	::-webkit-scrollbar {
		width: 4px;
		display: none;
	}

	::-webkit-scrollbar-track {
		border-radius: 5px;
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		background: #4156f2;
		border-radius: 2px;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`;
