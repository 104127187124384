import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { QUERY_KEYS } from '@/utils/query-keys';
import Api from '@/services/api';

export type UserOrganizationResponse = {
	organization_permission: {
		[key: string]: string[];
	};
};

export type UserPermissionsListDTO = {
	user_id?: string;
};

async function getOnlyUserPermissions(params: UserPermissionsListDTO): Promise<UserOrganizationResponse> {
	const url = '/user_management/user_permissions';
	const { data } = await Api.get<UserOrganizationResponse>(url, { params: params });
	return data;
}

export const useGetOnlyUserPermissions = (params: UserPermissionsListDTO) => {
	const userList = useQuery<any, AxiosError<Response>>(
		[QUERY_KEYS.GET_USER_PERMISSIONS, params.user_id],
		() => getOnlyUserPermissions(params),
		{
			enabled: !!params.user_id
		}
	);

	return userList;
};
