import React, { useEffect, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ToolCard } from '@/components/ToolCard';
import { SelectOption } from '../_components/SelectOption';
import { InjuriesGroup } from '../_components/InjuriesGroup';
import { CollapseCheck } from '../_components/CollapseCheck';
import { RiskLevelCard } from '../_components/RiskLevelCard';
import { Title, Paragraph, Text } from '@/components/Typography';
import { baseFormName, injuriesKeys, stepDescription } from '../contants';

import Hooks from '@/components/views/PreliminaryAnalysis/hooks';
import { useApplicationContext } from '@/context/Application';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';
import { useSocketReport } from '@/hooks/useSocket';

const { useRiskLevel } = Hooks;
const { useFormInstance } = Form;

export const KindOfWork = () => {
	const { organization, company } = useApplicationContext();
	const { file, getLevelRiskFields } = usePreliminaryAnalysis();

	const form = useFormInstance();
	const formName = ['kind_of_work'];

	const { isLoading, data, isError, ...riskLevel } = useRiskLevel(organization?.id, company?.id, file?.id);

	const [levelRisk, setLevelRisk] = useState({
		step: '',
		consequence_id: '',
		probability_id: '',
		exhibition_id: ''
	});

	const preliminaryFormValues = form.getFieldsValue(true).preliminary_analysis;
	const { niosh, kimPushPull, kimMHO, strainIndex } = useSocketReport();

	const [isNiosh, setIsNiosh] = useState(preliminaryFormValues.niosh);
	const [isKimMHO, setIsKimMHO] = useState(preliminaryFormValues.kim_mho);
	const [isKimPP, setIsKimPP] = useState(preliminaryFormValues.kim_push_pull);
	const [isStrainIndex, setIsStrainIndex] = useState(preliminaryFormValues.strain_index);

	useEffect(() => {
		niosh.status && setIsNiosh(true);
		kimPushPull.status && setIsKimPP(true);
		kimMHO.status && setIsKimMHO(true);
		strainIndex.status && setIsStrainIndex(true);
	}, [niosh, kimPushPull, kimMHO, strainIndex]);

	useEffect(() => {
		const { step, consequence_id, probability_id, exhibition_id } = levelRisk;

		if (step && consequence_id && probability_id && exhibition_id) {
			const formatStep = Array.isArray(step) ? [...formName, ...step] : [...formName, step];

			const payload = {
				step: formatStep,
				consequence_id,
				probability_id,
				exhibition_id
			};

			riskLevel.mutate(payload, {
				onSuccess: (data) => {
					form.setFields([
						{
							name: [baseFormName, ...formatStep, 'result'],
							value: data?.risk_level
						}
					]);
				}
			});
		}
	}, [levelRisk.step, levelRisk.consequence_id, levelRisk.probability_id, levelRisk.exhibition_id]);

	function updateLevelRisk(step, attribute, value) {
		const stepName = Array.isArray(step) ? [...step] : [step];
		const { consequence, exhibition, probability } = getLevelRiskFields(form, baseFormName, formName, stepName);
		if (!levelRisk.step && !levelRisk.consequence_id && !levelRisk.exhibition_id && !levelRisk.probability_id) {
			const options = ['consequence_id', 'exhibition_id', 'probability_id'];
			const forms = options
				.filter((item) => item !== attribute)
				.map((value) => ({
					name: [baseFormName, ...formName, step, value],
					value: undefined
				}));

			form.setFields(forms);
		}

		setLevelRisk({
			exhibition_id: exhibition,
			consequence_id: consequence,
			probability_id: probability,
			step,
			[attribute]: value
		});
	}

	const {
		fatigue_complaints,
		absenteeism,
		musculoskeletal_complaints,
		complaints_of_mental_overload,
		complaints_of_irritability,
		non_conforming_products,
		others
	} = injuriesKeys;

	function onResetFields(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'consequence_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'probability_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'exhibition_id'],
				value: undefined
			},

			{
				name: [baseFormName, ...formName, step, 'injuries'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'result'],
				value: undefined
			}
		]);
	}

	function setCheckedCollapse(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'checked'],
				value: true
			}
		]);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={16} offset={2}>
				<Title level={4}>Kind of work</Title>
			</Col>
			<Col span={16} offset={2}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col span={16} offset={2}>
				<Row gutter={[10, 10]}>
					{/* lift_carry_heavy_objects */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'lift_carry_heavy_objects', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('lift_carry_heavy_objects');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Lift and/or carry heavy objects">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Text strong>Possible injuries</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'lift_carry_heavy_objects',
														'injuries'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'lift_carry_heavy_objects',
																	'checked'
																]);

																if ((checkBox && !value) || value?.length === 0) {
																	return Promise.reject(
																		I18n.get('Select the injuries')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<InjuriesGroup
														onChange={() => setCheckedCollapse('lift_carry_heavy_objects')}
														options={[
															fatigue_complaints,
															absenteeism,
															musculoskeletal_complaints,
															complaints_of_mental_overload,
															complaints_of_irritability,
															non_conforming_products,
															others
														]}
													/>
												</Form.Item>
											</Col>
											<ToolCard
												isActive={isNiosh ? 1 : 0}
												reportSelected={'niosh'}
												title={'(NIOSH, ISO11228)'}
												fileId={file?.id}
											/>
										</Row>
									</Col>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Row gutter={[0, 20]}>
													<Col span={24}>
														<Text strong>Consequence</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'lift_carry_heavy_objects',
																'consequence_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'lift_carry_heavy_objects',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the consequence')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ConsequenceSelect
																onChange={(value) => {
																	setCheckedCollapse('lift_carry_heavy_objects');
																	updateLevelRisk(
																		'lift_carry_heavy_objects',
																		'consequence_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Probability</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'lift_carry_heavy_objects',
																'probability_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'lift_carry_heavy_objects',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the probability')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ProbabilitySelect
																onChange={(value) => {
																	setCheckedCollapse('lift_carry_heavy_objects');
																	updateLevelRisk(
																		'lift_carry_heavy_objects',
																		'probability_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Exposure</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'lift_carry_heavy_objects',
																'exhibition_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'lift_carry_heavy_objects',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the exhibition')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ExhibitionSelect
																onChange={(value) => {
																	setCheckedCollapse('lift_carry_heavy_objects');
																	updateLevelRisk(
																		'lift_carry_heavy_objects',
																		'exhibition_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>
											<Col span={24} style={{ paddingTop: '30px' }}>
												<Form.Item
													name={[
														baseFormName,
														...formName,
														'lift_carry_heavy_objects',
														'result'
													]}
												>
													<RiskLevelCard
														key={[...formName, 'lift_carry_heavy_objects']}
														step={[...formName, 'lift_carry_heavy_objects']}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* lift_carry_distant_objects */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'lift_carry_distant_objects', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('lift_carry_distant_objects');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Lifts and/or carries objects farther than 60 cm">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'lift_carry_distant_objects', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'lift_carry_distant_objects',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('lift_carry_distant_objects')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'lift_carry_distant_objects',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'lift_carry_distant_objects',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('lift_carry_distant_objects');
															updateLevelRisk(
																'lift_carry_distant_objects',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'lift_carry_distant_objects',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'lift_carry_distant_objects',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('lift_carry_distant_objects');
															updateLevelRisk(
																'lift_carry_distant_objects',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'lift_carry_distant_objects',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'lift_carry_distant_objects',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('lift_carry_distant_objects');
															updateLevelRisk(
																'lift_carry_distant_objects',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'lift_carry_distant_objects', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'lift_carry_distant_objects']}
												step={[...formName, 'lift_carry_distant_objects']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* performs_tasks_require_strength */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'performs_tasks_require_strength', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('performs_tasks_require_strength');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs tasks that require great strength">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'performs_tasks_require_strength',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'performs_tasks_require_strength',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('performs_tasks_require_strength')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_tasks_require_strength',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_tasks_require_strength',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('performs_tasks_require_strength');
															updateLevelRisk(
																'performs_tasks_require_strength',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_tasks_require_strength',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_tasks_require_strength',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('performs_tasks_require_strength');
															updateLevelRisk(
																'performs_tasks_require_strength',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_tasks_require_strength',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_tasks_require_strength',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('performs_tasks_require_strength');
															updateLevelRisk(
																'performs_tasks_require_strength',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'performs_tasks_require_strength',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'performs_tasks_require_strength']}
												step={[...formName, 'performs_tasks_require_strength']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* performs_tasks_require_push_pull */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'performs_tasks_require_push_pull', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('performs_tasks_require_push_pull');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs tasks that require pronounced pushing and pulling forces">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Text strong>Possible injuries</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_tasks_require_push_pull',
														'injuries'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_tasks_require_push_pull',
																	'checked'
																]);

																if ((checkBox && !value) || value?.length === 0) {
																	return Promise.reject(
																		I18n.get('Select the injuries')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<InjuriesGroup
														onChange={() =>
															setCheckedCollapse('performs_tasks_require_push_pull')
														}
														options={[
															fatigue_complaints,
															absenteeism,
															musculoskeletal_complaints,
															complaints_of_mental_overload,
															complaints_of_irritability,
															non_conforming_products,
															others
														]}
													/>
												</Form.Item>
											</Col>

											<ToolCard
												isActive={isKimPP ? 1 : 0}
												reportSelected={'kim-pp'}
												title={'KIM - Push and pull'}
												fileId={file?.id}
											/>
										</Row>
									</Col>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Row gutter={[0, 20]}>
													<Col span={24}>
														<Text strong>Consequence</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_tasks_require_push_pull',
																'consequence_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_tasks_require_push_pull',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the consequence')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ConsequenceSelect
																onChange={(value) => {
																	setCheckedCollapse(
																		'performs_tasks_require_push_pull'
																	);
																	updateLevelRisk(
																		'performs_tasks_require_push_pull',
																		'consequence_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Probability</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_tasks_require_push_pull',
																'probability_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_tasks_require_push_pull',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the probability')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ProbabilitySelect
																onChange={(value) => {
																	setCheckedCollapse(
																		'performs_tasks_require_push_pull'
																	);
																	updateLevelRisk(
																		'performs_tasks_require_push_pull',
																		'probability_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Exposure</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_tasks_require_push_pull',
																'exhibition_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_tasks_require_push_pull',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the exhibition')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ExhibitionSelect
																onChange={(value) => {
																	setCheckedCollapse(
																		'performs_tasks_require_push_pull'
																	);
																	updateLevelRisk(
																		'performs_tasks_require_push_pull',
																		'exhibition_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>
											<Col span={24} style={{ paddingTop: '30px' }}>
												<Form.Item
													name={[
														baseFormName,
														...formName,
														'performs_tasks_require_push_pull',
														'result'
													]}
												>
													<RiskLevelCard
														key={[...formName, 'performs_tasks_require_push_pull']}
														step={[...formName, 'performs_tasks_require_push_pull']}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* performs_repetitive_work */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'performs_repetitive_work', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('performs_repetitive_work');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs repetitive work">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Text strong>Possible injuries</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_repetitive_work',
														'injuries'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_repetitive_work',
																	'checked'
																]);

																if ((checkBox && !value) || value?.length === 0) {
																	return Promise.reject(
																		I18n.get('Select the injuries')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<InjuriesGroup
														onChange={() => setCheckedCollapse('performs_repetitive_work')}
														options={[
															fatigue_complaints,
															absenteeism,
															musculoskeletal_complaints,
															complaints_of_mental_overload,
															complaints_of_irritability,
															non_conforming_products,
															others
														]}
													/>
												</Form.Item>
											</Col>
											<ToolCard
												isActive={isStrainIndex ? 1 : 0}
												reportSelected={'strain-index'}
												title={'Revised Strain Index'}
												fileId={file?.id}
											/>
										</Row>
									</Col>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Row gutter={[0, 20]}>
													<Col span={24}>
														<Text strong>Consequence</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_repetitive_work',
																'consequence_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_repetitive_work',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the consequence')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ConsequenceSelect
																onChange={(value) => {
																	setCheckedCollapse('performs_repetitive_work');
																	updateLevelRisk(
																		'performs_repetitive_work',
																		'consequence_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Probability</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_repetitive_work',
																'probability_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_repetitive_work',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the probability')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ProbabilitySelect
																onChange={(value) => {
																	setCheckedCollapse('performs_repetitive_work');
																	updateLevelRisk(
																		'performs_repetitive_work',
																		'probability_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Exposure</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_repetitive_work',
																'exhibition_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_repetitive_work',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the exhibition')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ExhibitionSelect
																onChange={(value) => {
																	setCheckedCollapse('performs_repetitive_work');
																	updateLevelRisk(
																		'performs_repetitive_work',
																		'exhibition_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>
											<Col span={24} style={{ paddingTop: '30px' }}>
												<Form.Item
													name={[
														baseFormName,
														...formName,
														'performs_repetitive_work',
														'result'
													]}
												>
													<RiskLevelCard
														key={[...formName, 'performs_repetitive_work']}
														step={[...formName, 'performs_repetitive_work']}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* performs_work_frequent_finger */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'performs_work_frequent_finger', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('performs_work_frequent_finger');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs work that requires frequent finger, hand, or arm movements">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Text strong>Possible injuries</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_work_frequent_finger',
														'injuries'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_work_frequent_finger',
																	'checked'
																]);

																if ((checkBox && !value) || value?.length === 0) {
																	return Promise.reject(
																		I18n.get('Select the injuries')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<InjuriesGroup
														onChange={() =>
															setCheckedCollapse('performs_work_frequent_finger')
														}
														options={[
															fatigue_complaints,
															absenteeism,
															musculoskeletal_complaints,
															complaints_of_mental_overload,
															complaints_of_irritability,
															non_conforming_products,
															others
														]}
													/>
												</Form.Item>
											</Col>
											<ToolCard
												isActive={isKimMHO ? 1 : 0}
												reportSelected={'kim-mho'}
												title={'KIM - MHO'}
												fileId={file?.id}
											/>
										</Row>
									</Col>
									<Col span={12}>
										<Row>
											<Col span={24}>
												<Row gutter={[0, 20]}>
													<Col span={24}>
														<Text strong>Consequence</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_work_frequent_finger',
																'consequence_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_work_frequent_finger',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the consequence')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ConsequenceSelect
																onChange={(value) => {
																	setCheckedCollapse('performs_work_frequent_finger');
																	updateLevelRisk(
																		'performs_work_frequent_finger',
																		'consequence_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Probability</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_work_frequent_finger',
																'probability_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_work_frequent_finger',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the probability')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ProbabilitySelect
																onChange={(value) => {
																	setCheckedCollapse('performs_work_frequent_finger');
																	updateLevelRisk(
																		'performs_work_frequent_finger',
																		'probability_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
													<Col span={24}>
														<Text strong>Exposure</Text>
														<Form.Item
															style={{ paddingTop: '10px' }}
															name={[
																baseFormName,
																...formName,
																'performs_work_frequent_finger',
																'exhibition_id'
															]}
															rules={[
																({ getFieldValue }) => ({
																	validator(_, value) {
																		const checkBox = getFieldValue([
																			baseFormName,
																			...formName,
																			'performs_work_frequent_finger',
																			'checked'
																		]);

																		if (checkBox && !value) {
																			return Promise.reject(
																				I18n.get('Select the exhibition')
																			);
																		}

																		return Promise.resolve();
																	}
																})
															]}
														>
															<SelectOption.ExhibitionSelect
																onChange={(value) => {
																	setCheckedCollapse('performs_work_frequent_finger');
																	updateLevelRisk(
																		'performs_work_frequent_finger',
																		'exhibition_id',
																		value
																	);
																}}
															/>
														</Form.Item>
													</Col>
												</Row>
											</Col>
											<Col span={24} style={{ paddingTop: '30px' }}>
												<Form.Item
													name={[
														baseFormName,
														...formName,
														'performs_work_frequent_finger',
														'result'
													]}
												>
													<RiskLevelCard
														key={[...formName, 'performs_work_frequent_finger']}
														step={[...formName, 'performs_work_frequent_finger']}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* performs_work_frequent_keyboard */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'performs_work_frequent_keyboard', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('performs_work_frequent_keyboard');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Do intensive work with a keyboard or other input devices">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'performs_work_frequent_keyboard',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'performs_work_frequent_keyboard',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('performs_work_frequent_keyboard')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_work_frequent_keyboard',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_work_frequent_keyboard',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('performs_work_frequent_keyboard');
															updateLevelRisk(
																'performs_work_frequent_keyboard',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_work_frequent_keyboard',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_work_frequent_keyboard',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('performs_work_frequent_keyboard');
															updateLevelRisk(
																'performs_work_frequent_keyboard',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'performs_work_frequent_keyboard',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'performs_work_frequent_keyboard',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('performs_work_frequent_keyboard');
															updateLevelRisk(
																'performs_work_frequent_keyboard',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'performs_work_frequent_keyboard',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'performs_work_frequent_keyboard']}
												step={[...formName, 'performs_work_frequent_keyboard']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* perform_precision_work */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'perform_precision_work', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('perform_precision_work');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Perform precision work">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'perform_precision_work', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'perform_precision_work',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('perform_precision_work')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'perform_precision_work',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'perform_precision_work',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('perform_precision_work');
															updateLevelRisk(
																'perform_precision_work',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'perform_precision_work',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'perform_precision_work',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('perform_precision_work');
															updateLevelRisk(
																'perform_precision_work',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'perform_precision_work',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'perform_precision_work',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('perform_precision_work');
															updateLevelRisk(
																'perform_precision_work',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'perform_precision_work', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'perform_precision_work']}
												step={[...formName, 'perform_precision_work']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* perform_activities_high_visual */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'perform_activities_high_visual', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('perform_activities_high_visual');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Performs activities with high visual requirements">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'perform_activities_high_visual',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'perform_activities_high_visual',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('perform_activities_high_visual')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_mental_overload,
													complaints_of_irritability,
													non_conforming_products,
													others
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'perform_activities_high_visual',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'perform_activities_high_visual',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('perform_activities_high_visual');
															updateLevelRisk(
																'perform_activities_high_visual',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'perform_activities_high_visual',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'perform_activities_high_visual',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('perform_activities_high_visual');
															updateLevelRisk(
																'perform_activities_high_visual',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'perform_activities_high_visual',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'perform_activities_high_visual',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('perform_activities_high_visual');
															updateLevelRisk(
																'perform_activities_high_visual',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'perform_activities_high_visual',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'perform_activities_high_visual']}
												step={[...formName, 'perform_activities_high_visual']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
