import { Col } from 'antd';
import styled, { css } from 'styled-components';

type CustomProps = {
	selected?: boolean;
	hasBody?: boolean;
	open?: boolean;
};

const colorSelected = '#3856f8';

const borderSelected = css`
	box-shadow: lightgrey 2px 2px 10px;
	border: 1px solid ${colorSelected};
`;

export const Header = styled(Col)<CustomProps>`
	margin: 0px;
	font-size: 15px;
`;

export const Container = styled.div<CustomProps>`
	border-radius: 8px;
	user-select: none;
	border: 1px solid #ddd;
	background: #ffffff;
	cursor: ${(props) => (!props.selected || !props.hasBody ? 'pointer' : 'auto')};
	padding: 1.5rem 1.2rem;
	margin-bottom: 0;
	transition: all 0.2s;

	:hover {
		filter: ${(props) => (!props.selected || !props.hasBody ? 'brightness(0.9)' : 'brightness(1)')};
	}

	${(props) => props.selected && borderSelected}
`;

export const Body = styled.div<CustomProps>`
	border-top: none;
	opacity: ${(props) => (props.open ? '1' : '0')};
	max-height: ${(props) => (props.open ? '100%' : '0')};
	overflow: hidden;
	padding: ${(props) => (props.open ? '1rem' : '0 1rem')};
	transition: all 0.3s;
	cursor: auto;
`;
