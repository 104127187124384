import { useState, useCallback } from 'react';
import _ from 'lodash';

import { Step } from '../types';
import { useRebaReportContext } from '../context';

export const useStep = () => {
	const { initial_values } = useRebaReportContext();
	const [currentStep, setCurrentStep] = useState<number>(initial_values.step);

	const next = useCallback(() => {
		setCurrentStep(currentStep + 1);
	}, [currentStep]);

	const previous = useCallback(() => {
		setCurrentStep(currentStep - 1);
	}, [currentStep]);

	function gotToResults(stepsLength: number = 0): void {
		setCurrentStep(stepsLength - 1);
	}

	function setStepManyPersons(steps: any, personStep: Step): void {
		steps.splice(2, 0, personStep);
	}

	function setSelectedPersonValue(initialValues: any, persons: number): void {
		_.set(initialValues, 'selected_person', persons);
	}

	return { currentStep, next, previous, setStepManyPersons, setSelectedPersonValue, gotToResults, setCurrentStep };
};
