import React from 'react';
import { Average } from './Average';
import { Container } from './styles';

interface ExposureAverageProps {
	isLoading: boolean;
	data: number;
	isError: boolean;
}

export const ExposureAverage: React.FC<ExposureAverageProps> = (props) => (
	<Container span={24}>
		<Average {...props} />
	</Container>
);
