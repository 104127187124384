import React from 'react';
import { Col, Row } from 'antd';

import { PersonalisedReportStepKeyProvider } from '../../components/DefaultStepKey/context';
import { PersonalisedReportStepKeysResponse } from '../../../hooks';
import { CollapseCustom } from '../../components/CollapseCustom';
import { Paragraph, Title } from '@/components/Typography';
import { KinebotStepKeyTabs } from './KinebotStepKeyTabs';
import { DefaultStepKey } from '../../components';
import { Footer } from './Footer';

interface GenericStepProps {
	title: string;
	step_id: string;
	stepKeys: PersonalisedReportStepKeysResponse[];
}

export function GenericStep({ step_id, title, stepKeys }: Readonly<GenericStepProps>) {
	function renderStepKey(stepKey: PersonalisedReportStepKeysResponse, step_id: string): JSX.Element {
		let component = <DefaultStepKey />;

		if (stepKey.sub_step_keys && stepKey.sub_step_keys.length > 0) {
			component = (
				<KinebotStepKeyTabs
					key={stepKey.id}
					stepKey={stepKey}
					stepId={step_id}
					subStepKeys={stepKey.sub_step_keys}
				/>
			);
		}

		return (
			<PersonalisedReportStepKeyProvider
				key={stepKey.id}
				stepId={step_id}
				stepKey={stepKey}
				stepKeyFieldName={[`${step_id}`, `${stepKey.id}`]}
			>
				<CollapseCustom title={stepKey.description}>{component}</CollapseCustom>
			</PersonalisedReportStepKeyProvider>
		);
	}

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={23}>
				<Row align="middle">
					<Col xs={24}>
						<Title level={4}>{title}</Title>
					</Col>

					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Paragraph>Enter the data related to the selected video below</Paragraph>
					</Col>
				</Row>
				<Row align="middle" gutter={[0, 25]}>
					{stepKeys.map((stepKey) => {
						return renderStepKey(stepKey, step_id);
					})}
				</Row>
			</Col>
			<Footer step_id={step_id} />
		</Row>
	);
}
