import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { I18n } from '@aws-amplify/core';
import { message } from 'antd';

import {
	CreateReport,
	UpdateComment,
	GetTasksRequest,
	GetReportRequest,
	GetResultRequest,
	UpdateSelectedTask,
	UpdateReportInputs,
	GetSystemsOfUnitsRequest,
	UpdateReportBasicInformation,
	DownloadPDF
} from './types/request';
import {
	SystemOfUnitsDTO,
	LibertyMutualTaskDTO,
	LibertyMutualReportDTO,
	LibertyMutualResultDTO
} from './types/response';
import { LibertyMutualApiService } from './services/api';

const libertyMutualApiService = new LibertyMutualApiService();

export const useGetReport = ({ organization_id, company_id, file_id }: GetReportRequest) => {
	const parameters = { organization_id, company_id, file_id };
	const report = useQuery(
		['liberty_mutual_report', parameters],
		() => libertyMutualApiService.getReport(parameters),
		{
			enabled: !!organization_id && !!company_id && !!file_id,
			retry: 0
		}
	);
	return {
		...report,
		data: report.data ?? ({} as LibertyMutualReportDTO)
	};
};

export const useGetResult = ({ organization_id, company_id, file_id, finished }: GetResultRequest) => {
	const parameters = { organization_id, company_id, file_id };
	const report = useQuery(
		['liberty_mutual_result', parameters],
		() => libertyMutualApiService.getResults(parameters),
		{
			enabled: !!organization_id && !!company_id && !!file_id && !!finished
		}
	);
	return {
		...report,
		data: report.data ?? ({} as LibertyMutualResultDTO)
	};
};

export const useGetTasks = ({ organization_id, company_id }: GetTasksRequest) => {
	const parameters = { organization_id, company_id };
	const tasks = useQuery(['liberty_mutual_tasks', parameters], () => libertyMutualApiService.getTasks(parameters), {
		enabled: !!organization_id && !!company_id
	});
	return {
		...tasks,
		data: tasks.data ?? ([] as LibertyMutualTaskDTO[])
	};
};

export const useGetSystemOfUnits = ({ organization_id, company_id }: GetSystemsOfUnitsRequest) => {
	const parameters = { organization_id, company_id };
	const systemOfUnits = useQuery(
		['systems_of_units', parameters],
		() => libertyMutualApiService.getSystemsOfUnits(parameters),
		{
			enabled: !!organization_id && !!company_id
		}
	);
	return {
		...systemOfUnits,
		data: systemOfUnits.data ?? ([] as SystemOfUnitsDTO[])
	};
};

export const useCreateReport = () => {
	const queryClient = useQueryClient();
	return useMutation((body: CreateReport) => libertyMutualApiService.createReport(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['liberty_mutual_report']);
		}
	});
};

export const useUpdateReportBasicInformation = () => {
	const queryClient = useQueryClient();
	return useMutation(
		(body: UpdateReportBasicInformation) => libertyMutualApiService.updateReportBasicInformation(body),
		{
			onError: (err: any) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: () => {
				queryClient.invalidateQueries(['liberty_mutual_result']);
			}
		}
	);
};

export const useUpdateSelectedTask = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateSelectedTask) => libertyMutualApiService.updateSelectedTask(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['liberty_mutual_result']);
		}
	});
};

export const useUpdateReportInputs = () => {
	const queryClient = useQueryClient();
	return useMutation((body: UpdateReportInputs) => libertyMutualApiService.updateReportInputs(body), {
		onError: (err: any) => {
			message.error(I18n.get(err.response.data.message));
		},
		onSuccess: () => {
			queryClient.invalidateQueries(['liberty_mutual_result']);
			message.success(I18n.get('Liberty Mutual report updated successfully!'));
		}
	});
};

export const useUpdateComment = () => {
	return useMutation((body: UpdateComment) => libertyMutualApiService.updateComment(body), {
		onSuccess: () => {
			message.success(I18n.get('Liberty Mutual report updated successfully!'));
		},
		onError: () => {
			message.error(I18n.get('Fail to save the comments'));
		}
	});
};

export const useDownloadPDF = () => {
	return useMutation((body: DownloadPDF) => libertyMutualApiService.downloadPDF(body), {
		onSuccess: (url: string) => {
			let archive = document.createElement('a');
			archive.target = '_blank';
			archive.href = url;
			archive.click();
			archive.remove();
		},
		onError: () => {
			message.error(I18n.get('Failed to generate the PDF'));
		}
	});
};
