import React from 'react';
import { I18n } from '@aws-amplify/core';
import { useLibertyMutualContext } from '../../context';

export interface IconType {
	name: string;
	position: number;
	currentStep: number;
	onStepChange(step: number): void;
}

export const StepIcon: React.FC<IconType> = ({ currentStep, name, position, onStepChange }) => {
	const { libertyMutual, steps } = useLibertyMutualContext();
	if (currentStep === position) {
		return <span className="step-name">{I18n.get(name)}</span>;
	}

	const lastStep = steps.length - 1;

	function handleStepChange() {
		if (reportIsNotFinished() && isResultStepIcon()) {
			return;
		}
		onStepChange(position);
	}

	function isResultStepIcon() {
		return position === lastStep;
	}

	function reportIsNotFinished() {
		return !libertyMutual?.percentile_man || !libertyMutual?.percentile_woman;
	}

	const disabled = reportIsNotFinished() && isResultStepIcon() ? 'disabled-step' : '';

	return (
		<div className="step-hover">
			<span
				className={currentStep > position ? 'step-checked' : `step-number ${disabled}`}
				onClick={() => handleStepChange()}
			>
				{position + 1}
			</span>
			<span
				className={currentStep < position ? `step-number-hovered ${disabled}` : 'step-checked-hovered'}
				onClick={() => handleStepChange()}
			>
				{I18n.get(name)}
			</span>
		</div>
	);
};
