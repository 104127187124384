import Api from '@/services/api';
import { BaseParameters, FileDTO, WorstGradeDTO, ReportDTO } from './types';

const ENDPOINT = '/ergonomic-tool/reba';

function mountingUrlParams(path: string, payload: any) {
	const { organization_id, company_id, file_id } = payload;

	const queryString = `${ENDPOINT}/${path}/${file_id}?`;
	const urlParams = new URLSearchParams(queryString);

	urlParams.set('organization_id', organization_id);
	urlParams.set('company_id', company_id);

	return queryString + urlParams.toString();
}

async function getFile(parameters: BaseParameters): Promise<FileDTO> {
	const { file_id, organization_id, company_id } = parameters;
	const url = `/upload/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getSectors(parameters: BaseParameters): Promise<any> {
	const { organization_id, company_id } = parameters;
	const url = `/sector/${organization_id}/${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getWorkstations(parameters: any): Promise<any> {
	const { organization_id, company_id, sector_id } = parameters;
	const url = `/workstation/${organization_id}/${company_id}/${sector_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getImagesPersons(parameters: any): Promise<any> {
	const url = mountingUrlParams('/images', parameters);
	const { data } = await Api.get(url);
	return data;
}

async function getReport(parameters: BaseParameters): Promise<ReportDTO | null> {
	const { organization_id, company_id, file_id } = parameters;
	const url = `/ergonomic-tool/reba/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	if (data.status === 'failed') {
		return null;
	}
	return data;
}

async function calculationAngles(parameters: BaseParameters): Promise<any> {
	const { data } = await Api.post('/ergonomic-tool/reba/calculate-angles', parameters);
	return data;
}

async function getRiskRangeSettings({ organizationId, companyId }: any): Promise<any> {
	const url = `/ergonomic-tool/reba/risk-range?organization_id=${organizationId}&company_id=${companyId}`;
	const { data } = await Api.get(url);
	return data;
}

async function getScore(payload: BaseParameters): Promise<any> {
	const { organization_id, company_id, file_id } = payload;
	const url = `/ergonomic-tool/reba/score/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getWorstGrade(payload: BaseParameters): Promise<WorstGradeDTO> {
	const { organization_id, company_id, file_id } = payload;
	const url = `/ergonomic-tool/reba/worst-grade/${file_id}?organization_id=${organization_id}&company_id=${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getReportDownload(payload: any): Promise<Blob> {
	const { data } = await Api.post('/ergonomic-tool/reba/document/pdf', payload, { responseType: 'blob' });
	return data;
}

export const Services = {
	getFile,
	getSectors,
	getWorkstations,
	getImagesPersons,
	getReport,
	calculationAngles,
	getRiskRangeSettings,
	getScore,
	getWorstGrade,
	getReportDownload
};
