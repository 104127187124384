export type State = {
	queryParams: DashboardQueryParams;
};

export type Methods = {
	setQueryParams: React.Dispatch<React.SetStateAction<DashboardQueryParams>>;
	handleFilterSelection: ({ celluleName, sectorId, stressLevel, workstation, rsi }: DashboardQueryParams) => void;
	handleClearFilters: () => void;
};

export type Context = State & Methods;

export type StrainIndexScore = {
	score: number;
	type: 'safe' | 'hazardous';
	amount: number;
};

export type NioshScaleType = 'very_low' | 'low' | 'moderate' | 'high' | 'very_high';

export type NioshScore = {
	score: number;
	type: NioshScaleType;
	amount: number;
};

export type NioshScores = {
	[key: string]: NioshScore;
};

export type StrainIndexScores = {
	[key: string]: StrainIndexScore;
};

export type WorkstationsScore = {
	id: number;
	scale: number;
	amount: number;
	type: FiveScoresScaleType;
};

export type DashboardSummaryDTO = {
	total_files_not_analyzed: number;
	total_files_analyzed: number;
	scores: ScoresScale[];
};

export type DashboardWorkOganizationDTO = {
	total: number;
	scores: ScoresScale[];
};

export type DashboardNioshDTO = {
	total: number;
	scores: NioshScores;
};

export type DashboardStrainIndexDTO = {
	total: number;
	scores: StrainIndexScores;
};

export type WorstScores = 1 | 2 | 4 | 3 | 5;

export type DashboardWorkstationsDTO = {
	[key: string]: {
		amount: number;
		worst_result: WorstScores;
		worst_score: number;
		scores: WorkstationsScore[];
	};
};

export type RebaScores = {
	id: number;
	amount: number;
	scale: number;
	type: FiveScoresScaleType;
};

export type DashboardRebaReportsDTO = {
	id: number;
	body_part: string;
	reviews: number;
	scores: RebaScores[];
};

export type DashboardWorkstation = {
	id: number;
	workstation: string;
	workstation_id: string;
	reviews: number;
	worst_review_level: WorstScores;
	worst_score: number;
	scores: WorkstationsScore[];
};

export type Statuses = {
	id: number;
	status: 'to_do' | 'doing' | 'done';
	amount: number;
};

export type ActionPlansCards = {
	id: string;
	card: string;
	completed: number;
	deadline: Date;
	result: WorstScores;
	totalTasks: number;
};

export type DashboardActionPlansCardsDTO = {
	total_plans: number;
	action_cards: ActionPlansCards[];
};

export type DashboardActionPlansStatusDTO = {
	total_plans: number;
	statuses: Statuses[];
};

export type SectorDTO = {
	id: string;
	name: string;
	company_id: string;
	isActive: boolean;
	createdAt: Date;
	updatedAt: Date;
};

export type DashboardQueryParams = {
	sectorId?: string;
	workstation?: string;
	celluleName?: string;
	stressLevel?: number[];
	rsi?: number;
	niosh?: string;
	work_organization?: number;
	workstations?: { score: number; workstation: string };
	reba?: { score: number; body_part: string };
	action_plan?: string;
};

export type FiveScoresScaleType = 'not_analyzed' | 'acceptable' | 'moderate' | 'high' | 'very_high' | 'extreme';

export type ScoresScale = {
	scale: number;
	score?: number;
	id: number;
	type: FiveScoresScaleType;
	amount: number;
};

export type ENUM_NIOSH = 'VERY_LOW' | 'MODERATE' | 'HIGH' | 'VERY_HIGH' | 'PEAK' | 'POWERFUL_HITTING';

const ENUM_TYPE_SCALE = {
	NOT_ANALYZED: 'not_analyzed',
	ACCEPTABLE: 'acceptable',
	MODERATE: 'moderate',
	HIGH: 'high',
	VERY_HIGH: 'very_high',
	EXTREME: 'extreme'
};

export const colorMapper: Record<number, string> = {
	1: '#2CC852',
	2: '#FFDE31',
	3: '#F78A38',
	4: '#E74150',
	5: '#9B54E2'
};

export const ENUM_FIVE_SCORE_SCALE = {
	[ENUM_TYPE_SCALE.NOT_ANALYZED]: {
		caption: 'Not analyzed',
		fontColor: '#9d9d9d',
		trailColor: '#D9D9D9'
	},
	[ENUM_TYPE_SCALE.ACCEPTABLE]: {
		caption: 'Acceptable Risk',
		fontColor: '#2CC852',
		trailColor: '#2CC852'
	},
	[ENUM_TYPE_SCALE.MODERATE]: {
		caption: 'Moderate Risk',
		fontColor: '#FFDE31',
		trailColor: '#FFDE31'
	},
	[ENUM_TYPE_SCALE.HIGH]: {
		caption: 'High Risk',
		fontColor: '#F78A38',
		trailColor: '#F78A38'
	},
	[ENUM_TYPE_SCALE.VERY_HIGH]: {
		caption: 'Very High Risk',
		fontColor: '#E74150',
		trailColor: '#E74150'
	},
	[ENUM_TYPE_SCALE.EXTREME]: {
		caption: 'Extreme and Imminent Risk',
		fontColor: '#9B54E2',
		trailColor: '#9B54E2'
	}
};

export const ENUM_ACTION_PLANS_SCALE = {
	to_do: {
		fontColor: '#F78A38',
		trailColor: '#F78A38'
	},
	doing: {
		fontColor: '#FFDE31',
		trailColor: '#FFDE31'
	},
	done: {
		fontColor: '#2CC852',
		trailColor: '#2CC852'
	}
};

export const ENUM_NIOSH_SCORE_SCALE = {
	very_low: {
		caption: 'Very low',
		fontColor: '#2CC852',
		trailColor: '#2CC852'
	},
	low: {
		caption: 'Low',
		fontColor: '#FFDE31',
		trailColor: '#FFDE31'
	},
	moderate: {
		caption: 'Moderate',
		fontColor: '#F78A38',
		trailColor: '#F78A38'
	},
	high: {
		caption: 'High',
		fontColor: '#E74150',
		trailColor: '#E74150'
	},
	very_high: {
		caption: 'Very high',
		fontColor: '#9B54E2',
		trailColor: '#9B54E2'
	}
};

export const ENUM_STRAIN_INDEX_SCORE_SCALE = {
	safe: {
		caption: 'Safe',
		fontColor: '#2CC852',
		trailColor: '#2CC852'
	},
	hazardous: {
		caption: 'Hazardous',
		fontColor: '#9B54E2',
		trailColor: '#9B54E2'
	}
};
