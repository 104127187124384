import React, { ReactNode, useState } from 'react';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { Button, Form, Select } from 'antd';
import { I18n } from '@aws-amplify/core';

import { ICreateWorkstation, WorkstationDTO } from '@/hooks/useWorkstation/types';
import { useApplicationContext } from '@/context/Application';
import { Container, DividerStyled, InputStyled } from './styles';
import { useWorkstation } from '@/hooks/useWorkstation';

const { useFormInstance } = Form;

type WorkstationResponse = {
	id: string;
	name: string;
};

type WorkstationInput = {
	size?: SizeType;
	sectorId: string;
	loading: boolean;
	disabled?: boolean;
	noCreate?: boolean;
	handleClearWorkstations?: () => void;
	workstations: WorkstationDTO[] | undefined;
	handleSelectWorkstation?: (id: string) => void;
};

export function WorkstationSelect({
	loading,
	disabled,
	sectorId,
	workstations,
	size = 'middle',
	noCreate = false,
	handleClearWorkstations,
	handleSelectWorkstation,
	...restProps
}: Readonly<WorkstationInput>) {
	const form = useFormInstance();
	const [workstationName, setWorkstationName] = useState('');
	const [close, setClose] = useState<boolean | undefined>(undefined);

	const { organization, company } = useApplicationContext();

	const { useCreateWorkstation } = useWorkstation();

	const { mutateAsync: createWorkstation }: any = useCreateWorkstation();

	const onCreateWorkstation = async () => {
		const body: ICreateWorkstation = {
			organization_id: organization.id,
			company_id: company.id,
			sector_id: sectorId,
			name: workstationName
		};
		setClose(false);
		const { data } = await createWorkstation(body);
		form.setFieldValue('workstation_id', data.id);
		setWorkstationName('');
	};

	async function handleChange(id: string) {
		if (handleSelectWorkstation) {
			handleSelectWorkstation(id);
		}
	}

	function handleClear() {
		if (handleClearWorkstations) {
			handleClearWorkstations();
		}
	}

	const filterOption = (input: string, option: any) =>
		option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	const manageWorkstationDropdown = (menu: ReactNode) => {
		return (
			<div>
				{menu}
				{!noCreate && (
					<>
						<DividerStyled />
						<Container>
							<InputStyled
								value={workstationName}
								onChange={(e) => setWorkstationName(e.target.value)}
								placeholder={I18n.get('Create a new workstation')}
							/>
							<Button
								onClick={onCreateWorkstation}
								type="link"
								loading={createWorkstation.isLoading}
								disabled={!workstationName || workstationName.length < 3}
							>
								{I18n.get('Add item')}
							</Button>
						</Container>
					</>
				)}
			</div>
		);
	};

	return (
		<Select
			allowClear
			showSearch
			size={size}
			onClear={handleClear}
			placeholder={I18n.get('Workstation')}
			onChange={(value: string) => handleChange(value)}
			optionFilterProp="children"
			filterOption={filterOption}
			loading={loading}
			disabled={disabled}
			onDropdownVisibleChange={(isOpen) => setClose(isOpen)}
			dropdownRender={(menu) => manageWorkstationDropdown(menu)}
			open={close}
			{...restProps}
		>
			{workstations?.map((item: WorkstationResponse) => (
				<Select.Option key={item.id} value={item.id}>
					{item.name}
				</Select.Option>
			))}
		</Select>
	);
}
