import styled from 'styled-components';
import { Collapse, Checkbox } from 'antd';

interface ContainerProps {
	$isBlured?: boolean;
}

export const Container = styled(Collapse)<ContainerProps>`
	.ant-collapse-item {
		.ant-collapse-header {
			padding: 7px;
			border: ${({ $isBlured }) => ($isBlured ? '1px solid #2f54eb' : '0px')};
			background-color: ${({ $isBlured }) => ($isBlured ? '#eaeefd' : 'white')};
			border-radius: ${({ $isBlured }) => ($isBlured ? '10px' : '0px')} !important;
		}
	}
	.ant-collapse-content-box {
		padding: 0;
	}
	.ant-form-item {
		margin-bottom: 0;
	}
`;
export const Panel = styled(Collapse.Panel)``;

export const CheckGroupCustom = styled(Checkbox.Group)`
	background-color: #f8f8f8;
	width: 100%;
	.ant-checkbox-wrapper {
		border: 1px solid #e6e6e6;
		width: 100%;
		padding-left: 10%;
		.ant-checkbox {
			display: none;
		}
	}
	.ant-checkbox-wrapper-in-form-item {
		height: 32px;
		align-items: center;
	}
	.ant-checkbox-wrapper-checked {
		background-color: #eaeefd;
		border: 1px solid #2f54eb;
		border-radius: 7px;
	}
`;
