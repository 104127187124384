import React, { ReactNode, useState } from 'react';
import { Col, Form, Row } from 'antd';

import { Title } from '@/components/Typography';
import { CollapseCheck } from '@/components/ui/CollapseCheck';
import { usePersonalisedReportStepKeyContext } from './DefaultStepKey/context';

const { useFormInstance } = Form;

interface CollapseCustomProps {
	title: string;
	children: ReactNode;
}

export const CollapseCustom: React.FC<CollapseCustomProps> = ({ title, children }) => {
	const form = useFormInstance();
	const { checked, stepKeyFieldName } = usePersonalisedReportStepKeyContext();
	const [open, setOpen] = useState<boolean>(checked);

	function handleCheckStepKey(): void {
		form.setFieldValue([...stepKeyFieldName, 'checked'], !open);
		setOpen(!open);
	}

	return (
		<Col sm={24}>
			<CollapseCheck
				value={open}
				header={
					<Title level={5} style={{ margin: 0 }}>
						{title}
					</Title>
				}
				onChange={handleCheckStepKey}
			>
				<Form.Item name={[...stepKeyFieldName, 'checked']}>
					<Row justify="center">
						<Col sm={24} xxl={22}>
							{children}
						</Col>
					</Row>
				</Form.Item>
			</CollapseCheck>
		</Col>
	);
};
