import React from 'react';
import { RightOutlined } from '@ant-design/icons';
import { ButtonProps } from 'antd/es/button';
import { Button } from 'antd';

interface NextButtonProps extends ButtonProps {
	onNext: React.MouseEventHandler<HTMLElement>;
	iconStyle?: boolean;
	style: any;
}

export function NextButton({ onNext, iconStyle, style, ...props }: Readonly<NextButtonProps>) {
	return <Button {...props} style={style} icon={<RightOutlined style={style} />} type={'link'} onClick={onNext} />;
}
