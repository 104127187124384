import React from 'react';
import { Col, Divider, Row } from 'antd';

import { I18n } from '@aws-amplify/core';
import { Title } from '@/components/Typography';

import { Description, Score } from './styles';

type InjuriesType = {
	id: string;
	name: string;
	description: string;
};

type RiskDescriptionProps = {
	riskScale: number;
	score: number;
	consequence: {
		name: string;
		description: string;
	};
	probability: {
		name: string;
		description: string;
	};
	exhibition: {
		name: string;
		description: string;
	};
	injuries: Array<InjuriesType>;
};

export const RiskDescription: React.FC<RiskDescriptionProps> = ({
	riskScale,
	score,
	consequence,
	probability,
	exhibition,
	injuries
}) => {
	const stressLevel = ['#E6E8E8', '#2cc852', '#FFDE31', '#F78A38', '#E74150', '#9B54E2'];
	const stressDescription = [
		'Not analyzed',
		'Acceptable Risk',
		'Moderate Risk',
		'High Risk',
		'Very High Risk',
		'Extreme and Imminent Risk'
	];
	const color = stressLevel[riskScale];
	const risk = stressDescription[riskScale];

	const formatInjuries = (item: InjuriesType, index: number) => {
		const lastElementIndex: number = injuries.length - 1;
		const pontuation: string = lastElementIndex !== index ? ',' : '.';
		return `${I18n.get(item.description)}${pontuation} `;
	};

	return (
		<Row justify="space-between" style={{ marginBottom: '1rem' }}>
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Consequence')}
					</Title>
				</Row>
				<Row>
					<Description>{I18n.get(consequence?.description)}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Probability')}
					</Title>
				</Row>
				<Row>
					<Description>{I18n.get(probability?.description)}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Exposure')}
					</Title>
				</Row>
				<Row>
					<Description>
						<Description>{I18n.get(exhibition?.description)}</Description>
					</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0 }} level={5}>
						{I18n.get('Possible Injuries/Harms')}
					</Title>
				</Row>
				<Row>
					<Description>{injuries.map(formatInjuries)}</Description>
				</Row>
			</Col>
			<Divider type="vertical" style={{ height: 'auto' }} />
			<Col span={4}>
				<Row>
					<Title style={{ margin: 0, color }} level={5}>
						{I18n.get(risk)}
					</Title>
				</Row>
				<Row>
					<Score>
						{I18n.get('Score')}: {score}
					</Score>
				</Row>
			</Col>
		</Row>
	);
};
