import { message } from 'antd';
import { I18n } from '@aws-amplify/core';
import { queryClient } from '@/store/query';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
	getAngles,
	getSectors,
	saveComment,
	createReport,
	updateReport,
	getRiskRanges,
	createReportPDF,
	getRiskRangeSettings
} from './services';

export const useGetAngles = (organizationId, companyId, fileId) => {
	return useQuery(
		['file-angles', organizationId, companyId, fileId],
		() => getAngles(organizationId, companyId, fileId),
		{
			enabled: !!organizationId && !!companyId && !!fileId
		}
	);
};

export const useRiskRanges = () => {
	return useQuery(['risk-ranges'], () => getRiskRanges());
};

export const useRiskRangeSettings = (riskRangeId) => {
	return useQuery(['risk_ranges', 'risk_ranges_settings', riskRangeId], () => getRiskRangeSettings(riskRangeId), {
		enabled: !!riskRangeId,
		retry: false,
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};

export const useUpdateReport = () => {
	return useMutation((payload) => updateReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries(['file']);
			queryClient.invalidateQueries(['angle-time-report']);
		},
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};

export const useCreateReport = () => {
	return useMutation((payload) => createReport(payload), {
		onSuccess: () => {
			queryClient.invalidateQueries(['file']);
			queryClient.invalidateQueries(['angle-time-report']);
		},
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};

export const useSectors = (organizationId, companyId) => {
	return useQuery(['sectors', organizationId, companyId], () => getSectors(organizationId, companyId), {
		enabled: !!organizationId && !!companyId
	});
};

export const useSaveComment = () => {
	return useMutation((body) => saveComment(body), {
		onSuccess: () => {
			queryClient.invalidateQueries(['angle-time-report']);
		},
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};

export const useGeneratePDF = () => {
	return useMutation((payload) => createReportPDF(payload), {
		onSuccess: (data) => {
			const blob = new Blob([data], { type: 'application/pdf' });
			window.open(URL.createObjectURL(blob));
			message.success(I18n.get('Document created successfully'));
		},
		onError: (err) => {
			message.error(I18n.get(err.response.data.message));
		}
	});
};
