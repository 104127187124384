import React, { useRef, useState, useEffect, useCallback } from 'react';
import { Row, Col, Input, Button, DatePicker, Select, Collapse, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { I18n } from '@aws-amplify/core';
import styled from 'styled-components';
import moment from 'moment';

import { useApplicationContext } from '@/context/Application';
import { WorkstationSelect } from '../ui/Inputs/WorkstationSelect';
import Hooks from '../Settings/Organizations/hooks';
import Api from '@/services/api';

const { useGetWorkstations } = Hooks;

const Container = styled.div`
	padding-bottom: 10px;
`;

const { useForm } = Form;

export function Filter({ filters, setPage }) {
	const { organization, company } = useApplicationContext();

	const [form] = useForm();
	const history = useHistory();
	const isMounted = useRef(false);

	const openFilter = Object.keys(filters).length > 0 ? ['filter'] : [''];
	const init_state = { data: null, loading: false };

	const selected_values = {
		organization_id: filters?.organization_id || organization.id,
		company_id: filters?.company_id || company.id,
		sector_id: filters?.sector_id,
		date: filters?.start_date && [moment(filters.start_date), moment(filters.end_date)],
		original_name: filters?.original_name,
		workstation_id: filters?.workstation_id
	};

	const [organizations, setOrganizations] = useState(init_state);
	const [companies, setCompanies] = useState(init_state);
	const [sectors, setSector] = useState(init_state);

	const [selectedOrganizationId, setSelectedOrganizationId] = useState(selected_values.organization_id);
	const [selectedCompanyId, setSelectedCompanyId] = useState(selected_values.company_id);
	const [selectedSectorId, setSelectedSectorId] = useState(selected_values.sector_id);

	const [, setErrors] = useState(null);

	useEffect(() => {
		return () => {
			isMounted.current = true;
		};
	}, []);

	const workstations = useGetWorkstations(selectedOrganizationId, selectedCompanyId, selectedSectorId);

	const getOrganizationsCallback = useCallback(() => {
		Api.get('/organization/')
			.then(!isMounted.current && setOrganizations((current) => ({ ...current, loading: true })))
			.then((res) => {
				!isMounted.current && setOrganizations((current) => ({ ...current, data: res.data }));
			})
			.catch((err) => {
				!isMounted.current && setErrors(err);
			})
			.finally(() => {
				!isMounted.current && setOrganizations((current) => ({ ...current, loading: false }));
			});
	}, []);

	const getCompaniesCallback = useCallback(() => {
		let url = `/company/user_list/${selectedOrganizationId}`;
		Api.get(url)
			.then(setCompanies((current) => ({ ...current, loading: true })))
			.then((res) => {
				setCompanies((current) => ({ ...current, data: res.data }));
			})
			.catch((err) => setErrors(err))
			.finally(() => {
				setCompanies((current) => ({ ...current, loading: false }));
			});
	}, [selectedOrganizationId]);

	const getSectorsCallback = useCallback(() => {
		const url = `/company/${selectedOrganizationId}/${selectedCompanyId}/sector`;
		Api.get(url)
			.then(setSector((current) => ({ ...current, loading: true })))
			.then((res) => {
				setSector((current) => ({ ...current, data: res.data }));
			})
			.catch((err) => setErrors(err))
			.finally(() => {
				setSector((current) => ({ ...current, loading: false }));
			});
	}, [selectedOrganizationId, selectedCompanyId]);

	useEffect(() => {
		getOrganizationsCallback();
		getCompaniesCallback();
	}, []);

	useEffect(() => {
		if (selectedOrganizationId) {
			getCompaniesCallback();
		}
		if (selectedOrganizationId && selectedCompanyId) {
			getSectorsCallback();
		}
	}, [selectedOrganizationId, selectedCompanyId]);

	function onSelectOrganization(id) {
		setSelectedOrganizationId(id);
		setSelectedCompanyId(null);
		setSelectedSectorId(null);
		form.setFieldsValue({
			company_id: undefined,
			sector_id: undefined,
			workstation_id: undefined
		});
	}

	function onSelectCompany(id) {
		setSelectedCompanyId(id);
		setSelectedSectorId(null);
		form.setFieldsValue({
			sector_id: undefined,
			workstation_id: undefined
		});
	}

	function onSelectSector(id) {
		setSelectedSectorId(id);
		form.setFieldsValue({
			workstation_id: undefined
		});
	}

	function onClear() {
		setSelectedOrganizationId(null);
		setSelectedCompanyId(null);
		setSelectedSectorId(null);
		form.resetFields();

		history.push({
			pathname: '/reporting',
			search: `?cleared=true`
		});
		setPage(0);
	}

	function checkFilterValues(filters) {
		const values = Object.values(filters);
		const clean_values = values.filter((value) => value);
		return clean_values.length > 1;
	}

	function onFinish(values) {
		const { organization_id, company_id, original_name, date, sector_id, workstation_id } = values;

		let route = '/reporting?';

		if (organization_id) {
			route = `${route}organization_id=${organization_id}&`;
		}

		if (company_id) {
			route = `${route}company_id=${company_id}&`;
		}

		if (original_name) {
			route = `${route}original_name=${original_name}&`;
		}

		if (date) {
			const [start, end] = date;
			const start_date = moment(start).format();
			const end_date = moment(end).format();
			route = `${route}start_date=${start_date}&end_date=${end_date}&`;
		}

		if (sector_id) {
			route = `${route}sector_id=${sector_id}&`;
		}

		if (workstation_id) {
			route = `${route}workstation_id=${workstation_id}&`;
		}

		history.replace(route);
	}

	const filterOptionSelect = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

	return (
		<Container>
			<Collapse defaultActiveKey={openFilter}>
				<Collapse.Panel header={I18n.get('Filter')} key="filter">
					<Form layout="vertical" form={form} onFinish={onFinish} initialValues={selected_values}>
						<Row gutter={8}>
							<Col sm={6}>
								<Form.Item label={I18n.get('Company')} name="organization_id">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Company')}
										optionFilterProp="children"
										loading={organizations.loading}
										disabled={organizations.loading}
										onChange={onSelectOrganization}
										filterOption={filterOptionSelect}
									>
										{organizations.data?.map((item, key) => (
											<Select.Option key={key} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Industrial Site')} name="company_id">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Industrial Site')}
										optionFilterProp="children"
										onChange={onSelectCompany}
										loading={companies.loading}
										filterOption={filterOptionSelect}
										disabled={companies.loading || !selectedOrganizationId}
									>
										{companies.data?.map((item, key) => (
											<Select.Option key={key} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Sector')} name="sector_id">
									<Select
										showSearch
										style={{ width: '100%' }}
										placeholder={I18n.get('Sector')}
										optionFilterProp="children"
										loading={sectors.loading}
										onChange={onSelectSector}
										filterOption={filterOptionSelect}
										disabled={!(selectedOrganizationId && selectedCompanyId)}
									>
										{sectors.data?.map((item, key) => (
											<Select.Option key={key} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col sm={6}>
								<Form.Item label={I18n.get('Video')} name="original_name">
									<Input placeholder={I18n.get('Name')} style={{ width: '100%' }} />
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Period')} name="date">
									<DatePicker.RangePicker
										showTime={{ format: 'HH:mm:00' }}
										format="DD-MM-YYYY HH:mm:00"
										style={{ width: '100%' }}
									/>
								</Form.Item>
							</Col>
							<Col sm={6}>
								<Form.Item label={I18n.get('Workstation')} name="workstation_id">
									<WorkstationSelect
										workstations={workstations?.data}
										sectorId={selectedSectorId}
										loading={sectors.loading}
										noCreate={true}
									/>
								</Form.Item>
							</Col>
						</Row>
						<Row gutter={8} style={{ paddingTop: '20px' }}>
							<Col sm={3}>
								<Form.Item noStyle shouldUpdate>
									{({ getFieldsValue }) => {
										const fields_value = getFieldsValue();
										const is_filled = checkFilterValues(fields_value);

										return (
											<Button
												type="primary"
												htmlType="submit"
												disabled={!is_filled}
												icon={<SearchOutlined />}
												style={{ width: '100%' }}
											>
												{I18n.get('Search')}
											</Button>
										);
									}}
								</Form.Item>
							</Col>
							<Col sm={3}>
								<Button style={{ width: '100%' }} onClick={onClear}>
									{I18n.get('Clear')}
								</Button>
							</Col>
						</Row>
					</Form>
				</Collapse.Panel>
			</Collapse>
		</Container>
	);
}
