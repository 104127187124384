import React, { useState, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Radio, Form, Divider } from 'antd';

import { Image, SubTitle, WrapperCard, ButtonCard } from './styles';
import { POSTURE_INPUT_CONSTANTS } from './util';

import postureOne from '@/assets/img/posture1.svg';
import postureTwo from '@/assets/img/posture2.svg';
import postureThree from '@/assets/img/posture3.svg';

const {
	POSTURES,
	NO_HARDLY,
	AWKWARD_BODY,
	TRUNK_UPRIGHT,
	BODY_INCLINED,
	RARE_VARIATION,
	WORK_ORGANIZATION,
	FREQUENT_VARIATION
} = POSTURE_INPUT_CONSTANTS;

export function Posture() {
	const form = Form.useFormInstance();

	const [active, setActive] = useState([false, false, false]);

	function selectPosture(posture) {
		if (posture.active !== 'active') {
			setActive((prev) => ({
				...prev,
				0: false,
				1: false,
				2: false,
				[posture.id]: true
			}));
			form.setFieldsValue({
				posture_input: posture.id
			});
		}
		if (posture.active === 'active') {
			setActive((prev) => ({
				...prev,
				0: false,
				1: false,
				2: false
			}));
			form.setFieldsValue({
				posture_input: null
			});
		}
	}

	const postureOptions = [
		{
			id: 0,
			title: '0',
			description: TRUNK_UPRIGHT,
			icon: postureOne,
			imgStyle: {
				display: 'block',
				marginLeft: 'auto',
				marginRight: 'auto',
				width: '70%'
			},
			isPosture: true,
			active: active[0] ? 'active' : ''
		},
		{
			id: 1,
			title: '1',
			description: BODY_INCLINED,
			icon: postureTwo,
			imgStyle: {
				display: 'block',
				marginLeft: 'auto',
				marginRight: 'auto',
				width: '90%'
			},
			isPosture: true,
			active: active[1] ? 'active' : ''
		},
		{
			id: 2,
			title: '2',
			description: AWKWARD_BODY,
			icon: postureThree,
			imgStyle: {
				display: 'block',
				marginLeft: 'auto',
				marginRight: 'auto',
				width: '90%'
			},
			isPosture: true,
			active: active[2] ? 'active' : ''
		}
	];

	const temporalOptions = [
		{
			value: 0,
			label: I18n.get('Good'),
			description: I18n.get(FREQUENT_VARIATION)
		},
		{
			value: 1,
			label: I18n.get('Restricted'),
			description: I18n.get(RARE_VARIATION)
		},
		{
			value: 2,
			label: I18n.get('Unfavorable'),
			description: I18n.get(NO_HARDLY)
		}
	];

	useEffect(() => {
		if (form.getFieldValue('posture_input') >= 0) {
			setActive((prev) => ({
				...prev,
				[form.getFieldValue('posture_input')]: true
			}));
		}
	}, [form]);

	return (
		<Row>
			<Col xs={24} sm={24} md={24}>
				<Row
					gutter={[0, 30]}
					style={{
						marginTop: '15px',
						marginBottom: '15px',
						marginRight: '10px',
						marginLeft: '10px'
					}}
				>
					<Col sm={24}>
						<SubTitle>{I18n.get(POSTURES)}</SubTitle>
					</Col>
					<Col sm={24}>
						<Form.Item
							name="posture_input"
							rules={[
								{
									required: true,
									message: I18n.get('Choose an option')
								}
							]}
							style={{ alignContent: 'center' }}
						>
							<Row gutter={[10, 10]} type="flex" justify="center" align="top">
								{postureOptions.map((option, index) => (
									<Col sm={8} key={index}>
										<WrapperCard
											onClick={() => selectPosture(option)}
											height="450px"
											isPosture={option.isPosture}
											className={option.active}
										>
											<Row className="card_body">
												<Col sm={24}>
													<Image src={option.icon} style={option.imgStyle} />
													<Divider orientation="left" />
													<p
														style={{
															textAlign: 'justify',
															textJustify: 'inter-word',
															fontSize: '0.9em',
															whiteSpace: 'pre-line'
														}}
													>
														{I18n.get(option.description)
															.split(' /')
															.join(';\n')
															.split(';')
															.join('.\n')}
														.
													</p>
												</Col>
											</Row>
										</WrapperCard>
									</Col>
								))}
							</Row>
						</Form.Item>
					</Col>
				</Row>
				<Row
					gutter={[0, 30]}
					style={{
						marginTop: '15px',
						marginBottom: '15px',
						marginRight: '10px',
						marginLeft: '10px'
					}}
				>
					<Col sm={24} style={{ marginTop: '30px' }}>
						<SubTitle>{I18n.get(WORK_ORGANIZATION)}</SubTitle>
					</Col>
					<Col
						sm={24}
						style={{
							marginBottom: '75px'
						}}
					>
						<Form.Item
							name="temporal_distribution_input"
							rules={[
								{
									required: true,
									message: I18n.get('Choose an option')
								}
							]}
						>
							<Radio.Group name="temporal_distribution_input" style={{ width: '100%' }}>
								{temporalOptions.map((option, index) => (
									<Col sm={option.column} offset={option.offset} key={index}>
										<ButtonCard isButton={true}>
											<Radio
												options={temporalOptions}
												name="driveway_conditions_input"
												value={option.value}
											>
												<span
													style={{
														whiteSpace: 'initial',
														fontSize: '0.9em',
														fontWeight: 'bold',
														marginLeft: '1.5em',
														width: '10%'
													}}
												>
													{option.label}
												</span>
												<p
													style={{
														whiteSpace: 'initial',
														fontSize: '0.85em',
														width: '60%',
														marginLeft: '20%',
														display: 'block',
														float: 'right'
													}}
												>
													{option.description}
												</p>
											</Radio>
										</ButtonCard>
									</Col>
								))}
							</Radio.Group>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
