import React from 'react';
import { Row, Col, Form, Input, Select, Radio } from 'antd';
import { I18n } from '@aws-amplify/core';
import { SearchOutlined } from '@ant-design/icons';
import type { FormInstance } from 'antd/es/form/Form';
import { Button } from '@/components/ui/Button';
import { RoleUserKey, RoleUserValue, StatusUserKey, StatusUserValue } from '@/utils/enum';

const { Option } = Select;

interface FilterFormProps {
	form: FormInstance;
	onFinish: () => void;
	onClear: () => void;
}

export function FilterForm({ form, onFinish, onClear }: FilterFormProps) {
	return (
		<Form form={form} layout="vertical" initialValues={{ status: StatusUserKey.ALL }}>
			<Row gutter={[10, 8]}>
				<Col xs={12} sm={12} md={6}>
					<Form.Item name="name" label={I18n.get('Name')}>
						<Input placeholder={I18n.get('Insert a name')} />
					</Form.Item>
				</Col>
				<Col xs={12} sm={12} md={6}>
					<Form.Item name="email" label={I18n.get('E-mail')}>
						<Input placeholder={I18n.get('Insert an e-mail')} />
					</Form.Item>
				</Col>
				<Col xs={12} sm={12} md={6}>
					<Form.Item name="role" label={I18n.get('Role')}>
						<Select placeholder={I18n.get('Select a role')}>
							<Option value={RoleUserKey.MASTER}>{I18n.get(RoleUserValue.MASTER)}</Option>
							<Option value={RoleUserKey.SUPERVISOR}>{I18n.get(RoleUserValue.SUPERVISOR)}</Option>
							<Option value={RoleUserKey.USER}>{I18n.get(RoleUserValue.USER)}</Option>
						</Select>
					</Form.Item>
				</Col>
				<Col xs={12} sm={12} md={6}>
					<Form.Item name="status" label={I18n.get('Status')}>
						<Radio.Group>
							<Radio.Button value={StatusUserKey.ACTIVE}>{I18n.get(StatusUserValue.ACTIVE)}</Radio.Button>
							<Radio.Button value={StatusUserKey.INACTIVE}>
								{I18n.get(StatusUserValue.INACTIVE)}
							</Radio.Button>
							<Radio.Button value={StatusUserKey.ALL}>{I18n.get(StatusUserValue.ALL)}</Radio.Button>
						</Radio.Group>
					</Form.Item>
				</Col>
				<Col xs={24} sm={12} md={6} lg={6} xl={3}>
					<Button type="primary" onClick={onFinish} icon={<SearchOutlined />} style={{ width: '100%' }}>
						{I18n.get('Find')}
					</Button>
				</Col>
				<Col xs={24} sm={12} md={6} lg={6} xl={3}>
					<Button type="ghost" onClick={onClear} style={{ width: '100%' }}>
						{I18n.get('Clear')}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}
