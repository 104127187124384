import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { customReportService } from './services';
import { CustomReportResult, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type CreateResultDTO = BaseContext & {
	file_id?: string;
	role_name: string;
	result_id?: string;
	cellule_name?: string;
	activity_name: string;
	collection_date: Date;
	evaluator_id?: string;
	workstation_id: string;
	step_setting_id?: string;
	custom_report_id: string;
};

export type PersonalisedReportResultCreateResponse = CustomReportResult & {
	sector_id: string;
	company_id: string;
	organization_id: string;
};

async function createResult<T, Body>(payload: Body): Promise<T> {
	const url = '/custom-report/result';
	const { data } = await Api.post<T>(url, payload);
	return data;
}

export function useCreateResult() {
	const queryClient = useQueryClient();
	return useMutation<PersonalisedReportResultCreateResponse, AxiosError<Response>, CreateResultDTO>(
		(body) => createResult<PersonalisedReportResultCreateResponse, CreateResultDTO>(body),
		{
			onError: (err) => {
				const title = 'Oops! Something happened.';
				const message = err.response?.data?.message ?? 'Failed to update information';
				customReportService.showErrorMessage(title, message);
			},
			onSuccess: async () => {
				const message = 'Report created successfully';
				customReportService.showSuccessMessage(message);
				await queryClient.invalidateQueries(['personalised_report']);
				await queryClient.invalidateQueries(['personalised_report_result']);
			}
		}
	);
}
