import Api from '@/services/api';
import { useMutation } from '@tanstack/react-query';

interface DeleteFileParams {
	organization_id: string;
	company_id: string;
	file_id: string;
}

interface DeleteFileResult {
	status: string;
	message: string;
	data: {
		id: string;
	};
}

async function deleteFile({ organization_id, company_id, file_id }: DeleteFileParams): Promise<DeleteFileResult> {
	const url = `/upload/${file_id}`;
	const { data } = await Api.delete(url, { params: { organization_id, company_id } });
	return data;
}

export const useDeleteFile = (params: DeleteFileParams) => {
	return useMutation((file_id: string) => deleteFile({ ...params, file_id }));
};
