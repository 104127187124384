import React from 'react';
import { Menu } from 'antd';
import type { MenuProps } from 'antd';
import { I18n } from '@aws-amplify/core';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
	BarChartOutlined,
	CloudUploadOutlined,
	FilePdfOutlined,
	FileProtectOutlined,
	FileTextOutlined,
	ProfileOutlined,
	ReconciliationOutlined,
	InfoCircleOutlined,
	ControlOutlined,
	TeamOutlined,
	PartitionOutlined
} from '@ant-design/icons';

import Can from '@/components/Can';
import { MenuItem, ItemParams } from './types';
import { PersonalisedReportSVG } from '@/components/Icons/PersonalisedReportSVG';

export function MenuOptions() {
	const history = useHistory();
	const location = useLocation();
	const selectKey = location.pathname;

	function getItem({ label, key = null, icon, children, type, disabled = false }: ItemParams): MenuItem {
		return {
			key,
			icon,
			children,
			label,
			type,
			disabled
		} as MenuItem;
	}

	const items: MenuItem[] = [
		getItem({ label: I18n.get('Dashboard'), key: '/', icon: <BarChartOutlined /> }),
		getItem({ label: I18n.get('Upload'), key: '/upload', icon: <CloudUploadOutlined /> }),
		getItem({ label: I18n.get('Reports'), key: '/reporting', icon: <FilePdfOutlined /> }),
		getItem({
			key: null,
			label: I18n.get('EWA'),
			icon: <FileProtectOutlined />,
			children: [
				getItem({
					label: I18n.get('New document'),
					key: '/super-pea/new-document',
					icon: <FileTextOutlined />
				}),
				getItem({ label: I18n.get('File history'), key: '/super-pea/file-history', icon: <ProfileOutlined /> })
			]
		}),
		getItem({ label: I18n.get('Action plans'), key: '/action-plans', icon: <ReconciliationOutlined /> }),
		getItem({
			key: null,
			label: I18n.get('Settings'),
			icon: <InfoCircleOutlined />,
			children: [
				getItem({ label: I18n.get('My account'), key: '/settings/account', icon: <InfoCircleOutlined /> }),
				getItem({
					label: I18n.get('Preferences'),
					key: '/settings/preferences',
					icon: <ControlOutlined />,
					disabled: !Can('settings', 'preferences')
				}),
				getItem({
					label: I18n.get('Manage users'),
					key: '/users',
					icon: <TeamOutlined />,
					disabled: !Can('settings', 'management')
				}),
				getItem({
					label: I18n.get('Manage companies'),
					key: '/settings/companies',
					icon: <PartitionOutlined />,
					disabled: !Can('settings', 'management')
				}),
				getItem({
					label: I18n.get('Personalised reports'),
					key: '/settings/personalised-reports',
					icon: (
						<PersonalisedReportSVG
							width={15}
							height={15}
							margin="0 0.5rem 0 0"
							disabled={!Can('settings', 'personalise_report')}
						/>
					),
					disabled: !Can('settings', 'personalise_report')
				})
			]
		})
	];

	const handleOnClick: MenuProps['onClick'] = ({ key }): void => {
		history.push(key);
	};

	return (
		<Menu
			theme="light"
			mode="inline"
			items={items}
			onClick={handleOnClick}
			selectedKeys={[selectKey]}
			defaultSelectedKeys={[selectKey]}
		/>
	);
}
