import React from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Typography, Select, DatePicker, Form, Descriptions } from 'antd';

import { useInformations } from './hooks';
import { useRebaReportContext } from '../../../context';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';

const { useFormInstance } = Form;

export const Informations: React.FC = () => {
	const form = useFormInstance();
	const { organization, file, base_parameters } = useRebaReportContext();
	const { sectors, workstations } = useInformations(base_parameters);
	const sectorId = form.getFieldValue('sector_id');

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col sm={18}>
				<Row gutter={[0, 20]}>
					<Col sm={24}>
						<Typography.Text strong>
							{I18n.get('Enter the data related to the selected video below')}
						</Typography.Text>
					</Col>
					<Col xs={24} sm={24}>
						<Descriptions column={1} size="small">
							<Descriptions.Item label={I18n.get('File')}>{file.data?.original_name}</Descriptions.Item>
							<Descriptions.Item label={I18n.get('Date')}>
								{moment(file.data?.created_at).format('L')}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row justify="center" gutter={[10, 20]} style={{ marginTop: '10px' }}>
					<Col xs={24} sm={12}>
						<Form.Item name="organization_id" label={I18n.get('Company')}>
							<Select disabled={true} placeholder={I18n.get('Company')}>
								<Select.Option value={organization?.id}>{organization?.name}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col xs={24} sm={12}>
						<Form.Item
							name="sector_id"
							label={I18n.get('Sector')}
							rules={[
								{
									message: I18n.get('Select the sector'),
									required: true
								}
							]}
						>
							<SectorSelect sectors={sectors} loading={sectors.isLoading} disabled={sectors.isLoading} />
						</Form.Item>
					</Col>
					<Col xs={24} sm={12}>
						<Form.Item
							label={I18n.get('Workstation')}
							name="workstation_id"
							rules={[
								{
									message: I18n.get('Enter the workstation'),
									required: true
								}
							]}
						>
							<WorkstationSelect
								sectorId={sectorId}
								loading={sectors.isLoading}
								workstations={workstations.data}
							/>
						</Form.Item>
					</Col>
					<Col xs={24} sm={12}>
						<Form.Item
							name="collection_date"
							label={I18n.get('Collection date')}
							rules={[
								{
									message: I18n.get('Insert the date of collection'),
									required: true
								}
							]}
						>
							<DatePicker
								format="L"
								style={{ width: '100%' }}
								placeholder={I18n.get('Collection date')}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
