import React from 'react';
import styled from 'styled-components';
import { I18n } from '@aws-amplify/core';
import { Col, Row, Image, Card } from 'antd';
import { ClassificationGauge } from '@/components/ui/Reports/ClassificationGauge';

import { vehicles } from './constants';
import {
	Man,
	User,
	Team,
	Woman,
	InputRow,
	ResultText,
	InputTitle,
	ResultLabel,
	InputContent,
	GaugeContainer,
	InsideContainer
} from './styles';

const ParameterCard = styled(Card)`
	height: 100%;

	.ant-card-head,
	.ant-card-body {
		font-size: 13px;
	}

	.ant-card-head-title {
		overflow: auto;
		white-space: normal;
	}
`;

export const RiskRange = ({ reportData }) => {
	const conclusionOptions = {
		VERY_HIGH: {
			classification: 'high',
			color: 'red',
			risk: I18n.get('Very high')
		},
		HIGH: {
			classification: 'high',
			color: 'red',
			risk: I18n.get('High')
		},
		MODERATE: {
			classification: 'medium',
			color: 'orange',
			risk: I18n.get('Moderate')
		},
		LOW: {
			classification: 'low',
			color: 'green',
			risk: I18n.get('Low')
		}
	};

	return (
		<Row>
			<Col sm={5} offset={1} style={{ marginBottom: '25px' }}>
				<Row>
					<Col
						style={{
							width: 'auto',
							marginLeft: 'auto',
							marginRight: 'auto',
							marginBottom: '10px'
						}}
					>
						<ResultText
							strong
							style={{ fontSize: '20px' }}
							type={reportData?.labels.risk.type}
						>
							{I18n.get(reportData?.labels.risk.title)}
						</ResultText>
					</Col>
				</Row>
				<Row>
					<Col style={{ width: 'auto', marginLeft: 'auto', marginRight: 'auto' }}>
						<GaugeContainer>
							<ClassificationGauge
								classification={
									conclusionOptions[reportData?.labels.risk.value]
										.classification
								}
							/>
						</GaugeContainer>
					</Col>
				</Row>
				<Row>
					<Col style={{ width: 'auto', marginLeft: 'auto', marginRight: 'auto' }}>
						<ResultLabel
							color={conclusionOptions[reportData?.labels.risk.value].color}
						>
							{Math.round(reportData?.score)}
						</ResultLabel>
					</Col>
				</Row>
			</Col>
			<Col sm={7} offset={2}>
				<Row>
					<Col sm={10} style={{ marginTop: '3rem' }}>
						<Row>
							<Col sm={24} type="flex" align="middle">
								{reportData.pair === 0 ? <User /> : <Team />}
							</Col>
							<Col sm={24} type="flex" align="middle">
								<span>{I18n.get(reportData?.labels.pair)}</span>
							</Col>
						</Row>
					</Col>
					<Col sm={10} offset={4} style={{ marginTop: '3rem' }}>
						<Row>
							<Col sm={24} type="flex" align="middle">
								{reportData.gender === 0 ? <Man /> : <Woman />}
							</Col>
							<Col sm={24} type="flex" align="middle">
								<span>{I18n.get(reportData?.labels.gender)}</span>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
			<InsideContainer sm={6} offset={2}>
				<InputRow>
					{reportData?.labels.duration ? (
						<>
							<Col sm={11}>
								<InputTitle>{I18n.get('Duration')}:</InputTitle>
							</Col>
							<Col sm={13}>
								<InputContent>
									{I18n.get(reportData?.labels.duration)}{' '}
									{I18n.get('minutes')}
								</InputContent>
							</Col>
						</>
					) : (
						<>
							<Col sm={11}>
								<InputTitle>{I18n.get('Distance')}:</InputTitle>
							</Col>
							<Col sm={13}>
								<InputContent>
									{I18n.get(reportData?.labels.distance)}{' '}
									{I18n.get('meters')}
								</InputContent>
							</Col>
						</>
					)}
				</InputRow>
				<InputRow>
					<Col sm={11}>
						<InputTitle>{I18n.get('Mass: ')}</InputTitle>
					</Col>
					<Col sm={13}>
						<InputContent>{I18n.get(reportData?.labels.mass)} kg</InputContent>
					</Col>
				</InputRow>
				<InputRow>
					<Col sm={8}>
						<InputTitle>{I18n.get('Vehicle: ')}</InputTitle>
					</Col>
					<Col sm={13}>
						<InputContent
							style={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center'
							}}
						>
							<Image
								src={vehicles[reportData?.vehicle].src}
								style={vehicles[reportData?.vehicle].style}
							/>
							{vehicles[reportData?.vehicle].srcExtra && (
								<Image
									src={vehicles[reportData?.vehicle].srcExtra}
									style={vehicles[reportData?.vehicle].style}
								/>
							)}
							{vehicles[reportData?.vehicle].srcExtraTwo && (
								<Image
									src={vehicles[reportData?.vehicle].srcExtraTwo}
									style={vehicles[reportData?.vehicle].style}
								/>
							)}
						</InputContent>
					</Col>
				</InputRow>
			</InsideContainer>
			<Col span={24}>
				<Row gutter={[24]}>
					<Col sm={8}>
						<ParameterCard
							title={I18n.get('Probability of physical overload')}
							headStyle={{ fontWeight: 'bold' }}
						>
							<p>{I18n.get(reportData?.labels.diagnostics.PHYSICAL_OVERLOAD)}</p>
						</ParameterCard>
					</Col>
					<Col sm={8}>
						<ParameterCard
							title={I18n.get('Possible health consequences')}
							headStyle={{ fontWeight: 'bold' }}
						>
							<p>
								{I18n.get(reportData?.labels.diagnostics.HEALTH_CONSEQUENCES)}
							</p>
						</ParameterCard>
					</Col>
					<Col sm={8}>
						<ParameterCard
							title={I18n.get('Measures')}
							headStyle={{ fontWeight: 'bold' }}
						>
							<p>{I18n.get(reportData?.labels.diagnostics.MEASURES)}</p>
						</ParameterCard>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
