import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Form, message } from 'antd';
import { useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import _ from 'lodash';

import { REPORT_COMMENT_DEBOUNCE_TIME } from '@/utils/constants';
import { HeaderStep, Footer } from '@/components/views/Report/Steps.js';
import { useSessionStorage } from '@/hooks/useSessionStorage';
import { useLocalStorage } from '@/hooks/useLocalStorage';
import { useDebounce } from '@/hooks/useDebounce';
import Spinner from '@/components/Layout/Spinner';
import { useFile, useSectors } from '@/hooks/v1';
import { TimeAndForce } from './TimeAndForce';
import { Information } from './Information';
import { Conditions } from './Conditions';
import { Posture } from './Posture';
import { Result } from './Result';
import Api from '@/services/api';

const { useForm } = Form;

export function KimManualHandling() {
	const [form] = useForm();
	const history = useHistory();
	const { file_id } = useParams();
	const queryClient = useQueryClient();
	const { search } = useLocation();

	const [currentStep, setCurrentStep] = useState(0);

	const organization = useSelector((state) => state.organization.organization);
	const company = useSelector((state) => state.organization.company);

	const [cameFromPreliminary, setCameFromPreliminary] = useState(false);
	const [nameLocal] = useLocalStorage('kim-mho', false);
	const [nameSession, setNameSession] = useSessionStorage('kim-mho', false);

	const sectorId = Form.useWatch('sector_id', form);

	const { sectors } = useSectors({
		organizationId: organization?.id,
		companyId: company?.id
	});

	const { file } = useFile({
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file_id
	});

	async function getReport({ organizationId, companyId, fileId }) {
		if (!organizationId || !companyId || !fileId) return [];
		const url = `/ergonomic-tool/kim-mho/${fileId}?organization_id=${organizationId}&company_id=${companyId}`;
		const { data } = await Api.get(url);
		if (data.status === 'failed') {
			return null;
		}

		const normalizedReport = normalizeReport(data);
		return normalizedReport;
	}

	const reportParams = {
		organizationId: organization?.id,
		companyId: company?.id,
		fileId: file?.data?.id
	};

	const report = useQuery(['kim_mho', reportParams], () => getReport(reportParams), {
		enabled: !!organization && !!company && !!file.data,
		retry: false,
		refetchOnWindowFocus: false,
		onSuccess: (data) => {
			if (data) {
				goToResults();
			}
		}
	});

	useEffect(() => {
		return () => {
			queryClient.invalidateQueries(['kim_mho']);
		};
	}, [queryClient]);

	const isLoading = sectors.isLoading || file.isLoading || report.isLoading;
	const isError = sectors.isError || file.isError || report.isError;

	useEffect(() => {
		if (!isLoading) {
			if (nameLocal || nameSession) {
				setCameFromPreliminary(true);
			}
		}
	}, [isLoading]);

	useEffect(() => {
		if (cameFromPreliminary) {
			localStorage.removeItem('kim-mho');
			setNameSession(true);
		}
	}, [cameFromPreliminary]);

	const updateReport = useMutation(
		({ body, reportExists }) => {
			const { report_id } = body.report_input;
			if (reportExists) {
				return Api.put(`/ergonomic-tool/kim-mho/${report_id}`, body);
			} else {
				return Api.post('/ergonomic-tool/kim-mho', body);
			}
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const normalizedReport = normalizeReport(response.data);
				queryClient.setQueryData(['kim_mho', reportParams], normalizedReport);
				queryClient.invalidateQueries(['file']);
				message.success(I18n.get('Report created successfully'));
				onNext();
			}
		}
	);

	const updateComment = useMutation(
		({ body }) => {
			const { report_id } = body.report_input;
			return Api.put(`/ergonomic-tool/kim-mho/comment/${report_id}`, body);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const normalizedReport = normalizeReport(response.data);
				queryClient.setQueryData(['kim_mho', reportParams], normalizedReport);
				message.success(I18n.get('Comment updated successfully'));
			}
		}
	);

	const generatePDF = useMutation(
		({ body, opts }) => {
			return Api.post('/ergonomic-tool/kim-mho/document/pdf', body, opts);
		},
		{
			onError: (err) => {
				message.error(I18n.get(err.response.data.message));
			},
			onSuccess: (response) => {
				const blob = new Blob([response.data], {
					type: 'application/pdf'
				});
				window.open(URL.createObjectURL(blob));
				message.success(I18n.get('Document created successfully'));
			}
		}
	);

	function goToResults() {
		const finalStep = steps.length - 1;
		setCurrentStep(finalStep);
	}

	function onNext() {
		if (currentStep < steps.length - 2) {
			form.validateFields()
				.then(() => {
					setCurrentStep((prev) => prev + 1);
				})
				.catch(() => message.error(I18n.get('Enter the required values')));
		} else {
			setCurrentStep((prev) => prev + 1);
		}
	}

	function onPrevious() {
		setCurrentStep((prev) => prev - 1);
	}

	function onFinish() {
		form.validateFields()
			.then(() => {
				const values = form.getFieldsValue(true);
				const { organization_id, ...rest } = values;
				const company_id = company?.id;
				const report_input = {
					...rest,
					file_id: file.data.id,
					...(!!report.data && { report_id: report.data.id })
				};

				if (!report.data || isFormChanged(report_input, report.data)) {
					const body = { organization_id, company_id, report_input };
					updateReport.mutate({ body, reportExists: !!report.data });
				} else {
					onNext();
				}
			})
			.catch(() => message.error(I18n.get('Enter the required values')));
	}

	async function onClose() {
		await saveComment();
		cameFromPreliminary ? window.close() : history.push(`/reporting/${search}`);
	}

	const onChangeComment = useDebounce(saveComment, REPORT_COMMENT_DEBOUNCE_TIME);

	async function saveComment() {
		const values = form.getFieldsValue(true);
		const { comment, organization_id } = values;
		const company_id = company?.id;
		if ((comment || comment === '') && report.data.comment !== comment) {
			const report_input = { report_id: report.data.id, comment };
			const body = { organization_id, company_id, report_input };
			await updateComment.mutateAsync({ body });
		}
	}

	async function onDownloadPDF(fileData) {
		await saveComment();
		const browserLanguage = window.navigator.language ?? 'en-US';

		const body = {
			organization_id: organization?.id,
			company_id: company?.id,
			file_id: fileData.id,
			locale: browserLanguage
		};

		const opts = { responseType: 'blob' };

		generatePDF.mutate({ body, opts });
	}

	function isFormChanged(report_input, previousReport) {
		const existingFile = {
			sector_id: file.data.sector_id,
			workstation_id: file.data.workstation_id
		};

		const formFile = {
			sector_id: report_input.sector_id,
			workstation_id: report_input.workstation_id
		};

		const existingReport = {
			collection_date: moment(previousReport.collection_date).format('L'),
			duration: previousReport.duration,
			left_force_type: previousReport.left_force_type,
			left_force_frequency: previousReport.left_force_frequency,
			left_force_intensity: previousReport.left_force_intensity,
			right_force_type: previousReport.right_force_type,
			right_force_frequency: previousReport.right_force_frequency,
			right_force_intensity: previousReport.right_force_intensity,
			force_transfer: previousReport.force_transfer,
			arm_posture: previousReport.arm_posture,
			work_conditions: previousReport.work_conditions,
			temporal_distribution: previousReport.temporal_distribution,
			body_posture: previousReport.body_posture
		};

		const formReport = {
			collection_date: moment(report_input.collection_date).format('L'),
			duration: report_input.duration,
			left_force_type: report_input.left_force_type,
			left_force_frequency: report_input.left_force_frequency,
			left_force_intensity: report_input.left_force_intensity,
			right_force_type: report_input.right_force_type,
			right_force_frequency: report_input.right_force_frequency,
			right_force_intensity: report_input.right_force_intensity,
			force_transfer: report_input.force_transfer,
			arm_posture: report_input.arm_posture,
			work_conditions: report_input.work_conditions,
			temporal_distribution: report_input.temporal_distribution,
			body_posture: report_input.body_posture
		};

		const isFileChanged = !_.isEqual(existingFile, formFile);

		const isReportChanged = !_.isEqual(existingReport, formReport);

		return isFileChanged || isReportChanged;
	}

	// Normalize report when getting data from database
	function normalizeReport(reportData) {
		const normalizedReport = {
			...reportData,
			collection_date: moment(reportData.collection_date)
		};

		return normalizedReport;
	}

	const steps = [
		{
			title: I18n.get('Information'),
			content: <Information sectors={sectors} sectorId={sectorId} file={file} />,
			is_valid: true
		},
		{
			title: I18n.get('Time and force'),
			content: <TimeAndForce form={form} />,
			is_valid: true
		},
		{
			title: I18n.get('Conditions'),
			content: <Conditions />,
			is_valid: true
		},
		{
			title: I18n.get('Posture'),
			content: <Posture />,
			is_valid: true
		},
		{
			title: I18n.get('Result'),
			content: (
				<Result
					file={file}
					report={report}
					sectors={sectors}
					onDownloadPDF={onDownloadPDF}
					isLoadingPDF={generatePDF.isLoading}
					onChangeComment={onChangeComment}
				/>
			)
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <h2>Internal server error</h2>;
	}

	const initValues = {
		organization_id: organization?.id,
		sector_id: file?.data?.sector_id,
		workstation_id: file?.data?.workstation_id,
		...report?.data
	};

	return (
		<Form form={form} layout="horizontal" initialValues={initValues}>
			<Row justify="center">
				<Col sm={24} style={{ marginBottom: '16px', textAlign: 'center' }}>
					<h2>{I18n.get('Manual Handling Operations')}</h2>
				</Col>
				<Col xxl={20} xl={22} sm={24}>
					<Row justify="center" align="middle" style={{ marginBottom: '20px' }}>
						<Col sm={20} xxl={14}>
							<HeaderStep current={currentStep} steps={steps} />
						</Col>
					</Row>
					<Row justify="center">
						<Col sm={24} style={{ minHeight: '350px' }}>
							{isLoading ? <Spinner /> : steps[currentStep].content}
						</Col>
					</Row>
					<Row justify="center">
						<Col sm={24}>
							{!isLoading && (
								<Footer
									steps={steps}
									onNext={onNext}
									onClose={onClose}
									onFinish={onFinish}
									current={currentStep}
									isFetching={isLoading}
									onPrevious={onPrevious}
								/>
							)}
						</Col>
					</Row>
				</Col>
			</Row>
		</Form>
	);
}
