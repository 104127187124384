import { Text, Title } from '@/components/Typography';
import { Row, Col, Progress, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import { I18n } from '@aws-amplify/core';
import moment from 'moment';
import React from 'react';

import { riskCalc, riskColors, riskLevels } from '@/utils/riskRange';
import { CardContainer, CardDate, DeleteCardBtn } from './styles';
import { TagRisk } from '@/components/Report/styles';
import { ACTION_PLANS_MAPPER } from '@/utils/enum';

export function ActionCard({ actionPlan, index, onDelete, onEdit, calcProgress, id }) {
	let dateStatus = '';
	let deadlineDate = moment(actionPlan.deadline);

	if (actionPlan.board !== ACTION_PLANS_MAPPER.DONE) {
		if (deadlineDate.startOf('day') < moment().startOf('day')) {
			dateStatus = 'overdue';
		} else if (deadlineDate < moment().add(1, 'week')) {
			dateStatus = 'this-week';
		}
	}

	function onClick() {
		onEdit(actionPlan);
	}

	return (
		<Draggable draggableId={actionPlan.id} key={id} index={index}>
			{({ innerRef, draggableProps, dragHandleProps }) => (
				<CardContainer
					ref={innerRef}
					onClick={onClick}
					{...draggableProps}
					{...dragHandleProps}
					className={dateStatus}
				>
					<Row justify="space-between" style={{ height: '40px' }}>
						<Col span={21}>
							<Title level={5} style={{ fontSize: '14px' }} ellipsis={true}>
								{I18n.get(actionPlan.title)}
							</Title>
						</Col>
						<Col span={2}>
							<DeleteCardBtn onClick={onDelete}>
								<DeleteOutlined />
							</DeleteCardBtn>
						</Col>
					</Row>
					{actionPlan.score ? (
						<Row justify="space-between" align="middle" gutter={[10, 0]}>
							<Col span={8} xl={8} xxl={10}>
								<Progress percent={calcProgress(actionPlan.tasks)} size="small" />
							</Col>
							<Col span={6} xl={6} xxl={6} style={{ textAlign: 'end' }}>
								<TagRisk
									brightcolors={riskCalc(actionPlan.score)}
									color={riskColors[riskCalc(actionPlan.score) - 1]}
								>
									<Tooltip title={I18n.get(`${riskLevels[riskCalc(actionPlan.score)]}`)}>
										<Text ellipsis={true}>
											{I18n.get('Score : ')}
											{actionPlan.score}
										</Text>
									</Tooltip>
								</TagRisk>
							</Col>
							<Col span={8} xl={9} xxl={8}>
								<CardDate className={dateStatus}>
									{I18n.get('Deadline : ')}
									{deadlineDate.format('DD/MM/YY')}
								</CardDate>
							</Col>
						</Row>
					) : (
						<Row justify="space-between" align="middle" gutter={[10, 0]}>
							<Col span={12} xl={15} xxl={14}>
								<Progress percent={calcProgress(actionPlan.tasks)} size="small" />
							</Col>
							<Col span={12} xl={9} xxl={8}>
								<CardDate className={dateStatus}>
									{I18n.get('Deadline : ')}
									{deadlineDate.format('DD/MM/YY')}
								</CardDate>
							</Col>
						</Row>
					)}
				</CardContainer>
			)}
		</Draggable>
	);
}
