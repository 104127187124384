import React from 'react';

interface PersonalisedReportSVGProps {
	width: number;
	height: number;
	margin?: string;
}

export const PersonalisedReportHoveredSVG: React.FC<PersonalisedReportSVGProps> = ({ height, width, margin = 0 }) => (
	<span style={{ display: 'flex', margin: margin }}>
		<svg width={width} height={height} viewBox="0 0 78 55" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M76.357.228H27.76c-1.593 0-2.88 1.308-2.88 2.927v21.59H3.643c-1.592 0-2.88 1.309-2.88 2.928v24.883c0 1.62 1.288 2.928 2.88 2.928H52.24c1.593 0 2.88-1.308 2.88-2.928v-21.59h21.238c1.593 0 2.88-1.308 2.88-2.927V3.155c0-1.619-1.287-2.927-2.88-2.927zM48.999 49.263H31.001V30.966h17.998v18.297zm24.118-24.517H55.119V6.448h17.998v18.297z"
				fill="#fff"
			/>
		</svg>
	</span>
);
