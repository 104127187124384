import React, { useCallback } from 'react';
import { I18n } from '@aws-amplify/core';
import { UseQueryResult } from '@tanstack/react-query';

import { Hooks } from './hooks';
import { Charts } from './Charts';
import { Parameters } from './Parameters';
import { ReportDTO } from '../../../types';
import { ErrorMessage } from './ErrorMessage';
import { usePersons } from '../Persons/hooks';
import Spinner from '@/components/Layout/Spinner';
import { useRebaReportContext } from '../../../context';
import { ReportTemplate } from '@/components/views/Report/ReportTemplate';

const { useScore, useScorePerSecond, useScoreParts, useRebaResults } = Hooks;

interface ResultsProps {
	file: any;
	report: UseQueryResult<ReportDTO, Error>;
	sectors?: any;
	onDownloadPDF: any;
	isLoadingPDF: any;
	onChangeComment?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

export const Results: React.FC<ResultsProps> = ({
	file,
	report,
	onDownloadPDF,
	isLoadingPDF,
	onChangeComment
}: any) => {
	const { base_parameters } = useRebaReportContext();
	const { data } = useRebaResults(base_parameters);
	const score = useScore(base_parameters);
	const persons = usePersons(base_parameters);
	const scoreParts = useScoreParts(base_parameters);
	const scorePerSecond = useScorePerSecond(base_parameters);

	function getPersonSelect(file: any, persons = []) {
		const { selected_person } = file || {};
		const [person] = persons.filter((item: any) => item.person_id === selected_person);
		return person ?? {};
	}

	const selectPerson = getPersonSelect(file.data, persons.data);

	const isError = score.isError || scoreParts.isError || scorePerSecond.isError;
	const isLoading = score.isLoading || scoreParts.isLoading || scorePerSecond.isLoading;

	const onRetry = useCallback(() => {
		window.location.reload();
	}, []);

	const { repetition, coupling, force } = data?.reba || {};

	const sections = [
		{
			title: I18n.get('Parameters'),
			component: <Parameters repetition={repetition} coupling={coupling} force={force} />
		},
		{
			title: I18n.get('Analysis results'),
			component: (
				<Charts
					score={score.data}
					parts={scoreParts.data}
					selectPerson={selectPerson}
					scorePerSecond={scorePerSecond.data}
				/>
			)
		}
	];

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <ErrorMessage onRetry={onRetry} />;
	}

	return (
		<ReportTemplate
			sections={sections}
			fileData={file.data}
			isLoading={isLoading}
			title={I18n.get('REBA')}
			reportData={report.data}
			isLoadingPDF={isLoadingPDF}
			onDownloadPDF={onDownloadPDF}
			onChangeComment={onChangeComment}
		/>
	);
};
