import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form, Radio } from 'antd';

import { usePersons } from './hooks';
import { PersonCard } from './PersonCard';
import { WrapperRadioButton } from './styles';
import { Title } from '@/components/Typography';
import Spinner from '@/components/Layout/Spinner';
import { useRebaReportContext } from '../../../context';

export const Persons: React.FC = () => {
	const { base_parameters } = useRebaReportContext();
	const { isLoading, data, isError } = usePersons(base_parameters);

	if (isLoading) {
		return <Spinner />;
	}

	if (isError) {
		return <Title level={3}>Error</Title>;
	}

	return (
		<Row justify="center" gutter={[0, 20]}>
			<Col style={{ textAlign: 'center' }} span={24}>
				<Title level={5}>Identify people to be analyzed</Title>
			</Col>
			<Col span={24}>
				<Row justify={{ sm: 'center' }}>
					{data.length > 0 ? (
						<Col span={24}>
							<Form.Item
								name="selected_person"
								rules={[
									{
										required: true,
										message: ''
									}
								]}
							>
								<Radio.Group
									style={{
										display: 'flex',
										justifyContent: 'center'
									}}
								>
									{data.map((data: any) => (
										<Col span={6} key={data.id} style={{ textAlign: 'center' }}>
											<WrapperRadioButton value={data.person_id}>
												<PersonCard
													url={data.url}
													title={`${I18n.get('Person')} ${data.person_id}`}
													altImage={`${I18n.get('Person')} ${data.person_id}`}
													subTitle={`${I18n.get('Exposure')} ${data.exposure_percentage}%`}
												/>
											</WrapperRadioButton>
										</Col>
									))}
								</Radio.Group>
							</Form.Item>
						</Col>
					) : (
						<Col span={24}>
							<p style={{ textAlign: 'center' }}>
								{I18n.get('No person has been identified with more than 50% of accuracy.')}
								<br />
								{I18n.get('Please, record the video following the guidelines')}
							</p>
						</Col>
					)}
				</Row>
			</Col>
		</Row>
	);
};
