import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import Api from '@/services/api';
import { ActionPlan, ActionPlanTask, Response } from '@/types';
import { BaseContext } from '@/components/ui/Inputs/types/request';

export type GetActionPlanDTO = BaseContext & {
	file_id?: string;
	exposureId?: string;
	probabilityId?: string;
	consequenceId?: string;
	custom_report_sub_step_key_id: string;
	custom_report_step_key_setting_id: string;
};

export type ActionPlanTasks = Pick<ActionPlanTask, 'id' | 'description' | 'type' | 'is_completed'>;
export type ActionPlanResponse = Pick<
	ActionPlan,
	'id' | 'title' | 'description' | 'deadline' | 'responsible_user_id' | 'created_at'
>;
export type ActionPlanWithTaskResponse = ActionPlanResponse & {
	action_plan_task?: ActionPlanTasks[];
};

async function getActionPlan<T, Body>(payload: Body): Promise<T> {
	const url = '/action_plan/custom-report/sub-step-key/find-one';
	const { data } = await Api.get<T>(url, {
		params: payload
	});
	return data;
}

export function useGetActionPlan({
	file_id,
	company_id,
	exposureId,
	probabilityId,
	consequenceId,
	organization_id,
	custom_report_sub_step_key_id,
	custom_report_step_key_setting_id
}: GetActionPlanDTO) {
	const parameters = {
		file_id,
		company_id,
		organization_id,
		custom_report_sub_step_key_id,
		custom_report_step_key_setting_id
	};
	const actionPlan = useQuery<ActionPlanWithTaskResponse, AxiosError<Response>>(
		['personalised_report_sub_step_key_action_plan', parameters],
		() => getActionPlan<ActionPlanWithTaskResponse, GetActionPlanDTO>(parameters),
		{
			retry: 0,
			enabled:
				!!file_id &&
				!!exposureId &&
				!!company_id &&
				!!probabilityId &&
				!!consequenceId &&
				!!organization_id &&
				!!custom_report_sub_step_key_id &&
				!!custom_report_step_key_setting_id
		}
	);
	return {
		...actionPlan,
		data: actionPlan.data ?? ({} as ActionPlanWithTaskResponse)
	};
}
