import Api from '@/services/api';
import { useMutation } from '@tanstack/react-query';

interface ChangeStatusUserDTO {
	id: string;
	status: boolean;
}

interface ChangeStatusUserSuccess {
	message: string;
}

async function changeStatusUser({ id, status }: ChangeStatusUserDTO): Promise<ChangeStatusUserSuccess> {
	const url = '/user_management/user/update-status';
	const { data } = await Api.put(url, { id, status });
	return data;
}

export const useChangeStatusUser = () => {
	return useMutation(changeStatusUser);
};
