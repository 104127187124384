import { Form } from 'antd';
import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

async function getSectors(parameters: any) {
	const { organization_id, company_id } = parameters;
	const url = `/sector/${organization_id}/${company_id}`;
	const { data } = await Api.get(url);
	return data;
}

async function getWorkstations(parameters: any) {
	const { organization_id, company_id, sector_id } = parameters;
	const url = `/workstation/${organization_id}/${company_id}/${sector_id}`;
	const { data } = await Api.get(url);
	return data;
}

const { useFormInstance, useWatch } = Form;

export const useInformations = (parameters: any) => {
	const form = useFormInstance();
	const sector_id = useWatch('sector_id', form);

	const { organization_id, company_id } = parameters;

	const workstations_parameters = {
		organization_id,
		company_id,
		sector_id
	};

	const sectors = useQuery(['sectors', parameters], () => getSectors(parameters), {
		enabled: !!organization_id && !!company_id
	});

	const workstations = useQuery(
		['workstations', workstations_parameters],
		() => getWorkstations(workstations_parameters),
		{
			enabled: !!organization_id && !!company_id && !!sector_id
		}
	);

	return { sectors, workstations };
};
