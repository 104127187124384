import React from 'react';
import { Col, Image, Row } from 'antd';
import { I18n } from '@aws-amplify/core';

import pull_sustained_forceSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/pull_sustained_force.svg';
import push_sustained_forceSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/push_sustained_force.svg';

import { TaskNameMapper } from '../../../../hooks/types/response';
import { useLibertyMutualContext } from '../../../../context';
import { Body, CardContainer, Header } from '../styles';

interface SustainedForceCardProps {
	task_name: TaskNameMapper.Pull | TaskNameMapper.Push;
}

export const SustainedForceCard: React.FC<SustainedForceCardProps> = ({ task_name }) => {
	const {
		libertyMutualResult: { summary }
	} = useLibertyMutualContext();

	const sustainedForceSVGMapper = {
		[TaskNameMapper.Pull]: pull_sustained_forceSVG,
		[TaskNameMapper.Push]: push_sustained_forceSVG
	};

	return (
		<CardContainer>
			<Header>{I18n.get('Sustained force')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0 0' }}>
					<Col>
						<Row justify="space-between">
							<Col xs={24}>
								<Image
									preview={false}
									draggable={false}
									alt={I18n.get(`Sustained force: ${summary.sustained_force}`)}
									src={sustainedForceSVGMapper[task_name]}
									width="100px"
								/>
							</Col>
							<Col xs={24} style={{ padding: '0.75rem 0', fontSize: '1.1rem' }}>
								{I18n.get(summary.sustained_force)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Body>
		</CardContainer>
	);
};
