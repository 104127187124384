import React from 'react';
import { I18n } from '@aws-amplify/core';
import { Col, Image, Row } from 'antd';

import pull_initial_forceSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/pull_initial_force.svg';
import push_initial_forceSVG from '@/assets/svg/ergonomic_tools/liberty_mutual/result/push_initial_force.svg';

import { TaskNameMapper } from '../../../../hooks/types/response';
import { useLibertyMutualContext } from '../../../../context';
import { Body, CardContainer, Header } from '../styles';

interface InitialForceCardProps {
	task_name: TaskNameMapper.Pull | TaskNameMapper.Push;
}

export const InitialForceCard: React.FC<InitialForceCardProps> = ({ task_name }) => {
	const {
		libertyMutualResult: { summary }
	} = useLibertyMutualContext();

	const initialForceSVGMapper = {
		[TaskNameMapper.Pull]: pull_initial_forceSVG,
		[TaskNameMapper.Push]: push_initial_forceSVG
	};

	return (
		<CardContainer>
			<Header>{I18n.get('Initial force')}</Header>
			<Body>
				<Row justify="center" style={{ padding: '1rem 0 0 0' }}>
					<Col>
						<Row justify="space-between">
							<Col xs={24}>
								<Image
									preview={false}
									draggable={false}
									alt={I18n.get(`Initial force: ${summary.initial_force}`)}
									src={initialForceSVGMapper[task_name]}
									width="60px"
								/>
							</Col>
							<Col xs={24} style={{ padding: '0.75rem 0', fontSize: '1.1rem' }}>
								{I18n.get(summary.initial_force)}
							</Col>
						</Row>
					</Col>
				</Row>
			</Body>
		</CardContainer>
	);
};
