import React, { useState, useEffect } from 'react';
import { useGetAllOrganizationsWithUserPermissions } from '@/hooks';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Form, Row, Skeleton } from 'antd';

import { ListCollapse } from '../components/ListCollapse';
import * as S from './styles';

interface PermissionsProps {
	onChange?: (value: OrganizationCompanyMap) => void;
	value?: OrganizationCompanyMap;
	onFormChange?: () => void;
	isCreating: boolean;
}

export function Permissions({ value = {}, onChange, isCreating = false, onFormChange }: PermissionsProps) {
	const form = Form.useFormInstance();
	const userId = form.getFieldValue('user_id');

	const permissionsFilter = Form.useWatch('permissionsFilter', form);

	const [offset, setOffset] = useState<number>(0);

	const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
		useGetAllOrganizationsWithUserPermissions({
			name: permissionsFilter?.length > 2 ? permissionsFilter : '',
			offset,
			limit: 7,
			user_id: userId,
			isCreating
		});

	async function loadMoreData() {
		if (hasNextPage) {
			await fetchNextPage();
			setOffset(offset + 1);
		}
	}

	useEffect(() => {
		if (data.pages && data.pages.length > 0) {
			if (!form.getFieldValue('permissions')) {
				const newPermissions = data.pages[0].user_organization_permission.organization_permission;
				form.setFieldValue('permissions', newPermissions);
			}
			setOffset(data.pages.length - 1);
		}
	}, [data?.pages, form]);

	if (isLoading) {
		return (
			<S.InfiniteColumn>
				<Row style={{ marginBottom: '12px' }}>
					<Skeleton.Input block active />
				</Row>
				<Row style={{ marginBottom: '12px' }}>
					<Skeleton.Input block active />
				</Row>
				<Row style={{ marginBottom: '12px' }}>
					<Skeleton.Input block active />
				</Row>
				<Row style={{ marginBottom: '12px' }}>
					<Skeleton.Input block active />
				</Row>
				<Row style={{ marginBottom: '12px' }}>
					<Skeleton.Input block active />
				</Row>
			</S.InfiniteColumn>
		);
	}

	return (
		<>
			<S.InfiniteColumn id={`userPermissionsList-${userId}`}>
				{isSuccess &&
					data.pages?.map(({ master_permissions, total, id }) => (
						<InfiniteScroll
							key={id}
							dataLength={total}
							next={loadMoreData}
							hasMore={!isFetchingNextPage && hasNextPage !== undefined ? hasNextPage : true}
							loader={<></>}
							scrollableTarget={`userPermissionsList-${userId}`}
							style={{ overflowX: 'hidden' }}
						>
							<ListCollapse
								data={master_permissions}
								value={value}
								onChange={onChange}
								onFormChange={onFormChange}
							/>
						</InfiniteScroll>
					))}
			</S.InfiniteColumn>
		</>
	);
}
