import { DefaultTheme } from 'styled-components';

export const lightTheme: DefaultTheme = {
	title: 'light',
	colors: {
		primary: '#333',
		secondary: '#C62E65',
		background: '#fff',
		text: '#fff'
	}
};
