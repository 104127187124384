import React from 'react';
import { Input } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItem } from '../../../components/FormItem';

export const CelluleName: React.FC = () => (
	<FormItem namePath={['basic_information', 'cellule_name']} title="Cellule" fontSize="1rem" isRequired={false}>
		<Input size="large" placeholder={I18n.get('Cellule')} />
	</FormItem>
);
