import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';
import { Select, Row, Col, DatePicker, Form, Descriptions } from 'antd';

import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import hooks from '@/components/Settings/Organizations/hooks';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';
import { useApplicationContext } from '@/context/Application';

const { useGetWorkstations } = hooks;
const { useFormInstance } = Form;

export const Information = ({ sectors, sectorId, file }) => {
	const form = useFormInstance();

	const { organization, company } = useApplicationContext();
	const workstations = useGetWorkstations(organization.id, company.id, sectorId);

	const [inicialSector, setInicialSector] = useState(form.getFieldValue(['sector_id']));
	useEffect(() => {
		if (sectorId && sectorId !== inicialSector) {
			form.resetFields(['workstation_id']);
			form.setFields([
				{
					name: ['workstation_id'],
					value: undefined
				}
			]);
			setInicialSector('');
		}
	}, [sectorId]);

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col sm={18}>
				<Row>
					<Col sm={24}>
						<label>{I18n.get('Enter the data related to the selected video below')}</label>
					</Col>
				</Row>
				<Row align="middle">
					<Col sm={8} style={{ marginTop: '20px', paddingLeft: '60px' }}>
						<Descriptions column={1} size="small">
							<Descriptions.Item
								label={I18n.get('File')}
								contentStyle={{ fontWeight: 'bold' }}
								labelStyle={{ fontWeight: 'bold' }}
							>
								{file?.data.original_name}
							</Descriptions.Item>
							<Descriptions.Item label={I18n.get('Date')}>
								{moment(file?.data.createdAt).format('L')}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row gutter={[10, 20]} style={{ marginTop: '10px', marginLeft: '20px' }}>
					<Col sm={12}>
						<Form.Item name="organization_id" label={I18n.get('Company')} labelCol={{ span: 24 }}>
							<Select disabled={true} placeholder={I18n.get('Company')}>
								<Select.Option value={organization.id}>{organization.name}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="sector_id"
							label={I18n.get('Sector')}
							labelCol={{ span: 24 }}
							rules={[
								{
									required: true,
									message: I18n.get('Select the sector')
								}
							]}
						>
							<SectorSelect sectors={sectors} loading={sectors.isLoading} disabled={sectors.isLoading} />
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							label={I18n.get('Workstation')}
							name="workstation_id"
							rules={[
								{
									required: true,
									message: I18n.get('Enter the workstation')
								}
							]}
							labelCol={{ span: 24 }}
						>
							<WorkstationSelect
								workstations={workstations?.data}
								sectorId={sectorId}
								loading={sectors.isLoading}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							label={I18n.get('Collection date')}
							name="collection_date"
							rules={[
								{
									required: true,
									message: I18n.get('Insert the date of collection')
								}
							]}
							labelCol={{ span: 24 }}
						>
							<DatePicker
								format={'L'}
								placeholder={I18n.get('Collection date')}
								style={{ width: '100%' }}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
