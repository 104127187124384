import styled, { css } from 'styled-components';

const colorSelected = '#3856f8';

const borderSelected = css`
	box-shadow: lightgrey 2px 2px 10px;
	border: 1px solid ${colorSelected};
`;

const colorTitleSelected = css`
	color: ${colorSelected};
`;

export const Title = styled.strong`
	margin: 0px;
	font-size: 15px;
	${(props) => props.selected && colorTitleSelected}
`;

export const Container = styled.div`
	border-radius: 8px;
	border: 1px solid #ddd;
	background: #fafafa;
	padding: 15px;
	margin-bottom: 0;

	${(props) => props.selected && borderSelected}
`;

export const Body = styled.div`
	border-top: none;
	opacity: ${(props) => (props.open ? '1' : '0')};
	max-height: ${(props) => (props.open ? '100%' : '0')};
	overflow: hidden;
	padding: ${(props) => (props.open ? '15px' : '0 15px')};
	transition: all 0.3s;
`;
