import React from 'react';
import { Col } from 'antd';
import moment from 'moment';
import { I18n } from '@aws-amplify/core';

import { Topic, Value } from '../styles';
import { useLibertyMutualContext } from '../../../../context';

export const CollectionDate: React.FC = () => {
	const {
		libertyMutualResult: {
			informations: { collection_date }
		}
	} = useLibertyMutualContext();

	return (
		<Col xs={24}>
			<Topic>{I18n.get('Collection date')}:</Topic>
			<Value>{moment(collection_date).format('L')}</Value>
		</Col>
	);
};
