import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

const ENDPOINT = '/ergonomic-tool/reba';

function mountingUrlParams(path: any, payload: any) {
	const { organization_id, company_id, file_id } = payload;

	const queryString = `${ENDPOINT}/${path}/${file_id}?`;
	const urlParams = new URLSearchParams(queryString);

	urlParams.set('organization_id', organization_id);
	urlParams.set('company_id', company_id);

	return queryString + urlParams.toString();
}

async function getImagesPersons(payload: any) {
	const url = mountingUrlParams('/images', payload);
	const { data } = await Api.get(url);
	return data;
}

export const usePersons = (parameters: any) => {
	const { organization_id, company_id, file_id} = parameters;
	const persons = useQuery(['reba-person-images', parameters], () => getImagesPersons(parameters), {
		enabled:!!organization_id && !!company_id && !!file_id
	});
	return persons;
};
