import styled from 'styled-components';

export const Label = styled.span`
	margin-right: 8px;
	font-weight: bold;
`;

export const ScoresContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

export const BarContainer = styled.div`
	margin-bottom: 10px;
	min-width: 600px;

	@media (max-width: 1460px) {
		min-width: 450px;
	}
`;
