import React from 'react';
import { Row, Col } from 'antd';
import { Line } from 'react-chartjs-2';
import { I18n } from '@aws-amplify/core';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

export const LineChartCollection = ({ selected, data = {}, settings, reportType = 'Angle' }) => {
	let annotations;

	if (!settings) {
		settings = [
			{
				min: 0,
				max: 2,
				color: 'rgba(44, 200, 86, 0.3)'
			},
			{
				min: 2,
				max: 4,
				color: 'rgba(255, 222, 49, 0.3)'
			},
			{
				min: 4,
				max: 8,
				color: 'rgba(247, 138, 56, 0.3)'
			},
			{
				min: 8,
				max: 11,
				color: 'rgba(231, 65, 80, 0.3)'
			},
			{
				min: 11,
				max: 13,
				color: 'rgba(153, 102, 255, 0.3)'
			}
		];
	}

	const options_bodyparts = {
		neck: { min: -25, max: 80 },
		trunk: { min: -65, max: 185 },
		left_knee: { min: -5, max: 120 },
		right_knee: { min: -5, max: 120 },
		left_lower_arm: { min: -5, max: 185 },
		right_lower_arm: { min: -5, max: 185 },
		left_upper_arm: { min: -45, max: 185 },
		right_upper_arm: { min: -45, max: 185 }
	};

	const scaleTicks = options_bodyparts[selected] || { min: 0, max: 13 };

	if (settings && settings.length > 0) {
		settings.forEach((item, index) => {
			annotations = {
				...annotations,
				[`line${index + 1}`]: {
					type: 'box',
					backgroundColor: item.color,
					borderWidth: 0,
					yMax: item.max,
					yMin: item.min,
					xMin: -0.5
				}
			};
		});
	}

	const options = {
		responsive: true,
		animation: false,
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					usePointStyle: true
				}
			},
			title: {
				display: false
			},
			datalabels: {
				display: false
			},
			annotation: {
				drawTime: 'beforeDraw',
				annotations: annotations
			}
		},
		scales: {
			x: {
				title: {
					display: true,
					text: I18n.get('Time in seconds (s)')
				}
			},
			y: {
				suggestedMin: 0,
				ticks: {
					precision: 0
				},
				title: {
					display: true,
					text: I18n.get(reportType)
				},
				...scaleTicks
			}
		}
	};

	const labels = Object.keys(data).map((item) => item);

	const default_config = {
		label: I18n.get('Angle by time'),
		borderWidth: 3,
		pointRadius: 0,
		lineTension: 0.3,
		pointStyle: 'line',
		borderColor: 'rgb(53, 162, 235)',
		backgroundColor: 'rgba(53, 162, 235, 0.5)',
		data: Object.keys(data).map((item) => data[item].angle)
	};

	const config_score_left = {
		label: I18n.get('Score left'),
		data: Object.keys(data).map((item) => data[item].score_left),
		borderColor: 'rgb(255, 99, 132)',
		backgroundColor: 'rgba(255, 99, 132, 0.5)'
	};
	const config_score_right = {
		label: I18n.get('Score right'),
		data: Object.keys(data).map((item) => data[item].score_right),
		borderColor: 'rgb(53, 162, 235)',
		backgroundColor: 'rgba(53, 162, 235, 0.5)'
	};

	const datasets = selected === 'score_seconds' ? [config_score_left, config_score_right] : [default_config];

	const payload = { labels, datasets };

	return (
		<Row justify="center">
			<Col sm={20}>
				<Line height={90} data={payload} options={options} plugins={annotationPlugin} />
			</Col>
		</Row>
	);
};
