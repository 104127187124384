import React from 'react';
import { Row, Col } from 'antd';

import { VideoContainer } from '@/components/VideoContainer';
import { useLibertyMutualContext } from '../../context';
import { Text, Title } from '@/components/Typography';
import { CollectionDate } from './CollectionDate';
import { SystemOfUnits } from './SystemsOfUnits';
import { Organizations } from './Organizations';
import { Workstations } from './Workstations';
import { Companies } from './Companies';
import { Sectors } from './Sectors';

export function Informations() {
	const { libertyMutual, file_id } = useLibertyMutualContext();

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col xs={20}>
				<Row align="middle">
					<Col xs={12}>
						<Title level={4}>General informations</Title>
					</Col>
				</Row>
				<Row justify="center">
					<Col xs={24} style={{ marginBottom: '20px' }}>
						<Text>Enter the data related to the selected video below</Text>
					</Col>
				</Row>
				<Row justify="center">
					<VideoContainer
						fileId={file_id}
						originalName={libertyMutual.original_name}
						uploadedDate={libertyMutual.uploaded_date}
					/>
				</Row>
				<Row justify="center">
					<Col xs={12} style={{ marginTop: '1rem' }}>
						<Row gutter={[0, 20]}>
							<Organizations />
							<Companies />
							<Sectors />
							<Workstations />
							<CollectionDate />
							<SystemOfUnits />
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
