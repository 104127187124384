import { useQuery } from '@tanstack/react-query';
import Api from '@/services/api';

import { Evaluator } from '@/types';
import { EvaluatorsRequest } from '@/components/ui/Inputs/types/request';

async function getEvaluators(params: EvaluatorsRequest): Promise<Evaluator[]> {
	const url = '/evaluator/find-all';
	const { data } = await Api.get<Evaluator[]>(url, {
		params
	});
	return data;
}

export function useEvaluators({ organization_id, company_id }: EvaluatorsRequest) {
	const enabled = !!organization_id && !!company_id;

	const evaluators = useQuery(
		['evaluators', { organization_id, company_id }],
		() => getEvaluators({ organization_id, company_id }),
		{
			enabled
		}
	);
	return evaluators;
}
