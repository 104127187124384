import React, { useState, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Row, Col, Form } from 'antd';

import hooks from '@/components/views/PreliminaryAnalysis/hooks';
import { Title, Paragraph, Text } from '@/components/Typography';
import { useApplicationContext } from '@/context/Application';
import { usePreliminaryAnalysis } from '@/components/views/PreliminaryAnalysis/context';

import { SelectOption } from '../_components/SelectOption';
import { InjuriesGroup } from '../_components/InjuriesGroup';
import { CollapseCheck } from '../_components/CollapseCheck';
import { RiskLevelCard } from '../_components/RiskLevelCard';

import { baseFormName, stepDescription, injuriesKeys } from '../contants';

const { useRiskLevel } = hooks;
const { useFormInstance } = Form;

export function PsychosocialInfluence() {
	const { organization, company } = useApplicationContext();
	const { file, getLevelRiskFields } = usePreliminaryAnalysis();

	const form = useFormInstance();
	const formName = ['psychosocial_influence'];

	const { isLoading, data, isError, ...riskLevel } = useRiskLevel(organization?.id, company?.id, file?.id);

	const [levelRisk, setLevelRisk] = useState({
		step: '',
		consequence_id: '',
		probability_id: '',
		exhibition_id: ''
	});

	useEffect(() => {
		const { step, consequence_id, probability_id, exhibition_id } = levelRisk;

		if (step && consequence_id && probability_id && exhibition_id) {
			const formatStep = Array.isArray(step) ? [...formName, ...step] : [...formName, step];

			const payload = {
				step: formatStep,
				consequence_id,
				probability_id,
				exhibition_id
			};

			riskLevel.mutate(payload, {
				onSuccess: (data) => {
					form.setFields([
						{
							name: [baseFormName, ...formatStep, 'result'],
							value: data?.risk_level
						}
					]);
				}
			});
		}
	}, [levelRisk.step, levelRisk.consequence_id, levelRisk.probability_id, levelRisk.exhibition_id]);

	function updateLevelRisk(step, attribute, value) {
		const stepName = Array.isArray(step) ? [...step] : [step];
		const { consequence, exhibition, probability } = getLevelRiskFields(form, baseFormName, formName, stepName);

		if (!levelRisk.step && !levelRisk.consequence_id && !levelRisk.exhibition_id && !levelRisk.probability_id) {
			const options = ['consequence_id', 'exhibition_id', 'probability_id'];
			const forms = options
				.filter((item) => item !== attribute)
				.map((value) => ({
					name: [baseFormName, ...formName, step, value],
					value: undefined
				}));

			form.setFields(forms);
		}

		setLevelRisk({
			exhibition_id: exhibition,
			consequence_id: consequence,
			probability_id: probability,
			step,
			[attribute]: value
		});
	}

	const {
		fatigue_complaints,
		absenteeism,
		musculoskeletal_complaints,
		complaints_of_irritability,
		non_conforming_products,
		unexcused_absences
	} = injuriesKeys;

	function onResetFields(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'consequence_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'probability_id'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'exhibition_id'],
				value: undefined
			},

			{
				name: [baseFormName, ...formName, step, 'injuries'],
				value: undefined
			},
			{
				name: [baseFormName, ...formName, step, 'result'],
				value: undefined
			}
		]);
	}

	function setCheckedCollapse(step) {
		form.setFields([
			{
				name: [baseFormName, ...formName, step, 'checked'],
				value: true
			}
		]);
	}

	return (
		<Row gutter={[0, 10]}>
			<Col span={16} offset={2}>
				<Title level={4} align="start">
					Influence of psychosocial factors
				</Title>
			</Col>
			<Col span={16} offset={2}>
				<Paragraph>{stepDescription}</Paragraph>
			</Col>
			<Col span={16} offset={2}>
				<Row gutter={[10, 10]}>
					{/* activity_with_mental_overload */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'activity_with_mental_overload', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('activity_with_mental_overload');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Activity with mental overload or underload">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'activity_with_mental_overload',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'activity_with_mental_overload',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('activity_with_mental_overload')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'activity_with_mental_overload',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'activity_with_mental_overload',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('activity_with_mental_overload');
															updateLevelRisk(
																'activity_with_mental_overload',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'activity_with_mental_overload',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'activity_with_mental_overload',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('activity_with_mental_overload');
															updateLevelRisk(
																'activity_with_mental_overload',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'activity_with_mental_overload',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'activity_with_mental_overload',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('activity_with_mental_overload');
															updateLevelRisk(
																'activity_with_mental_overload',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'activity_with_mental_overload',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'activity_with_mental_overload']}
												step={[...formName, 'activity_with_mental_overload']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* working_with_time_pressure */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'working_with_time_pressure', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('working_with_time_pressure');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Work pace with time pressure and high demands">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'working_with_time_pressure', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'working_with_time_pressure',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('working_with_time_pressure')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'working_with_time_pressure',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'working_with_time_pressure',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('working_with_time_pressure');
															updateLevelRisk(
																'working_with_time_pressure',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'working_with_time_pressure',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'working_with_time_pressure',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('working_with_time_pressure');
															updateLevelRisk(
																'working_with_time_pressure',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'working_with_time_pressure',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'working_with_time_pressure',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('working_with_time_pressure');
															updateLevelRisk(
																'working_with_time_pressure',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[baseFormName, ...formName, 'working_with_time_pressure', 'result']}
										>
											<RiskLevelCard
												key={[...formName, 'working_with_time_pressure']}
												step={[...formName, 'working_with_time_pressure']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* work_related_stress */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'work_related_stress', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('work_related_stress');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Presence of work-related stress">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'work_related_stress', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'work_related_stress',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('work_related_stress')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'work_related_stress',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'work_related_stress',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('work_related_stress');
															updateLevelRisk(
																'work_related_stress',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'work_related_stress',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'work_related_stress',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('work_related_stress');
															updateLevelRisk(
																'work_related_stress',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'work_related_stress',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'work_related_stress',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('work_related_stress');
															updateLevelRisk(
																'work_related_stress',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item name={[baseFormName, ...formName, 'work_related_stress', 'result']}>
											<RiskLevelCard
												key={[...formName, 'work_related_stress']}
												step={[...formName, 'work_related_stress']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* low_job_satisfaction */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'low_job_satisfaction', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('low_job_satisfaction');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Reports of low job satisfaction">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'low_job_satisfaction', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'low_job_satisfaction',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('low_job_satisfaction')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'low_job_satisfaction',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'low_job_satisfaction',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('low_job_satisfaction');
															updateLevelRisk(
																'low_job_satisfaction',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'low_job_satisfaction',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'low_job_satisfaction',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('low_job_satisfaction');
															updateLevelRisk(
																'low_job_satisfaction',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'low_job_satisfaction',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'low_job_satisfaction',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('low_job_satisfaction');
															updateLevelRisk(
																'low_job_satisfaction',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item name={[baseFormName, ...formName, 'low_job_satisfaction', 'result']}>
											<RiskLevelCard
												key={[...formName, 'low_job_satisfaction']}
												step={[...formName, 'low_job_satisfaction']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/*  activity_with_lack_of_autonomy */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'activity_with_lack_of_autonomy', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('activity_with_lack_of_autonomy');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Activity with lack of autonomy (low influence, low control)">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[
												baseFormName,
												...formName,
												'activity_with_lack_of_autonomy',
												'injuries'
											]}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'activity_with_lack_of_autonomy',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('activity_with_lack_of_autonomy')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'activity_with_lack_of_autonomy',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'activity_with_lack_of_autonomy',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('activity_with_lack_of_autonomy');
															updateLevelRisk(
																'activity_with_lack_of_autonomy',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'activity_with_lack_of_autonomy',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'activity_with_lack_of_autonomy',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('activity_with_lack_of_autonomy');
															updateLevelRisk(
																'activity_with_lack_of_autonomy',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'activity_with_lack_of_autonomy',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'activity_with_lack_of_autonomy',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('activity_with_lack_of_autonomy');
															updateLevelRisk(
																'activity_with_lack_of_autonomy',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item
											name={[
												baseFormName,
												...formName,
												'activity_with_lack_of_autonomy',
												'result'
											]}
										>
											<RiskLevelCard
												key={[...formName, 'activity_with_lack_of_autonomy']}
												step={[...formName, 'activity_with_lack_of_autonomy']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
					{/* low_social_support */}
					<Col span={24}>
						<Form.Item
							name={[baseFormName, ...formName, 'low_social_support', 'checked']}
							rules={[
								{
									validator: (_, value) => {
										if (!value) {
											onResetFields('low_social_support');
										}

										return Promise.resolve();
									}
								}
							]}
						>
							<CollapseCheck title="Low social support">
								<Row gutter={[0, 28]} style={{ padding: '20px' }}>
									<Col span={12}>
										<Text strong>Possible injuries</Text>
										<Form.Item
											style={{ paddingTop: '10px' }}
											name={[baseFormName, ...formName, 'low_social_support', 'injuries']}
											rules={[
												({ getFieldValue }) => ({
													validator(_, value) {
														const checkBox = getFieldValue([
															baseFormName,
															...formName,
															'low_social_support',
															'checked'
														]);

														if ((checkBox && !value) || value?.length === 0) {
															return Promise.reject(I18n.get('Select the injuries'));
														}

														return Promise.resolve();
													}
												})
											]}
										>
											<InjuriesGroup
												onChange={() => setCheckedCollapse('low_social_support')}
												options={[
													fatigue_complaints,
													absenteeism,
													musculoskeletal_complaints,
													complaints_of_irritability,
													non_conforming_products,
													unexcused_absences
												]}
											/>
										</Form.Item>
									</Col>
									<Col span={12}>
										<Row gutter={[0, 20]}>
											<Col span={24}>
												<Text strong>Consequence</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'low_social_support',
														'consequence_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'low_social_support',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the consequence')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ConsequenceSelect
														onChange={(value) => {
															setCheckedCollapse('low_social_support');
															updateLevelRisk(
																'low_social_support',
																'consequence_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Probability</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'low_social_support',
														'probability_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'low_social_support',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the probability')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ProbabilitySelect
														onChange={(value) => {
															setCheckedCollapse('low_social_support');
															updateLevelRisk(
																'low_social_support',
																'probability_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
											<Col span={24}>
												<Text strong>Exposure</Text>
												<Form.Item
													style={{ paddingTop: '10px' }}
													name={[
														baseFormName,
														...formName,
														'low_social_support',
														'exhibition_id'
													]}
													rules={[
														({ getFieldValue }) => ({
															validator(_, value) {
																const checkBox = getFieldValue([
																	baseFormName,
																	...formName,
																	'low_social_support',
																	'checked'
																]);

																if (checkBox && !value) {
																	return Promise.reject(
																		I18n.get('Select the exhibition')
																	);
																}

																return Promise.resolve();
															}
														})
													]}
												>
													<SelectOption.ExhibitionSelect
														onChange={(value) => {
															setCheckedCollapse('low_social_support');
															updateLevelRisk(
																'low_social_support',
																'exhibition_id',
																value
															);
														}}
													/>
												</Form.Item>
											</Col>
										</Row>
									</Col>
									<Col span={12} offset={12}>
										<Form.Item name={[baseFormName, ...formName, 'low_social_support', 'result']}>
											<RiskLevelCard
												key={[...formName, 'low_social_support']}
												step={[...formName, 'low_social_support']}
											/>
										</Form.Item>
									</Col>
								</Row>
							</CollapseCheck>
						</Form.Item>
					</Col>
				</Row>
			</Col>
		</Row>
	);
}
