import React from 'react';
import { Spin } from 'antd';
import { I18n } from '@aws-amplify/core';
import { LoadingOutlined } from '@ant-design/icons';
import { Score } from './Score';
import { Container, MessageError } from './styles';

const antIcon = <LoadingOutlined style={{ fontSize: '1rem' }} spin />;

interface AverageProps {
	isLoading: boolean;
	data: number;
	isError: boolean;
}

export const Average: React.FC<AverageProps> = ({ isLoading = false, data, isError = false }) => (
	<Container>
		<h2>{I18n.get('Average exposure of the workplace')}:</h2>
		{isLoading && <Spin indicator={antIcon} />}
		{data && <Score result={data} />}
		{isError && <MessageError>{I18n.get('Failed to fetch data')}</MessageError>}
	</Container>
);
