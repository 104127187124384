import React, { useContext, useEffect, useState } from 'react';
import { Select, Row, Col, DatePicker, Form, Descriptions, Typography } from 'antd';
import { I18n } from '@aws-amplify/core';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { AngleTimeContext } from './context';
import hooks from '@/components/Settings/Organizations/hooks';
import { SectorSelect } from '@/components/ui/Inputs/SectorSelect';
import { WorkstationSelect } from '@/components/ui/Inputs/WorkstationSelect';

const { useFormInstance } = Form;
const { useGetWorkstations } = hooks;

export const Information = ({ sectors, riskRanges, sectorId }) => {
	const form = useFormInstance();
	const { organizationId, companyId, organizationName, file, selectRangeRisk } = useContext(AngleTimeContext);
	const workstations = useGetWorkstations(organizationId, companyId, sectorId);
	const [inicialSector, setInicialSector] = useState(form.getFieldValue(['sector_id']));

	useEffect(() => {
		if (sectorId && sectorId !== inicialSector) {
			form.resetFields(['workstation_id']);
			form.setFields([
				{
					name: ['workstation_id'],
					value: undefined
				}
			]);
			setInicialSector('');
		}
	}, [sectors]);

	return (
		<Row justify="center" gutter={[5, 20]}>
			<Col sm={18}>
				<Row>
					<Col sm={24}>
						<Typography.Text strong>
							{I18n.get('Enter the data related to the selected video below')}
						</Typography.Text>
					</Col>
				</Row>
				<Row align="middle">
					<Col sm={8} md={24} style={{ marginTop: '20px' }}>
						<Descriptions column={1} size="small">
							<Descriptions.Item label={I18n.get('File')}>{file?.data.original_name}</Descriptions.Item>
							<Descriptions.Item label={I18n.get('Date')}>
								{moment(file?.data.created_at).format('L')}
							</Descriptions.Item>
						</Descriptions>
					</Col>
				</Row>
				<Row justify="center" gutter={[10, 20]} style={{ marginTop: '10px' }}>
					<Col sm={12}>
						<Form.Item name="organization_id" label={I18n.get('Company')}>
							<Select disabled={true} placeholder={I18n.get('Company')}>
								<Select.Option value={organizationId}>{organizationName}</Select.Option>
							</Select>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="sector_id"
							label={I18n.get('Sector')}
							rules={[
								{
									message: I18n.get('Select the sector'),
									required: true
								}
							]}
						>
							<SectorSelect sectors={sectors} loading={sectors.isLoading} disabled={sectors.isLoading} />
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="workstation_id"
							label={I18n.get('Workstation')}
							rules={[
								{
									message: I18n.get('Enter the workstation'),
									required: true
								}
							]}
						>
							<WorkstationSelect
								sectorId={sectorId}
								loading={sectors.isLoading}
								workstations={workstations?.data}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="collection_date"
							label={I18n.get('Collection date')}
							rules={[
								{
									message: I18n.get('Insert the date of collection'),
									required: true
								}
							]}
						>
							<DatePicker
								format="L"
								style={{ width: '100%' }}
								placeholder={I18n.get('Collection date')}
								disabledDate={(current) => current && current.valueOf() >= Date.now()}
							/>
						</Form.Item>
					</Col>
					<Col sm={12}>
						<Form.Item
							name="range_risk_id"
							style={{ margin: 0 }}
							label={I18n.get('Risk range')}
							rules={[
								{
									message: I18n.get('Select a risk band'),
									required: true
								}
							]}
						>
							<Select
								showSearch
								loading={riskRanges.isLoading}
								disabled={riskRanges.isLoading}
								placeholder={I18n.get('Risk range')}
								onChange={selectRangeRisk}
							>
								{riskRanges.data?.some((e) => e.standard) && (
									<Select.OptGroup label="Default">
										{riskRanges.data?.map(
											(item, key) =>
												item.standard && (
													<Select.Option key={key} value={item.id}>
														{item.name}
													</Select.Option>
												)
										)}
									</Select.OptGroup>
								)}
								{riskRanges.data?.some((e) => !e.standard) && (
									<Select.OptGroup label="Custom">
										{riskRanges.data?.map(
											(item, key) =>
												!item.standard && (
													<Select.Option key={key} value={item.id}>
														{item.name}
													</Select.Option>
												)
										)}
									</Select.OptGroup>
								)}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12} />
				</Row>
				<Row gutter={[10, 20]} style={{ marginTop: '10px' }}>
					<Col sm={12} style={{ display: 'flex', justifyContent: 'end' }}>
						<Link to="/settings/preferences">{I18n.get('Create risk range')}</Link>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};
