import React, { createContext, ReactNode, useContext, useEffect } from 'react';
import { I18n } from '@aws-amplify/core';
import { Form } from 'antd';
import moment from 'moment';

import * as Socket from '@/hooks/useSocket';
import { usePersonalisedReportContext } from '../../../../context';
import { useApplicationContext } from '@/context/Application';
import { AntdDropdownOption, DropdownOptions } from '../../../../context/types';
import { useGetActionPlan, useCalculateCustomReportStepKeyRisk } from '../hooks';
import { Context, ergonomicToolReportSocketMapper, Methods, States } from './types';
import { InformationsResponse, PersonalisedReportStepKeysResponse } from '../../../../hooks';

const { useFormInstance, useWatch } = Form;

type PersonalisedReportStepKeyProviderProps = {
	stepId: string;
	children: ReactNode;
	stepKeyFieldName: string[];
	stepKey: PersonalisedReportStepKeysResponse;
};

type teste =
	| Socket.RebaResponse
	| Socket.NioshResponse
	| Socket.KimPpResponse
	| Socket.KimMhoResponse
	| Socket.AngleTimeResponse
	| Socket.StrainIndexResponse
	| Socket.LibertyMutualResponse
	| null;

const PersonalisedReportStepKeyContext = createContext<Context>({} as Context);

export function PersonalisedReportStepKeyProvider({
	stepId,
	stepKey,
	children,
	stepKeyFieldName
}: Readonly<PersonalisedReportStepKeyProviderProps>) {
	const form = useFormInstance();
	const { company, organization } = useApplicationContext();
	const { personalisedReport, file_id } = usePersonalisedReportContext();
	const exposureId = useWatch([...stepKeyFieldName, 'exposure_id'], form);
	const consequenceId = useWatch([...stepKeyFieldName, 'consequence_id'], form);
	const probabilityId = useWatch([...stepKeyFieldName, 'probability_id'], form);

	function mapErgonomicTools(tools: Socket.IReportState, informations?: InformationsResponse): Socket.IReportState {
		if (!informations) {
			return {} as Socket.IReportState;
		}
		const { file_id } = informations;
		const ergonomicTool: Socket.IReportState = defineTools(tools, informations, file_id);
		return ergonomicTool;
	}

	function defineTools(
		tools: Socket.IReportState,
		informations: InformationsResponse,
		file_id: string | undefined
	): Socket.IReportState {
		const ergonomicTool = {} as Socket.IReportState;
		for (const tool in tools) {
			const typedTool = tool as Socket.Tools;
			const report = tools[typedTool];
			const ergonomicToolResponse: teste = informations[ergonomicToolReportSocketMapper[typedTool]];
			ergonomicTool[typedTool] = {
				status: toolExists(report, file_id, ergonomicToolResponse) ?? false,
				data: report.data ?? ergonomicToolResponse ?? undefined
			};
		}
		return ergonomicTool;
	}

	function toolExists(
		report: Socket.ReportEvent,
		file_id: string | undefined,
		ergonomicToolResponse: teste
	): boolean | undefined {
		return checkToolByFile(report, file_id) ?? fileHasTool(ergonomicToolResponse);
	}

	function checkToolByFile(tool: Socket.ReportEvent, file_id?: string) {
		const existingToolForFile = tool?.data?.file_id === file_id;
		if (!existingToolForFile) {
			return undefined;
		}
		return tool.status;
	}

	function fileHasTool(tool: Socket.ErgonomicToolReportResponse | null) {
		return !!tool?.id;
	}

	const {
		data: levelRisk,
		isLoading: gettingLevelRisk,
		isFetching: fetchingLevelRisk,
		isError: errorCalculatingRisk
	} = useCalculateCustomReportStepKeyRisk({
		step_key_id: stepKey.id,
		exposure_id: exposureId,
		company_id: company?.id,
		consequence_id: consequenceId,
		probability_id: probabilityId,
		organization_id: organization?.id,
		custom_report_score_scale_id: personalisedReport?.custom_report_score_scale_id
	});

	const {
		data: actionPlan,
		isFetching: fetchingActionPlan,
		isLoading: gettingActionPlan,
		isError: errorGettingActionPlan
	} = useGetActionPlan({
		file_id,
		exposureId,
		probabilityId,
		consequenceId,
		company_id: company.id,
		organization_id: organization.id,
		custom_report_step_key_setting_id: stepKey.custom_report_step_key_setting_id
	});

	useEffect(() => {
		const actionPlanFieldName = [...stepKeyFieldName, 'action_plans'];
		form.setFieldValue([...actionPlanFieldName, 'deadline'], moment(actionPlan?.deadline));
		form.setFieldValue([...actionPlanFieldName, 'description'], actionPlan?.description);
		form.setFieldValue([...actionPlanFieldName, 'title'], actionPlan?.title);
		form.setFieldValue([...actionPlanFieldName, 'responsible_user_id'], actionPlan?.responsible_user_id);
	}, [actionPlan]);

	if (levelRisk) {
		form.setFieldValue([...stepKeyFieldName, 'score'], levelRisk.score);
		form.setFieldValue([...stepKeyFieldName, 'result'], levelRisk.result);
	}

	function mapStepKeyDropdownOptions(options: DropdownOptions): AntdDropdownOption[] {
		return options.map(({ id, description }) => ({
			value: id,
			label: I18n.get(description)
		}));
	}

	const actionPlans = {
		errorGettingActionPlan,
		gettingActionPlan: gettingActionPlan || fetchingActionPlan,
		actionPlan: errorGettingActionPlan ? undefined : actionPlan
	};

	const states: States = {
		stepId,
		stepKeyFieldName,
		stepKeyId: stepKey.id,
		checked: stepKey.checked,
		actionPlanData: actionPlans,
		hasInjury: stepKey.has_injury,
		ergonomicTools: stepKey.ergonomic_tools,
		stepKeySettingId: stepKey.custom_report_step_key_setting_id,
		riskLevelData: {
			levelRisk,
			errorCalculatingRisk,
			gettingRisk: gettingLevelRisk || fetchingLevelRisk
		}
	};

	const methods: Methods = {
		mapErgonomicTools,
		mapStepKeyDropdownOptions
	};

	const context: Context = {
		...states,
		...methods
	};

	return (
		<PersonalisedReportStepKeyContext.Provider value={context}>
			{children}
		</PersonalisedReportStepKeyContext.Provider>
	);
}

export function usePersonalisedReportStepKeyContext() {
	const context = useContext(PersonalisedReportStepKeyContext);
	return context;
}
