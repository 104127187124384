import React from 'react';
import { Col, Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { FormItem } from '../../../components/FormItem';
import { useListCompanies } from '@/hooks/v1/useListCompanies';
import { CompanySelect } from '@/components/ui/Inputs/CompanySelect';
import { useApplicationContext } from '@/context/Application';

const { useFormInstance, useWatch } = Form;

export const Companies: React.FC = () => {
	const form = useFormInstance();
	const { organization, company } = useApplicationContext();

	const fieldName = ['basic_information', 'company_id'];

	const companyId = useWatch(fieldName, form);
	const { data: companies, isFetching } = useListCompanies({
		organization_id: organization?.id,
		company_id: company?.id
	});

	function handleCompanySelect(id: string): void {
		form.setFieldValue(fieldName, id);
		clearHierarchy();
	}

	function handleClearCompany(): void {
		form.setFieldValue(fieldName, undefined);
		clearHierarchy();
	}

	function clearHierarchy(): void {
		form.setFieldValue(['basic_information', 'sector_id'], undefined);
		form.setFieldValue(['basic_information', 'workstation_id'], undefined);
	}

	return (
		<Col xs={24}>
			<FormItem
				fontSize="1rem"
				isRequired={true}
				namePath={fieldName}
				title="Industrial site"
				errorMessage={I18n.get('Select a company')}
			>
				<CompanySelect
					size="large"
					disabled={true}
					loading={isFetching}
					companies={companies}
					companyId={companyId ?? company?.id}
					handleClearCompany={handleClearCompany}
					handleSelectCompany={handleCompanySelect}
				/>
			</FormItem>
		</Col>
	);
};
